import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    selectContainer: {
      '& .MuiSelect-root': {
        minWidth: 'auto',
      },
    },
  }),
);
