import { FC } from 'react';
import { FormLabel, Checkbox, FormHelperText, FormControlLabel, FormControl, FormGroup } from '@material-ui/core';

import { FieldProps } from 'formik/dist/Field';
import useStyles from './CustomCheckGroupField.styles';
import { CustomLabel } from '../CustomLabel';

interface CustomCheckFieldProps extends FieldProps {
  title: string;
  options: { label: string; value: string }[];
  tooltipMessage?: string;
  value: string[];
  onChange: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
}

const CustomCheckField: FC<CustomCheckFieldProps> = ({
  form,
  field,
  title,
  options,
  tooltipMessage,
  value,
  onChange,
}) => {
  const classes = useStyles();

  const hasError = form.touched[field.name] && !!form.errors[field.name];

  return (
    <FormControl>
      <FormLabel component={() => <CustomLabel title={title} tooltipMessage={tooltipMessage} />} />

      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={`${option.value}`}
            value={option.value}
            control={
              <Checkbox
                checked={value.includes(option.value)}
                onChange={() => onChange(option.value)}
                color="primary"
              />
            }
            label={option.label}
            className={classes.controlLabel}
          />
        ))}
      </FormGroup>

      {hasError && <FormHelperText error>{form.errors[field.name]}</FormHelperText>}
    </FormControl>
  );
};

export default CustomCheckField;
