import { FC } from 'react';
// eslint-disable-next-line import/no-cycle
import { CustomNumberField } from 'components/inputs';
import { useApplications } from 'store/applications/hooks';
import { mapAppRegionToCurrencySymbol } from 'core/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AmountNumberInput: FC = (props: any) => {
  const { applicationDetails } = useApplications();

  return (
    <CustomNumberField
      {...props}
      prefix={mapAppRegionToCurrencySymbol(applicationDetails?.region)}
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

export default AmountNumberInput;
