import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { AccountBalanceWallet } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { ApplicationRegion } from 'store/applications/types';
import useStyles from '../LeadDetails/LeadDetails.styles';
import { DataList } from '../DataList';

interface FundingInfoProps {
  fundingData: {
    funding_amount?: number;
    funding_reasons?: string | never[];
    funding_date?: Date;
    credit_limit?: number;
    credit_score_check_consent_given_at?: Date;
  };
  appRegion: ApplicationRegion;
}

const FundingInfo: FC<FundingInfoProps> = ({ fundingData, appRegion }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.sectionWithIconContainer}>
        <Typography className={classes.sectionTitle}>{t('pages.lead.sections.funding.title')}</Typography>
        <AccountBalanceWallet className={classes.sectionIcon} />
      </Box>

      <DataList entity={fundingData} translationKey="funding" appRegion={appRegion} />
    </>
  );
};

export default FundingInfo;
