import { FC, forwardRef, SyntheticEvent } from 'react';
import { Snackbar, SnackbarOrigin } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useTranslation } from 'react-i18next';

import useStyles from './AlertMessage.styles';
import { AlertMessageStatus } from '../../../core/types';

const Alert = forwardRef((props: AlertProps, ref) => <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />);

interface AlertMessageProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  message?: string;
  status?: AlertMessageStatus;
  ignoreClickAway?: boolean;
  autoHideDuration?: number | null;
  anchorOrigin?: SnackbarOrigin;
}

const AlertMessage: FC<AlertMessageProps> = ({
  open,
  title,
  message,
  onClose,
  anchorOrigin,
  status = AlertMessageStatus.ERROR,
  ignoreClickAway = true,
  autoHideDuration = 10000,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway' && ignoreClickAway) return;
    if (onClose) onClose();
  };

  const getAlertMessage = () => {
    if (message) return message;
    if (status === AlertMessageStatus.SUCCESS) {
      return t('global.success');
    }
    return t('global.error');
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose && handleClose}
      className={classes.container}
      anchorOrigin={anchorOrigin}
    >
      <Alert onClose={onClose && handleClose} severity={status}>
        {title && <AlertTitle className={classes.title}>{title}</AlertTitle>}
        <span className={classes.message}>{getAlertMessage()}</span>
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
