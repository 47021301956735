import { FC, useMemo, useEffect } from 'react';
import memoize from 'lodash.memoize';
import debounce from 'lodash.debounce';
import * as yup from 'yup';
import i18n from 'i18next';
import { useFormikContext } from 'formik';

import { checkForActiveApplication as checkForActiveApplicationApi } from 'http/applications';
import { ApplicationRegion } from 'store/applications/types';
import CustomNumberFormat from 'components/inputs/CustomNumberField/CustomNumberField';

export const SEARCH_DEBOUNCE_DELAY = 500; // miliseconds

export const VALIDATION_DEBOUNCE_DELAY = 500; // miliseconds

export const USA_PHONE_NUMBER_REGEX = /^(\d{3})-(\d{3})-(\d{4})$/;

export const IRL_PHONE_NUMBER_REGEX = /^(\d{3})-(\d{3})-(\d{2,4})$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PhoneNumberInput: FC = (props: any) => (
  <CustomNumberFormat {...props} thousandSeparator={false} format="###-###-####" type="tel" useFormattedValue />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EINNumberInput: FC = (props: any) => (
  <CustomNumberFormat {...props} thousandSeparator={false} format="##-#######" type="tel" useFormattedValue />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const memoizeResolver = (...args: any[]) => {
  return `${args[0]}-${args[1]?.parent?.partner_id}`;
};

const validateCompanyNumber = memoize(async (value: string | undefined, ctx: yup.TestContext) => {
  const partnerId = ctx.parent?.partner_id;
  if (!value || !partnerId) return true;
  try {
    await checkForActiveApplicationApi({ registered_number: value, partner_id: partnerId });
    return true;
  } catch (error) {
    return false;
  }
}, memoizeResolver);

export const formSchema = yup.object({
  partner_id: yup.string().required(i18n.t('pages.dashboard.addAdminActions.inputs.partner_id.required')),
  email: yup
    .string()
    .email(i18n.t('pages.dashboard.addAdminActions.inputs.email.error'))
    .required(i18n.t('pages.dashboard.addAdminActions.inputs.email.required')),
  registered_name: yup.string().required(i18n.t('pages.dashboard.addAdminActions.inputs.registered_name.required')),
  director_id: yup
    .string()
    .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
      region === ApplicationRegion.UK
        ? schema.required(i18n.t('pages.dashboard.addAdminActions.inputs.director.required'))
        : schema.notRequired(),
    ),
  first_name: yup.string().required(i18n.t('pages.dashboard.addAdminActions.inputs.first_name.required')),
  last_name: yup.string().required(i18n.t('pages.dashboard.addAdminActions.inputs.last_name.required')),
  formation_date: yup.string().required(i18n.t('pages.dashboard.addAdminActions.inputs.formation_date.required')),
  registered_number: yup
    .string()
    .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
      region !== ApplicationRegion.USA
        ? schema
            .required(i18n.t('pages.dashboard.addAdminActions.inputs.registered_number.required'))
            .test(
              'isCompanyAvailable',
              i18n.t('pages.dashboard.addAdminActions.inputs.registered_number.error'),
              validateCompanyNumber,
            )
        : schema.notRequired(),
    ),
  company_status: yup
    .string()
    .equals(['active'], i18n.t('pages.dashboard.addAdminActions.inputs.company_status.error')),
  region: yup
    .string()
    .oneOf(Object.values(ApplicationRegion))
    .required(i18n.t('pages.dashboard.addAdminActions.inputs.region.required')),
  phone_number: yup
    .string()
    .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
      region === ApplicationRegion.UK
        ? schema.notRequired()
        : schema
            .matches(
              region === ApplicationRegion.USA ? USA_PHONE_NUMBER_REGEX : IRL_PHONE_NUMBER_REGEX,
              i18n.t('pages.dashboard.addAdminActions.inputs.phone_number.error'),
            )
            .required(i18n.t('pages.dashboard.addAdminActions.inputs.phone_number.required')),
    ),
  ein: yup
    .string()
    .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
      region !== ApplicationRegion.USA
        ? schema.notRequired()
        : schema
            .min(9, i18n.t('pages.dashboard.addAdminActions.inputs.ein.error'))
            .required(i18n.t('pages.dashboard.addAdminActions.inputs.ein.required')),
    ),
});

export const FormValidation: FC = () => {
  const { values, validateForm } = useFormikContext();

  const debouncedValidate = useMemo(() => debounce(validateForm, VALIDATION_DEBOUNCE_DELAY), [validateForm]);

  useEffect(() => {
    debouncedValidate();
  }, [values, debouncedValidate]);

  return null;
};
