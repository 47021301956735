import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    detailItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  }),
);
