import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      marginBottom: 12,
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    editor: {
      marginTop: theme.spacing(1),
      border: `1px solid ${theme.palette.greyLightBorder.main}`,
      borderRadius: 4,
      height: 500,
      width: '100%',
      maxWidth: 960,
      '& .ql-container.ql-snow': {
        border: 'none',
        height: 457,
        '& h2': {
          fontSize: theme.typography.pxToRem(20),
          lineHeight: theme.typography.pxToRem(28),
          fontWeight: 700,
        },
        '& h3': {
          fontSize: theme.typography.pxToRem(18),
          lineHeight: theme.typography.pxToRem(24),
          fontWeight: 700,
        },
        '& h4': {
          fontSize: theme.typography.pxToRem(16),
          lineHeight: theme.typography.pxToRem(22),
          fontWeight: 700,
        },
        '& p': {
          fontSize: theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(20),
          fontFamily: theme.typography.fontFamily,
        },
        '& .ql-tooltip': {
          left: '5px !important',
        },
      },
      '& .ql-toolbar.ql-snow': {
        border: 'none',
        borderBottom: `1px solid ${theme.palette.greyLightBorder.main}`,
        '& .ql-formats': {
          marginRight: 5,
        },
      },
    },
    editorError: {
      borderColor: `${theme.palette.error.main} !important`,
    },
    cancelButton: {
      width: 75,
      marginRight: 8,
    },
    faqsContent: {
      marginTop: theme.spacing(1),
      width: '100%',
      maxWidth: 960,
      '& *': {
        margin: '4px 0',
      },
      '& h2': {
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(28),
        fontWeight: 700,
      },
      '& h3': {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 700,
      },
      '& h4': {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(22),
        fontWeight: 700,
      },
      '& p': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontFamily: theme.typography.fontFamily,
      },
    },
  }),
);
