import { FC, useRef, useMemo, ChangeEvent } from 'react';
import { Grid, FormLabel, FormControlLabel, Switch, Box, Chip, FormHelperText } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import pickBy from 'lodash.pickby';

import { AlertDialog } from 'components/AlertDialog';
import { useLenders } from 'store/lenders/hooks';
import { CustomTextField, CustomLabel, CustomSelect } from 'components/inputs';
import { CreateLender, LenderDetails } from 'store/lenders/types';
import { DocumentUpload } from 'components/DocumentUpload';
import { updateLenderLogo } from 'http/lenders';
import { Document, DocumentUploadResponse } from 'core/types';
import { ApplicationRegion } from 'store/applications/types';
import { accountCountryOptions, accountEmployeesOptions, accountIndustryOptions, websiteRegExp } from 'core/constants';
import useStyles from './CreateLenderModal.styles';

interface FormValues {
  name: string;
  displayed_name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  active: boolean;
  address: string;
  logo_url: string;
  website: string;
  supported_regions: string[];
  phone_number: string;
  job_title: string;
  country_code: string;
  industry: string;
  employees: string;
}

interface CreateLenderModalProps {
  open: boolean;
  toggleOpen: () => void;
  lender?: LenderDetails;
}

const CreateLenderModal: FC<CreateLenderModalProps> = ({ open, toggleOpen, lender }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, createLender, updateLender, setLenderLogo } = useLenders(() => {
    if (open) {
      formRef.current?.resetForm();
      toggleOpen();
    }
  });

  const lenderSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required(t('pages.lenders.admin.createLender.inputs.name.required')).nullable(),
        displayed_name: yup
          .string()
          .required(t('pages.lenders.admin.createLender.inputs.displayed_name.required'))
          .nullable(),
        contact_first_name: yup
          .string()
          .required(t('pages.lenders.admin.createLender.inputs.contact_first_name.required'))
          .nullable(),
        contact_last_name: yup
          .string()
          .required(t('pages.lenders.admin.createLender.inputs.contact_last_name.required'))
          .nullable(),
        contact_email: yup
          .string()
          .email(t('pages.lenders.admin.createLender.inputs.contact_email.error'))
          .required(t('pages.lenders.admin.createLender.inputs.contact_email.required'))
          .nullable(),
        active: yup.boolean(),
        logo_url: yup.string().url(t('pages.lenders.admin.createLender.inputs.logo_url.error')),
        website: yup.string().matches(websiteRegExp, t('pages.lenders.admin.createLender.inputs.website.error')),
        address: yup.string(),
        supported_regions: yup
          .array(yup.string())
          .min(1, t('pages.lenders.admin.createLender.inputs.supported_regions.required')),
        phone_number: yup.string(),
        job_title: yup.string(),
        country_code: yup.string().required(t('pages.lenders.admin.createLender.inputs.country_code.required')),
        industry: yup.string(),
        employees: yup.string(),
      }),
    [t],
  );

  const initialValues: FormValues = {
    name: lender?.name ?? '',
    displayed_name: lender?.displayed_name ?? '',
    contact_first_name: lender?.contact_person_name?.split(' ')[0] ?? '',
    contact_last_name: lender?.contact_person_name?.split(' ')[1] ?? '',
    contact_email: lender?.contact_person_email ?? '',
    active: lender?.active ?? false,
    address: lender?.address ?? '',
    logo_url: lender?.logo_url ?? '',
    website: lender?.website ?? '',
    supported_regions: lender?.supported_regions ?? [],
    phone_number: lender?.phone_number ?? '',
    job_title: lender?.job_title ?? '',
    country_code: lender?.country_code ?? '',
    industry: lender?.industry ?? '',
    employees: lender?.employees ?? '',
  };

  const onSubmit = (values: FormValues) => {
    const lenderData = { ...pickBy(values), active: values.active } as CreateLender;

    if (lender?.id) {
      updateLender({ id: lender.id, lender: lenderData });
    } else {
      createLender(lenderData);
    }
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  const uploadDocument = async (data: FormData): Promise<DocumentUploadResponse | null> => {
    if (lender) {
      return updateLenderLogo(lender.id, data);
    }
    return null;
  };

  const onUploadSuccess = (document: Document) => {
    if (lender && document.url) {
      setLenderLogo({ lenderId: lender.id, logoUrl: document.url });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={lenderSchema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
        <AlertDialog
          open={open}
          dialogContentTitle={
            lender ? t('pages.lender.admin.updateLender.title') : t('pages.lenders.admin.createLender.title')
          }
          handleCancel={onCancel}
          handleConfirm={handleSubmit}
          confirmButtonTitle={
            lender
              ? t('pages.lender.admin.updateLender.buttons.submit')
              : t('pages.lenders.admin.createLender.buttons.submit')
          }
          loading={loading}
        >
          <Form noValidate>
            <Grid container direction="column" spacing={4} className={classes.container}>
              <Grid item>
                <Field
                  autoFocus
                  id="name"
                  fullWidth
                  component={CustomTextField}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  title={t('pages.lenders.admin.createLender.inputs.name.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="displayed_name"
                  fullWidth
                  component={CustomTextField}
                  name="displayed_name"
                  value={values.displayed_name}
                  onChange={handleChange}
                  title={t('pages.lenders.admin.createLender.inputs.displayed_name.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="contact_first_name"
                  fullWidth
                  component={CustomTextField}
                  name="contact_first_name"
                  value={values.contact_first_name}
                  onChange={handleChange}
                  title={t('pages.lenders.admin.createLender.inputs.contact_first_name.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="contact_last_name"
                  fullWidth
                  component={CustomTextField}
                  name="contact_last_name"
                  value={values.contact_last_name}
                  onChange={handleChange}
                  title={t('pages.lenders.admin.createLender.inputs.contact_last_name.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="contact_email"
                  fullWidth
                  component={CustomTextField}
                  name="contact_email"
                  value={values.contact_email}
                  onChange={handleChange}
                  title={t('pages.lenders.admin.createLender.inputs.contact_email.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="country_code"
                  fullWidth
                  component={CustomSelect}
                  options={accountCountryOptions}
                  name="country_code"
                  value={values.country_code}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                    setFieldValue('country_code', event.target.value)
                  }
                  title={t('pages.lenders.admin.createLender.inputs.country_code.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <CustomLabel title={t('pages.lenders.admin.createLender.inputs.supported_regions.label')} />

                <Box className={classes.supportedRegionsContainer}>
                  {Object.values(ApplicationRegion).map((region) => {
                    const alreadySelected = values.supported_regions.find((v) => v === region);
                    return (
                      <Chip
                        id={region}
                        key={region}
                        color={alreadySelected ? 'primary' : 'default'}
                        label={region}
                        onClick={() => {
                          if (alreadySelected) {
                            setFieldValue(
                              'supported_regions',
                              values.supported_regions.filter((v) => v !== region),
                            );
                          } else {
                            setFieldValue('supported_regions', [...values.supported_regions, region]);
                          }
                        }}
                        className={classes.supportedRegionOption}
                      />
                    );
                  })}
                </Box>

                {touched.supported_regions && errors.supported_regions && (
                  <FormHelperText error>{errors.supported_regions}</FormHelperText>
                )}
              </Grid>

              {!lender && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                          setFieldValue('active', checked)
                        }
                        checked={values.active}
                      />
                    }
                    label={values.active ? t('global.states.enabled') : t('global.states.disabled')}
                    className={classes.switchLabel}
                  />
                </Grid>
              )}

              <Grid item>
                <Field
                  id="logo_url"
                  fullWidth
                  component={CustomTextField}
                  name="logo_url"
                  value={values.logo_url}
                  onChange={handleChange}
                  title={t('pages.lenders.admin.createLender.inputs.logo_url.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="website"
                  fullWidth
                  component={CustomTextField}
                  name="website"
                  value={values.website}
                  onChange={handleChange}
                  title={t('pages.lenders.admin.createLender.inputs.website.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="address"
                  fullWidth
                  component={CustomTextField}
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  title={t('pages.lenders.admin.createLender.inputs.address.label')}
                  className={classes.textInput}
                />
              </Grid>

              {lender && (
                <Grid item className={classes.documentUploadContainer}>
                  <FormLabel component={() => <CustomLabel title="Logo" />} />

                  <DocumentUpload uploadDocument={uploadDocument} onUploadSuccess={onUploadSuccess} />
                </Grid>
              )}

              <Grid item>
                <Field
                  id="phone_number"
                  fullWidth
                  component={CustomTextField}
                  name="phone_number"
                  value={values.phone_number}
                  onChange={handleChange}
                  title={t('pages.lenders.admin.createLender.inputs.phone_number.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="job_title"
                  fullWidth
                  component={CustomTextField}
                  name="job_title"
                  value={values.job_title}
                  onChange={handleChange}
                  title={t('pages.lenders.admin.createLender.inputs.job_title.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="industry"
                  fullWidth
                  component={CustomSelect}
                  options={accountIndustryOptions}
                  name="industry"
                  value={values.industry}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('industry', event.target.value)}
                  title={t('pages.lenders.admin.createLender.inputs.industry.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="employees"
                  fullWidth
                  component={CustomSelect}
                  options={accountEmployeesOptions}
                  name="employees"
                  value={values.employees}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('employees', event.target.value)}
                  title={t('pages.lenders.admin.createLender.inputs.employees.label')}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>
          </Form>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default CreateLenderModal;
