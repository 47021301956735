import { FC, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { CSVDownloader } from 'react-papaparse';
import { useTranslation } from 'react-i18next';
import { useLenders } from 'store/lenders/hooks';

import { CreateLenderModal } from '../CreateLenderModal';
import { UploadLendersModal } from '../UploadLendersModal';
import useStyles from './LenderAdminActions.styles';

type Lender = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const LenderAdminActions: FC = () => {
  const [createOpen, setCreateOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);

  const { lenders } = useLenders();

  const { t } = useTranslation();
  const classes = useStyles();

  const toggleCreateOpen = () => setCreateOpen((prevState) => !prevState);
  const toggleUploadOpen = () => setUploadOpen((prevState) => !prevState);

  const transformedLenders = lenders.map((lender: Lender) => {
    const transformedLender: Lender = {};
    Object.keys(lender).forEach((key) => {
      const transformedHeader = key.replace(/([A-Z])/g, ' $1');
      const formattedHeader = transformedHeader.charAt(0).toUpperCase() + transformedHeader.slice(1);
      transformedLender[formattedHeader] = lender[key as keyof Lender]; // Use type assertion here
    });
    return transformedLender;
  });

  return (
    <Box className={classes.actionContainer}>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={toggleCreateOpen}
        className={classes.lenderActionButton}
      >
        {t('pages.lenders.admin.actions.create')}
      </Button>

      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={toggleUploadOpen}
        className={classes.lenderActionButton}
      >
        {t('pages.lenders.admin.actions.upload')}
      </Button>

      <Button variant="outlined" color="primary" className={classes.lenderActionButton} disableElevation>
        <CSVDownloader
          filename="BScore_Lenders"
          data={transformedLenders}
          config={{
            header: true,
            delimiter: ',',
          }}
          bom
        >
          {t('pages.lenders.admin.actions.download')}
        </CSVDownloader>
      </Button>

      <CreateLenderModal open={createOpen} toggleOpen={toggleCreateOpen} />

      <UploadLendersModal open={uploadOpen} toggleOpen={toggleUploadOpen} />
    </Box>
  );
};

export default LenderAdminActions;
