import { FC, useState } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CreatePartnerModal } from '../CreatePartnerModal';

const PartnerAdminActions: FC = () => {
  const [createOpen, setCreateOpen] = useState(false);
  const { t } = useTranslation();

  const toggleCreateOpen = () => setCreateOpen((prevState) => !prevState);

  return (
    <>
      <Button variant="contained" color="primary" disableElevation onClick={toggleCreateOpen}>
        {t('pages.partners.admin.actions.create')}
      </Button>

      <CreatePartnerModal open={createOpen} toggleOpen={toggleCreateOpen} />
    </>
  );
};

export default PartnerAdminActions;
