import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Fade, Grid, List, ListItem, Typography } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import { AccordionPanel } from 'components/AccordionPanel';
import { ApplicationNoteType } from 'core/types';
import { ApplicationDetails, ApplicationRegion, fundingReasonLabels, OfferStatus } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { getClearBitLogo } from 'store/applications/utils';
// eslint-disable-next-line import/no-cycle
import { AdminActions, EmailList } from '..';
import { ActionLead } from '../ActionLead';
import { ApplicationNotes } from '../ApplicationNotes';
import { CompanyDocuments } from '../CompanyDocuments';
import { CompanyInfo } from '../CompanyInfo';
import { CompanyProfile } from '../CompanyProfile';
import { DirectorInfo } from '../DirectorInfo';
import { FinancialInfo } from '../FinancialInfo';
import { FundingInfo } from '../FundingInfo';
import { Rejection } from '../Rejection';
import useStyles from './LeadDetails.styles';
import { ReceivedEmailList } from '../ReceivedEmailList';

interface LeadDetailsProps {
  details: ApplicationDetails | null;
  selectedOfferId?: string;
  setSelectedOfferId: Dispatch<SetStateAction<string | undefined>>;
  showAdminActions: boolean | null;
  children?: React.ReactNode;
}

const LeadDetails: FC<LeadDetailsProps> = ({
  details,
  selectedOfferId,
  setSelectedOfferId,
  showAdminActions,
  children,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isAdmin, isLender, isCompanyOwner, isPartner } = useAuth();

  if (!details) {
    return null;
  }

  const {
    company,
    funding_amount,
    funding_reasons,
    funding_date,
    credit_limit,
    credit_score_check_consent_given_at,
    status,
    created_at,
    id,
    internal_notes,
    external_notes,
    partner,
    application_rejection,
    region,
    offers,
  } = details;

  const hideAddress = isLender && !offers.some((offer) => offer.status === OfferStatus.ACCEPTED);

  const applicant = company.people.find((p) => p.applicant);
  const directors = company.people.filter((p) => !p.applicant);

  const fundingReasons = funding_reasons ? funding_reasons.map((reason) => fundingReasonLabels[reason]).join(', ') : '';

  const fundingData = {
    funding_amount,
    funding_reasons: fundingReasons,
    funding_date,
    credit_limit,
    credit_score_check_consent_given_at,
  };

  const accordions = [
    {
      title: t('pages.lead.sections.header.title'),
      subtitle: t('pages.lead.sections.header.subtitle'),
      content: (
        <ActionLead
          company={company}
          fundingAmount={funding_amount}
          status={status}
          createdAt={created_at}
          partnerName={partner?.name}
        />
      ),
    },
    {
      title: isAdmin ? t('pages.lead.sections.documents.upload') : t('pages.lead.sections.documents.title'),
      subtitle: t('pages.lead.sections.documents.viewUploadedDocuments'),
      content: <CompanyDocuments company={company} appId={id} />,
    },
    ...(!(isLender || isCompanyOwner)
      ? [
          {
            title: t('pages.lead.sections.notes.header'),
            subtitle: t('pages.lead.sections.notes.subHeader'),
            content: (
              <List>
                {isAdmin && (
                  <ListItem>
                    <ApplicationNotes
                      type={ApplicationNoteType.INTERNAL}
                      applicationId={id}
                      initialNotes={internal_notes}
                    />
                  </ListItem>
                )}
                <ListItem>
                  <ApplicationNotes
                    type={ApplicationNoteType.EXTERNAL}
                    applicationId={id}
                    initialNotes={external_notes}
                  />
                </ListItem>
              </List>
            ),
          },
        ]
      : []),
    ...(showAdminActions
      ? [
          {
            title: t('pages.lead.admin.actions.title'),
            subtitle: t('pages.lead.admin.actions.subtitle'),
            content: <AdminActions selectedOfferId={selectedOfferId} setSelectedOfferId={setSelectedOfferId} />,
          },
        ]
      : []),
  ];

  return (
    <>
      <Grid item container xs={12} sm={4} style={{ paddingRight: 10 }}>
        <Fade in>
          <Box>
            <Typography className={classes.sectionTitle}>{t('pages.lead.sections.actions.title')}</Typography>

            {accordions.map(({ title, subtitle, content }) => (
              <AccordionPanel key={title} title={title} subtitle={subtitle} content={content} initialExpanded />
            ))}
          </Box>
        </Fade>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Fade in>
          <Grid item container spacing={2} className={classes.lightBg}>
            <Grid item xs={6}>
              <Typography variant="h5" gutterBottom>
                {t('pages.lead.title')}
              </Typography>
              <Typography variant="body2">
                {t('pages.lead.description', { companyName: details?.company.registered_name })}
              </Typography>
            </Grid>
            <Grid item container xs={6} justify="flex-end">
              <Avatar
                alt={company?.website || company?.registered_name}
                src={getClearBitLogo(company.website)}
                className={classes.leadAvatar}
              />
            </Grid>
            {applicant && (
              <Grid item xs={12} md={4}>
                <DirectorInfo
                  companyPerson={applicant}
                  appRegion={region}
                  hideAddress={hideAddress}
                  maskedEmail={details.masked_email}
                />
              </Grid>
            )}
            {company && (
              <Grid item xs={12} md={4}>
                <CompanyInfo company={company} appRegion={region} />
              </Grid>
            )}
            {company.financial_information && (
              <Grid item xs={12} md={4}>
                <FinancialInfo financialInfo={company.financial_information} appRegion={region} />

                {funding_amount && <FundingInfo fundingData={fundingData} appRegion={region} />}
              </Grid>
            )}
            {directors.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Box className={classes.sectionWithIconContainer}>
                    <Typography className={classes.sectionTitle}>{t('pages.lead.sections.directors.title')}</Typography>
                    <PeopleIcon className={classes.sectionIcon} />
                  </Box>
                </Grid>

                {directors.map((director) => (
                  <Grid item xs={12} md={4} key={director.id}>
                    <DirectorInfo companyPerson={director} withTitle={false} appRegion={region} />
                  </Grid>
                ))}
              </>
            )}
            {isAdmin && region === ApplicationRegion.UK && (
              <Grid item xs={12}>
                <CompanyProfile company={company} applicationId={id} />
              </Grid>
            )}
            {(isAdmin || isPartner) && (
              <Grid item xs={12}>
                <EmailList applicationId={details.id} companyId={details.company.id} />
              </Grid>
            )}
            {(isAdmin || isPartner) && (
              <Grid item xs={12}>
                <ReceivedEmailList applicationId={details.id} companyId={details.company.id} />
              </Grid>
            )}
            {(isAdmin || isPartner) && application_rejection && (
              <Grid item xs={6}>
                <Rejection rejection={application_rejection} isPartner={isPartner} appRegion={region} />
              </Grid>
            )}
            {children && children}
          </Grid>
        </Fade>
      </Grid>
    </>
  );
};

export default LeadDetails;
