import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ListItem, ListItemText } from '@material-ui/core';
import { Launch } from '@material-ui/icons';

import { LoadingButton } from 'components/buttons';
import { useWidget } from 'hooks';
import { applicationAuth as applicationAuthApi } from 'http/applications';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { useAuth } from 'store/auth/hooks';
import routes from 'router/routes';

import useLeadStyles from '../LeadDetails/LeadDetails.styles';
import useStyles from './WidgetMode.styles';

const WidgetMode: FC = () => {
  const [loading, setLoading] = useState(false);
  const leadClasses = useLeadStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { applicationDetails, getApplicationDetails } = useApplications();
  const { setError } = useAdmin();
  const { isAdmin, isCompanyOwner } = useAuth();
  const { openWidget } = useWidget();
  const history = useHistory();

  const onClose = () => {
    if (isCompanyOwner) history.push(routes.dashboard);

    if (applicationDetails?.id) getApplicationDetails(applicationDetails.id);
  };

  const openWidgetAsAdmin = async () => {
    const partner = applicationDetails?.partner;
    const applicant = applicationDetails?.company.people.find((p) => p.applicant);

    if (!applicationDetails || !partner?.api_keys.public_key || !applicant) return;

    setLoading(true);
    try {
      const accessToken = await applicationAuthApi(partner.id, applicationDetails?.company.id, applicant.email);

      openWidget(partner.api_keys.public_key, accessToken, onClose);
    } catch (error) {
      setError((error as Error).message ?? true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isCompanyOwner) {
      openWidgetAsAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListItem className={leadClasses.listItem}>
      <ListItemText className={leadClasses.listItemTitle}>
        {t(`pages.lead.sections.header.${isAdmin ? 'widgetMode' : 'editApp'}`)}
      </ListItemText>
      <LoadingButton
        variant="contained"
        color="primary"
        disableElevation
        containerClassName={classes.leadActionButton}
        onClick={openWidgetAsAdmin}
        loading={loading}
        startIcon={<Launch />}
      >
        {t(`pages.lead.sections.header.${isAdmin ? 'launch' : 'edit'}`)}
      </LoadingButton>
    </ListItem>
  );
};

export default WidgetMode;
