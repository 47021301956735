import { ChangeEvent, FC } from 'react';
import { Box, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { CustomLabel, CustomNumberField } from 'components/inputs';
import useCommonStyles from 'core/styles';
import { CurrencySymbol } from 'core/types';
import { AmountNumberInput } from '../AmountNumberInput';
import useStyles from './RangeNumberInput.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NumberInput: FC = (props: any) => <CustomNumberField {...props} decimalScale={0} />;

interface RangeNumberInputProps {
  label?: string;
  minValue?: number;
  maxValue?: number;
  onChangeMinValue: (value: number) => void;
  onChangeMaxValue: (value: number) => void;
  withPrefix?: boolean;
}

const RangeNumberInput: FC<RangeNumberInputProps> = ({
  label,
  minValue,
  maxValue,
  onChangeMinValue,
  onChangeMaxValue,
  withPrefix,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const onMinChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (value === '') return;
    onChangeMinValue(Number(value));
  };

  const onMaxChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (value === '') return;
    onChangeMaxValue(Number(value));
  };

  return (
    <Box>
      {label && <CustomLabel title={label} labelClassName={classes.inputLabel} />}

      <Box className={classes.inputsContainer}>
        <CustomLabel title={`${CurrencySymbol.GBP} ${t('global.min')}`} labelClassName={classes.inputLabel} />

        <TextField
          type="text"
          placeholder="0.00"
          variant="outlined"
          value={minValue ?? ''}
          className={clsx([commonClasses.textInput, classes.textInput])}
          InputProps={{ inputComponent: withPrefix ? AmountNumberInput : NumberInput }}
          onChange={onMinChange}
        />

        <CustomLabel title={`${CurrencySymbol.GBP} ${t('global.max')}`} labelClassName={classes.inputLabel} />

        <TextField
          type="text"
          placeholder="4000.00"
          variant="outlined"
          value={maxValue ?? ''}
          className={clsx([commonClasses.textInput, classes.textInput])}
          InputProps={{ inputComponent: withPrefix ? AmountNumberInput : NumberInput }}
          onChange={onMaxChange}
        />
      </Box>
    </Box>
  );
};

export default RangeNumberInput;
