import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth/reducers';
import accountReducer from './account/reducers';
import globalReducer from './global/reducers';
import adminReducer from './admin/reducers';
import applicationsReducer from './applications/reducers';
import lendersReducer from './lenders/reducers';
import partnersReducer from './partners/reducers';
import remindersReducer from './reminders/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  global: globalReducer,
  admin: adminReducer,
  applications: applicationsReducer,
  lenders: lendersReducer,
  partners: partnersReducer,
  reminders: remindersReducer,
});

export default rootReducer;
