import { Box, Typography } from '@material-ui/core';
import { Money as FinancialInfoIcon } from '@material-ui/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationRegion, CompanyFinancialInformation } from 'store/applications/types';
import { DataList } from '../DataList';
import useStyles from '../LeadDetails/LeadDetails.styles';

interface FinancialInfoProps {
  financialInfo: CompanyFinancialInformation;
  appRegion: ApplicationRegion;
}

const FinancialInfo: FC<FinancialInfoProps> = ({ financialInfo, appRegion }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { company_id, ...financialData } = financialInfo;

  return (
    <>
      <Box className={classes.sectionWithIconContainer}>
        <Typography className={classes.sectionTitle}>{t('pages.lead.sections.financial.title')} </Typography>
        <FinancialInfoIcon className={classes.sectionIcon} />
      </Box>

      <DataList entity={financialData} translationKey="financial" appRegion={appRegion} />
    </>
  );
};

export default FinancialInfo;
