import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
    },
    circularProgress: {
      position: 'absolute',
      top: 10,
      left: 'calc(50% - 12.5px)',
    },
  }),
);
