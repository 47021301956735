import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useTabsStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    minHeight: 'auto',
    flexGrow: 1,
    width: '100%',
  },
});

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.typography.pxToRem(17),
      lineHeight: theme.typography.pxToRem(16),
      marginBottom: 12,
      display: 'flex',
      alignItems: 'center',
    },
    titleAvatar: { marginRight: 5, background: theme.palette.primary.main },
    bold: {},
    cardContainer: {
      border: `1px solid ${theme.palette.greyLightBorder.main}`,
      height: 214,
    },
    cardsContainer: {
      marginBottom: theme.spacing(2),
      minHeight: 642,
      alignContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        minHeight: 856,
      },
    },
  }),
);
