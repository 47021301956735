import { FC, useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { ResponsiveContainer, LineChart, Line, Tooltip, TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { parseMoney } from 'utils';
import { EcommerceProviders } from 'core/types';
import { useCompanyValuations } from 'hooks';
import { useApplications } from 'store/applications/hooks';
import { mapAppRegionToSupportedCurrency } from 'core/utils';
import useStyles from '../chartStyles';

const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { applicationDetails } = useApplications();

  if (active && payload && payload.length) {
    const {
      payload: { credit_limit, total_sales, valuation, valuation_date },
    } = payload[0];

    return (
      <Box className={classes.tooltipContainer}>
        <Typography>{`${t('pages.lead.sections.paymentData.valuations.tooltip.date')}: ${moment(valuation_date).format(
          'MMM YYYY',
        )}`}</Typography>
        <Typography>{`${t('pages.lead.sections.paymentData.valuations.tooltip.valuation')}: ${parseMoney(
          Number(valuation),
          mapAppRegionToSupportedCurrency(applicationDetails?.region),
        )}`}</Typography>
        <Typography>{`${t('pages.lead.sections.paymentData.valuations.tooltip.totalSales')}: ${parseMoney(
          Number(total_sales),
          mapAppRegionToSupportedCurrency(applicationDetails?.region),
        )}`}</Typography>
        <Typography>{`${t('pages.lead.sections.paymentData.valuations.tooltip.creditLimit')}: ${parseMoney(
          Number(credit_limit),
          mapAppRegionToSupportedCurrency(applicationDetails?.region),
        )}`}</Typography>
      </Box>
    );
  }

  return null;
};

interface ValuationsProps {
  companyId: string;
  provider: EcommerceProviders;
}

const Valuations: FC<ValuationsProps> = ({ companyId, provider }) => {
  const classes = useStyles();
  const { loading, error, valuations, getCompanyValuations } = useCompanyValuations();
  const { t } = useTranslation();

  useEffect(() => {
    getCompanyValuations(companyId, provider);
  }, [companyId, getCompanyValuations, provider]);

  const startValuation = valuations[0];
  const endValuation = valuations.slice(-1)[0];

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{t('pages.lead.sections.paymentData.valuations.title')}</Typography>

      <ResponsiveContainer width="100%" height={240}>
        <LineChart data={valuations} margin={{ bottom: 30, top: 10 }}>
          <Tooltip content={<CustomTooltip />} />

          <Line type="monotone" dataKey="valuation" stroke="#8884d8" fill="#8884d8" dot={false} />
        </LineChart>
      </ResponsiveContainer>

      {valuations.length > 0 ? (
        <Box className={classes.labelContainer}>
          <Typography className={classes.label}>{moment(startValuation?.valuation_date).format('MMM YYYY')}</Typography>
          <Typography className={classes.label}>{moment(endValuation?.valuation_date).format('MMM YYYY')}</Typography>
        </Box>
      ) : (
        <Box className={classes.absContainer}>
          <Typography className={classes.greyText}>{t('pages.lead.sections.paymentData.valuations.noData')}</Typography>
        </Box>
      )}

      {loading && (
        <Box className={classes.absContainer}>
          <CircularProgress color="primary" />
        </Box>
      )}

      {!!error && (
        <Box className={classes.absContainer}>
          <Typography className={classes.greyText}>{t('pages.lead.sections.paymentData.valuations.error')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Valuations;
