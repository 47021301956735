import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      marginBottom: 12,
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    tableContainer: {
      marginTop: theme.spacing(3),
    },
    editButton: {
      height: '30px',
      width: '30px',
    },
    editIcon: {
      fontSize: '20px',
      color: theme.palette.primary.main,
    },
  }),
);
