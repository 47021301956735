import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Tabs, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import sortBy from 'lodash.sortby';
import { usePrevious } from 'hooks';
import { StyledTab } from 'components/StyledTab';
import { BalanceTransactionsQuery } from 'store/applications/types';
import useBalanceTransactions from 'hooks/useBalanceTransactions';
import useDetailsStyles from '../LeadDetails/LeadDetails.styles';
import useStyles, { useTabsStyles } from './BalanceData.styles';
import { TransactionsTable } from './components/TransactionsTable';
import { TransactionsChart } from './components/TransactionsChart';

interface BalanceDataProps {
  companyId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bankProviders: Record<any, any[]>;
}

const BalanceData: FC<BalanceDataProps> = ({ companyId, bankProviders }) => {
  const { getBalanceTransactions, transactions, total, loading, error } = useBalanceTransactions();
  const detailsClasses = useDetailsStyles();
  const tabsClasses = useTabsStyles();
  const classes = useStyles();
  const [activePage, setActivePage] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const prevActivePage = usePrevious(activePage);
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const connectedProvidersList = Object.keys(bankProviders) as any[];

  const connectedProviders = Object.entries(bankProviders).flatMap(([bank_name, accounts]) =>
    accounts.map((account) => `${bank_name} - ${account.account_id}`),
  );

  const selectedProvider = connectedProvidersList[activeTab];

  const selectProviderId = useMemo(() => {
    return bankProviders[selectedProvider]?.[0]?.id;
  }, [bankProviders, selectedProvider]);

  const transactionsQuery: BalanceTransactionsQuery = useMemo(
    () => ({
      per_page: 10,
      page: activePage + 1,
      companyAccountId: selectProviderId,
    }),
    [activePage, selectProviderId],
  );

  const handleChange = (event: React.ChangeEvent<object>, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (prevActivePage !== undefined && prevActivePage !== activePage) {
      getBalanceTransactions(companyId, transactionsQuery);
    }
  }, [activePage, companyId, getBalanceTransactions, prevActivePage, transactionsQuery]);

  useEffect(() => {
    getBalanceTransactions(companyId, transactionsQuery);
  }, [companyId, transactionsQuery, getBalanceTransactions, selectedProvider]);

  return (
    <Box className={classes.container}>
      <Typography className={detailsClasses.sectionTitle}>{t('pages.lead.sections.balanceData.title')}</Typography>

      <Tabs
        value={activeTab}
        onChange={handleChange}
        classes={tabsClasses}
        variant="fullWidth"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
      >
        {connectedProviders.map((provider, index) => (
          <StyledTab key={provider} label={provider} value={index} />
        ))}
      </Tabs>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TransactionsChart loading={loading} error={!!error} transactions={sortBy(transactions, 'date_time')} />
        </Grid>

        <Grid item xs={12}>
          <Box marginTop="10px" marginBottom="20px">
            <Typography className={detailsClasses.sectionTitle}>
              {t('pages.lead.sections.balanceData.transactionsTable.title')}
            </Typography>
          </Box>
          <TransactionsTable
            transactions={transactions}
            total={total}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BalanceData;
