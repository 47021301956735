import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { usePrevious } from 'hooks';
import { useApplications } from 'store/applications/hooks';
import { getOffersByStatusOverview } from 'store/applications/utils';
import useMainStyles from 'core/styles';
import { useAuth } from 'store/auth/hooks';
import { Overview } from '../Overview';
import useStyles from './OffersOverview.styles';

interface OffersOverviewProps {
  fromDate: Date;
  toDate: Date;
  preview?: boolean;
}

const OffersOVerview: FC<OffersOverviewProps> = ({ fromDate, toDate, preview }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const mainClasses = useMainStyles();
  const { offersOverview, getOffersOverview } = useApplications();
  const { isLender } = useAuth();
  const prevFromDate = usePrevious(fromDate);
  const prevToDate = usePrevious(toDate);

  useEffect(() => {
    if (!isLender && (prevFromDate?.getTime() !== fromDate.getTime() || prevToDate?.getTime() !== toDate.getTime())) {
      getOffersOverview({ start: fromDate.toISOString(), end: toDate.toISOString() });
    }
  }, [fromDate, getOffersOverview, isLender, prevFromDate, prevToDate, toDate]);

  // eslint-disable-next-line @typescript-eslint/ban-types

  const parsedOffersOverview = useMemo(() => {
    return getOffersByStatusOverview(offersOverview.data, fromDate.toISOString(), toDate.toISOString());
  }, [fromDate, offersOverview.data, toDate]);

  const pieChartData = useMemo(() => {
    return parsedOffersOverview.data.map((offer) => ({
      name: offer.name,
      value: offer.total,
    }));
  }, [parsedOffersOverview]);

  const pieChartColorRange = [
    '#E58C8A',
    '#FFF07C',
    '#80FF72',
    '#7EE8FA',
    '#2c373e',
    '#EEC0C6',
    '#6B717E',
    '#EFAAC4',
    '#D4DCCD',
  ];

  return (
    <Grid item xs={12}>
      <Typography className={clsx([classes.title, classes.bold])} component="span">
        <Avatar variant="square" sizes="small" color="primary" className={classes.titleAvatar}>
          {offersOverview.total}
        </Avatar>
        {t('pages.home.offers.statusTitle')}
      </Typography>

      {preview && (
        <Box
          // className={classes.cardsContainer}
          style={{
            height: '500px',
            width: '100%',
          }}
        >
          <Paper className={mainClasses.pieChartContainer}>
            {offersOverview.total > 0 ? (
              <>
                <ResponsiveContainer width="70%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieChartData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {pieChartData.map((entry, index) => {
                        return (
                          <Cell
                            key={`cell-${entry.value}`}
                            fill={pieChartColorRange[index % pieChartColorRange.length]}
                          />
                        );
                      })}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
                <div>
                  <List>
                    {pieChartData.map((entry, index) => {
                      if (entry.value) {
                        return (
                          <ListItem key={`cell-${entry.value}`}>
                            <ListItemAvatar>
                              <Avatar
                                variant="square"
                                style={{ backgroundColor: pieChartColorRange[index % pieChartColorRange.length] }}
                              >
                                {entry.value}
                              </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                              disableTypography
                              primary={<Typography style={{ textTransform: 'lowercase' }}> {entry.name}</Typography>}
                            />
                          </ListItem>
                        );
                      }
                      return <></>;
                    })}
                  </List>
                </div>
              </>
            ) : (
              <Alert severity="warning">{t('pages.home.applications.warning')}</Alert>
            )}
          </Paper>
        </Box>
      )}
      {!preview && (
        <Grid container item spacing={2} className={classes.cardsContainer}>
          {parsedOffersOverview.data.map((overview) => {
            const data = Object.entries(overview.data).map(([date, amount]) => ({ date, amount }));
            const Icon = overview.logo ? <img src={overview.logo} alt={overview.name} /> : undefined;
            return (
              <Grid item xs={12} sm={6} md={3} className={classes.cardContainer} key={overview.name}>
                <Overview
                  totalCount={offersOverview.total}
                  name={overview.name}
                  total={overview.total}
                  data={data}
                  Icon={Icon}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

export default OffersOVerview;
