import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Container, Grid, Box, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MUIDataTableState } from 'mui-datatables';
import { Loader } from 'components/Loader';
import { Table, customHeadRender } from 'components/table';
import { useLenders } from 'store/lenders/hooks';
import { AlertMessageStatus, LenderQuery, LendersTableColumnNames } from 'core/types';
import { Lender } from 'store/lenders/types';
import routes from 'router/routes';
import { useGlobalState } from 'store/global/hooks';
import { AlertMessage } from 'components/alerts';
import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import { usePrevious } from 'hooks';
import { toTitleCase } from 'utils';
import useStyles from './Lenders.styles';
import { LenderAdminActions } from './components';

const Lenders: FC = () => {
  const [activePage, setActivePage] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, lenders, error, total, success, setError, setSuccess, getLenders } = useLenders();
  const history = useHistory();
  const { error: globalError } = useGlobalState();
  const prevActivePage = usePrevious(activePage);

  useEffect(() => {
    return () => {
      setError(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lenderQuery: LenderQuery = useMemo(
    () => ({
      per_page: ROWS_PER_TABLE_PAGE,
      page: activePage + 1,
    }),
    [activePage],
  );

  useEffect(() => {
    if (prevActivePage !== activePage) {
      getLenders(lenderQuery);
    }
  }, [activePage, getLenders, lenderQuery, prevActivePage]);

  const columns = [
    {
      name: LendersTableColumnNames.LOGO,
      label: t('pages.lenders.table.columns.logoUrl'),
      options: {
        customHeadRender,
        customBodyRender: (value?: string) =>
          value ? <img src={value} alt="Lender" className={classes.lenderLogo} /> : null,
      },
    },
    {
      name: LendersTableColumnNames.LENDER_NAME,
      label: t('pages.lenders.table.columns.lenderName'),
      options: {
        customHeadRender,
      },
    },
    {
      name: LendersTableColumnNames.REGIONS,
      label: t('pages.lenders.table.columns.regions'),
      options: {
        customHeadRender,
        customBodyRender: (values: Array<string>) =>
          values.map((value) => <Chip style={{ marginRight: 2 }} label={value} key={value} />),
      },
    },
    {
      name: LendersTableColumnNames.USER_NAME,
      label: t('pages.lenders.table.columns.userName'),
      options: {
        customHeadRender,
      },
    },
    {
      name: LendersTableColumnNames.EMAIL,
      label: t('pages.lenders.table.columns.email'),
      options: {
        customHeadRender,
      },
    },
    {
      name: LendersTableColumnNames.ROLE,
      label: t('pages.lenders.table.columns.role'),
      options: {
        customHeadRender,
      },
    },
    {
      name: LendersTableColumnNames.ENABLED,
      label: t('pages.lenders.table.columns.enabled'),
      options: {
        customHeadRender,
        customBodyRender: (value: boolean) => toTitleCase(String(value)),
      },
    },
  ];

  const onItemClick = useCallback(
    (itemId: string) => {
      history.push(`${routes.lender}/${itemId}`);
    },
    [history],
  );

  const onTableChange = useCallback((action: string, tableState: MUIDataTableState) => {
    if (action === 'changePage') {
      setActivePage(tableState.page);
    }
  }, []);

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box flex={1} marginRight="24px" minWidth="200px">
            <Typography className={classes.title}>{t('pages.lenders.title')}</Typography>
            <Typography className={classes.subtitle}>{t('pages.lenders.description')}</Typography>
          </Box>
        </Grid>

        <Grid item xs={4} className={classes.actionContainer}>
          <LenderAdminActions />
        </Grid>

        <Grid item xs={12} className={classes.tableContainer}>
          <Table<Lender>
            columns={columns}
            data={lenders}
            onItemClick={onItemClick}
            onTableChange={onTableChange}
            page={activePage}
            count={total}
            rowsPerPage={ROWS_PER_TABLE_PAGE}
          />
        </Grid>

        <Loader visible={loading} />

        {!globalError && (
          <>
            <AlertMessage
              open={!!error}
              onClose={() => setError(false)}
              message={typeof error === 'string' ? error : undefined}
              autoHideDuration={5000}
            />

            <AlertMessage
              open={!!success}
              onClose={() => setSuccess(false)}
              message={typeof success === 'string' ? success : undefined}
              autoHideDuration={5000}
              status={AlertMessageStatus.SUCCESS}
            />
          </>
        )}
      </Grid>
    </Container>
  );
};

export default Lenders;
