import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Typography, Tabs, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash.sortby';

import { useCompanyTransactions, usePrevious } from 'hooks';
import { CompanyTransactionsQuery, EcommerceProvider } from 'store/applications/types';
import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import { StyledTab } from 'components/StyledTab';
import { useAuth } from 'store/auth/hooks';
import { EcommerceProviders } from 'core/types';
import useDetailsStyles from '../LeadDetails/LeadDetails.styles';
import { useTabsStyles } from './PaymentData.styles';
import { SyncTransactionsButton, TransactionsChart, TransactionsTable, Valuations } from './components';

interface PaymentDataProps {
  companyId: string;
  ecommerceProviders: Record<EcommerceProviders, EcommerceProvider[]>;
}

const PaymentData: FC<PaymentDataProps> = ({ companyId, ecommerceProviders }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const detailsClasses = useDetailsStyles();
  const tabsClasses = useTabsStyles();
  const { loading, error, transactions, total, getCompanyTransactions } = useCompanyTransactions();
  const prevActivePage = usePrevious(activePage);
  const { t } = useTranslation();
  const { isAdmin } = useAuth();

  const connectedProviders = Object.keys(ecommerceProviders) as EcommerceProviders[];

  const selectedProvider = connectedProviders[activeTab];

  const transactionsQuery: CompanyTransactionsQuery = useMemo(
    () => ({
      per_page: ROWS_PER_TABLE_PAGE,
      page: activePage + 1,
      provider: selectedProvider,
    }),
    [activePage, selectedProvider],
  );

  useEffect(() => {
    if (prevActivePage !== undefined && prevActivePage !== activePage) {
      getCompanyTransactions(companyId, transactionsQuery);
    }
  }, [activePage, companyId, getCompanyTransactions, prevActivePage, transactionsQuery]);

  useEffect(() => {
    getCompanyTransactions(companyId, transactionsQuery);
  }, [selectedProvider, companyId, getCompanyTransactions, transactionsQuery]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  if (!transactions.length) return null;

  return (
    <Box marginTop="40px" marginBottom="40px">
      <Typography className={detailsClasses.sectionTitle}>{t('pages.lead.sections.paymentData.title')}</Typography>

      <Tabs
        value={activeTab}
        onChange={handleChange}
        classes={tabsClasses}
        variant="fullWidth"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
      >
        {connectedProviders.map((provider, index) => (
          <StyledTab key={provider} label={provider} value={index} />
        ))}
      </Tabs>

      <Grid container spacing={1}>
        {isAdmin &&
          ecommerceProviders[selectedProvider].reduce((acc: JSX.Element[], val: EcommerceProvider) => {
            if (!val.syncStartedAt) return acc;
            acc.push(
              <Grid item xs={12} key={val.id}>
                <SyncTransactionsButton companyId={companyId} provider={val} />
              </Grid>,
            );
            return acc;
          }, [])}

        <Grid item xs={12} md={6}>
          <TransactionsChart
            loading={loading}
            error={!!error}
            transactions={sortBy(transactions, 'transaction_date')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Valuations companyId={companyId} provider={selectedProvider} />
        </Grid>

        <Grid item xs={12}>
          <Box marginTop="10px" marginBottom="20px">
            <Typography className={detailsClasses.sectionTitle}>
              {t('pages.lead.sections.paymentData.transactionsTable.title')}
            </Typography>
          </Box>

          <TransactionsTable
            transactions={transactions}
            total={total}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentData;
