import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    padding: '2rem',
    backgroundColor: theme.palette.background.default,
    flexDirection: 'column',
    height: '100%',
  },
  button: {
    minWidth: '215px',
  },
  activeButton: {
    minWidth: '215px',
  },
  btnDiv: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  titleImg: {
    height: '120px',
    textAlign: 'center',
  },
  padding: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
}));
