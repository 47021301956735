import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& .MuiFormControl-root': {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },

      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:before': {
        border: 'none',
      },

      '& .MuiInputBase-input': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
        width: 100,
        height: 36,
        textAlign: 'center',
        backgroundColor: theme.palette.customGrey.main,
        borderRadius: 4,
        paddingTop: 0,
        paddingBottom: 0,
        cursor: 'pointer',
      },
    },
    dateContainer: {
      position: 'relative',
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
        marginBottom: 0,
      },
      '& .MuiInputBase-input': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
        borderRadius: 4,
        paddingLeft: 13,
        cursor: 'pointer',
        border: `1px solid ${theme.palette.greyLightBorder.main}`,
        height: 22,
      },
      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:before': {
        border: 'none',
      },
    },
    icon: {
      width: 14,
      height: 14,
      color: '#687C8D',
      position: 'absolute',
      top: 40,
      right: 10,
    },
    inputError: {
      '& .MuiInputBase-input': {
        borderColor: theme.palette.error.main,
      },
    },
  }),
);
