import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 350,
      position: 'relative',
      '& .MuiInputBase-root': {
        height: 40,
      },
      '& .MuiInputBase-input': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(2),
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    searchIcon: {
      position: 'absolute',
      top: 18,
      left: 17,
      height: 18,
      width: 18,
    },
    closeIcon: {
      position: 'absolute',
      top: 18,
      right: 5,
      height: 18,
      width: 18,
      cursor: 'pointer',
    },
  }),
);
