import { ChangeEvent, FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { FormLabel, Grid } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import { LoadingButton } from 'components/buttons';
import { CustomLabel, CustomSelect } from 'components/inputs';
import { offerProductTypesOptions } from 'core/constants';
import { useAdmin } from 'store/admin/hooks';
import { OfferProductType } from 'store/admin/types';
import { useApplications } from 'store/applications/hooks';
import { useLenders } from 'store/lenders/hooks';
import useStyles from './LenderIntroductionModal.styles';

interface FormValues {
  lenderId: string | undefined;
  productType: OfferProductType | undefined;
}

interface LenderIntroductionModalProps {
  open: boolean;
  toggleOpen: () => void;
  disabled?: boolean;
}

const LenderIntroductionModal: FC<LenderIntroductionModalProps> = ({ open, toggleOpen, disabled }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, introduceLender } = useAdmin(() => {
    if (open) {
      formRef.current?.resetForm();
      toggleOpen();
    }
  });
  const { allLenders } = useLenders();
  const { applicationDetails } = useApplications();

  const offerSchema = useMemo(
    () =>
      yup.object({
        lenderId: yup.string().required(t('pages.lead.admin.lenderIntroduction.inputs.lenderId.required')).nullable(),
        productType: yup
          .string()
          .required(t('pages.lead.admin.lenderIntroduction.inputs.productType.required'))
          .nullable(),
      }),
    [t],
  );

  const lendersOptions = useMemo(() => {
    const emptyOption = [{ label: '', value: '' }];
    if (allLenders.length > 0) {
      return emptyOption.concat(allLenders.map((lender) => ({ label: lender.displayed_name, value: lender.id })));
    }
    return emptyOption;
  }, [allLenders]);

  const initialValues: FormValues = { lenderId: undefined, productType: undefined };

  const onSubmit = (values: FormValues) => {
    if (applicationDetails?.id && values.lenderId && values.productType) {
      introduceLender({ id: applicationDetails.id, lenderId: values.lenderId, productType: values.productType });
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={offerSchema} innerRef={formRef}>
      {({ values, handleSubmit, setFieldValue }) => (
        <Form noValidate>
          <FormLabel component={() => <CustomLabel title={t('pages.lead.admin.lenderIntroduction.subTitle')} />} />
          <Grid container direction="column" spacing={4} className={classes.container}>
            <Grid item>
              <Field
                autoFocus
                id="lenderId"
                fullWidth
                component={CustomSelect}
                options={lendersOptions}
                name="lenderId"
                value={values.lenderId}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('lenderId', event.target.value)}
                title={t('pages.lead.admin.lenderIntroduction.inputs.lenderId.label')}
                className={classes.textInput}
                disabled={loading}
              />
            </Grid>

            <Grid item>
              <Field
                id="productType"
                fullWidth
                component={CustomSelect}
                options={offerProductTypesOptions}
                name="productType"
                value={values.productType}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('productType', event.target.value)}
                title={t('pages.lead.admin.lenderIntroduction.inputs.productType.label')}
                className={classes.textInput}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <LoadingButton
            variant="contained"
            onClick={() => handleSubmit()}
            color="primary"
            disableElevation
            loading={loading}
            disabled={disabled}
            startIcon={<Email />}
          >
            {t('pages.lead.admin.lenderIntroduction.buttons.confirm')}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
};

export default LenderIntroductionModal;
