import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    textInput: {
      '& .MuiInputBase-root': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
        height: 36,
      },
      '& .Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
      },
      '& .Mui-error': {
        color: theme.palette.error.main,
        marginLeft: 0,
        marginRight: 0,
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    selectContainer: {
      '& .MuiSelect-root': {
        height: 36,
        minWidth: '10em',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 16,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
      },
    },
    selectExpandIcon: {
      height: 20,
      width: 20,
      color: '#687C8D',
      pointerEvents: 'none',
      position: 'absolute',
      top: 8,
      right: 10,
    },
    pieChartContainer: {
      height: '100%',
      width: '100%',
      padding: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
