import { FC, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';

import useStyles from './AccordionPanel.styles';

interface AccordionPanelProps {
  title: string;
  subtitle: string;
  content?: JSX.Element;
  initialExpanded?: boolean;
}

const AccordionPanel: FC<AccordionPanelProps> = ({ title, subtitle, content, initialExpanded = false }) => {
  const [expanded, setExpanded] = useState(initialExpanded);
  const classes = useStyles();

  // eslint-disable-next-line
  const handleChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
        <Typography className={classes.heading}>{title}</Typography>
        <Typography className={classes.secondaryHeading}>{subtitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          {content}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionPanel;
