import { FC, useState, useCallback, useMemo, useEffect } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { MUIDataTableMeta, MUIDataTableState } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Table, customHeadRender } from 'components/table';
import { Loader } from 'components/Loader';
import { Reminder, RemindersQuery } from 'store/reminders/types';
import { usePrevious } from 'hooks';
import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import { useReminders } from 'store/reminders/hooks';
import { AlertMessageStatus, RemindersTableColumnNames } from 'core/types';
import { useGlobalState } from 'store/global/hooks';
import { AlertMessage } from 'components/alerts';
import routes from 'router/routes';
import { useAuth } from 'store/auth/hooks';
import useStyles from './Reminders.styles';
import { ReminderCheckbox } from './components';

const DATE_FORMAT = 'YYYY-MM-DD';

const today = moment().format(DATE_FORMAT);

const Reminders: FC = () => {
  const [activePage, setActivePage] = useState(0);
  const prevActivePage = usePrevious(activePage);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, error, reminders, total, success, setError, setSuccess, getReminders } = useReminders();
  const { error: globalError } = useGlobalState();
  const { uid: currentUserId } = useAuth();

  const remindersQuery: RemindersQuery = useMemo(
    () => ({
      per_page: ROWS_PER_TABLE_PAGE,
      page: activePage + 1,
    }),
    [activePage],
  );

  useEffect(() => {
    if (prevActivePage !== activePage) {
      getReminders(remindersQuery);
    }
  }, [activePage, getReminders, remindersQuery, prevActivePage]);

  const columns = useMemo(
    () => [
      {
        name: RemindersTableColumnNames.REMINDER,
        label: t('pages.reminders.table.columns.reminder'),
        options: {
          customHeadRender,
          customBodyRender: (_: undefined, tableMeta: MUIDataTableMeta) => {
            const selectedReminder: Reminder | undefined = reminders[tableMeta.rowIndex];

            if (!selectedReminder) return null;

            const disabled = currentUserId !== selectedReminder.assignee.id;

            return <span className={disabled ? classes.disabled : undefined}>{selectedReminder.reminder}</span>;
          },
        },
      },
      {
        name: RemindersTableColumnNames.DUE_DATE,
        label: t('pages.reminders.table.columns.dueDate'),
        options: {
          customHeadRender,
          customBodyRender: (_: undefined, tableMeta: MUIDataTableMeta) => {
            const selectedReminder: Reminder | undefined = reminders[tableMeta.rowIndex];

            if (!selectedReminder) return null;

            const dueDate = moment(selectedReminder.due_date).format(DATE_FORMAT);
            const disabled = currentUserId !== selectedReminder.assignee.id;

            if (moment(today).isSame(dueDate)) {
              return (
                <span className={disabled ? classes.disabled : undefined}>
                  {t('components.adminReminders.dueToday')}
                </span>
              );
            }

            return (
              <span className={disabled ? classes.disabled : undefined}>
                {moment(selectedReminder.due_date).format('DD/MM/YYYY')}
              </span>
            );
          },
        },
      },
      {
        name: RemindersTableColumnNames.COMPANY,
        label: t('pages.reminders.table.columns.company'),
        options: {
          customHeadRender,
          customBodyRender: (_: undefined, tableMeta: MUIDataTableMeta) => {
            const selectedReminder: Reminder | undefined = reminders[tableMeta.rowIndex];

            if (!selectedReminder) return null;

            const disabled = currentUserId !== selectedReminder.assignee.id;

            return (
              <Link
                to={`${routes.lead}/${selectedReminder.application_id}`}
                className={disabled ? classes.disabled : undefined}
              >
                {selectedReminder.company_name}
              </Link>
            );
          },
        },
      },
      {
        name: RemindersTableColumnNames.ASSIGNEE,
        label: t('pages.reminders.table.columns.assignee'),
        options: {
          customHeadRender,
          customBodyRender: (_: undefined, tableMeta: MUIDataTableMeta) => {
            const selectedReminder: Reminder | undefined = reminders[tableMeta.rowIndex];

            if (!selectedReminder) return null;

            return selectedReminder.assignee.name;
          },
        },
      },
      {
        name: RemindersTableColumnNames.DONE,
        label: t('pages.reminders.table.columns.done'),
        options: {
          customHeadRender,
          customBodyRender: (_: undefined, tableMeta: MUIDataTableMeta) => {
            const selectedReminder: Reminder | undefined = reminders[tableMeta.rowIndex];

            if (!selectedReminder) return null;

            return <ReminderCheckbox reminder={selectedReminder} />;
          },
        },
      },
    ],
    [classes.disabled, currentUserId, reminders, t],
  );

  const onTableChange = useCallback((action: string, tableState: MUIDataTableState) => {
    if (action === 'changePage') {
      setActivePage(tableState.page);
    }
  }, []);

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title}>{t('pages.reminders.title')}</Typography>
          <Typography className={classes.subtitle}>{t('pages.reminders.subtitle')}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.tableContainer}>
        <Table<Reminder>
          columns={columns}
          data={reminders}
          onTableChange={onTableChange}
          page={activePage}
          count={total}
          rowsPerPage={ROWS_PER_TABLE_PAGE}
        />
      </Grid>

      <Loader visible={loading} />

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Reminders;
