import { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Container, Grid, IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward, Edit } from '@material-ui/icons';
import { MUIDataTableCustomHeadRenderer, MUIDataTableMeta, MUISortOptions } from 'mui-datatables';
import { AlertMessage } from 'components/alerts';
import { Loader } from 'components/Loader';
import { CustomHeadRender, Table } from 'components/table';
import { ColumnHeader } from 'components/table/ColumnHeader';
import { OFFER_FOLLOW_UP_ENABLED, OFFER_REJECT_ENABLED } from 'core/constants';
import { AlertMessageStatus, OffersTableColumnNames, EcommerceProviders } from 'core/types';
import {
  mapAppRegionToSupportedCurrency,
  mapOfferInterestTypeToLabel,
  mapOfferProductTypeToLabel,
  mapOfferStatusToLabel,
} from 'core/utils';
import { usePrevious, useScrollToTop } from 'hooks';
import routes from 'router/routes';
import { useAccount } from 'store/account/hooks';
import { useAdmin } from 'store/admin/hooks';
import { MonthlyRepaymentType, OfferProductType } from 'store/admin/types';
import { useApplications } from 'store/applications/hooks';
import { ApplicationStatus, EcommerceProvider, LenderOffer, OfferStatus } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { useGlobalState } from 'store/global/hooks';
import { decimalToPercentage, parseMoney } from 'utils';
import { MakeOfferModal, PaymentData, RejectModal, UpdateOfferStatusModal } from './components';
import useStyles from './Lead.styles';
import { LeadDetails } from './components/LeadDetails';
import BankData from './components/BankData/BankData';
import { BalanceData } from './components/BalanceData';

interface SelectedOffer {
  id: string;
  status: OfferStatus;
  reject?: boolean;
}

const customHeadRender = (
  columnMeta: MUIDataTableCustomHeadRenderer,
  handleToggleColumn: (columnIndex: number) => void,
  sortOrder: MUISortOptions,
) => (
  <CustomHeadRender
    key={`${columnMeta.name}-${columnMeta.index}`}
    columnMeta={columnMeta}
    handleToggleColumn={handleToggleColumn}
    sortOrder={sortOrder}
  />
);

const Lead: FC<RouteComponentProps> = ({ history }) => {
  const [selectedOffer, setSelectedOffer] = useState<LenderOffer | null>(null);
  const [selectedOfferStatus, setSelectedOfferStatus] = useState<SelectedOffer | null>(null);
  const [selectedOfferId, setSelectedOfferId] = useState<string | undefined>();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { isAdmin, isLenderAdmin, isCompanyOwner } = useAuth();
  const { getApplicationDetails, applicationDetails, clearApplicationDetails, pendingApplicationsIds, loading } =
    useApplications();
  const { error, clearError, success, setSuccess } = useAdmin();
  const { error: globalError } = useGlobalState();
  const { account } = useAccount();
  const prevLoading = usePrevious(loading);
  const prevApplicationDetails = usePrevious(applicationDetails);

  useScrollToTop(!prevApplicationDetails && !!prevLoading && !loading);

  useEffect(() => {
    if (id) {
      getApplicationDetails(id);
    }
  }, [getApplicationDetails, id]);

  useEffect(() => {
    return () => {
      clearApplicationDetails();
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const offers = useMemo(() => applicationDetails?.offers ?? [], [applicationDetails]);

  const appRegion = applicationDetails?.region;

  const columns = useMemo(
    () => [
      ...(isAdmin || isLenderAdmin
        ? [
            {
              name: OffersTableColumnNames.EDIT,
              label: '',
              options: {
                customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) => (
                  <ColumnHeader key={`edit-${columnMeta.index}`} title="" empty />
                ),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                customBodyRender: (_: any, tableMeta: MUIDataTableMeta) => {
                  const onClick = () => setSelectedOffer(offers[tableMeta.rowIndex]);
                  return (
                    <IconButton color="primary" className={classes.editButton} onClick={onClick}>
                      <Edit className={classes.editIcon} />
                    </IconButton>
                  );
                },
              },
            },
          ]
        : []),
      {
        name: OffersTableColumnNames.DATE_STARTED,
        label: t('pages.lead.sections.table.columns.dateStarted'),
        options: {
          customHeadRender,
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleString() : null),
        },
      },
      ...(!isCompanyOwner
        ? [
            {
              name: OffersTableColumnNames.LENDER_NAME,
              label: t('pages.lead.sections.table.columns.lenderName'),
              options: {
                customHeadRender,
              },
            },
            {
              name: OffersTableColumnNames.LENDER_TYPE,
              label: t('pages.lead.sections.table.columns.lenderType'),
              options: {
                customHeadRender,
              },
            },
          ]
        : []),
      {
        name: OffersTableColumnNames.PRODUCT_TYPE,
        label: t('pages.lead.sections.table.columns.productType'),
        options: {
          customHeadRender,
          customBodyRender: (value: OfferProductType) => (isCompanyOwner ? mapOfferProductTypeToLabel(value) : value),
        },
      },
      {
        name: OffersTableColumnNames.STATUS,
        label: t('pages.lead.sections.table.columns.status'),
        options: {
          customHeadRender,
          customBodyRender: isAdmin
            ? (value: OfferStatus, tableMeta: MUIDataTableMeta) => {
                const offer = offers[tableMeta.rowIndex];
                const onClick = () => {
                  if (offer) {
                    setSelectedOfferStatus({ id: offer.id, status: offer.status });
                  }
                };
                return (
                  <Button variant="contained" color="primary" disableElevation onClick={onClick}>
                    {value}
                  </Button>
                );
              }
            : (value: OfferStatus, tableMeta: MUIDataTableMeta) => {
                const offer = offers[tableMeta.rowIndex];
                let status = value;
                // Since we're flattening offers and their rates/revenue repayments we have to check
                // if an offer rate has been selected to confirm that the offer's been accepted. If the
                // offer status is ACCEPTED but not "selected" it means another rate belonging to the same
                // offer was the one that's been accepted.
                if (isCompanyOwner && status === OfferStatus.ACCEPTED && !offer.selected) {
                  status = OfferStatus.APPLICANT_DECLINED;
                }
                return mapOfferStatusToLabel(status);
              },
        },
      },
      ...(isAdmin || isLenderAdmin
        ? [
            {
              name: OffersTableColumnNames.FOLLOW_UP,
              label: '',
              options: {
                customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) => (
                  <ColumnHeader key={`follow-up-${columnMeta.index}`} title="" empty />
                ),
                customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
                  const offer = offers[tableMeta.rowIndex];
                  const canChangeStatus = offer && OFFER_FOLLOW_UP_ENABLED[offer.status];
                  const onClick = () => {
                    if (offer && canChangeStatus) setSelectedOfferId(offer.id);
                  };
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={onClick}
                      disabled={!canChangeStatus}
                      className={classes.followUpButton}
                    >
                      {t('pages.lead.admin.actions.followUp')}
                    </Button>
                  );
                },
              },
            },
            {
              name: OffersTableColumnNames.REJECT,
              label: '',
              options: {
                customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) => (
                  <ColumnHeader key={`reject-${columnMeta.index}`} title="" empty />
                ),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                customBodyRender: (_: any, tableMeta: MUIDataTableMeta) => {
                  const offer = offers[tableMeta.rowIndex];
                  const canRejectOffer = offer?.status && OFFER_REJECT_ENABLED[offer.status];
                  const onClick = () => {
                    if (offer && canRejectOffer) {
                      setSelectedOfferStatus({ id: offer.id, status: offer.status, reject: true });
                    }
                  };
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={onClick}
                      disabled={!canRejectOffer}
                    >
                      {t('pages.lead.sections.table.columns.reject')}
                    </Button>
                  );
                },
              },
            },
          ]
        : []),
      ...(!isCompanyOwner
        ? [
            {
              name: OffersTableColumnNames.MIN_CREDIT,
              label: t('pages.lead.sections.table.columns.minCredit'),
              options: {
                customHeadRender,
                customBodyRender: (value: number) => parseMoney(value, mapAppRegionToSupportedCurrency(appRegion)),
              },
            },
            {
              name: OffersTableColumnNames.MAX_CREDIT,
              label: t('pages.lead.sections.table.columns.maxCredit'),
              options: {
                customHeadRender,
                customBodyRender: (value: number) => parseMoney(value, mapAppRegionToSupportedCurrency(appRegion)),
              },
            },
            {
              name: OffersTableColumnNames.BORROWED_AMOUNT,
              label: t('pages.lead.sections.table.columns.borrowedAmount'),
              options: {
                customHeadRender,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                customBodyRender: (_: any, tableMeta: MUIDataTableMeta) => {
                  const offer = offers[tableMeta.rowIndex];
                  if (!offer) return parseMoney(0, mapAppRegionToSupportedCurrency(appRegion));

                  let borrowedAmount = 0;

                  if (offer.product_type === OfferProductType.REVENUE_BASED) {
                    borrowedAmount = offer.revenueRepayments.find((r) => r.selected)?.total_get ?? 0;
                  } else {
                    borrowedAmount = offer.rates.find((r) => r.selected)?.principal ?? 0;
                  }

                  return parseMoney(borrowedAmount, mapAppRegionToSupportedCurrency(appRegion));
                },
              },
            },
            {
              name: OffersTableColumnNames.DURATION,
              label: t('pages.lead.sections.table.columns.duration'),
              options: {
                customHeadRender,
              },
            },
          ]
        : []),
      ...(isCompanyOwner
        ? [
            {
              name: OffersTableColumnNames.LOAN_AMOUNT,
              label: t('pages.lead.sections.table.columns.loanAmount'),
              options: {
                customHeadRender,
                customBodyRender: (value: number) =>
                  value ? parseMoney(value, mapAppRegionToSupportedCurrency(appRegion)) : null,
              },
            },
            {
              name: OffersTableColumnNames.DURATION,
              label: t('pages.lead.sections.table.columns.duration'),
              options: {
                customHeadRender,
              },
            },
            {
              name: OffersTableColumnNames.MONTHLY_INTEREST_RATE,
              label: t('pages.lead.sections.table.columns.monthlyInterestRate'),
              options: {
                customHeadRender,
                customBodyRender: (value: number) => (value ? decimalToPercentage(value) : null),
              },
            },
            {
              name: OffersTableColumnNames.MONTHLY_REPAYMENT_AMOUNT,
              label: t('pages.lead.sections.table.columns.monthlyRepaymentAmount'),
              options: {
                customHeadRender,
                customBodyRender: (value: number) =>
                  value ? parseMoney(value, mapAppRegionToSupportedCurrency(appRegion)) : null,
              },
            },
            {
              name: OffersTableColumnNames.MONTHLY_REPAYMENT_TYPE,
              label: t('pages.lead.sections.table.columns.monthlyRepaymentType'),
              options: {
                customHeadRender,
                customBodyRender: (value: MonthlyRepaymentType) => (value ? mapOfferInterestTypeToLabel(value) : null),
              },
            },
            {
              name: OffersTableColumnNames.REPAYMENT,
              label: t('pages.lead.sections.table.columns.repayment'),
              options: {
                customHeadRender,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                customBodyRender: (_: any, tableMeta: MUIDataTableMeta) => {
                  const offer = offers[tableMeta.rowIndex];
                  if (!offer || offer.product_type !== OfferProductType.REVENUE_BASED) return null;
                  return `${decimalToPercentage(offer.sweep)} ${offer.sweep_terms}`;
                },
              },
            },
            {
              name: OffersTableColumnNames.TOTAL_REPAYMENT,
              label: t('pages.lead.sections.table.columns.totalRepayment'),
              options: {
                customHeadRender,
                customBodyRender: (value: number) =>
                  value ? parseMoney(value, mapAppRegionToSupportedCurrency(appRegion)) : null,
              },
            },
            {
              name: OffersTableColumnNames.VALID_UNTIL,
              label: t('pages.lead.sections.table.columns.validUntil'),
              options: {
                customHeadRender,
                customBodyRender: (value: string) => (value ? new Date(value).toLocaleString() : null),
              },
            },
          ]
        : []),
    ],
    [
      appRegion,
      classes.editButton,
      classes.editIcon,
      classes.followUpButton,
      isAdmin,
      isCompanyOwner,
      isLenderAdmin,
      offers,
      t,
    ],
  );

  const clearSelectedOffer = () => setSelectedOffer(null);

  const goBack = () => {
    if (isCompanyOwner) history.push(routes.dashboard);
    else history.push(routes.leads);
  };

  const clearSelectedOfferStatus = () => setSelectedOfferStatus(null);

  const nextApplicationId = useMemo(() => {
    const pendingApplicationsLength = pendingApplicationsIds.length;
    const currentApplicationIndex = pendingApplicationsIds.findIndex((appId) => appId === id);
    const nextApplicationIndex = currentApplicationIndex + 1;
    let nextAppId = '';
    if (currentApplicationIndex >= 0) {
      if (nextApplicationIndex < pendingApplicationsLength) {
        nextAppId = pendingApplicationsIds[nextApplicationIndex];
      } else if (currentApplicationIndex > 0) {
        [nextAppId] = pendingApplicationsIds;
      }
    } else {
      [nextAppId] = pendingApplicationsIds;
    }
    return nextAppId;
  }, [id, pendingApplicationsIds]);

  const onNextPendingApplication = () => {
    if (nextApplicationId) {
      history.push(`${routes.lead}/${nextApplicationId}`);
    }
  };

  const showAdminActions =
    (isAdmin || isLenderAdmin) &&
    applicationDetails &&
    applicationDetails.status !== ApplicationStatus.CLOSED &&
    applicationDetails.status !== ApplicationStatus.REJECTED;

  const ecommerceProviders = useMemo(() => {
    if (!applicationDetails || applicationDetails.company.ecommerce_providers.length === 0) return null;

    return applicationDetails.company.ecommerce_providers.reduce(
      (acc: Record<EcommerceProviders, EcommerceProvider[]>, val: EcommerceProvider) => {
        if (!acc[val.provider]) {
          acc[val.provider] = [];
        }
        acc[val.provider].push(val);
        return acc;
      },
      {} as Record<EcommerceProviders, EcommerceProvider[]>,
    );
  }, [applicationDetails]);

  const bankProviders = useMemo(() => {
    if (!applicationDetails || applicationDetails.company.company_accounts.length === 0) return null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return applicationDetails.company.company_accounts.reduce((acc: Record<any, any[]>, val: any) => {
      if (!acc[val.bank_name]) {
        acc[val.bank_name] = [];
      }
      acc[val.bank_name].push(val);
      return acc;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as Record<any, any[]>);
  }, [applicationDetails]);

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={3} className={classes.contentContainer}>
        <Loader visible={loading} />

        <Grid item xs={6}>
          <Button
            className={classes.actionButton}
            variant="text"
            color="default"
            onClick={goBack}
            startIcon={<ArrowBack className={classes.navIcon} />}
          >
            {t('pages.lead.navPrev')}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Grid container justify="flex-end">
            {applicationDetails && nextApplicationId && (
              <Button
                className={classes.actionButton}
                variant="text"
                color="default"
                onClick={onNextPendingApplication}
                endIcon={<ArrowForward className={classes.navIcon} />}
              >
                {t('pages.lead.navNext')}
              </Button>
            )}
          </Grid>
        </Grid>

        {applicationDetails?.offers && applicationDetails?.offers.length > 0 && (
          <Grid
            item
            xs={12}
            className={isAdmin || isLenderAdmin ? classes.tableContainer : classes.partnerTableContainer}
          >
            <Table<LenderOffer> columns={columns} data={offers} />
          </Grid>
        )}

        {loading ? (
          <Loader visible />
        ) : (
          <LeadDetails
            details={applicationDetails}
            showAdminActions={showAdminActions}
            selectedOfferId={selectedOfferId}
            setSelectedOfferId={setSelectedOfferId}
          >
            {applicationDetails && ecommerceProviders && (
              <PaymentData companyId={applicationDetails.company.id} ecommerceProviders={ecommerceProviders} />
            )}
            {applicationDetails && bankProviders && (
              <BankData companyId={applicationDetails.company.id} bankProviders={bankProviders} />
            )}
            {applicationDetails && bankProviders && (
              <BalanceData companyId={applicationDetails.company.id} bankProviders={bankProviders} />
            )}
          </LeadDetails>
        )}

        {selectedOffer && (
          <MakeOfferModal
            open={!!selectedOffer}
            toggleOpen={clearSelectedOffer}
            offer={selectedOffer}
            lenderId={isLenderAdmin && account.id ? account.id : undefined}
          />
        )}

        {selectedOfferStatus && !selectedOfferStatus.reject && (
          <UpdateOfferStatusModal
            open
            toggleOpen={clearSelectedOfferStatus}
            id={selectedOfferStatus.id}
            offerStatus={selectedOfferStatus?.status}
          />
        )}

        {selectedOfferStatus?.reject && (
          <RejectModal open toggleOpen={clearSelectedOfferStatus} offerId={selectedOfferStatus.id} />
        )}
      </Grid>

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={clearError}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Lead;
