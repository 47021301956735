import { FC, Fragment, useMemo } from 'react';
import { Typography, Divider, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@material-ui/icons/Person';
import moment from 'moment';

import { ApplicationRegion, CompanyPersonWithAddress } from 'store/applications/types';
import { isHomeowner, sortByDate } from 'utils';
import useStyles from '../LeadDetails/LeadDetails.styles';
import { DataList } from '../DataList';

interface DirectorInfoProps {
  companyPerson: CompanyPersonWithAddress;
  appRegion: ApplicationRegion;
  withTitle?: boolean;
  hideAddress?: boolean;
  maskedEmail?: string;
}

const DirectorInfo: FC<DirectorInfoProps> = ({
  companyPerson,
  appRegion,
  withTitle = true,
  hideAddress,
  maskedEmail,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { addresses, company_id, applicant, director, ...directorData } = companyPerson;

  const applicantData = useMemo(() => {
    const is_homeowner = addresses.some((addr) => addr.status && isHomeowner(addr.status));

    return {
      ...directorData,
      ...(maskedEmail ? { masked_email: maskedEmail } : null),
      is_homeowner,
      ...(hideAddress
        ? {
            phone_number: 'Contact details will be visible once the user accepts your offer.',
            email: 'Contact details will be visible once the user accepts your offer.',
          }
        : null),
    };
  }, [addresses, directorData, hideAddress, maskedEmail]);

  const applicantAddresses = useMemo(() => {
    const sortedAddresses = addresses.map((addr) => ({ ...addr, date: addr.start_date as string })).sort(sortByDate);
    return sortedAddresses.map(({ date, ...addr }, index) => {
      const nextStartDate = sortedAddresses[index - 1]?.start_date;
      return {
        ...addr,
        to_date: nextStartDate
          ? moment(nextStartDate).subtract(1, 'days').format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      };
    });
  }, [addresses]);

  return (
    <>
      {withTitle && (
        <Box className={classes.sectionWithIconContainer}>
          <Typography className={classes.sectionTitle}>{t('pages.lead.sections.director.title')}</Typography>
          <PersonIcon className={classes.sectionIcon} />
        </Box>
      )}

      <DataList entity={applicantData} translationKey="director" appRegion={appRegion} />

      <Divider className={classes.horizontalDetailsDivider} />

      {applicantAddresses.map(({ start_date, to_date, ...address }, index) => {
        return (
          <Fragment key={address.id}>
            <Typography className={classes.greySubtitle} style={{ marginTop: 16 }}>
              {t('pages.lead.sections.address.title')} {applicantAddresses.length > 1 ? index + 1 : ''}
            </Typography>

            <DataList entity={{ ...address, start_date, to_date }} translationKey="address" appRegion={appRegion} />
          </Fragment>
        );
      })}
    </>
  );
};

export default DirectorInfo;
