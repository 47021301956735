import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import moment from 'moment';
import { MUIDataTableCustomHeadRenderer, MUIDataTableMeta, MUIDataTableState, MUISortOptions } from 'mui-datatables';
import routes from 'router/routes';
import { ColumnHeader, customHeadRender, Table } from 'components/table';
import { ROWS_PER_TABLE_PAGE, SEARCH_ROWS_PER_TABLE_PAGE } from 'core/constants';
import { ApplicationsTableColumnNames, TableColumnOption } from 'core/types';

import {
  mapApplicationStatusColor,
  mapApplicationStatusToBackgroundColor,
  mapApplicationStatusToLabel,
  mapAppRegionToSupportedCurrency,
} from 'core/utils';
import { useApplications } from 'store/applications/hooks';
import { Application, ApplicationStatus, Company } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { composeFullName, parseHoursToTimeSpentLabel, parseMoney } from 'utils';
import {
  AppStatusFilter,
  AsigneeFilter,
  LoanAmountFilter,
  PartnerFilter,
  RegionFilter,
  RevenueFilter,
  TradingTimeFilter,
} from '../filters';
import useStyles from './LeadsTable.styles';

const LeadsTable: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isAdmin, isLender, isPartner, isCompanyOwner } = useAuth();
  const {
    applications,
    total,
    filters: { searchTerm, activePage },
    updateApplicationsQuery,
    getApplications,
  } = useApplications();
  const history = useHistory();

  useEffect(() => {
    if (!isLender) getApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLender]);

  const columns = useMemo(() => {
    const tableCols: {
      name: ApplicationsTableColumnNames;
      label: string;
      options: TableColumnOption;
    }[] = [
      {
        name: ApplicationsTableColumnNames.COMPANY_NAME,
        label: t('pages.dashboard.table.columns.companyName'),
        options: {
          customHeadRender,
          customBodyRender: (value: Company, tableMeta: MUIDataTableMeta) => {
            const app = applications[tableMeta.rowIndex];
            return (
              <>
                <span>{value?.registered_name}</span>
                <br />
                <span className={classes.timeSpent}>
                  {app.created_at ? new Date(app.created_at).toLocaleString() : null}
                </span>
              </>
            );
          },
        },
      },
      {
        name: ApplicationsTableColumnNames.REGION,
        label: t('pages.dashboard.table.columns.region'),
        options: {
          customHeadRender:
            isAdmin || isPartner
              ? (
                  columnMeta: MUIDataTableCustomHeadRenderer,
                  handleToggleColumn: (columnIndex: number) => void,
                  sortOrder: MUISortOptions,
                ) => customHeadRender(columnMeta, handleToggleColumn, sortOrder, <RegionFilter />)
              : customHeadRender,
        },
      },
      {
        name: ApplicationsTableColumnNames.APPLICANT_NAME,
        label: t('pages.dashboard.table.columns.applicantName'),
        options: {
          customHeadRender,
          customBodyRender: (value: Company) =>
            value?.director ? composeFullName(value.director.first_name, value.director.last_name) : null,
        },
      },
      {
        name: ApplicationsTableColumnNames.MONTHLY_REVENUE,
        label: t('pages.dashboard.table.columns.monthlyRevenue'),
        options: {
          customHeadRender: (
            columnMeta: MUIDataTableCustomHeadRenderer,
            handleToggleColumn: (columnIndex: number) => void,
            sortOrder: MUISortOptions,
          ) => {
            if (!isCompanyOwner) {
              return customHeadRender(columnMeta, handleToggleColumn, sortOrder, <RevenueFilter />);
            }
            return <ColumnHeader title={t('pages.dashboard.table.columns.monthlyRevenue')} />;
          },
          customBodyRender: (value: Company, tableMeta: MUIDataTableMeta) => {
            const app = applications[tableMeta.rowIndex];
            return parseMoney(
              value?.financial_information?.monthly_revenue,
              mapAppRegionToSupportedCurrency(app?.region),
            );
          },
        },
      },
      {
        name: ApplicationsTableColumnNames.APPLICATION_STATUS,
        label: t('pages.dashboard.table.columns.applicationStatus'),
        options: {
          customHeadRender: (
            columnMeta: MUIDataTableCustomHeadRenderer,
            handleToggleColumn: (columnIndex: number) => void,
            sortOrder: MUISortOptions,
          ) => {
            if (!isCompanyOwner && !isLender) {
              return customHeadRender(columnMeta, handleToggleColumn, sortOrder, <AppStatusFilter />);
            }
            return <ColumnHeader title={t('pages.dashboard.table.columns.applicationStatus')} />;
          },
          customBodyRender: (value: ApplicationStatus, tableMeta: MUIDataTableMeta) => {
            const app = applications[tableMeta.rowIndex];
            const diffInHours = moment().diff(moment(app.status_updated_at ?? app.created_at), 'hours', true);
            return (
              <>
                <Chip
                  label={mapApplicationStatusToLabel(value)}
                  style={{
                    backgroundColor: mapApplicationStatusToBackgroundColor(value),
                    color: mapApplicationStatusColor(value),
                  }}
                />
                <br />
                <span className={classes.timeSpent}>
                  {t('pages.dashboard.table.rows.applicationStatus.timeSpent')}:{' '}
                  {parseHoursToTimeSpentLabel(diffInHours)}
                </span>
              </>
            );
          },
        },
      },
      {
        name: ApplicationsTableColumnNames.LOAN_AMOUNT,
        label: t('pages.dashboard.table.columns.loanAmount'),
        options: {
          customHeadRender: (
            columnMeta: MUIDataTableCustomHeadRenderer,
            handleToggleColumn: (columnIndex: number) => void,
            sortOrder: MUISortOptions,
          ) => {
            if (!isCompanyOwner) {
              return customHeadRender(columnMeta, handleToggleColumn, sortOrder, <LoanAmountFilter />);
            }
            return <ColumnHeader title={t('pages.dashboard.table.columns.loanAmount')} />;
          },
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const app = applications[tableMeta.rowIndex];
            return parseMoney(value, mapAppRegionToSupportedCurrency(app?.region));
          },
        },
      },
      ...(isAdmin
        ? [
            {
              name: ApplicationsTableColumnNames.PARTNER_NAME,
              label: t('pages.dashboard.table.columns.partnerName'),
              options: {
                customHeadRender: (
                  columnMeta: MUIDataTableCustomHeadRenderer,
                  handleToggleColumn: (columnIndex: number) => void,
                  sortOrder: MUISortOptions,
                ) => customHeadRender(columnMeta, handleToggleColumn, sortOrder, <PartnerFilter />),
              },
            },

            {
              name: ApplicationsTableColumnNames.BUSINESS_AGE,
              label: t('pages.dashboard.table.columns.businessAge'),
              options: {
                customHeadRender: (
                  columnMeta: MUIDataTableCustomHeadRenderer,
                  handleToggleColumn: (columnIndex: number) => void,
                  sortOrder: MUISortOptions,
                ) => customHeadRender(columnMeta, handleToggleColumn, sortOrder, <TradingTimeFilter />),
                customBodyRender: (value: Company) =>
                  `${moment().diff(moment(value?.formation_date), 'months').toString()} months`,
              },
            },
          ]
        : []),
      ...(isAdmin || isLender
        ? [
            {
              name: ApplicationsTableColumnNames.ASSIGNEE_NAME,
              label: t('pages.dashboard.table.columns.assigneeName'),
              options: {
                customHeadRender: (
                  columnMeta: MUIDataTableCustomHeadRenderer,
                  handleToggleColumn: (columnIndex: number) => void,
                  sortOrder: MUISortOptions,
                ) => customHeadRender(columnMeta, handleToggleColumn, sortOrder, <AsigneeFilter />),
              },
            },
          ]
        : []),
    ];

    return tableCols;
  }, [applications, classes.timeSpent, isAdmin, isLender, isPartner, t, isCompanyOwner]);

  const onItemClick = useCallback(
    (itemId: string) => {
      history.push(`${routes.lead}/${itemId}`);
    },
    [history],
  );

  const onTableChange = useCallback(
    (action: string, tableState: MUIDataTableState) => {
      if (action === 'changePage') {
        updateApplicationsQuery({ activePage: tableState.page });
      }
    },
    [updateApplicationsQuery],
  );

  return (
    <Table<Application>
      columns={columns}
      data={applications}
      onItemClick={onItemClick}
      onTableChange={onTableChange}
      page={activePage}
      count={total}
      pagination={!searchTerm}
      rowsPerPage={searchTerm ? SEARCH_ROWS_PER_TABLE_PAGE : ROWS_PER_TABLE_PAGE}
    />
  );
};

export default LeadsTable;
