import { FC, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { createAdmin as createAdminApi } from 'http/admin';
import { AlertDialog } from 'components/AlertDialog';
import { CustomTextField } from 'components/inputs';
import { useAdmin } from 'store/admin/hooks';
import { UserRole } from 'core/types';
import { composeFullName } from 'utils';
import useStyles from './CreateAdminModal.styles';

interface FormValues {
  first_name: string;
  last_name: string;
  occupation?: string;
  email: string;
}

interface CreateAdminModalProps {
  open: boolean;
  toggleOpen: () => void;
}

const CreateAdminModal: FC<CreateAdminModalProps> = ({ open, toggleOpen }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { setSuccess, setError, updateAdminUsers } = useAdmin();

  const lenderSchema = yup.object({
    first_name: yup.string().required(t('pages.admins.admin.createAdmin.inputs.first_name.required')),
    last_name: yup.string().required(t('pages.admins.admin.createAdmin.inputs.last_name.required')),
    occupation: yup.string().notRequired(),
    email: yup
      .string()
      .email(t('pages.admins.admin.createAdmin.inputs.email.error'))
      .required(t('pages.admins.admin.createAdmin.inputs.email.required')),
  });

  const initialValues: FormValues = {
    first_name: '',
    last_name: '',
    occupation: '',
    email: '',
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const user = await createAdminApi(values);
      const createdAt = new Date().toISOString();
      updateAdminUsers({
        ...values,
        ...user,
        role: UserRole.ADMIN,
        fullName: composeFullName(values.first_name, values.last_name),
        created_at: createdAt,
        updated_at: createdAt,
        can_delete: true,
      });
      setSuccess(t('pages.admins.admin.success.create') as string);
      onCancel();
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={lenderSchema}
        innerRef={formRef}
        enableReinitialize
      >
        {({ handleChange, values, handleSubmit }) => (
          <AlertDialog
            open={open}
            dialogContentTitle={t('pages.admins.admin.createAdmin.title')}
            handleCancel={onCancel}
            handleConfirm={handleSubmit}
            confirmButtonTitle={t('pages.admins.admin.actions.create')}
            loading={loading}
          >
            <Form noValidate>
              <Grid container direction="column" spacing={4} className={classes.container}>
                <Grid item>
                  <Field
                    autoFocus
                    id="first_name"
                    fullWidth
                    component={CustomTextField}
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    title={t('pages.admins.admin.createAdmin.inputs.first_name.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="last_name"
                    fullWidth
                    component={CustomTextField}
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    title={t('pages.admins.admin.createAdmin.inputs.last_name.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="occupation"
                    fullWidth
                    component={CustomTextField}
                    name="occupation"
                    value={values.occupation}
                    onChange={handleChange}
                    title={t('pages.admins.admin.createAdmin.inputs.occupation.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="email"
                    fullWidth
                    component={CustomTextField}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    title={t('pages.admins.admin.createAdmin.inputs.email.label')}
                    className={classes.textInput}
                  />
                </Grid>
              </Grid>
            </Form>
          </AlertDialog>
        )}
      </Formik>
    </>
  );
};

export default CreateAdminModal;
