import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      color: theme.palette.common.black,
      fontSize: theme.typography.pxToRem(12),
    },
    selectInput: {
      width: 125,
      height: 40,
    },
  }),
);
