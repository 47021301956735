import { ChangeEvent, FC } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Box,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { lenderTableFilters, tableFilters } from 'core/constants';
import { mapApplicationStatusToLabel } from 'core/utils';
import { useApplications } from 'store/applications/hooks';
import { ApplicationStatus } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import useStyles from './AppStatusFilter.styles';

const AppStatusFilter: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLender } = useAuth();
  const {
    filters: { activeFilters },
    updateApplicationsQuery,
  } = useApplications();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetName = event.target.name as ApplicationStatus;
    if (activeFilters.includes(targetName)) {
      updateApplicationsQuery({ activeFilters: activeFilters.filter((filter) => filter !== targetName) });
    } else {
      updateApplicationsQuery({ activeFilters: [...activeFilters, targetName] });
    }
  };

  const clearFilters = () => {
    updateApplicationsQuery({ activeFilters: [ApplicationStatus.OFFERS_REQUESTED] });
  };

  const selectAll = () => {
    updateApplicationsQuery({
      activeFilters: [
        ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED,
        ApplicationStatus.CLOSED,
        ApplicationStatus.FUNDS_DISBURSED,
        ApplicationStatus.NEW,
        ApplicationStatus.OFFERS_REQUESTED,
        ApplicationStatus.OFFER_ACCEPTED,
        ApplicationStatus.PENDING,
        ApplicationStatus.PREQUALIFIED,
        ApplicationStatus.REJECTED,
      ],
    });
  };

  const filters = isLender ? lenderTableFilters : tableFilters;

  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={activeFilters}
        input={<OutlinedInput label="Tag" />}
        IconComponent={() => <ExpandMore className={classes.expandIcon} />}
        renderValue={() =>
          activeFilters.map((filter) => <Chip label={mapApplicationStatusToLabel(filter)} className={classes.chip} />)
        }
        className={classes.select}
      >
        <MenuItem disabled value="">
          <em>{t('pages.dashboard.filters.actions.title')}</em>
        </MenuItem>
        {filters.map((filter) => (
          <FormControlLabel
            key={filter}
            className={classes.controlLabel}
            control={
              <Checkbox
                checked={activeFilters.includes(filter)}
                onChange={handleChange}
                name={filter}
                color="primary"
                className={classes.checkbox}
              />
            }
            label={mapApplicationStatusToLabel(filter)}
          />
        ))}
        {!isLender && (
          <Box className={classes.actionContainer}>
            <Button
              color="primary"
              onClick={clearFilters}
              disableElevation
              variant="outlined"
              className={classes.appStatusButton}
            >
              {t('pages.dashboard.filters.actions.unSelectAll')}
            </Button>
            <Button
              color="primary"
              onClick={selectAll}
              disableElevation
              variant="outlined"
              className={classes.appStatusButton}
            >
              {t('pages.dashboard.filters.actions.selectAll')}
            </Button>
          </Box>
        )}
      </Select>
    </FormControl>
  );
};

export default AppStatusFilter;
