import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      marginBottom: 12,
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },

    actionButton: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      '& span span': {
        marginRight: 4,
      },
    },
    navIcon: {
      width: 20,
      height: 20,
      color: theme.palette.common.black,
    },
    headerSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: 24,
      flexWrap: 'wrap',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    listItemTitle: {
      '& span': {
        fontWeight: 700,
      },
    },
    listItemText: {
      flex: 'none',
    },
    tableContainer: {
      marginTop: theme.spacing(3),
    },
    partnerActions: {
      marginTop: theme.spacing(4),
      display: 'flex',
    },
    partnerActionButton: {
      marginLeft: theme.spacing(2),
    },
    usersContainer: {
      marginTop: theme.spacing(7),
    },
    switchContainer: {
      marginLeft: theme.spacing(2.5),
    },
    partnerLogo: {
      width: 'auto',
      maxHeight: 160,
      maxWidth: 300,
      borderRadius: 0,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
  }),
);
