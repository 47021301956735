import { FC, ChangeEvent } from 'react';
import { Box, Select, TextField } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

import { CustomLabel, CustomNumberField } from 'components/inputs';
import useCommonStyles from 'core/styles';
import { TimePeriod } from 'store/applications/types';
import { AmountNumberInput } from '../AmountNumberInput';
import useStyles from './NumberSelectInput.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NumberInput: FC = (props: any) => <CustomNumberField {...props} decimalScale={0} />;

interface TextSelectInputProps {
  label?: string;
  numberValue?: number;
  timePeriodValue?: TimePeriod;
  onChangeNumberInput: (number: number) => void;
  onChangeTimePeriod: (timePeriod: TimePeriod) => void;
  withPrefix?: boolean;
  options: { label: string; value: string }[];
}

const TextSelectInput: FC<TextSelectInputProps> = ({
  label,
  numberValue,
  timePeriodValue,
  onChangeNumberInput,
  onChangeTimePeriod,
  withPrefix,
  options,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (value === '') return;
    onChangeNumberInput(Number(value));
  };

  const onSelect = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    onChangeTimePeriod(event.target.value as TimePeriod);
  };

  return (
    <Box>
      {label && <CustomLabel title={label} labelClassName={classes.inputLabel} />}

      <Box className={classes.inputsContainer}>
        <TextField
          type="text"
          placeholder="3"
          variant="outlined"
          value={numberValue ?? ''}
          className={clsx([commonClasses.textInput, classes.textInput])}
          InputProps={{ inputComponent: withPrefix ? AmountNumberInput : NumberInput }}
          onChange={onChange}
        />

        <Box className={clsx([commonClasses.selectContainer, classes.selectContainer])}>
          <Select
            native
            IconComponent={() => <ExpandMore className={commonClasses.selectExpandIcon} />}
            variant="outlined"
            value={timePeriodValue}
            onChange={onSelect}
          >
            {options.map((option) => {
              return (
                <option key={`option${option.value}`} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default TextSelectInput;
