import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    controlLabel: {
      height: 30,
      '& .MuiButtonBase-root .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(18),
      },
      '& .MuiFormControlLabel-label': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 'bold',
        color: theme.palette.common.black,
        marginLeft: theme.spacing(0.5),
      },
    },
  }),
);
