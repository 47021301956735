import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    bellButton: {
      width: 44,
      height: 44,
      position: 'relative',
    },
    bellCounterContainer: {
      position: 'absolute',
      backgroundColor: theme.palette.error.main,
      width: 18,
      height: 18,
      borderRadius: 9,
      top: 5,
      right: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bellCounterText: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 700,
      color: theme.palette.common.white,
    },
    menuHeader: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      borderBottom: `1px dashed ${theme.palette.greyLightBorder.main}`,
    },
    menuTitle: {
      fontSize: theme.typography.pxToRem(15),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 600,
    },
    menuSubtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
      color: theme.palette.greyLabel.main,
    },
    menuActionText: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(28),
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
    menuSectionTitleContainer: {
      padding: '10px 16px',
    },
    menuSectionTitle: {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: 800,
      color: theme.palette.greyLabel.main,
      textTransform: 'uppercase',
    },
    menuItem: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    menuFooter: {
      padding: theme.spacing(1),
      paddingBottom: 0,
      borderTop: `1px dashed ${theme.palette.greyLightBorder.main}`,
      textAlign: 'center',
    },
    actionButton: {
      margin: 0,
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    messageText: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
      color: theme.palette.greyLabel.main,
      textAlign: 'center',
    },
    messageContainer: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
    },
  }),
);
