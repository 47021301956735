import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Box,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import routes from 'router/routes';
import { useLenders } from 'store/lenders/hooks';
import { Loader } from 'components/Loader';
import { AlertMessageStatus } from 'core/types';
import { useAccount } from 'store/account/hooks';
import { useGlobalState } from 'store/global/hooks';
import { AlertMessage } from 'components/alerts';
import { AccountUsers } from 'components/AccountUsers';
import { UserSwitchEnabled } from 'components/UserSwitchEnabled';
import { AccountType } from 'store/applications/types';
import { countryCodeLabel } from 'core/countries';
import { CreateAccountOwnerModal } from 'components/CreateAccountOwnerModal';
import useStyles from './Lenders.styles';
import { DeleteLenderModal } from './components';
import { CreateLenderModal } from '../lenders/components';

const Lender: FC = () => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [createOwnerOpen, setCreateOwnerOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    loading,
    lenderDetails,
    error: lendersError,
    success: lendersSucess,
    getLenderDetails,
    setLenderDetails,
    setError: setLendersError,
    setSuccess: setLendersSuccess,
  } = useLenders();
  const {
    error: accountError,
    success: accountSuccess,
    setError: setAccountError,
    setSuccess: setAccountSuccess,
  } = useAccount();
  const { error: globalError } = useGlobalState();

  useEffect(() => {
    return () => setLenderDetails(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLenderDetails(id);
  }, [getLenderDetails, id]);

  const goBack = () => history.push(routes.lenders);

  const toggleDeleteOpen = () => setDeleteOpen((prevState) => !prevState);

  const toggleUpdateOpen = () => setUpdateOpen((prevState) => !prevState);

  const toggleCreateOwnerOpen = () => setCreateOwnerOpen((prevState) => !prevState);

  const clearError = () => {
    if (accountError) setAccountError(false);
    if (lendersError) setLendersError(false);
  };

  const clearSuccess = () => {
    if (accountSuccess) setAccountSuccess(false);
    if (lendersSucess) setLendersSuccess(false);
  };

  const error = accountError || lendersError;

  const success = accountSuccess || lendersSucess;

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={3} className={classes.contentContainer}>
        <Grid item xs={9}>
          <Typography className={classes.title}>
            {lenderDetails ? lenderDetails.name : t('pages.lender.title')}
          </Typography>
          <Typography className={classes.subtitle}>{t('pages.lender.description')}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography className={classes.title}>{t('pages.lender.lastOpened')}:</Typography>
          <Typography className={classes.subtitle}>{moment().format('DD/MM/YYYY')}</Typography>
        </Grid>

        <Grid item xs={12} className={classes.navActionsContainer}>
          <Button
            className={classes.actionButton}
            variant="text"
            color="default"
            onClick={goBack}
            startIcon={<ArrowBack className={classes.navIcon} />}
          >
            {t('pages.lender.navPrev')}
          </Button>
        </Grid>

        {lenderDetails && (
          <>
            <Grid item xs={12}>
              <List className={classes.headerSection}>
                {lenderDetails.logo_url && (
                  <ListItem className={classes.listItem}>
                    <ListItemAvatar>
                      <Avatar src={lenderDetails.logo_url} className={classes.lenderLogo} />
                    </ListItemAvatar>
                  </ListItem>
                )}

                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    {t('pages.lender.sections.header.accountName')}
                  </ListItemText>
                  <ListItemText className={classes.listItemText}>{lenderDetails.name}</ListItemText>
                </ListItem>

                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    {t('pages.lender.sections.header.displayedName')}
                  </ListItemText>
                  <ListItemText className={classes.listItemText}>{lenderDetails.displayed_name}</ListItemText>
                </ListItem>

                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    {t('pages.lender.sections.header.contactName')}
                  </ListItemText>
                  <ListItemText className={classes.listItemText}>{lenderDetails.contact_person_name}</ListItemText>
                </ListItem>

                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    {t('pages.lender.sections.header.contactEmail')}
                  </ListItemText>
                  <ListItemText className={classes.listItemText}>{lenderDetails.contact_person_email}</ListItemText>
                </ListItem>

                {lenderDetails.phone_number && (
                  <ListItem className={classes.listItem}>
                    <ListItemText className={classes.listItemTitle}>
                      {t('pages.lender.sections.header.phoneNumber')}
                    </ListItemText>
                    <ListItemText className={classes.listItemText}>{lenderDetails.phone_number}</ListItemText>
                  </ListItem>
                )}

                {lenderDetails.job_title && (
                  <ListItem className={classes.listItem}>
                    <ListItemText className={classes.listItemTitle}>
                      {t('pages.lender.sections.header.jobTitle')}
                    </ListItemText>
                    <ListItemText className={classes.listItemText}>{lenderDetails.job_title}</ListItemText>
                  </ListItem>
                )}

                {lenderDetails.website && (
                  <ListItem className={classes.listItem}>
                    <ListItemText className={classes.listItemTitle}>
                      {t('pages.lender.sections.header.website')}
                    </ListItemText>
                    <ListItemText className={classes.listItemText}>{lenderDetails.website}</ListItemText>
                  </ListItem>
                )}

                {lenderDetails.country_code && (
                  <ListItem className={classes.listItem}>
                    <ListItemText className={classes.listItemTitle}>
                      {t('pages.lender.sections.header.country')}
                    </ListItemText>
                    <ListItemText className={classes.listItemText}>{`${countryCodeLabel[lenderDetails.country_code]} (${
                      lenderDetails.country_code
                    })`}</ListItemText>
                  </ListItem>
                )}

                {lenderDetails.industry && (
                  <ListItem className={classes.listItem}>
                    <ListItemText className={classes.listItemTitle}>
                      {t('pages.lender.sections.header.industry')}
                    </ListItemText>
                    <ListItemText className={classes.listItemText}>{lenderDetails.industry}</ListItemText>
                  </ListItem>
                )}

                {lenderDetails.employees && (
                  <ListItem className={classes.listItem}>
                    <ListItemText className={classes.listItemTitle}>
                      {t('pages.lender.sections.header.employees')}
                    </ListItemText>
                    <ListItemText className={classes.listItemText}>{lenderDetails.employees}</ListItemText>
                  </ListItem>
                )}

                {lenderDetails.address && (
                  <ListItem className={classes.listItem}>
                    <ListItemText className={classes.listItemTitle}>
                      {t('pages.lender.sections.header.address')}
                    </ListItemText>
                    <ListItemText className={classes.listItemText}>{lenderDetails.address}</ListItemText>
                  </ListItem>
                )}

                {lenderDetails.supported_regions && (
                  <ListItem className={classes.listItem}>
                    <ListItemText className={classes.listItemTitle}>
                      {t('pages.lender.sections.header.supportedRegions')}
                    </ListItemText>
                    <ListItemText className={classes.listItemText}>
                      {lenderDetails.supported_regions.join(', ')}
                    </ListItemText>
                  </ListItem>
                )}
              </List>

              <Divider />
            </Grid>

            <Grid item xs={12} className={classes.actionContainer}>
              {!lenderDetails.owner && (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.lenderActionButton}
                  onClick={toggleCreateOwnerOpen}
                >
                  {t('pages.lender.buttons.createOwner')}
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.lenderActionButton}
                onClick={toggleUpdateOpen}
              >
                {t('pages.lender.admin.actions.update')}
              </Button>

              <Box className={classes.switchContainer}>
                <UserSwitchEnabled
                  id={lenderDetails.id}
                  initialActive={lenderDetails.active}
                  accountType={AccountType.LENDER}
                  setSuccess={setLendersSuccess}
                  setError={setLendersError}
                  successMessage={t('pages.lender.messages.updateSuccess') as string}
                />
              </Box>
            </Grid>

            <Grid item xs={12} className={classes.usersContainer}>
              <AccountUsers lenderId={id} />
            </Grid>

            <DeleteLenderModal open={deleteOpen} toggleOpen={toggleDeleteOpen} />

            <CreateLenderModal open={updateOpen} toggleOpen={toggleUpdateOpen} lender={lenderDetails} />

            <CreateAccountOwnerModal
              open={createOwnerOpen}
              toggleOpen={toggleCreateOwnerOpen}
              accountType={AccountType.LENDER}
            />
          </>
        )}
      </Grid>

      <Loader visible={loading} />

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={clearError}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={clearSuccess}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Lender;
