import { FC } from 'react';
import { Box, FormLabel, TextField } from '@material-ui/core';
import { FieldProps } from 'formik';

import { CustomLabel } from '../CustomLabel';

interface CustomTextFieldProps extends FieldProps {
  title: string;
  placeholder?: string;
  tooltipMessage?: string;
  InputProps?: Record<string, unknown>;
}

const CustomTextField: FC<CustomTextFieldProps> = ({ title, placeholder, form, field, tooltipMessage, ...rest }) => {
  const hasError = form.touched[field.name] && form.errors[field.name];
  const InputProps = rest.InputProps || {};

  return (
    <Box>
      <FormLabel component={() => <CustomLabel title={title} tooltipMessage={tooltipMessage} />} />

      <TextField
        type="text"
        placeholder={placeholder}
        error={form.touched[field.name] && !!form.errors[field.name]}
        helperText={hasError ? <>{form.errors[field.name]}</> : null}
        InputProps={{ ...InputProps }}
        variant="outlined"
        {...field}
        {...rest}
        value={field.value || ''}
      />
    </Box>
  );
};

export default CustomTextField;
