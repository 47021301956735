import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    textAreaError: {
      borderColor: `${theme.palette.error.main} !important`,
    },
    rejectionReasonsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > *': {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        marginRight: theme.spacing(1.5),
      },
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: theme.spacing(1),
      },
    },
    rejectionReasonOption: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  }),
);
