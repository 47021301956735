import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    checkboxContainer: {
      minWidth: 145,
      '& .MuiFormControlLabel-label': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
      },
    },
  }),
);
