import { ChangeEvent, FC, useMemo, useRef } from 'react';
import {
  TextareaAutosize,
  Typography,
  Grid,
  FormHelperText,
  Button,
  Box,
  Chip,
  FormLabel,
  TextField,
} from '@material-ui/core';
import { Formik, Form, Field, FormikProps, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';

import { AlertDialog } from 'components/AlertDialog';
import {
  CustomTextField,
  CustomNumberField,
  CustomSelect,
  CustomDatePicker,
  CustomRadioField,
  CustomLabel,
} from 'components/inputs';
import { OfferProductType, MakeRevenueBasedOfferData, MakeBasicOfferData } from 'store/admin/types';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import {
  adminOfferStatusOptions,
  lenderOfferStatusOptions,
  offerInterestTypesOptions,
  offerProductTypesOptions,
  OFFER_UPDATE_ENABLED,
  rejectionReasonsOptions,
} from 'core/constants';
import { useLenders } from 'store/lenders/hooks';
import { LenderOffer, OfferStatus, OfferType } from 'store/applications/types';
import { ReactComponent as DashedDivider } from 'assets/icons/dashedDivider.svg';
import { useAuth } from 'store/auth/hooks';
import { mapAppRegionToCurrencySymbol, mapAppRegionToSupportedCurrency } from 'core/utils';
import useStyles from './MakeOfferModal.styles';
import {
  getInitialValues,
  FormValues,
  PercentageNumberInput,
  initialRate,
  getRateFieldError,
  getFormSchema,
} from './utils';
import { RawDataList } from '../RawDataList';
import { AmountNumberInput } from '../AmountNumberInput';

interface MakeOfferModalProps {
  open: boolean;
  toggleOpen: () => void;
  offer?: LenderOffer;
  lenderId?: string;
}

const MakeOfferModal: FC<MakeOfferModalProps> = ({ open, toggleOpen, offer, lenderId }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, makeAnOffer, updateOfferDetails } = useAdmin(() => {
    if (open) {
      formRef.current?.resetForm();
      toggleOpen();
    }
  });
  const { applicationDetails } = useApplications();
  const { allLenders } = useLenders();
  const { isAdmin } = useAuth();

  const lendersOptions = useMemo(() => {
    const emptyOption = [{ label: '', value: '' }];
    if (allLenders.length > 0) {
      return emptyOption.concat(allLenders.map((lender) => ({ label: lender.displayed_name, value: lender.id })));
    }
    return emptyOption;
  }, [allLenders]);

  const initialValues: FormValues = getInitialValues(lendersOptions, offer, lenderId);

  const onSubmit = (values: FormValues) => {
    if (applicationDetails && values.validUntil && values.productType && values.status) {
      let offerData;
      if (values.productType === OfferProductType.REVENUE_BASED) {
        offerData = {
          application_id: applicationDetails.id,
          lender_id: values.lenderId as string,
          status: values.status,
          product_type: values.productType,
          offer_details: {
            valid_until: values.validUntil,
            revenue_repayments: values.rates.map((rate) => ({
              total_repayment: Number(rate.totalRepayableAmount),
              total_get: Number(rate.eligibleAmount),
              sweep: Number(rate.repaymentPercentage) / 100,
              sweep_terms: rate.repaymentTerms,
              currency: mapAppRegionToSupportedCurrency(applicationDetails.region),
            })),
          },
        } as MakeRevenueBasedOfferData;
      } else {
        offerData = {
          application_id: applicationDetails.id,
          lender_id: values.lenderId as string,
          status: values.status,
          product_type: values.productType,
          offer_details: {
            min_credit_offer: Number(values.minCredit),
            max_credit_offer: Number(values.maxCredit),
            duration_in_months: Number(values.loanDuration),
            currency: mapAppRegionToSupportedCurrency(applicationDetails.region),
            valid_until: values.validUntil,
            rates: values.rates.map((rate) => ({
              interest_rate: Number(rate.interestRate) / 100,
              principal: Number(rate.eligibleAmount),
              monthly_repayment: Number(rate.monthlyRepayment),
              total_repayment: Number(rate.totalRepayableAmount),
              monthly_repayment_type: rate.interestType,
            })),
          },
        } as MakeBasicOfferData;
      }
      if (values.notes) offerData.notes = values.notes;
      if (values.rejectionReason) offerData.rejection_reason = values.rejectionReason;
      if (values.rejectionReasonTags) offerData.rejection_reason_tags = values.rejectionReasonTags;

      if (!offer) {
        makeAnOffer(offerData);
      } else {
        const { offer_details, notes, rejection_reason, rejection_reason_tags, status } = offerData;
        updateOfferDetails({
          id: offer.id,
          details: {
            ...offer_details,
            notes: notes || '',
            status,
            rejection_reason: rejection_reason || '',
            rejection_reason_tags,
          },
        });
      }
    }
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  const appRegion = applicationDetails?.region;

  const formSchema = useMemo(() => getFormSchema(lenderId, appRegion), [lenderId, appRegion]);

  const cannotBeUpdated = (offer?.status && !OFFER_UPDATE_ENABLED[offer.status]) || offer?.type === OfferType.API;

  const currencySymbol = mapAppRegionToCurrencySymbol(appRegion);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema} innerRef={formRef}>
      {({ handleChange, values, handleSubmit, touched, errors, setFieldValue, setTouched }) => (
        <AlertDialog
          open={open}
          dialogContentTitle={t('pages.lead.admin.makeOffer.title')}
          handleCancel={onCancel}
          handleConfirm={handleSubmit}
          confirmButtonTitle={
            offer ? t('pages.lead.admin.makeOffer.buttons.update') : t('pages.lead.admin.makeOffer.buttons.confirm')
          }
          loading={loading}
          disabled={cannotBeUpdated}
        >
          <Form noValidate>
            <Grid container direction="column" spacing={4} className={classes.container}>
              {!lenderId && (
                <Grid item>
                  <Field
                    autoFocus
                    id="lenderId"
                    fullWidth
                    component={CustomSelect}
                    options={lendersOptions}
                    name="lenderId"
                    value={values.lenderId}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('lenderId', event.target.value)}
                    title={t('pages.lead.admin.makeOffer.inputs.lenderId.label')}
                    className={classes.textInput}
                    disabled={!!offer}
                  />
                </Grid>
              )}

              <Grid item>
                <Field
                  autoFocus={!!lenderId}
                  id="status"
                  fullWidth
                  component={CustomSelect}
                  options={isAdmin ? adminOfferStatusOptions : lenderOfferStatusOptions}
                  name="status"
                  value={values.status}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    const newStatus = event.target.value as OfferStatus;
                    setFieldValue('status', newStatus);
                    if (newStatus !== OfferStatus.DECLINED) {
                      setFieldValue('rejectionReason', null);
                      setFieldValue('rejectionReasonTags', []);
                    }
                  }}
                  title={t('pages.lead.admin.makeOffer.inputs.status.label')}
                  className={classes.textInput}
                  disabled={offer?.type === OfferType.API}
                />
              </Grid>

              <Grid item>
                <Field
                  id="productType"
                  fullWidth
                  component={CustomSelect}
                  options={offerProductTypesOptions}
                  name="productType"
                  value={values.productType}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('productType', event.target.value)}
                  title={t('pages.lead.admin.makeOffer.inputs.productType.label')}
                  className={classes.textInput}
                  disabled={!!offer}
                />
              </Grid>

              {values.productType !== OfferProductType.REVENUE_BASED && (
                <>
                  <Grid item>
                    <Field
                      id="minCredit"
                      fullWidth
                      component={CustomTextField}
                      name="minCredit"
                      value={values.minCredit}
                      onChange={handleChange}
                      title={t('pages.lead.admin.makeOffer.inputs.minCredit.label')}
                      placeholder={t('pages.lead.admin.makeOffer.inputs.minCredit.placeholder', { currencySymbol })}
                      className={classes.textInput}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ inputComponent: AmountNumberInput }}
                      disabled={loading || cannotBeUpdated}
                    />
                  </Grid>

                  <Grid item>
                    <Field
                      id="maxCredit"
                      fullWidth
                      component={CustomTextField}
                      name="maxCredit"
                      value={values.maxCredit}
                      onChange={handleChange}
                      title={t('pages.lead.admin.makeOffer.inputs.maxCredit.label')}
                      placeholder={t('pages.lead.admin.makeOffer.inputs.maxCredit.placeholder', { currencySymbol })}
                      className={classes.textInput}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ inputComponent: AmountNumberInput }}
                      disabled={loading || cannotBeUpdated}
                    />
                  </Grid>

                  <Grid item>
                    <Field
                      id="loanDuration"
                      fullWidth
                      component={CustomTextField}
                      name="loanDuration"
                      value={values.loanDuration}
                      onChange={handleChange}
                      title={t('pages.lead.admin.makeOffer.inputs.loanDuration.label')}
                      placeholder={t('pages.lead.admin.makeOffer.inputs.loanDuration.placeholder')}
                      className={classes.textInput}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ inputComponent: CustomNumberField }}
                      disabled={loading || cannotBeUpdated}
                    />
                  </Grid>
                </>
              )}

              <Grid item>
                <CustomDatePicker
                  name="validUntil"
                  label={t('pages.lead.admin.makeOffer.inputs.validUntil.label')}
                  value={values.validUntil}
                  onChange={(date: Moment) => {
                    setFieldValue('validUntil', date ? date.toDate() : null);
                    if (!touched.validUntil) {
                      setTimeout(() => setTouched({ ...(formRef.current?.touched || {}), validUntil: true }), 250);
                    }
                  }}
                  clearable
                  openTo="date"
                  format="DD MMMM YYYY"
                  disabled={loading || cannotBeUpdated}
                  error={touched.validUntil && errors.validUntil ? errors.validUntil : undefined}
                />
              </Grid>

              <FieldArray name="rates">
                {({ remove: removeRate, push: pushRate }) => (
                  <>
                    {values.rates.map((rate, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid container item direction="column" spacing={4} key={`rate-${index}`}>
                        {index > 0 && (
                          <Grid item className={classes.dividerContainer}>
                            <DashedDivider />
                          </Grid>
                        )}

                        <Grid item className={classes.addRateContainer}>
                          <Typography className={classes.rateNumberTitle}>
                            {t('pages.lead.admin.makeOffer.rate', { number: index + 1 })}
                          </Typography>

                          {!cannotBeUpdated && values.rates.length > 1 && (
                            <Button
                              color="primary"
                              variant="outlined"
                              aria-label={`delete rate ${index + 1}`}
                              onClick={() => removeRate(index)}
                              className={classes.deleteRateButton}
                              id={`deleteRateButton${index + 1}`}
                            >
                              {t('global.buttons.delete')}
                            </Button>
                          )}
                        </Grid>

                        <Grid item>
                          <Field
                            id={`rates.${index}.eligibleAmount`}
                            fullWidth
                            component={CustomTextField}
                            name={`rates.${index}.eligibleAmount`}
                            value={rate.eligibleAmount}
                            onChange={handleChange}
                            title={t('pages.lead.admin.makeOffer.inputs.eligibleAmount.label')}
                            placeholder={t('pages.lead.admin.makeOffer.inputs.eligibleAmount.placeholder', {
                              currencySymbol,
                            })}
                            className={classes.textInput}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputComponent: AmountNumberInput }}
                            disabled={loading || cannotBeUpdated}
                            error={!!getRateFieldError(touched, errors, index, 'eligibleAmount')}
                            helperText={getRateFieldError(touched, errors, index, 'eligibleAmount')}
                          />
                        </Grid>

                        {values.productType !== OfferProductType.REVENUE_BASED && (
                          <>
                            <Grid item>
                              <Field
                                id={`rates.${index}.interestRate`}
                                fullWidth
                                component={CustomTextField}
                                name={`rates.${index}.interestRate`}
                                value={rate.interestRate}
                                onChange={handleChange}
                                title={t('pages.lead.admin.makeOffer.inputs.interestRate.label')}
                                placeholder={t('pages.lead.admin.makeOffer.inputs.interestRate.placeholder')}
                                className={classes.textInput}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ inputComponent: PercentageNumberInput }}
                                disabled={loading || cannotBeUpdated}
                                error={!!getRateFieldError(touched, errors, index, 'interestRate')}
                                helperText={getRateFieldError(touched, errors, index, 'interestRate')}
                              />
                            </Grid>

                            <Grid item>
                              <Field
                                id={`rates.${index}.monthlyRepayment`}
                                fullWidth
                                component={CustomTextField}
                                name={`rates.${index}.monthlyRepayment`}
                                value={rate.monthlyRepayment}
                                onChange={handleChange}
                                title={t('pages.lead.admin.makeOffer.inputs.monthlyRepayment.label')}
                                placeholder={t('pages.lead.admin.makeOffer.inputs.monthlyRepayment.placeholder', {
                                  currencySymbol,
                                })}
                                className={classes.textInput}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ inputComponent: AmountNumberInput }}
                                disabled={loading || cannotBeUpdated}
                                error={!!getRateFieldError(touched, errors, index, 'monthlyRepayment')}
                                helperText={getRateFieldError(touched, errors, index, 'monthlyRepayment')}
                              />
                            </Grid>

                            <Grid item>
                              <Field
                                id={`rates.${index}.interestType`}
                                aria-label="interestType"
                                component={CustomRadioField}
                                options={offerInterestTypesOptions}
                                name={`rates.${index}.interestType`}
                                value={rate.interestType}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                  setFieldValue(`rates.${index}.interestType`, event.target.value)
                                }
                                title={t('pages.lead.admin.makeOffer.inputs.interestType.label')}
                                className={classes.textInput}
                                error={!!getRateFieldError(touched, errors, index, 'interestType')}
                                helperText={getRateFieldError(touched, errors, index, 'interestType')}
                              />
                            </Grid>
                          </>
                        )}

                        {values.productType === OfferProductType.REVENUE_BASED && (
                          <Grid item container spacing={3}>
                            <Grid item xs={12} sm={4}>
                              <Field
                                id={`rates.${index}.repaymentPercentage`}
                                fullWidth
                                component={CustomTextField}
                                name={`rates.${index}.repaymentPercentage`}
                                value={rate.repaymentPercentage}
                                onChange={handleChange}
                                title={t('pages.lead.admin.makeOffer.inputs.repaymentPercentage.label')}
                                placeholder={t('pages.lead.admin.makeOffer.inputs.repaymentPercentage.placeholder')}
                                className={classes.textInput}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ inputComponent: PercentageNumberInput }}
                                disabled={loading || cannotBeUpdated}
                                error={!!getRateFieldError(touched, errors, index, 'repaymentPercentage')}
                                helperText={getRateFieldError(touched, errors, index, 'repaymentPercentage')}
                              />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                              <Field
                                id={`rates.${index}.repaymentTerms`}
                                fullWidth
                                component={CustomTextField}
                                name={`rates.${index}.repaymentTerms`}
                                value={rate.repaymentTerms}
                                onChange={handleChange}
                                title={t('pages.lead.admin.makeOffer.inputs.repaymentTerms.label')}
                                className={classes.textInput}
                                InputLabelProps={{ shrink: true }}
                                disabled={loading || cannotBeUpdated}
                                error={!!getRateFieldError(touched, errors, index, 'repaymentTerms')}
                                helperText={getRateFieldError(touched, errors, index, 'repaymentTerms')}
                              />
                            </Grid>
                          </Grid>
                        )}

                        <Grid item>
                          <Field
                            id={`rates.${index}.totalRepayableAmount`}
                            fullWidth
                            component={CustomTextField}
                            name={`rates.${index}.totalRepayableAmount`}
                            value={rate.totalRepayableAmount}
                            onChange={handleChange}
                            title={t('pages.lead.admin.makeOffer.inputs.totalRepayableAmount.label')}
                            placeholder={t('pages.lead.admin.makeOffer.inputs.totalRepayableAmount.placeholder', {
                              currencySymbol,
                            })}
                            className={classes.textInput}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputComponent: AmountNumberInput }}
                            disabled={loading || cannotBeUpdated}
                            error={!!getRateFieldError(touched, errors, index, 'totalRepayableAmount')}
                            helperText={getRateFieldError(touched, errors, index, 'totalRepayableAmount')}
                          />
                        </Grid>
                      </Grid>
                    ))}

                    {!cannotBeUpdated && (
                      <Grid item>
                        <Button
                          color="primary"
                          fullWidth
                          onClick={() => pushRate(initialRate)}
                          className={classes.addRateButton}
                          variant="outlined"
                          id="addAddressButton"
                        >
                          {t('pages.lead.admin.makeOffer.buttons.addRate')}
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </FieldArray>

              <Grid item>
                <Typography className={classes.text}>{t('pages.lead.admin.makeOffer.inputs.notes.label')} </Typography>

                <TextareaAutosize
                  id="notes"
                  value={values.notes || ''}
                  aria-label="minimum height"
                  rowsMin={5}
                  onChange={handleChange}
                  className={touched.notes && errors.notes ? classes.textAreaError : ''}
                  disabled={loading || cannotBeUpdated}
                />

                {touched.notes && errors.notes && <FormHelperText error>{errors.notes}</FormHelperText>}
              </Grid>

              {values.status === OfferStatus.DECLINED && (
                <>
                  <Grid item>
                    <CustomLabel title={t('pages.lead.admin.makeOffer.inputs.rejectionReasonTags.label')} />

                    <Box className={classes.rejectionReasonsContainer}>
                      {rejectionReasonsOptions.map((option) => {
                        const alreadySelected = values.rejectionReasonTags.find((v) => v === option.value);
                        return (
                          <Chip
                            id={option.value}
                            key={option.value}
                            color={alreadySelected ? 'primary' : 'default'}
                            label={option.label}
                            onClick={() => {
                              if (alreadySelected) {
                                setFieldValue(
                                  'rejectionReasonTags',
                                  values.rejectionReasonTags.filter((v) => v !== option.value),
                                );
                              } else {
                                setFieldValue('rejectionReasonTags', [...values.rejectionReasonTags, option.value]);
                              }
                            }}
                            className={classes.rejectionReasonOption}
                          />
                        );
                      })}
                    </Box>

                    {touched.rejectionReasonTags && errors.rejectionReasonTags && (
                      <FormHelperText error>{errors.rejectionReasonTags}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item>
                    <CustomLabel title={t('pages.lead.admin.makeOffer.inputs.rejectionReason.label')} />
                    <TextareaAutosize
                      id="rejectionReason"
                      value={values.rejectionReason || ''}
                      aria-label="minimum height"
                      rowsMin={5}
                      onChange={handleChange}
                      className={touched.rejectionReason && errors.rejectionReason ? classes.textAreaError : ''}
                      disabled={loading}
                    />

                    {touched.rejectionReason && errors.rejectionReason && (
                      <FormHelperText error>{errors.rejectionReason}</FormHelperText>
                    )}
                  </Grid>
                </>
              )}

              {offer?.external_id && (
                <Grid item>
                  <Box>
                    <FormLabel
                      component={() => <CustomLabel title={t('pages.lead.admin.makeOffer.inputs.externalId.label')} />}
                    />

                    <TextField
                      id="externalId"
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      value={offer?.external_id}
                      fullWidth
                      className={classes.textInput}
                      disabled
                    />
                  </Box>
                </Grid>
              )}

              {offer?.decline_reason && (
                <Grid item>
                  <Box mt={1}>
                    <Typography className={classes.declineResonLabel}>
                      {t('pages.lead.admin.makeOffer.declineReason')}
                    </Typography>

                    <RawDataList json={offer?.decline_reason} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Form>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default MakeOfferModal;
