import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 750,
      background: theme.palette.background.default,
      padding: theme.spacing(1.5),
      marginBottom: theme.spacing(2),

      '&:hover': {
        opacity: 1,
        cursor: 'pointer',
      },
    },

    inactive: {
      opacity: 0.2,
    },

    webhookContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 40,
      position: 'relative',
    },
    urlContainer: {
      flex: 1,
      overflow: 'hidden',
    },
    url: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    actionsContainer: {
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(6),
    },
    switchLabel: {
      marginRight: theme.spacing(0.5),
      '& > span': {
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(18),
      },
    },
    deleteButton: {
      height: '36px',
      width: '36px',
    },
    deleteIcon: {
      fontSize: '22px',
      color: theme.palette.primary.main,
    },
    loaderContainer: {
      position: 'absolute',
      right: 175,
    },
    offerStatusContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(1),
      '& > *': {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        marginRight: theme.spacing(1.5),
      },
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: theme.spacing(1),
      },
    },
    offerStatusOption: {
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(16),
    },
    collapseButton: {
      height: '34px',
      width: '34px',
      marginLeft: theme.spacing(1),
    },
    secretButton: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: 600,
      padding: theme.spacing(0.5),
      height: 28,
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    signingSecret: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      padding: theme.spacing(0.5),
      paddingBottom: theme.spacing(1),
      fontWeight: 600,
    },
  }),
);
