import { FC, ChangeEvent } from 'react';
import { Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useApplications } from 'store/applications/hooks';
import useStyles from './HomeOwnerFilter.styles';

const HomeOwnerFilter: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    filters: { isHomeOwner },
    updateApplicationsQuery,
  } = useApplications();

  const onChangeHomeowner = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateApplicationsQuery({ isHomeOwner: checked });
  };

  return (
    <Box className={classes.checkboxContainer}>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!isHomeOwner}
            onChange={onChangeHomeowner}
            name="isHomeowner"
            color="secondary"
            size="medium"
          />
        }
        label={t('pages.dashboard.filters.isHomeowner.title')}
      />
    </Box>
  );
};

export default HomeOwnerFilter;
