import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      marginTop: theme.spacing(2),
      border: `1px solid ${theme.palette.greyLightBorder.main}`,
      padding: '8px 16px',
    },
    title: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 600,
    },
    greyText: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    tooltipContainer: {
      border: `1px solid ${theme.palette.greyLightBorder.main}`,
      padding: theme.spacing(1),
      '& > p': {
        textTransform: 'capitalize',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    labelContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: -20,
      marginBottom: 4,
    },
    label: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(15),
      color: theme.palette.greyLabel.main,
    },
    absContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
