import { ActionReducerMapBuilder, createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';

import { getAppFilters } from 'utils';
import { OverviewDateRange } from 'core/types';
import { ApplicationsQueryFilters, ApplicationsState, ApplicationStatus, TimePeriod } from './types';
import {
  getApplications,
  getApplicationDetails,
  clearApplicationDetails,
  updateApplicationsQuery,
  setApplicationsSearchTerm,
  getApplicationsOverview,
  updateApplicationDetails,
  clearApplicationsFilters,
  setDateRange,
  getOffersOverview,
} from './actions';
import { updateApplicationStatus, updateOfferStatus, rejectApplication } from '../admin/actions';
import { getAllPartners } from '../partners/actions';

const initialFiltersState: ApplicationsQueryFilters = {
  activePage: 0,
  activeFilters: [
    ApplicationStatus.PREQUALIFIED,
    ApplicationStatus.PENDING,
    ApplicationStatus.OFFERS_REQUESTED,
    ApplicationStatus.OFFER_ACCEPTED,
    ApplicationStatus.NEW,
    ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED,
  ],
  fromDate: moment().subtract(3, 'months').toISOString(),
  toDate: moment().endOf('day').toISOString(),
  searchTerm: '',
  assigneeId: undefined,
  region: undefined,
  // deep filters
  minTradingTime: undefined,
  minTradingTimePeriod: TimePeriod.MONTHLY, // default value
  revenuePeriod: TimePeriod.MONTHLY, // default value
  minLoanAmount: undefined,
  maxLoanAmount: undefined,
  isHomeOwner: undefined,
  onlineSales: undefined,
  documentTypes: [],
  partnerId: undefined,
  // only FE
  dateRange: OverviewDateRange.THREE_MONTHS,
};

const getInitialFiltersState = (): ApplicationsQueryFilters => {
  const savedAppFilters = getAppFilters() ?? {};
  return { ...initialFiltersState, ...savedAppFilters, toDate: moment().endOf('day').toISOString() };
};

const initialState: ApplicationsState = {
  loading: false,
  error: false,
  applications: [],
  pendingApplicationsIds: [],
  applicationDetails: null,
  applicationsOverview: { total: 0, data: [] },
  // filters and pagination
  total: -1,
  filters: getInitialFiltersState(),
  offersOverview: { total: 0, data: [] },
};

const applicationsStore = createSlice({
  name: 'applications',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ApplicationsState>) => {
    builder.addCase(getApplications.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.applications = payload.applications;
      state.total = payload.total;
      state.pendingApplicationsIds = payload.applications
        .filter(
          (application) =>
            application.status === ApplicationStatus.OFFERS_REQUESTED ||
            application.status === ApplicationStatus.NEW ||
            application.status === ApplicationStatus.PENDING ||
            application.status === ApplicationStatus.PREQUALIFIED ||
            application.status === ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED,
        )
        .map((app) => app.id);
    });

    builder.addCase(getApplicationDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.applicationDetails = payload;
    });

    builder.addCase(clearApplicationDetails, (state) => {
      state.applicationDetails = null;
    });

    builder.addCase(updateApplicationStatus.fulfilled, (state, { payload }) => {
      if (payload.applications) {
        state.applications = payload.applications;
        state.pendingApplicationsIds = payload.applications
          .filter((application) => application.status === ApplicationStatus.PENDING)
          .map((app) => app.id);
      }
      if (payload.applicationDetails) {
        state.applicationDetails = payload.applicationDetails;
      }
    });

    builder.addCase(rejectApplication.fulfilled, (state, { payload }) => {
      if (payload) {
        state.applicationDetails = payload;
      }
    });

    builder.addCase(updateOfferStatus.fulfilled, (state, { payload }) => {
      if (payload && state.applicationDetails) {
        state.applicationDetails.offers[payload.offerIndex] = payload.updatedOffer;
      }
    });

    builder.addCase(
      updateApplicationsQuery,
      (
        state,
        {
          payload: {
            activePage,
            activeFilters,
            fromDate,
            toDate,
            assigneeId,
            minTradingTime,
            minTradingTimePeriod,
            minRevenue,
            revenuePeriod,
            minLoanAmount,
            maxLoanAmount,
            isHomeOwner,
            onlineSales,
            documentTypes,
            region,
            partnerId,
          },
        },
      ) => {
        if (typeof activePage === 'number') state.filters.activePage = activePage;
        if (activeFilters) state.filters.activeFilters = activeFilters;
        if (fromDate) state.filters.fromDate = fromDate;
        if (toDate) state.filters.toDate = toDate;
        if (typeof minTradingTime === 'number') state.filters.minTradingTime = minTradingTime;
        if (minTradingTimePeriod) state.filters.minTradingTimePeriod = minTradingTimePeriod;
        if (typeof minRevenue === 'number') state.filters.minRevenue = minRevenue;
        if (revenuePeriod) state.filters.revenuePeriod = revenuePeriod;
        if (typeof minLoanAmount === 'number') state.filters.minLoanAmount = minLoanAmount;
        if (typeof maxLoanAmount === 'number') state.filters.maxLoanAmount = maxLoanAmount;
        if (typeof isHomeOwner === 'boolean') state.filters.isHomeOwner = isHomeOwner;
        if (typeof onlineSales === 'number') state.filters.onlineSales = onlineSales;
        if (documentTypes) state.filters.documentTypes = documentTypes;
        if (typeof assigneeId === 'string') state.filters.assigneeId = assigneeId;
        if (typeof region === 'string') state.filters.region = region;
        if (typeof partnerId === 'string') state.filters.partnerId = partnerId;
      },
    );

    builder.addCase(setApplicationsSearchTerm, (state, { payload }) => {
      state.filters.searchTerm = payload;
    });

    builder.addCase(getApplicationsOverview.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.applicationsOverview = payload;
    });

    builder.addCase(updateApplicationDetails, (state, { payload }) => {
      if (state.applicationDetails) {
        state.applicationDetails.funding_amount = payload.fundingAmount;
      }
    });

    builder.addCase(clearApplicationsFilters, (state) => {
      state.filters = initialFiltersState;
    });

    builder.addCase(setDateRange, (state, { payload }) => {
      state.filters.dateRange = payload;
    });

    builder.addCase(getOffersOverview.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.offersOverview.total = payload.total;
      state.offersOverview.data = payload.data;
    });

    builder.addCase(getAllPartners.fulfilled, (state, { payload }) => {
      if (!state.filters.partnerId) return;
      const selectedPartner = payload.find((partner) => partner.id === state.filters.partnerId);
      if (!selectedPartner) state.filters.partnerId = undefined;
    });

    builder.addMatcher(
      isAnyOf(
        getApplications.pending,
        getApplicationDetails.pending,
        getApplicationsOverview.pending,
        getOffersOverview.pending,
      ),
      (state) => {
        state.loading = true;
        state.error = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getApplications.rejected,
        getApplicationDetails.rejected,
        getApplicationsOverview.rejected,
        getOffersOverview.rejected,
      ),
      (state, { error }) => {
        state.loading = false;
        state.error = error?.message || true;
      },
    );
  },
});

export default applicationsStore.reducer;
