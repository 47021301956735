import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Select, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { AlertDialog } from 'components/AlertDialog';
import { AlertMessage } from 'components/alerts';
import { DocumentUploadMulti } from 'components/DocumentUpload';
import { additionalDocumentOptions } from 'core/constants';
import { AlertMessageStatus, CompanyDocumentType, DocUploadResponse } from 'core/types';
import { uploadCompanyDocument as uploadCompanyDocumentApi, deleteDocument as deleteDocumentApi } from 'http/admin';
import { useAdmin } from 'store/admin/hooks';
import { CompanyDetails, CompanyDocument } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import useStyles from '../LeadDetails/LeadDetails.styles';
import { DocumentList } from './components';
import MergedDocumentList from './components/MergedDocumentList/MergeDocumentList';

interface CompanyDocumentsProps {
  company?: CompanyDetails;
  appId: string;
}

const CompanyDocuments: FC<CompanyDocumentsProps> = ({ company, appId }) => {
  const [loading, setLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [documentType, setDocumentType] = useState<CompanyDocumentType>(CompanyDocumentType.BANK_STATEMENT);
  const [uploadedDocs, setUploadedDocs] = useState<CompanyDocument[]>(company?.documents ?? []);
  const [selectedDoc, setSelectedDoc] = useState<CompanyDocument | null>(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const { isAdmin, isLenderAdmin, isCompanyOwner, isLender } = useAuth();
  const { setError } = useAdmin();

  const [companyDocs, emailAttachments] = useMemo(() => {
    return uploadedDocs.reduce(
      (acc, val): [CompanyDocument[], CompanyDocument[]] => {
        if (val.type !== CompanyDocumentType.EMAIL_ATTACHMENTS) {
          acc[0].push(val);
        } else {
          acc[1].push(val);
        }
        return acc;
      },
      [[], []] as [CompanyDocument[], CompanyDocument[]],
    );
  }, [uploadedDocs]);

  const afterUpdate = (documents: CompanyDocument[]) => {
    setUploadedDocs((prevState) => {
      return [
        ...prevState.filter((doc) => documents.every((document) => document.name !== doc.name)),
        ...(documents as CompanyDocument[]),
      ];
    });
    setUploadSuccess(true);
  };

  const uploadDocument = async (data: FormData): Promise<DocUploadResponse | null> => {
    if (company?.id) {
      return uploadCompanyDocumentApi(company.id, data);
    }
    return null;
  };

  const onUploadSuccess = (documents: CompanyDocument[]) => {
    afterUpdate(documents);
  };

  const clearUploadSuccess = () => setUploadSuccess(false);

  const handleCancel = () => setSelectedDoc(null);

  const handleConfirmDelete = async () => {
    if (!(selectedDoc && company?.id)) return;
    setLoading(true);
    try {
      await deleteDocumentApi(company.id, selectedDoc.id as string);
      setUploadedDocs((prevState) => prevState.filter((doc) => doc.id !== selectedDoc.id));
      setSelectedDoc(null);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  const isAdminUser = isAdmin || isLenderAdmin;

  return (
    <>
      <Grid container spacing={3}>
        {isAdmin && (
          <Grid item xs={12}>
            <Box>
              <Typography className={classes.uploadDocTitle}>{t('pages.lead.sections.documents.upload')}</Typography>

              <Box className={classes.uploadDocContainer}>
                <Box className={classes.selectContainer}>
                  <Select
                    label={t('pages.lead.sections.documents.type')}
                    native
                    IconComponent={() => <ExpandMore className={classes.expandIcon} />}
                    variant="outlined"
                    onChange={(
                      event: ChangeEvent<{
                        name?: string | undefined;
                        value: unknown;
                      }>,
                    ) => setDocumentType((event.target.value as CompanyDocumentType) || undefined)}
                    value={documentType}
                  >
                    {additionalDocumentOptions.map(({ label, value }) => {
                      return (
                        <option key={`option${value}`} value={value}>
                          {label}
                        </option>
                      );
                    })}
                  </Select>
                </Box>
              </Box>

              <DocumentUploadMulti
                uploadDocument={uploadDocument}
                onUploadSuccess={onUploadSuccess}
                documentType={documentType}
                companyDocs={companyDocs}
                afterUpdate={afterUpdate}
              />
            </Box>
          </Grid>
        )}

        {company?.id && (
          <Grid item xs={12}>
            <DocumentList
              title={t('pages.lead.sections.documents.download')}
              companyId={company.id}
              documents={companyDocs}
              setDocuments={setUploadedDocs}
            />
          </Grid>
        )}

        {isAdminUser && (
          <Grid item xs={12}>
            <MergedDocumentList
              title={t('pages.lead.sections.documents.merged')}
              appId={appId}
              documents={companyDocs}
            />
          </Grid>
        )}

        {!isCompanyOwner && !isLender && company?.id && (
          <Grid item xs={12}>
            <DocumentList
              title={t('pages.lead.sections.documents.emailAttachments')}
              companyId={company.id}
              documents={emailAttachments}
            />
          </Grid>
        )}
      </Grid>

      <AlertDialog
        open={!!selectedDoc}
        handleCancel={handleCancel}
        handleConfirm={handleConfirmDelete}
        dialogContentTitle={selectedDoc?.name ?? t('pages.lead.admin.deleteDocument.title')}
        dialogContentText={t('pages.lead.admin.deleteDocument.description')}
        confirmButtonTitle={t('pages.lead.admin.deleteDocument.buttons.confirm')}
        loading={loading}
      />

      <AlertMessage
        open={uploadSuccess}
        onClose={clearUploadSuccess}
        message={t('global.uploadDocumentSuccess')}
        status={AlertMessageStatus.SUCCESS}
      />
    </>
  );
};

export default CompanyDocuments;
