import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 196,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    status: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 600,
      wordBreak: 'break-word',
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    semibold: {
      fontWeight: 600,
    },
    titleContainer: {
      display: 'flex',
      position: 'relative',
    },
    chip: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 700,
      lineHeight: theme.typography.pxToRem(20),
      marginLeft: theme.spacing(1),
      height: 22,
      padding: '1px 4px',
      borderRadius: 4,
      marginTop: -1,
      backgroundColor: theme.palette.chipGreyBackground.main,
      '& span': {
        padding: 0,
      },
    },
    tooltipContainer: {
      border: `1px solid ${theme.palette.greyLightBorder.main}`,
      padding: theme.spacing(1),
      '& > p': {
        textTransform: 'capitalize',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    labelContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: -20,
      marginBottom: 4,
    },
    label: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(15),
      color: theme.palette.greyLabel.main,
    },
    iconContainer: {
      width: 80,
      overflow: 'hidden',
      position: 'absolute',
      right: 0,
      top: 0,
      '& img, & svg': {
        maxWidth: '100%',
        height: 'auto',
      },
    },
    chartContainer: {
      width: '100%',
    },
  }),
);
