import { FC } from 'react';
import { Box, FormLabel, TextareaAutosize, FormHelperText } from '@material-ui/core';
import { FieldProps } from 'formik/dist/Field';
import clsx from 'clsx';

import useStyles from './CustomTextarea.styles';
import { CustomLabel } from '../CustomLabel';

interface CustomTextareaProps extends FieldProps {
  title: string;
  value: string;
  placeholder?: string;
  tooltipMessage?: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
}

const CustomTextarea: FC<CustomTextareaProps> = ({
  title,
  placeholder,
  form,
  field,
  tooltipMessage,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const hasError = form.touched[field.name] && form.errors[field.name];

  return (
    <Box>
      <FormLabel component={() => <CustomLabel title={title} tooltipMessage={tooltipMessage} />} />

      <TextareaAutosize
        placeholder={placeholder}
        rowsMin={5}
        className={clsx([className, hasError && classes.error])}
        {...field}
        {...rest}
        value={field.value || ''}
      />

      {hasError && <FormHelperText error>{form.errors[field.name]}</FormHelperText>}
    </Box>
  );
};

export default CustomTextarea;
