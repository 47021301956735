import { useState, useCallback } from 'react';

import { getCompanyValuations as getCompanyValuationsApi } from 'http/companies';
import { EcommerceProviders } from 'core/types';
import { CompanyValuation } from 'store/applications/types';

interface CompanyValuationsReturnHook {
  loading: boolean;
  error: string | boolean;
  valuations: CompanyValuation[];
  getCompanyValuations: (companyId: string, provider: EcommerceProviders) => Promise<void>;
}

const useCompanyValuations = (): CompanyValuationsReturnHook => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [valuations, setValuations] = useState<CompanyValuation[]>([]);

  const getCompanyValuations = useCallback(async (companyId: string, provider: EcommerceProviders) => {
    setLoading(true);
    try {
      const data = await getCompanyValuationsApi(companyId, provider);
      setValuations(data.reverse());
    } catch (err) {
      setError((err as Error)?.message || true);
    }
    setLoading(false);
  }, []);

  return { loading, error, valuations, getCompanyValuations };
};

export default useCompanyValuations;
