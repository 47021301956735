import {
  OverviewDateRange,
  CompanyDocumentType,
  EcommerceProviders,
  RejectionReasonTag,
  SupportedCurrencies,
} from 'core/types';
import { MonthlyRepaymentType, OfferProductType } from 'store/admin/types';
import { PartnerDetails } from 'store/partners/types';

export enum ApplicationStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  PREQUALIFIED = 'PREQUALIFIED',
  ADDITIONAL_DETAILS_REQUESTED = 'ADDITIONAL_DETAILS_REQUESTED',
  OFFERS_REQUESTED = 'OFFERS_REQUESTED',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  FUNDS_DISBURSED = 'FUNDS_DISBURSED',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
}

export enum AddressStatus {
  TENANT = 'tenant',
  OWNER_WITH_MORTGAGE = 'owner_with_mortgage',
  OWNER_NO_MORTGAGE = 'owner_no_mortgage',
  RENT_FREE = 'rent_free',
}

export interface Address {
  id: string;
  line_1: string;
  city?: string | null;
  postcode: string;
  house_number?: string | null;
  line_2?: string | null;
  county?: string | null;
  region?: string | null;
  country_code: string;
  start_date?: string | null;
  current: boolean;
  property_equity?: number | null;
  status?: AddressStatus | null;
}

export enum CompanyType {
  LLC = 'llc',
  LTD = 'ltd',
  LLP = 'llp',
  SOLE_TRADER = 'sole_trader',
  PLC = 'plc',
}

export interface CompanyPerson {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  masked_email?: string;
  phone_number: string;
  date_of_birth: string;
  director: boolean;
  applicant: boolean;
  company_id: string;
  nationality?: string;
  country_of_residence?: string;
  occupation?: string;
  appointed_on?: string;
  resigned_on?: string;
}

export interface Company {
  id: string;
  trading_name: string;
  registered_name: string;
  registered_number: string;
  type: CompanyType;
  region: string;
  formation_date: string;
  trading_from_date?: string;
  website?: string | null;
  description?: string | null;
  address: Address;
  vat_number?: string | null;
  vat_registration_date?: Date | null;
  director: CompanyPerson;
  company_profile?: CompanyHouseProfile;
  financial_information?: CompanyFinancialInformation;
  sic_codes: string[];
}

export enum FundingReason {
  DAILY_OPERATIONS = 'DAILY_OPERATIONS',
  PURCHASING_STOCK = 'PURCHASING_STOCK',
  LOAN_REFINANCE = 'LOAN_REFINANCE',
  MARKETING = 'MARKETING',
  HIRING = 'HIRING',
  PAYING_TAX_BILL = 'PAYING_TAX_BILL',
  PURCHASING_ASSET = 'PURCHASING_ASSET',
  DEBT_CONSOLIDATION = 'DEBT_CONSOLIDATION',
  WORKING_CAPITAL = 'WORKING_CAPITAL',
  BUYING_PROPERTY = 'BUYING_PROPERTY',
  OTHER = 'OTHER',
}

export const fundingReasonLabels = {
  [FundingReason.DAILY_OPERATIONS]: 'Daily operations',
  [FundingReason.PURCHASING_STOCK]: 'Purchasing stock',
  [FundingReason.LOAN_REFINANCE]: 'Loan refinance',
  [FundingReason.MARKETING]: 'Marketing',
  [FundingReason.HIRING]: 'Hiring',
  [FundingReason.PAYING_TAX_BILL]: 'Paying a tax bill',
  [FundingReason.PURCHASING_ASSET]: 'Purchase company asset(s)',
  [FundingReason.DEBT_CONSOLIDATION]: 'Consolidate debts',
  [FundingReason.WORKING_CAPITAL]: 'Access working capital',
  [FundingReason.BUYING_PROPERTY]: 'Buying a property',
  [FundingReason.OTHER]: 'Other',
};

export enum ApplicationRegion {
  UK = 'UK',
  USA = 'USA',
  IRL = 'IRL',
}

export interface Application {
  id: string;
  account_id: string;
  company: Company;
  status: ApplicationStatus;
  partner_id: string;
  partner_name?: string;
  funding_amount?: number;
  funding_date?: Date;
  funding_reasons?: FundingReason[];
  credit_limit?: number;
  created_at?: string;
  // temp - change to optional
  updated_at: string;
  assignee_id?: string;
  assignee_name?: string;
  status_updated_at?: string;
  region: ApplicationRegion;
}

export interface ApplicationsListResponse {
  applications: Application[];
  total: number;
  page: number;
  per_page: number;
}

export enum AccountType {
  PARTNER = 'partner',
  LENDER = 'lender',
}

export interface Account {
  id: string;
  name: string;
  type: AccountType;
}

export interface CompanyFinancialInformation {
  company_id: string;
  monthly_revenue: number;
  year_to_date_revenue: number;
  gross_margin_percentage?: number;
  business_customer_revenue_percentage?: number;
  cash_balance?: number;
  debit_balance?: number;
  inventory_balance?: number;
  receivable_balance?: number;
  debt_balance?: number;
  repeat_customers_revenue_percentage: number;
  online_sales_revenue_percentage: number;
}

export interface CompanyPersonWithAddress extends CompanyPerson {
  addresses: Address[];
}

export interface CompanyCCJ {
  id: string;
  company_id: string;
  partner_id?: string;
  type?: CCJType;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details?: Record<string, any>;
}

export enum CCJType {
  ACTIVE = 'ACTIVE',
  HISTORIC = 'HISTORIC',
}

export interface CompanyDocument {
  id?: string;
  type?: string;
  name?: string;
  url?: string;
}

interface IObjectKeys {
  // eslint-disable-next-line
  [key: string]: any;
}
export interface CompanyHouseProfile extends IObjectKeys {
  can_file: boolean;
  company_name: string;
  company_number: string;
  date_of_creation: string;
  etag: string;
  has_been_liquidated: boolean;
  has_charges: boolean;
  has_insolvency_history: boolean;
  has_super_secure_pscs: boolean;
  jurisdiction: string;
  last_full_members_list_date: string;
  registered_office_is_in_dispute: string;
  status: string;
  type: string;
  undeliverable_registered_office_address: string;
  accounts: {
    accounting_reference_date: {
      day: number;
      month: number;
    };
    last_accounts: {
      made_up_to: string;
      // eslint-disable-next-line
      type: any;
    };
    next_due: string;
    next_made_up_to: string;
    overdue: boolean;
  };
  confirmation_statement: {
    last_made_up_to: string;
    next_due: string;
    next_made_up_to: string;
    overdue: boolean;
  };
  links: {
    persons_with_significant_control: string;
    persons_with_significant_control_statements: string;
    registers: string;
    self: string;
  };
}

export interface EcommerceProvider {
  id: string;
  provider: EcommerceProviders;
  name?: string;
  domain?: string;
  logo_uri?: string;
  syncing: boolean;
  syncStartedAt?: string;
  syncEndedAt?: string;
  syncError?: string;
}

export interface CompanyAccounts {
  account_id: string;
  account_sub_type: string | null;
  account_type: string | null;
  company_id: string;
  description: string | null;
  id: string;
  identification: string | null;
  bank_name: string;
  nickname: string | null;
  scheme_name: string | null;
  secondary_identification: string | null;
  status: string;
}

export interface CompanyDetails extends Company {
  financial_information?: CompanyFinancialInformation;
  people: CompanyPersonWithAddress[];
  ccjs: CompanyCCJ[];
  documents: CompanyDocument[];
  ecommerce_providers: EcommerceProvider[];
  company_accounts: CompanyAccounts[];
}

export enum OfferStatus {
  PENDING = 'PENDING',
  DEFERRED = 'DEFERRED', // Already applied for a loan
  DECLINED = 'DECLINED',
  ADDITIONAL_DETAILS_REQUESTED = 'ADDITIONAL_DETAILS_REQUESTED',
  OFFERED = 'OFFERED',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  LENDER_CALL_FAILED = 'LENDER_CALL_FAILED',
  RULES_DECLINED = 'RULES_DECLINED',
  APPLICANT_DECLINED = 'APPLICANT_DECLINED',
}

export interface OfferRate {
  interest_rate: number;
  principal: number;
  effective_on_day?: number;
  selected?: boolean;
  rate_id: string;
  monthly_repayment_type: MonthlyRepaymentType;
  monthly_repayment: number;
  total_repayment: number;
}

export interface OfferRevenueRepayment {
  currency: SupportedCurrencies;
  revenue_repayment_id: string;
  selected: false;
  sweep: number;
  sweep_terms: string;
  total_get: number;
  total_repayment: number;
}

export enum OfferType {
  API = 'API',
  MANUAL = 'MANUAL',
}

export interface Offer {
  id: string;
  application_id: string;
  lender_name: string;
  lender_logo?: string;
  status: OfferStatus;
  type: OfferType;
  currency: SupportedCurrencies;
  notes?: string;
  rejection_reason?: string;
  valid_until?: string;
  created_at: string;
  updated_at: string;
  rejection_reason_tags?: RejectionReasonTag[];
  decline_reason?: string; // JSON
  lender_raw_response?: string; // JSON
  metadata?: string; // JSON
  external_id?: string;
  // added when role is company owner
  unique_id?: string;
  loan_amount?: number;
  selected?: boolean;
}

export interface BasicOffer extends Offer {
  product_type: OfferProductType.CREDIT_LINE | OfferProductType.REVOLVING_CREDIT_FACILITY | OfferProductType.TERM_LOAN;
  min_credit_offer: number;
  max_credit_offer: number;
  rates: OfferRate[];
  duration_in_months: number;
  interest_type: string;
  // added when role is company owner
  rate_id?: string;
  monthly_repayment?: number;
  monthly_repayment_type?: MonthlyRepaymentType;
  total_repayment?: number;
}

export interface RevenueOffer extends Offer {
  product_type: OfferProductType.REVENUE_BASED;
  revenueRepayments: OfferRevenueRepayment[];
  // added when role is company owner
  repayment_id?: string;
  sweep?: number;
  sweep_terms?: string;
  total_repayment?: number;
}

export type LenderOffer = BasicOffer | RevenueOffer;

export interface Lender {
  id: string;
  account_id?: string;
  name: string;
  displayed_name: string;
  address?: string;
  contact_person_name: string;
  contact_person_email: string;
  logo_url?: string;
  website?: string;
}

export interface ApplicationDetailsRequested {
  offer_id?: string;
  document_types: CompanyDocumentType[];
  notes?: string;
  resolved: boolean;
  admin_email?: string;
}

export interface ApplicationRejection {
  unmet_criteria?: Record<string, string[]>;
  rejection_reason?: string;
  rejected_at: string;
  rejection_reason_tags?: RejectionReasonTag[];
}

export interface ApplicationDetails {
  id: string;
  funding_amount?: number;
  funding_date?: Date;
  funding_reasons?: FundingReason[];
  credit_limit?: number;
  application_rejection?: ApplicationRejection;
  unmet_criteria?: Record<string, string[]>;
  internal_notes?: string;
  external_notes?: string;
  created_at?: string;
  updated_at?: string;
  status: ApplicationStatus;
  partner?: PartnerDetails;
  company: CompanyDetails;
  offers: LenderOffer[];
  details_requested?: ApplicationDetailsRequested[];
  assignee: { id: string };
  credit_score_check_consent_given_at?: Date;
  region: ApplicationRegion;
  active_automated_emails?: boolean;
  masked_email?: string;
}

export interface ApplicationsQueryFilters {
  activePage: number;
  activeFilters: ApplicationStatus[];
  fromDate: string;
  toDate: string;
  searchTerm: string;
  assigneeId?: string;
  region?: ApplicationRegion;
  // deep filters
  minTradingTime?: number;
  minTradingTimePeriod?: TimePeriod;
  minRevenue?: number;
  revenuePeriod?: TimePeriod;
  minLoanAmount?: number;
  maxLoanAmount?: number;
  isHomeOwner?: boolean;
  onlineSales?: number;
  documentTypes: CompanyDocumentType[];
  partnerId?: string;
  // only FE
  dateRange?: OverviewDateRange;
}

export interface ApplicationsState {
  loading: boolean;
  error: boolean | string;
  applications: Application[];
  pendingApplicationsIds: string[];
  applicationDetails: ApplicationDetails | null;
  applicationsOverview: {
    total: number;
    data: ApplicationOverviewItem[];
  };
  // for pagination
  total: number;
  filters: ApplicationsQueryFilters;
  offersOverview: {
    total: number;
    data: OfferOverviewItem[];
  };
}

export interface ApplicationsReturnHook extends ApplicationsState {
  getApplications: () => void;
  getApplicationDetails: (id: string) => void;
  clearApplicationDetails: () => void;
  updateApplicationsQuery: (queryOptions: Partial<ApplicationsQueryFilters>) => void;
  setApplicationsSearchTerm: (searchTerm: string) => void;
  getApplicationsOverview: (query: OverviewQuery) => void;
  updateApplicationDetails: (data: { fundingAmount: number }) => void;
  clearApplicationsFilters: () => void;
  setDateRange: (dateRange?: OverviewDateRange) => void;
  getOffersOverview: (query: OverviewQuery) => void;
}

export interface NormalizedApplication {
  id: string;
  companyName: string;
  directorName: string;
  applicationStatus: ApplicationStatus;
  loanAmount: number;
  dateStarted: string;
}

export interface ApplicationQuery {
  per_page?: number;
  page?: number;
  start?: string; // date
  end?: string; // date
  status?: string;
  search?: string;
  assigneeId?: string;
  min_trading_date?: string; // date
  min_revenue?: number;
  revenue_period?: TimePeriod;
  document_exists?: boolean;
  min_loan_amount?: number;
  max_loan_amount?: number;
  is_homeowner?: boolean;
  min_online_sales?: number;
  max_online_sales?: number;
  document_types?: string;
  region?: string;
  partnerId?: string;
}

export interface ApplicationOverview {
  status: ApplicationStatus;
  total: number;
  data: Record<string, number>;
}

export interface CompanyValuation {
  id: string;
  provider: EcommerceProviders;
  valuation_date: string;
  valuation: number;
  credit_limit: number;
  total_sales?: number;
}

export interface CompanyTransaction {
  id: string;
  company_id: string;
  provider_id: string;
  provider: EcommerceProviders;
  transaction_date: string;
  description?: string;
  amount: number;
  currency: string;
  type: string;
  merchant_name?: string;
  category?: string;
  classification?: string;
}

export interface CompanyTransactionsList {
  transactions: CompanyTransaction[];
  total: number;
  page: number;
  per_page: number;
}

export interface BankTransaction {
  id: string;
  amount: number;
  booking_date_time: Date | null;
  company_account_id: string;
  company_id: string;
  credit_debit_indicator: string;
  currency: string;
}
export interface BalanceTransaction {
  id: string;
  company_id: string;
  company_account_id: string;
  amount: number;
  currency: string;
  credit_debit_indicator: string;
  type: string;
  account_id: string;
  date_time: Date | null;
}
export interface BalanceTransactionsList {
  balances: BalanceTransaction[];
  total: number;
  page: number;
  per_page: number;
}
export interface BankTransactionsList {
  transactions: BankTransaction[];
  total: number;
  page: number;
  per_page: number;
}

export interface CompanyTransactionsQuery {
  per_page?: number;
  page?: number;
  provider: EcommerceProviders;
}

export interface BankTransactionsQuery {
  per_page?: number;
  page?: number;
  companyAccountId: string;
}
export interface BalanceTransactionsQuery {
  per_page?: number;
  page?: number;
  companyAccountId: string;
}

export enum TimePeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum OnlineSales {
  LESS_THAN_25 = 0.24,
  BETWEEN_25_50 = 0.26,
  BETWEEN_50_75 = 0.51,
  MORE_THAN_75 = 0.76,
}

export interface OfferOverviewItem {
  id: string;
  status: OfferStatus;
  updated_at: string;
  lender_name: string;
  lender_logo?: string;
}

export interface OverviewQuery {
  start?: string; // date
  end?: string; // date
}

export interface OffersOverview {
  name: string;
  total: number;
  data: Record<string, number>;
  logo?: string;
}

export interface ApplicationOverviewItem {
  id: string;
  status: ApplicationStatus;
  updated_at: string;
}

export enum WebhookNotificationTypes {
  OFFER_UPDATE = 'OFFER_UPDATE',
  APPLICATION_UPDATE = 'APPLICATION_UPDATE',
}
