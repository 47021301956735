import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { usePrevious } from 'hooks';
import { mapApplicationStatusToLabel } from 'core/utils';
import { useApplications } from 'store/applications/hooks';
import useMainStyles from 'core/styles';
import { getApplicationsByStatusOverview } from 'store/applications/utils';
import { useAuth } from 'store/auth/hooks';
import { Overview } from '../Overview';
import useStyles from './AppsOverview.styles';

interface AppsOverviewProps {
  fromDate: Date;
  toDate: Date;
  preview?: boolean;
}

const AppsOverview: FC<AppsOverviewProps> = ({ fromDate, toDate, preview }) => {
  const classes = useStyles();
  const mainClasses = useMainStyles();
  const { t } = useTranslation();
  const { applicationsOverview, getApplicationsOverview } = useApplications();
  const { isLender } = useAuth();
  const prevFromDate = usePrevious(fromDate);
  const prevToDate = usePrevious(toDate);

  useEffect(() => {
    if (!isLender && (prevFromDate?.getTime() !== fromDate.getTime() || prevToDate?.getTime() !== toDate.getTime())) {
      getApplicationsOverview({
        start: fromDate.toISOString(),
        end: toDate.toISOString(),
      });
    }
  }, [fromDate, getApplicationsOverview, isLender, prevFromDate, prevToDate, toDate]);

  const parsedApplicationsOverview = useMemo(() => {
    return getApplicationsByStatusOverview(applicationsOverview.data, fromDate.toISOString(), toDate.toISOString());
  }, [applicationsOverview.data, fromDate, toDate]);

  const pieChartData = useMemo(() => {
    return parsedApplicationsOverview.data.map((overview) => ({
      name: mapApplicationStatusToLabel(overview.status),
      value: overview.total,
    }));
  }, [parsedApplicationsOverview]);

  const pieChartColorRange = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#2c373e',
    '#E58C8A',
    '#d95359',
    '#706993',
    '#F4E8C1',
    '#331E38',
  ];

  return (
    <Grid item xs={12}>
      <Typography className={clsx([classes.title, classes.bold])}>
        <Avatar variant="square" sizes="small" color="primary" className={classes.titleAvatar}>
          {applicationsOverview.total}
        </Avatar>

        {t('pages.home.applications.title')}
      </Typography>

      {preview && (
        <Box
          className={classes.cardsContainer}
          style={{
            height: '500px',
            width: '100%',
          }}
        >
          <Paper className={mainClasses.pieChartContainer}>
            {applicationsOverview.total > 0 ? (
              <>
                <ResponsiveContainer width="70%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieChartData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {pieChartData.map((entry, index) => {
                        return (
                          <Cell
                            key={`cell-${entry.value}`}
                            fill={pieChartColorRange[index % pieChartColorRange.length]}
                          />
                        );
                      })}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
                <div>
                  <List>
                    {pieChartData.map((entry, index) => {
                      if (entry.value > 0) {
                        return (
                          <ListItem key={`cell-${entry.value}`}>
                            <ListItemAvatar>
                              <Avatar
                                variant="square"
                                style={{ backgroundColor: pieChartColorRange[index % pieChartColorRange.length] }}
                              >
                                {entry.value}
                              </Avatar>
                            </ListItemAvatar>

                            <ListItemText secondary={entry.name} />
                          </ListItem>
                        );
                      }
                      return <></>;
                    })}
                  </List>
                </div>
              </>
            ) : (
              <Alert severity="warning">{t('pages.home.applications.warning')}</Alert>
            )}
          </Paper>
        </Box>
      )}
      {!preview && (
        <Grid container item spacing={2} className={classes.cardsContainer}>
          {parsedApplicationsOverview.data.map((overview) => {
            const data = Object.entries(overview.data).map(([date, amount]) => ({ date, amount }));
            return (
              <Grid item xs={12} sm={6} md={3} className={classes.cardContainer} key={overview.status}>
                <Overview
                  totalCount={applicationsOverview.total}
                  name={overview.status}
                  total={overview.total}
                  data={data}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

export default AppsOverview;
