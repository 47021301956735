import { FC, useMemo } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

import useStyles from '../LeadDetails/LeadDetails.styles';

interface RawDataListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: string;
}

const RawDataList: FC<RawDataListProps> = ({ json }) => {
  const classes = useStyles();

  const data = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let parsedData: Record<string, any> = {};
    try {
      parsedData = JSON.parse(json);
    } catch (error) {
      /** */
    }
    return parsedData;
  }, [json]);

  return (
    <List>
      {Object.keys(data).map((key: string) => {
        return (
          <ListItem className={classes.horizontalItem} key={key}>
            <ListItemText className={classes.horizontalItemKey}>{key}</ListItemText>

            <ListItemText className={classes.horizontalItemValue}>{String(data[key])}</ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

export default RawDataList;
