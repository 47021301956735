import { ChangeEvent, FC } from 'react';
import { Checkbox, FormControl, FormControlLabel, OutlinedInput, Select, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { CompanyDocumentType } from 'core/types';
import { useApplications } from 'store/applications/hooks';
import useStyles from './DocumentTypesFilter.styles';

const DocumentTypesFilter: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    filters: { documentTypes },
    updateApplicationsQuery,
  } = useApplications();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetName = event.target.name as CompanyDocumentType;
    if (documentTypes.includes(targetName)) {
      updateApplicationsQuery({ documentTypes: documentTypes.filter((filter) => filter !== targetName) });
    } else {
      updateApplicationsQuery({ documentTypes: [...documentTypes, targetName] });
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={documentTypes}
        input={<OutlinedInput label="Tag" />}
        IconComponent={() => <ExpandMore className={classes.expandIcon} />}
        renderValue={() => documentTypes.join(', ')}
        className={classes.select}
      >
        {Object.values(CompanyDocumentType).map((filter) => (
          <FormControlLabel
            key={filter}
            className={classes.controlLabel}
            control={
              <Checkbox
                checked={documentTypes.includes(filter)}
                onChange={handleChange}
                name={filter}
                color="primary"
                className={classes.checkbox}
              />
            }
            label={<Typography className={classes.strongText}>{filter}</Typography>}
          />
        ))}
      </Select>
      <Typography variant="caption" id="demo-multiple-checkbox-label">
        {t('pages.dashboard.filters.documentTypes.title')}
      </Typography>
    </FormControl>
  );
};

export default DocumentTypesFilter;
