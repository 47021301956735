import { Avatar, Button, ButtonGroup, Chip, Container, Grid, Select } from '@material-ui/core';
import { AccessTime, Clear, ExpandMore, HighlightOff, MonetizationOn } from '@material-ui/icons';
import isEqual from 'lodash.isequal';
import { ChangeEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomSearch } from 'components/inputs';
import { Loader } from 'components/Loader';
import { overviewFilterOptions, SEARCH_DEBOUNCED_DELAY } from 'core/constants';
import { OverviewDateRange, UserQuery, UserRole } from 'core/types';
import { useDebounce, usePrevious } from 'hooks';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { ApplicationStatus } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { getDateRangeByFilter } from 'utils';
import { LeadsTable } from './components';
import { DocumentTypesFilter, HomeOwnerFilter, OnlineSalesFilter } from './components/filters';
import useStyles from './Leads.styles';

const pendingStatuses = [
  ApplicationStatus.PREQUALIFIED,
  ApplicationStatus.PENDING,
  ApplicationStatus.OFFERS_REQUESTED,
  ApplicationStatus.OFFER_ACCEPTED,
  ApplicationStatus.NEW,
  ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED,
];

const fundedStatuses = [ApplicationStatus.FUNDS_DISBURSED];

const notFundedStatuses = [ApplicationStatus.CLOSED, ApplicationStatus.REJECTED];

const adminQuery: UserQuery = {
  per_page: 1000,
  page: 1,
  role: `${UserRole.ADMIN},${UserRole.SUPER_ADMIN}`,
};

const Leads: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    loading,
    filters: {
      searchTerm,
      activeFilters,
      assigneeId,
      minTradingTime,
      minRevenue,
      minLoanAmount,
      maxLoanAmount,
      isHomeOwner,
      onlineSales,
      documentTypes,
      dateRange,
      region,
      partnerId,
    },
    getApplications,
    updateApplicationsQuery,
    setApplicationsSearchTerm,
    clearApplicationsFilters,
    setDateRange,
  } = useApplications();
  const { isAdmin, isLender } = useAuth();
  const { getAdminUsers } = useAdmin();
  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DEBOUNCED_DELAY);
  const prevDebouncedSearchTerm = usePrevious(debouncedSearchTerm);

  useEffect(() => {
    if (isLender) {
      updateApplicationsQuery({ activeFilters: [ApplicationStatus.OFFERS_REQUESTED] });
    }
  }, [isLender, updateApplicationsQuery]);

  useEffect(() => {
    getAdminUsers(adminQuery);
  }, [getAdminUsers]);

  useEffect(() => {
    if (prevDebouncedSearchTerm !== undefined && prevDebouncedSearchTerm !== debouncedSearchTerm) {
      if (debouncedSearchTerm) updateApplicationsQuery({ activePage: 0 });
      else getApplications();
    }
  }, [debouncedSearchTerm, getApplications, prevDebouncedSearchTerm, updateApplicationsQuery]);

  const isDefaultStatusFilter = isLender
    ? activeFilters.includes(ApplicationStatus.OFFERS_REQUESTED) ||
      activeFilters.includes(ApplicationStatus.OFFER_ACCEPTED)
    : isEqual(activeFilters, pendingStatuses);
  const isFundedStatusFilter = isEqual(activeFilters, fundedStatuses);
  const isNotFundedStatusFilter = isEqual(activeFilters, notFundedStatuses);

  const handleInProgressLeads = () => {
    const appStatusFilters = isLender ? [ApplicationStatus.OFFERS_REQUESTED] : pendingStatuses;
    updateApplicationsQuery({ activeFilters: appStatusFilters });
  };

  const handleFundedLeads = () => {
    updateApplicationsQuery({ activeFilters: fundedStatuses });
  };

  const handleNotFundedLeads = () => {
    updateApplicationsQuery({ activeFilters: notFundedStatuses });
  };

  const handleDateRangeChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const dateRangeFilter = event.target.value as OverviewDateRange;
    setDateRange(dateRangeFilter);
    const { start, end } = getDateRangeByFilter(dateRangeFilter);
    updateApplicationsQuery({ fromDate: start.toISOString(), toDate: end.toISOString(), activePage: 0 });
  };

  // const handleMyOffersChange = () => {
  //   if (activeFilters.includes(ApplicationStatus.OFFER_ACCEPTED)) {
  //     updateApplicationsQuery({ activeFilters: [ApplicationStatus.OFFERS_REQUESTED] });
  //     return;
  //   }
  //   updateApplicationsQuery({ activeFilters: [ApplicationStatus.OFFER_ACCEPTED] });
  // };

  const onSearchChange = (term: string) => {
    setApplicationsSearchTerm(term);
    setDateRange(OverviewDateRange.ALL_TIME);
    const { start, end } = getDateRangeByFilter(OverviewDateRange.ALL_TIME);
    updateApplicationsQuery({
      fromDate: start.toISOString(),
      toDate: end.toISOString(),
      activePage: 0,
      activeFilters: [...pendingStatuses, ...fundedStatuses, ...notFundedStatuses],
    });
  };

  const clearFilters = () => {
    setDateRange();
    clearApplicationsFilters();
  };

  const canClearFilters =
    assigneeId ||
    !isDefaultStatusFilter ||
    minTradingTime ||
    minRevenue ||
    minLoanAmount ||
    maxLoanAmount ||
    isHomeOwner ||
    onlineSales ||
    documentTypes.length > 0 ||
    searchTerm !== '' ||
    dateRange !== OverviewDateRange.THREE_MONTHS ||
    region ||
    partnerId;

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <Chip
            avatar={
              <Avatar>
                <Clear fontSize="small" />
              </Avatar>
            }
            color="primary"
            label={t('pages.dashboard.filters.actions.clear')}
            onClick={clearFilters}
            disabled={!canClearFilters}
            size="small"
          />
        </Grid>
        <Grid item className={classes.filtersContainer} xs={10}>
          <ButtonGroup aria-label="contained primary button group">
            <Button
              variant={isDefaultStatusFilter ? 'contained' : 'outlined'}
              color={isDefaultStatusFilter ? 'primary' : 'default'}
              onClick={handleInProgressLeads}
              startIcon={<AccessTime />}
              className={classes.statusButton}
            >
              {t('pages.dashboard.filters.actions.inProgress')}
            </Button>
            <Button
              variant={isFundedStatusFilter ? 'contained' : 'outlined'}
              color={isFundedStatusFilter ? 'primary' : 'default'}
              onClick={handleFundedLeads}
              startIcon={<MonetizationOn />}
              className={classes.statusButton}
            >
              {t('pages.dashboard.filters.actions.funded')}
            </Button>
            {isAdmin && (
              <Button
                variant={isNotFundedStatusFilter ? 'contained' : 'outlined'}
                color={isNotFundedStatusFilter ? 'primary' : 'default'}
                onClick={handleNotFundedLeads}
                startIcon={<HighlightOff />}
                className={classes.statusButton}
              >
                {t('pages.dashboard.filters.actions.notFunded')}
              </Button>
            )}
          </ButtonGroup>

          {/* {isLender && isDefaultStatusFilter && (
            <Box className={classes.checkboxContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={activeFilters.includes(ApplicationStatus.OFFER_ACCEPTED)}
                    onChange={handleMyOffersChange}
                    name="isHomeowner"
                    color="secondary"
                    size="medium"
                  />
                }
                label={t('pages.dashboard.filters.myOffers.title')}
              />
            </Box>
          )} */}
        </Grid>
        <Grid item className={classes.filtersContainer} xs={10}>
          <CustomSearch value={searchTerm} onChange={onSearchChange} onClose={clearFilters} />

          {(isAdmin || isLender) && <DocumentTypesFilter />}

          <OnlineSalesFilter />

          <HomeOwnerFilter />
        </Grid>

        <Grid item xs={2}>
          <Grid container className={classes.filtersContainer} justify="flex-end">
            <Select
              native
              IconComponent={() => <ExpandMore className={classes.expandIcon} />}
              variant="outlined"
              value={dateRange ?? ''}
              onChange={handleDateRangeChange}
              className={classes.select}
            >
              {overviewFilterOptions.map(({ label, value }) => {
                return (
                  <option key={`option${value}`} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.tableContainer}>
          <LeadsTable />
        </Grid>
      </Grid>

      <Loader visible={loading} />
    </Container>
  );
};

export default Leads;
