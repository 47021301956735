import { useState, useCallback } from 'react';

import { getCompanyTransactions as getCompanyTransactionsApi } from 'http/companies';
import { CompanyTransaction, CompanyTransactionsQuery } from 'store/applications/types';

interface CompanyValuationsReturnHook {
  loading: boolean;
  error: string | boolean;
  transactions: CompanyTransaction[];
  total: number;
  getCompanyTransactions: (companyId: string, query: CompanyTransactionsQuery) => Promise<void>;
}

const useCompanyTransactions = (): CompanyValuationsReturnHook => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [transactionData, setTransactionsData] = useState<{ transactions: CompanyTransaction[]; total: number }>({
    transactions: [],
    total: -1,
  });

  const getCompanyTransactions = useCallback(async (companyId: string, query: CompanyTransactionsQuery) => {
    setLoading(true);
    try {
      const data = await getCompanyTransactionsApi(companyId, query);
      setTransactionsData({ transactions: data.transactions, total: data.total });
    } catch (err) {
      setError((err as Error)?.message || true);
    }
    setLoading(false);
  }, []);

  return { loading, error, ...transactionData, getCompanyTransactions };
};

export default useCompanyTransactions;
