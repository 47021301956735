import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    documentsRequested: {
      '& .MuiFormControlLabel-root .MuiTypography-body1': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    notesContainer: {
      paddingTop: '0px !important',
    },
    textAreaError: {
      borderColor: `${theme.palette.error.main} !important`,
    },
  }),
);
