import { FC } from 'react';
import { Typography, List, ListItem, ListItemText, Divider, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BusinessIcon from '@material-ui/icons/Business';
import moment from 'moment';

import { ApplicationRegion, CompanyDetails } from 'store/applications/types';
import { camelCaseToSentenceCaseText, parseMoney } from 'utils';
import { mapAppRegionToSupportedCurrency } from 'core/utils';
import { sicCodeLabel } from 'core/sicCodes';
import useLeadStyles from '../LeadDetails/LeadDetails.styles';
import { DataList } from '../DataList';
import useStyles from './CompanyInfo.styles';

const dateFormatRegex = new RegExp(/\d{4}-\d{2}-\d{2}/);

interface CompanyInfoProps {
  company: CompanyDetails;
  appRegion: ApplicationRegion;
}

const CompanyInfo: FC<CompanyInfoProps> = ({ company, appRegion }) => {
  const { t } = useTranslation();
  const leadClasses = useLeadStyles();
  const classes = useStyles();

  const {
    id,
    company_profile,
    financial_information,
    people,
    documents,
    address,
    ccjs,
    ecommerce_providers,
    registered_name,
    registered_number,
    type,
    region,
    formation_date,
    sic_codes,
    company_accounts,
    ...rest
  } = company;

  const companyData = {
    registered_name,
    registered_number,
    type,
    region,
    formation_date,
    business_age: `${moment().diff(moment(company.formation_date), 'months').toString()} months`,
    sic_codes: sic_codes.map((code) => `${code} - ${sicCodeLabel[code]}`),
    ...rest,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderData = (title: string, data: any): any => {
    if (typeof data !== 'object') {
      let parsedValue = data;
      if (dateFormatRegex.test(parsedValue)) {
        parsedValue = moment(parsedValue).format('DD/MM/YYYY');
      }
      if (/amount/i.test(title)) {
        parsedValue = parseMoney(parsedValue, mapAppRegionToSupportedCurrency(appRegion));
      }
      return <ListItemText className={leadClasses.horizontalItemValue}>{parsedValue}</ListItemText>;
    }
    if (Array.isArray(data)) {
      return data.map((d) => renderData(title, d));
    }
    return (
      <List>
        {Object.keys(data).map((key) => (
          <ListItem className={classes.detailItem} key={key}>
            <ListItemText className={leadClasses.horizontalItemKey}>{camelCaseToSentenceCaseText(key)}</ListItemText>

            {renderData(key, data[key])}
          </ListItem>
        ))}
      </List>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderCCJDetails = (details: Record<string, any>) => {
    return Object.keys(details).map((key) => {
      return (
        <ListItem className={classes.detailItem} key={key} style={{ paddingLeft: 0 }}>
          <ListItemText className={leadClasses.horizontalItemKey}>{camelCaseToSentenceCaseText(key)}</ListItemText>

          {renderData(key, details[key])}
        </ListItem>
      );
    });
  };

  return (
    <>
      <Box className={leadClasses.sectionWithIconContainer}>
        <Typography className={leadClasses.sectionTitle}>{t('pages.lead.sections.company.title')}</Typography>
        <BusinessIcon className={leadClasses.sectionIcon} />
      </Box>

      <DataList entity={companyData} translationKey="company" appRegion={appRegion} />

      {address && (
        <>
          <Divider className={leadClasses.horizontalDetailsDivider} />
          <Typography className={leadClasses.greySubtitle} style={{ marginTop: 16 }}>
            {t('pages.lead.sections.address.title')}
          </Typography>

          <DataList entity={address} translationKey="address" appRegion={appRegion} />
        </>
      )}

      {ccjs.length > 0 && (
        <>
          <Divider className={leadClasses.horizontalDetailsDivider} />
          <Typography className={leadClasses.greySubtitle} style={{ marginTop: 16 }}>
            {t('pages.lead.sections.company.ccjs')}
          </Typography>

          <List>
            {ccjs.map((ccj) => (
              <>
                {ccj.type && (
                  <ListItem className={leadClasses.horizontalItem} key={ccj.id}>
                    <ListItemText className={leadClasses.horizontalItemKey}>{ccj.type}</ListItemText>

                    <ListItemText className={leadClasses.horizontalItemValue}>{ccj.description}</ListItemText>
                  </ListItem>
                )}

                {ccj.details && renderCCJDetails(ccj.details)}
              </>
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default CompanyInfo;
