import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      width: '100%',
    },
    submitButton: {
      marginTop: theme.spacing(2),
      maxWidth: 120,
      alignSelf: 'flex-end',
    },
    savedNotesTextLabel: {
      marginLeft: 0,
      textAlign: 'left',
    },
    savedNotesTextArea: {
      width: '100%',
    },
    editNotesButton: {
      fontSize: '20px',
      transition: 'opacity 0.3s',
    },
    saveNotesButton: {
      maxWidth: 120,
      alignSelf: 'flex-end',
    },
    cancelNotesButton: {
      maxWidth: 120,
      marginRight: theme.spacing(2),
      alignSelf: 'flex-end',
    },
  }),
);
