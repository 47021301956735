import { FC, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  IconButton,
} from '@material-ui/core';
import { FileRejection } from 'react-dropzone';
import { HighlightOff as RejectedFileIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { allowedFileTypes } from 'core/constants';
import { CompanyDocumentType, Document, DocumentUploadResponse } from 'core/types';
import { noop } from 'utils';
import useStyles from './DocumentUpload.styles';
import { FileDrop } from '../FileDrop';

interface DocumentUploadProps {
  uploadDocument(data: FormData): Promise<DocumentUploadResponse | null>;
  validationError?: string;
  onUploadSuccess?: (document: Document) => void;
  documentType?: CompanyDocumentType;
}

const DocumentUpload: FC<DocumentUploadProps> = ({
  uploadDocument,
  validationError,
  onUploadSuccess,
  documentType,
}) => {
  const [rejectedFiles, setRejections] = useState<FileRejection[]>([]);
  const [uploadedDoc, setUploadedDoc] = useState<Document | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleUpload = useCallback(
    (documents: Document[]) => {
      const document = documents[0];
      if (document.url) {
        document.url = `${document.url}?${Date.now()}`;
      }
      setUploadedDoc(document);
      if (onUploadSuccess) {
        onUploadSuccess(document);
      }
    },
    [onUploadSuccess],
  );

  const handleRejection = useCallback((files: FileRejection[]) => {
    setRejections(files);
  }, []);

  return (
    <Box>
      <FileDrop
        allowedFileTypes={allowedFileTypes}
        onUpload={handleUpload}
        onReject={handleRejection}
        uploadMessage={t('global.uploadDocument')}
        uploadDocument={uploadDocument}
        validationError={rejectedFiles?.length > 0 || validationError}
        documentType={documentType}
      />

      {uploadedDoc && uploadedDoc.url && (
        <Box marginTop="15px">
          <Typography className={classes.sectionTitle}>Uploaded files</Typography>

          <List>
            <ListItem className={classes.demo}>
              <ListItemText primary={uploadedDoc.name} className={classes.fileTitle} />
              <ListItemAvatar>
                <Avatar alt="Uploaded logo" className={classes.logo} src={uploadedDoc.url} />
              </ListItemAvatar>

              {/* <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={noop}>
                  <Avatar className={classes.deleteAvatar}>
                    <DeleteFileIcon className={classes.deleteIcon} />
                  </Avatar>
                </IconButton>
              </ListItemSecondaryAction> */}
            </ListItem>
          </List>
        </Box>
      )}

      {rejectedFiles.length > 0 && (
        <Box marginTop="15px">
          <Typography className={classes.sectionTitle}>Rejected files</Typography>

          <List>
            {rejectedFiles.map((res: FileRejection) => {
              return (
                <ListItem key={res.file.name}>
                  <ListItemText primary={res.file.name} className={classes.fileTitle} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={noop}>
                      <div className={classes.deleteAvatar}>
                        <RejectedFileIcon color="error" />
                      </div>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default DocumentUpload;
