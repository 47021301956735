import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    columnHeaderItem: {
      backgroundColor: theme.palette.customGrey.main,
      height: 60,
      padding: theme.spacing(1),
      borderRadius: 0,
      cursor: 'pointer',
    },
    columnHeaderButton: {
      borderRadius: 0,
      padding: '4px 6px',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 'bold',
      height: 'auto',
      minHeight: 'auto',
      wordBreak: 'inherit',
    },
    columnHeaderIconContainer: {
      height: 30,
      width: 20,
      position: 'relative',
    },
    columnHeaderSortUpIcon: {
      width: 14,
      height: 12,
      position: 'absolute',
      right: 0,
      top: 6,
    },
    columnHeaderSortDownIcon: {
      width: 14,
      height: 12,
      position: 'absolute',
      right: 0,
      top: 13,
    },
    columnMoreIcon: {
      width: 20,
      height: 20,
      position: 'absolute',
      right: 0,
      top: 5,
    },
    menuItem: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }),
);
