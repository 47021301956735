import { ChangeEvent, FC, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AlertDialog } from 'components/AlertDialog';
import { CustomSelect } from 'components/inputs';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { OfferStatus } from 'store/applications/types';
import { adminOfferStatusOptions, offerStatusOptions } from 'core/constants';
import { useAuth } from 'store/auth/hooks';
import useStyles from './UpdateOfferStatusModal.styles';

interface FormValues {
  status: OfferStatus;
}

interface UpdateOfferStatusModalProps {
  open: boolean;
  toggleOpen: () => void;
  id: string;
  offerStatus: OfferStatus;
}

const UpdateOfferStatusModal: FC<UpdateOfferStatusModalProps> = ({ open, toggleOpen, id, offerStatus }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, updateOfferStatus } = useAdmin(() => {
    if (open) {
      formRef.current?.resetForm();
      toggleOpen();
    }
  });
  const { applicationDetails } = useApplications();
  const { isAdmin } = useAuth();

  const offerSchema = yup.object({
    status: yup.string().oneOf(Object.values(OfferStatus)).required(),
  });

  const initialValues: FormValues = {
    status: offerStatus,
  };

  const onSubmit = (values: FormValues) => {
    if (applicationDetails) {
      updateOfferStatus({ id, status: values.status });
    }
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={offerSchema} innerRef={formRef}>
      {({ values, handleSubmit, setFieldValue }) => (
        <AlertDialog
          open={open}
          dialogContentTitle={t('pages.lead.admin.updateOfferStatus.title')}
          handleCancel={onCancel}
          handleConfirm={handleSubmit}
          loading={loading}
        >
          <Form noValidate>
            <Grid container direction="column" spacing={4} className={classes.container}>
              <Grid item>
                <Field
                  autoFocus
                  id="status"
                  fullWidth
                  component={CustomSelect}
                  options={isAdmin ? adminOfferStatusOptions : offerStatusOptions}
                  name="status"
                  value={values.status}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('status', event.target.value)}
                  title={t('pages.lead.admin.updateOfferStatus.inputs.status.label')}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>
          </Form>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default UpdateOfferStatusModal;
