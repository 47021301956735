import { FC, useRef, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { FormHelperText, Grid, TextareaAutosize, Box, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import * as yup from 'yup';

import { AlertDialog } from 'components/AlertDialog';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { ApplicationRejection, OfferStatus } from 'store/applications/types';
import { RejectionReasonTag } from 'core/types';
import { CustomLabel } from 'components/inputs';
import { rejectionReasonsOptions } from 'core/constants';
import { useAuth } from 'store/auth/hooks';
import { useAccount } from 'store/account/hooks';
import { OfferProductType } from 'store/admin/types';
import useStyles from './RejectModal.styles';

interface FormValues {
  rejectionReason: string | null;
  rejectionReasonTags: RejectionReasonTag[];
}

interface RejectModalProps {
  open: boolean;
  toggleOpen: () => void;
  offerId?: string;
  rejection?: ApplicationRejection;
}

const RejectModal: FC<RejectModalProps> = ({ open, toggleOpen, offerId, rejection }) => {
  const [confirmRejectOpen, setConfirmRejectOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const { loading, rejectApplication, updateOfferStatus, makeAnOffer } = useAdmin(() => {
    if (open) {
      formRef.current?.resetForm();
      toggleOpen();
    }
  });
  const { applicationDetails } = useApplications();
  const { isLenderAdmin } = useAuth();
  const { account } = useAccount();

  const rejectSchema = yup.object({
    rejectionReason: yup.string().required(t('pages.lead.admin.reject.inputs.rejectionReason.required')).nullable(),
    rejectionReasonTags: yup
      .array(yup.string())
      .min(1, t('pages.lead.admin.reject.inputs.rejectionReasonTags.error'))
      .required(t('pages.lead.admin.reject.inputs.rejectionReasonTags.required'))
      .nullable(),
  });

  const initialValues: FormValues = {
    rejectionReason: rejection?.rejection_reason ?? null,
    rejectionReasonTags: rejection?.rejection_reason_tags ?? [],
  };

  const onSubmit = (values: FormValues) => {
    if (isLenderAdmin && applicationDetails && account.id) {
      makeAnOffer({
        application_id: applicationDetails.id,
        lender_id: account.id,
        status: OfferStatus.DECLINED,
        product_type: OfferProductType.REVENUE_BASED,
        offer_details: {
          valid_until: new Date(),
          revenue_repayments: [],
        },
        rejection_reason: values.rejectionReason ?? undefined,
        rejection_reason_tags: values.rejectionReasonTags,
      });
      return;
    }
    if (offerId) {
      updateOfferStatus({
        id: offerId,
        status: OfferStatus.DECLINED,
        rejectionReason: values.rejectionReason ?? undefined,
        rejectionReasonTags: values.rejectionReasonTags,
      });
      return;
    }
    if (applicationDetails) {
      rejectApplication({
        id: applicationDetails.id,
        rejectionReason: values.rejectionReason ?? undefined,
        rejectionReasonTags: values.rejectionReasonTags,
      });
    }
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  const toggleConfirmReject = () => setConfirmRejectOpen((prevState) => !prevState);

  const onConfirm = async () => {
    const errors = await formRef.current?.validateForm();
    formRef.current?.setTouched({ rejectionReason: true, rejectionReasonTags: true });
    if (errors && Object.keys(errors).length === 0) toggleConfirmReject();
  };

  const onReject = () => {
    formRef.current?.handleSubmit();
    toggleConfirmReject();
  };

  const confirmDescription = t(`pages.lead.admin.reject.confirm.${offerId ? 'offer' : 'application'}.description`);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={rejectSchema}
        innerRef={formRef}
        enableReinitialize
      >
        {({ values, touched, errors, handleChange, setFieldValue, handleSubmit }) => (
          <AlertDialog
            open={open}
            dialogContentTitle={t('pages.lead.admin.reject.title')}
            handleCancel={onCancel}
            handleConfirm={rejection ? handleSubmit : onConfirm}
            loading={loading}
          >
            <Form noValidate>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <CustomLabel title={t('pages.lead.admin.reject.inputs.rejectionReasonTags.label')} />

                  <Box className={classes.rejectionReasonsContainer}>
                    {rejectionReasonsOptions.map((option) => {
                      const alreadySelected = values.rejectionReasonTags.find((v) => v === option.value);
                      return (
                        <Chip
                          id={option.value}
                          key={option.value}
                          color={alreadySelected ? 'primary' : 'default'}
                          label={option.label}
                          onClick={() => {
                            if (alreadySelected) {
                              setFieldValue(
                                'rejectionReasonTags',
                                values.rejectionReasonTags.filter((v) => v !== option.value),
                              );
                            } else {
                              setFieldValue('rejectionReasonTags', [...values.rejectionReasonTags, option.value]);
                            }
                          }}
                          className={classes.rejectionReasonOption}
                        />
                      );
                    })}
                  </Box>

                  {touched.rejectionReasonTags && errors.rejectionReasonTags && (
                    <FormHelperText error>{errors.rejectionReasonTags}</FormHelperText>
                  )}
                </Grid>

                <Grid item>
                  <CustomLabel title={t('pages.lead.admin.reject.inputs.rejectionReason.label')} />
                  <TextareaAutosize
                    id="rejectionReason"
                    value={values.rejectionReason || ''}
                    aria-label="minimum height"
                    rowsMin={5}
                    onChange={handleChange}
                    className={touched.rejectionReason && errors.rejectionReason ? classes.textAreaError : ''}
                    disabled={loading}
                  />

                  {touched.rejectionReason && errors.rejectionReason && (
                    <FormHelperText error>{errors.rejectionReason}</FormHelperText>
                  )}
                </Grid>

                {rejection && (
                  <Grid item>
                    <Alert severity="info">{t('pages.lead.admin.reject.messages.editInfo')}</Alert>
                  </Grid>
                )}
              </Grid>
            </Form>
          </AlertDialog>
        )}
      </Formik>

      <AlertDialog
        open={confirmRejectOpen}
        dialogContentTitle={t('pages.lead.admin.reject.confirm.title')}
        dialogContentText={confirmDescription}
        confirmButtonTitle={t('pages.lead.admin.reject.confirm.reject')}
        handleCancel={toggleConfirmReject}
        handleConfirm={onReject}
      />
    </>
  );
};

export default RejectModal;
