import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      // paddingBottom: theme.spacing(8),
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      marginBottom: 12,
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    navActionsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(5),

      '& .MuiButton-startIcon': {
        marginRight: theme.spacing(2),
      },

      '& .MuiButton-endIcon': {
        marginLeft: theme.spacing(2),
      },
    },
    actionButton: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      '& span span': {
        marginRight: 4,
      },
    },
    navIcon: {
      width: 20,
      height: 20,
      color: theme.palette.common.black,
    },
    tableContainer: {
      marginBottom: theme.spacing(5),
      '& .MuiTableRow-root': {
        cursor: 'auto',
      },

      '& th:nth-child(6), td:nth-child(6)': {
        padding: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        minWidth: 40,
        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
    },
    partnerTableContainer: {
      marginBottom: theme.spacing(5),
      '& .MuiTableRow-root': {
        cursor: 'auto',
      },
      '& th:nth-child(n+2), td:nth-child(n+2)': {
        minWidth: 135,
      },
      '& th:nth-child(n+5), td:nth-child(n+5)': {
        minWidth: 150,
      },
      '& th:nth-child(n+7), td:nth-child(n+7)': {
        minWidth: 165,
      },
      '& th:nth-child(n+9), td:nth-child(n+9)': {
        minWidth: 130,
      },
    },
    editButton: {
      height: '30px',
      width: '30px',
    },
    editIcon: {
      fontSize: '20px',
      color: theme.palette.primary.main,
    },
    followUpButton: {
      minWidth: 160,
    },
  }),
);
