import { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { MUIDataTableCustomHeadRenderer, MUIDataTableMeta, MUIDataTableState } from 'mui-datatables';
import { usePrevious } from 'hooks';
import { Table, customHeadRender, ColumnHeader } from 'components/table';
import { AccountRoles, User, UserQuery, UserRole, UsersTableColumnNames } from 'core/types';
import { useAccount } from 'store/account/hooks';
import { lenderRolesOptions, partnerRolesOptions, ROWS_PER_TABLE_PAGE } from 'core/constants';
import { useAuth } from 'store/auth/hooks';
import { mapUserRoleToName } from 'core/utils';
import { AlertDialog } from 'components/AlertDialog';
import { UpdateUserRoleModal } from 'components/UpdateUserRoleModal';
import useStyles from './AccountUsers.styles';

interface AccountUsersProps {
  partnerId?: string;
  lenderId?: string;
}

const AccountUsers: FC<AccountUsersProps> = ({ partnerId, lenderId }) => {
  const [activePage, setActivePage] = useState(0);
  const [selectedUser, setSelectedUser] = useState<{ id: string; name: string } | null>(null);
  const [selectedUserRole, setSelectedUserRole] = useState<{ id: string; role: AccountRoles } | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { total, users, account, getAccountUsers, deleteAccountUser } = useAccount(() => setSelectedUser(null));
  const { isPartnerAdmin, isLenderAdmin } = useAuth();
  const prevActivePage = usePrevious(activePage);
  const accountOwnerId = account.owner.id;
  const prevAccountOwnerId = usePrevious(accountOwnerId);

  const usersQuery: UserQuery = useMemo(
    () => ({
      per_page: ROWS_PER_TABLE_PAGE,
      page: activePage + 1, // mui databale page is zero based index
      partner_id: partnerId,
      lender_id: lenderId,
    }),
    [activePage, lenderId, partnerId],
  );

  useEffect(() => {
    if (prevActivePage !== activePage) {
      getAccountUsers(usersQuery);
    }
  }, [activePage, usersQuery, getAccountUsers, prevActivePage, prevAccountOwnerId, accountOwnerId]);

  const columns = useMemo(
    () => [
      {
        name: UsersTableColumnNames.NAME,
        label: t('pages.team.table.columns.name'),
        options: { customHeadRender },
      },
      {
        name: UsersTableColumnNames.EMAIL,
        label: t('pages.team.table.columns.email'),
        options: { customHeadRender },
      },
      {
        name: UsersTableColumnNames.ROLE,
        label: t('pages.team.table.columns.role'),
        options: {
          customHeadRender,
          customBodyRender:
            isPartnerAdmin || isLenderAdmin
              ? (value: UserRole, tableMeta: MUIDataTableMeta) => {
                  const user = users[tableMeta.rowIndex];
                  const onClick = () => {
                    if (user) setSelectedUserRole({ id: user.id, role: user.role as AccountRoles });
                  };
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={onClick}
                      disabled={!user?.can_delete}
                    >
                      {mapUserRoleToName(value)}
                    </Button>
                  );
                }
              : (value: UserRole) => mapUserRoleToName(value),
        },
      },
      {
        name: UsersTableColumnNames.CREATED_AT,
        label: t('pages.team.table.columns.createdAt'),
        options: {
          customHeadRender,
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleDateString() : null),
        },
      },
      ...(isPartnerAdmin || isLenderAdmin
        ? [
            {
              name: UsersTableColumnNames.REMOVE,
              label: '',
              options: {
                customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) => (
                  <ColumnHeader key={`remove-${columnMeta.index}`} title="" empty />
                ),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                customBodyRender: (_: any, tableMeta: MUIDataTableMeta) => {
                  const user = users[tableMeta.rowIndex];
                  const onClick = () => {
                    if (user) setSelectedUser({ id: user.id, name: user.fullName });
                  };
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={onClick}
                      className={classes.removeButton}
                      disabled={!user?.can_delete}
                    >
                      {t('pages.team.deleteUser.buttons.delete')}
                    </Button>
                  );
                },
              },
            },
          ]
        : []),
    ],
    [classes.removeButton, isLenderAdmin, isPartnerAdmin, t, users],
  );

  const onTableChange = useCallback((action: string, tableState: MUIDataTableState) => {
    if (action === 'changePage') {
      setActivePage(tableState.page);
    }
  }, []);

  const handleDeleteCancel = () => setSelectedUser(null);

  const handleDeleteConfirm = () => {
    if (selectedUser) deleteAccountUser(selectedUser.id);
  };

  return (
    <>
      <Table<User>
        columns={columns}
        data={users}
        page={activePage}
        count={total}
        pagination
        rowsPerPage={ROWS_PER_TABLE_PAGE}
        onTableChange={onTableChange}
      />

      {selectedUserRole && (
        <UpdateUserRoleModal
          open
          toggleOpen={() => setSelectedUserRole(null)}
          roleOptions={isPartnerAdmin ? partnerRolesOptions : lenderRolesOptions}
          userRole={selectedUserRole}
        />
      )}

      {selectedUser && (
        <AlertDialog
          open
          dialogContentTitle={t('pages.team.deleteUser.dialog.title')}
          dialogContentText={t('pages.team.deleteUser.dialog.description', { name: selectedUser?.name })}
          handleConfirm={handleDeleteConfirm}
          handleCancel={handleDeleteCancel}
          confirmButtonTitle={t('pages.team.deleteUser.buttons.delete')}
        />
      )}
    </>
  );
};

export default AccountUsers;
