import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      marginBottom: 12,
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    rulesContainer: {
      '& p, & span, & li': {
        textTransform: 'capitalize',
      },
    },
    rulesSectionTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 600,
      marginTop: theme.spacing(2),
    },
    table: {
      '& .MuiTableRow-root td:nth-child(2)': {
        textTransform: 'none',
      },
    },
    highlightedRow: {
      backgroundColor: theme.palette.action.hover,
      '& span': {
        fontWeight: 600,
      },
    },
    switchLabel: {
      marginRight: theme.spacing(0.5),
      '& > span': {
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(18),
      },
    },
    row: {
      cursor: 'auto',
    },
  }),
);
