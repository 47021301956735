import { useState, useCallback } from 'react';

import { getBalanceTransactions as getBalanceTransactionsApi } from 'http/bank';
import { BalanceTransaction, BalanceTransactionsQuery } from 'store/applications/types';

interface BalanceValuationsReturnHook {
  loading: boolean;
  error: string | boolean;
  transactions: BalanceTransaction[];
  total: number;
  getBalanceTransactions: (companyId: string, query: BalanceTransactionsQuery) => Promise<void>;
}

const useBalanceTransactions = (): BalanceValuationsReturnHook => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [transactionData, setTransactionsData] = useState<{ transactions: BalanceTransaction[]; total: number }>({
    transactions: [],
    total: -1,
  });

  const getBalanceTransactions = useCallback(async (companyId: string, query: BalanceTransactionsQuery) => {
    setLoading(true);
    try {
      const data = await getBalanceTransactionsApi(companyId, query);
      setTransactionsData({ transactions: data.balances, total: data.total });
    } catch (err) {
      setError((err as Error)?.message || true);
    }
    setLoading(false);
  }, []);

  return { loading, error, ...transactionData, getBalanceTransactions };
};

export default useBalanceTransactions;
