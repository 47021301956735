import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Container, Grid, Typography, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useGlobalState } from 'store/global/hooks';
import { useAdmin } from 'store/admin/hooks';
import { Loader } from 'components/Loader';
import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus, ApiLenderRulesEngine, GlobalFundingCriteria } from 'core/types';

import { camelCaseToSentenceCaseText } from 'utils';
import { mapRuleCriteriaToLabel } from 'core/utils';
import { RuleEngineRule } from 'store/admin/types';
import { FundingReason } from 'store/applications/types';
import useStyles from './RulesEngine.styles';
import { Rule } from './components';
import { ActionRuleModal } from './components/ActionRuleModal';

const RulesEngine: FC = () => {
  const [selectedRule, setSelectedRule] = useState<RuleEngineRule | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { error: globalError } = useGlobalState();
  const { loading, error, success, rulesEngine, setError, setSuccess, getRulesEngine } = useAdmin();

  useEffect(() => {
    getRulesEngine();
  }, [getRulesEngine]);

  const parsedRules = useMemo(() => {
    return rulesEngine.reduce((acc: Record<string, RuleEngineRule[]>, val: RuleEngineRule) => {
      const lenderName = val.lender_name?.toLowerCase();
      const ruleCriteria = val.criteria;
      if (lenderName) {
        if (acc[lenderName]) acc[lenderName].push(val);
        else acc[lenderName] = [val];
      }

      if (ruleCriteria) {
        if (acc[ruleCriteria]) acc[ruleCriteria].push(val);
        else acc[ruleCriteria] = [val];
      }

      return acc;
    }, {} as Record<string, RuleEngineRule[]>);
  }, [rulesEngine]);

  const renderRule = (rule: RuleEngineRule): JSX.Element => {
    return (
      <Fragment key={rule.id}>
        <Rule rule={rule} onSelect={() => setSelectedRule(rule)} />

        {rule.rules?.map(renderRule)}
      </Fragment>
    );
  };

  const renderRulesSection = (title: string, rules: RuleEngineRule[]) => {
    return (
      <Box m={2} key={title}>
        <Typography className={classes.rulesSectionTitle}>{camelCaseToSentenceCaseText(title)}</Typography>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow className={classes.row}>
              <TableCell>{t('pages.rulesEngine.rules.table.fact')}</TableCell>
              <TableCell>{t('pages.rulesEngine.rules.table.operator')}</TableCell>
              <TableCell>{t('pages.rulesEngine.rules.table.value')}</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rules.map(renderRule)}</TableBody>
        </Table>
      </Box>
    );
  };

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title}>{t('pages.rulesEngine.title')}</Typography>
          <Typography className={classes.subtitle}>{t('pages.rulesEngine.subtitle')}</Typography>
        </Grid>

        {parsedRules && (
          <Grid item xs={12} md={9} lg={6}>
            <Box marginLeft="-10px">
              <Typography className={classes.rulesSectionTitle}>
                {t('pages.rulesEngine.rules.sections.prequalification')}
              </Typography>

              {[...Object.values(FundingReason), ...Object.values(GlobalFundingCriteria)].map((criteria) => {
                if (!parsedRules[criteria]) return null;
                return renderRulesSection(mapRuleCriteriaToLabel(criteria), parsedRules[criteria]);
              })}

              <Typography className={classes.rulesSectionTitle}>
                {t('pages.rulesEngine.rules.sections.lenders')}
              </Typography>

              {Object.values(ApiLenderRulesEngine).map((lender) => {
                if (!parsedRules[lender]) return null;
                return renderRulesSection(lender, parsedRules[lender]);
              })}
            </Box>
          </Grid>
        )}
      </Grid>

      {selectedRule && (
        <ActionRuleModal open={!!selectedRule} toggleOpen={() => setSelectedRule(null)} rule={selectedRule} />
      )}

      <Loader visible={loading} />

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default RulesEngine;
