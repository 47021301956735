import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),

      '& .MuiGrid-item': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    text: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.greyText.main,
      paddingBottom: 20,
    },
    textInput: {
      minWidth: '100%',

      '& .MuiInputBase-root': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
        height: 36,
      },

      '& .Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
      },

      '& .Mui-error': {
        color: theme.palette.error.main,
        marginLeft: 0,
        marginRight: 0,
      },

      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    totalContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(6),
    },
    totalText: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.greyText.main,
    },
    totalValue: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    textAreaError: {
      borderColor: `${theme.palette.error.main} !important`,
    },
    inputLabel: {
      '& p': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        color: theme.palette.common.black,
      },
      '& > .MuiButtonBase-root': {
        marginTop: -theme.spacing(2),
      },
    },
    dividerContainer: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1),

      '& svg': {
        width: '100%',
      },
    },
    addRateContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(-2),
      marginTop: theme.spacing(1),
    },
    rateNumberTitle: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 700,
      width: '100%',

      color: theme.palette.primary.main,
    },
    deleteRateButton: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      fontWeight: 400,
      marginTop: -6,
      minHeight: 24,
      width: 61,
      height: 24,
      padding: 0,
      borderRadius: 6,
    },
    addRateButton: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    rejectionReasonsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > *': {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        marginRight: theme.spacing(1.5),
      },
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: theme.spacing(1),
      },
    },
    rejectionReasonOption: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
    declineResonLabel: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.greyText.main,
    },
  }),
);
