import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import routes from 'router/routes';
import { usePartners } from 'store/partners/hooks';
import useApiStyles from 'pages/settings/Api/Api.styles';
import { ApiSecret } from 'pages/settings/Api/components';
import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus } from 'core/types';
import { Display, KeyType } from 'pages/settings/Api/components/ApiSecret/ApiSecret';
import { Loader } from 'components/Loader';
import { useGlobalState } from 'store/global/hooks';
import { AccountType } from 'store/applications/types';
import { AccountUsers } from 'components/AccountUsers';
import { UserSwitchEnabled } from 'components/UserSwitchEnabled';
import { countryCodeLabel } from 'core/countries';
import { CreatePartnerModal } from 'pages/partners/components';
import { CreateAccountOwnerModal } from 'components/CreateAccountOwnerModal';
import useStyles from './Partner.styles';

const PartnerDetails: FC = () => {
  const classes = useStyles();
  const apiClasses = useApiStyles();
  const [updateOpen, setUpdateOpen] = useState(false);
  const [createOwnerOpen, setCreateOwnerOpen] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { error: globalError } = useGlobalState();
  const { loading, error, setError, partnerDetails, getPartnerDetails, success, setSuccess, setPartnerDetails } =
    usePartners();
  const history = useHistory();

  useEffect(() => {
    return () => setPartnerDetails(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPartnerDetails(id);
  }, [getPartnerDetails, id]);

  const goBack = () => history.push(routes.partners);

  const toggleUpdateOpen = () => setUpdateOpen((prevState) => !prevState);

  const toggleCreateOwnerOpen = () => setCreateOwnerOpen((prevState) => !prevState);

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Typography className={classes.title}>{partnerDetails?.name}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            className={classes.actionButton}
            variant="text"
            color="default"
            onClick={goBack}
            startIcon={<ArrowBack className={classes.navIcon} />}
          >
            {t('pages.partner.navPrev')}
          </Button>
        </Grid>

        <Grid item xs={12} className={classes.partnerActions}>
          {partnerDetails && (
            <Box className={classes.switchContainer}>
              <UserSwitchEnabled
                id={partnerDetails.id}
                initialActive={partnerDetails.active}
                accountType={AccountType.PARTNER}
                setSuccess={setSuccess}
                setError={setError}
                successMessage={t('pages.partner.messages.updateSuccess') as string}
              />
            </Box>
          )}
          {partnerDetails && !partnerDetails.owner && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.partnerActionButton}
              onClick={toggleCreateOwnerOpen}
            >
              {t('pages.partner.buttons.createOwner')}
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.partnerActionButton}
            onClick={toggleUpdateOpen}
          >
            {t('pages.partners.admin.actions.update')}
          </Button>
        </Grid>

        <Grid item sm={12} md={6}>
          <List className={classes.headerSection}>
            {partnerDetails?.logo_url && (
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar src={partnerDetails?.logo_url} className={classes.partnerLogo} />
                </ListItemAvatar>
              </ListItem>
            )}

            <ListItem className={classes.listItem}>
              <ListItemText className={classes.listItemTitle}>
                {t('pages.partner.sections.header.accountName')}
              </ListItemText>
              <ListItemText className={classes.listItemText}>{partnerDetails?.name}</ListItemText>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText className={classes.listItemTitle}>
                {t('pages.partner.sections.header.displayedName')}
              </ListItemText>
              <ListItemText className={classes.listItemText}>{partnerDetails?.displayed_name}</ListItemText>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText className={classes.listItemTitle}>
                {t('pages.partner.sections.header.contactName')}
              </ListItemText>
              <ListItemText className={classes.listItemText}>{partnerDetails?.contact_person_name}</ListItemText>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText className={classes.listItemTitle}>
                {t('pages.partner.sections.header.contactEmail')}
              </ListItemText>
              <ListItemText className={classes.listItemText}>{partnerDetails?.contact_person_email}</ListItemText>
            </ListItem>

            {partnerDetails?.phone_number && (
              <ListItem className={classes.listItem}>
                <ListItemText className={classes.listItemTitle}>
                  {t('pages.partner.sections.header.phoneNumber')}
                </ListItemText>
                <ListItemText className={classes.listItemText}>{partnerDetails.phone_number}</ListItemText>
              </ListItem>
            )}

            {partnerDetails?.job_title && (
              <ListItem className={classes.listItem}>
                <ListItemText className={classes.listItemTitle}>
                  {t('pages.partner.sections.header.jobTitle')}
                </ListItemText>
                <ListItemText className={classes.listItemText}>{partnerDetails.job_title}</ListItemText>
              </ListItem>
            )}

            {partnerDetails?.website && (
              <ListItem className={classes.listItem}>
                <ListItemText className={classes.listItemTitle}>
                  {t('pages.partner.sections.header.website')}
                </ListItemText>
                <ListItemText className={classes.listItemText}>{partnerDetails.website}</ListItemText>
              </ListItem>
            )}

            {partnerDetails?.country_code && (
              <ListItem className={classes.listItem}>
                <ListItemText className={classes.listItemTitle}>
                  {t('pages.partner.sections.header.country')}
                </ListItemText>
                <ListItemText className={classes.listItemText}>
                  {`${countryCodeLabel[partnerDetails.country_code]} (${partnerDetails.country_code})`}
                </ListItemText>
              </ListItem>
            )}

            {partnerDetails?.industry && (
              <ListItem className={classes.listItem}>
                <ListItemText className={classes.listItemTitle}>
                  {t('pages.partner.sections.header.industry')}
                </ListItemText>
                <ListItemText className={classes.listItemText}>{partnerDetails.industry}</ListItemText>
              </ListItem>
            )}

            {partnerDetails?.employees && (
              <ListItem className={classes.listItem}>
                <ListItemText className={classes.listItemTitle}>
                  {t('pages.partner.sections.header.employees')}
                </ListItemText>
                <ListItemText className={classes.listItemText}>{partnerDetails.employees}</ListItemText>
              </ListItem>
            )}

            {partnerDetails?.address && (
              <ListItem className={classes.listItem}>
                <ListItemText className={classes.listItemTitle}>
                  {t('pages.partner.sections.header.address')}
                </ListItemText>
                <ListItemText className={classes.listItemText}>{partnerDetails.address}</ListItemText>
              </ListItem>
            )}

            {partnerDetails?.logo_url && (
              <ListItem className={classes.listItem}>
                <ListItemText className={classes.listItemTitle}>
                  {t('pages.partner.sections.header.logoUrl')}
                </ListItemText>
                <ListItemText className={classes.listItemText}>
                  <a href={partnerDetails.logo_url} target="_blank" rel="noreferrer">
                    {t('pages.partner.sections.header.logoUrl')}
                  </a>
                </ListItemText>
              </ListItem>
            )}
          </List>

          <Divider />
        </Grid>

        <Grid item sm={12} md={6}>
          <Paper elevation={2} className={apiClasses.keysContainer}>
            <Typography className={classes.subtitle} gutterBottom>
              {t('pages.partner.keysTitle')}
            </Typography>

            {partnerDetails &&
              Object.entries(partnerDetails.api_keys).reduce((acc: JSX.Element[], [key, value]) => {
                if (key !== 'id') {
                  const isSecretKey = /secret/i.test(key);
                  acc.push(
                    <ApiSecret
                      display={isSecretKey ? Display.MASK : Display.RAW}
                      key={`${key}${value}`}
                      title={t(`pages.api.apiKeys.${key}`)}
                      secret={value || ''}
                      type={isSecretKey ? KeyType.SECRET : KeyType.PUBLIC}
                      accountId={partnerDetails.id}
                      accountType={AccountType.PARTNER}
                      name={key}
                    />,
                  );
                }
                return acc;
              }, [])}
          </Paper>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.usersContainer}>
        <Typography className={classes.subtitle} gutterBottom>
          {t('pages.partner.users')}
        </Typography>
        <AccountUsers partnerId={id} />
      </Grid>

      {partnerDetails && (
        <CreatePartnerModal open={updateOpen} toggleOpen={toggleUpdateOpen} partner={partnerDetails} />
      )}

      <CreateAccountOwnerModal
        open={createOwnerOpen}
        toggleOpen={toggleCreateOwnerOpen}
        accountType={AccountType.PARTNER}
      />

      <Loader visible={loading} />

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default PartnerDetails;
