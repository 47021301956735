import { useSelector, shallowEqual } from 'react-redux';
import { useActionCreator } from 'hooks';

import { RootState } from 'store/types';
import {
  getApplications,
  getApplicationDetails,
  clearApplicationDetails,
  updateApplicationsQueryAction,
  setApplicationsSearchTerm,
  getApplicationsOverview,
  updateApplicationDetails,
  clearApplicationsFiltersAction,
  setDateRange,
  getOffersOverview,
} from './actions';
import { ApplicationsReturnHook } from './types';

export const useApplications = (): ApplicationsReturnHook => {
  const applicationsState = useSelector((state: RootState) => state.applications, shallowEqual);

  return {
    ...applicationsState,
    getApplications: useActionCreator(getApplications),
    getApplicationDetails: useActionCreator(getApplicationDetails),
    clearApplicationDetails: useActionCreator(clearApplicationDetails),
    updateApplicationsQuery: useActionCreator(updateApplicationsQueryAction),
    setApplicationsSearchTerm: useActionCreator(setApplicationsSearchTerm),
    getApplicationsOverview: useActionCreator(getApplicationsOverview),
    updateApplicationDetails: useActionCreator(updateApplicationDetails),
    clearApplicationsFilters: useActionCreator(clearApplicationsFiltersAction),
    setDateRange: useActionCreator(setDateRange),
    getOffersOverview: useActionCreator(getOffersOverview),
  };
};
