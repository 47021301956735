import { FC, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { syncCompanyTransactions as syncCompanyTransactionsApi } from 'http/admin';
import { LoadingButton } from 'components/buttons';
import { EcommerceProvider } from 'store/applications/types';
import { useAdmin } from 'store/admin/hooks';
import useStyles from './SyncTransactionsButton.styles';

interface SyncTransactionsButtonProps {
  companyId: string;
  provider: EcommerceProvider;
}

const SyncTransactionsButton: FC<SyncTransactionsButtonProps> = ({ companyId, provider }) => {
  const [syncing, setSyncing] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { setError } = useAdmin();

  const syncTransactions = async (providerId: string) => {
    setSyncing(true);
    try {
      await syncCompanyTransactionsApi(companyId, providerId);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.providerActionContainer}>
        <Typography className={classes.providerName}>{provider.name}</Typography>

        <LoadingButton
          variant="contained"
          onClick={() => syncTransactions(provider.id)}
          color="primary"
          disableElevation
          loading={syncing || provider.syncing}
        >
          {t('pages.lead.sections.paymentData.sync.button')}
        </LoadingButton>
      </Box>

      {provider.syncStartedAt && (
        <Typography className={classes.syncText}>
          {t('pages.lead.sections.paymentData.sync.startedAt')}: {new Date(provider.syncStartedAt).toLocaleString()}
        </Typography>
      )}

      {provider.syncEndedAt && (
        <Typography className={classes.syncText}>
          {t('pages.lead.sections.paymentData.sync.endedAt')}: {new Date(provider.syncEndedAt).toLocaleString()}
        </Typography>
      )}

      {provider.syncError && (
        <Typography className={clsx([classes.syncText, classes.errorText])}>{provider.syncError}</Typography>
      )}
    </Box>
  );
};

export default SyncTransactionsButton;
