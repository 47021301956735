import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),

      '& .MuiGrid-item': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    documentUploadContainer: {
      maxWidth: '100%',
      marginBottom: 8,
      '& > div:first-child': {
        marginBottom: -12,
      },
    },
  }),
);
