import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      '& .MuiGrid-item': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    textInput: {
      '& .MuiInputBase-root': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
        height: 36,
      },
      '& .Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
      },
      '& .Mui-error': {
        color: theme.palette.error.main,
        marginLeft: 0,
        marginRight: 0,
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    dateInput: {
      cursor: 'pointer',

      '& input': {
        border: `1px solid ${theme.palette.greyLightBorder.main}`,
        padding: `${theme.spacing(2.3)}px ${theme.spacing(1.81)}px`,
        font: 'inherit',
        height: 0,
        fontWeight: 'bold',
      },
    },
    editor: {
      border: `1px solid ${theme.palette.greyLightBorder.main}`,
      borderRadius: 4,
      height: 250,
      '& .ql-container.ql-snow': {
        border: 'none',
        height: 207,
        '& *': {
          fontSize: theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(20),
          fontFamily: theme.typography.fontFamily,
        },
        '& .ql-tooltip': {
          left: '5px !important',
        },
      },
      '& .ql-toolbar.ql-snow': {
        border: 'none',
        borderBottom: `1px solid ${theme.palette.greyLightBorder.main}`,
        '& .ql-formats': {
          marginRight: 5,
        },
      },
    },
    editorError: {
      borderColor: `${theme.palette.error.main} !important`,
    },
    fileDropOuter: {
      border: `2px dashed ${theme.palette.greyLightBorder.main}`,
      padding: '40px 40px',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      borderRadius: 12,
      height: 100,
    },
    fileDropInner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    listItem: {
      padding: 4,
    },
    deleteButton: {
      height: '25px',
      width: '25px',
      padding: 0,
    },
    deleteAvatar: {
      height: '20px',
      width: '20px',
      display: 'flex',
      '& svg': {
        width: '100%',
        height: '100%',
      },
    },
    sectionTitle: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: '17px',
      letterSpacing: -0.336,
      color: theme.palette.greyText.main,
      marginBottom: 5,
    },
    fileTitle: {
      marginTop: 0,
      marginBottom: 0,
      '& span': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(17),
        letterSpacing: -0.336,
      },
    },
    dropFilesMessage: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.greyText.main,
      marginTop: 2,
    },
  }),
);
