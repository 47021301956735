import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    keysContainer: {
      padding: theme.spacing(3),
    },
    mainSubtitle: {
      marginBottom: theme.spacing(3),
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
  }),
);
