import { ChangeEvent, FC, useState } from 'react';
import { Box, FormControl, TextareaAutosize, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from 'components/buttons';
import { ApplicationNoteType } from 'core/types';
import { updateApplicationNotes } from 'http/admin';
import { useAdmin } from 'store/admin/hooks';
import { useAuth } from 'store/auth/hooks';
import { toTitleCase } from 'utils';
import useStyles from './ApplicationNotes.styles';

interface ApplicationNotesProps {
  type: ApplicationNoteType;
  applicationId: string;
  initialNotes?: string;
}

const ApplicationNotes: FC<ApplicationNotesProps> = ({ type, applicationId, initialNotes }) => {
  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [selected, setSelected] = useState(false);
  const [notes, setNotes] = useState(initialNotes || '');
  const [newNote, setNewNote] = useState('');
  const { t } = useTranslation();
  const classes = useStyles();
  const { setError, setSuccess } = useAdmin();
  const { isAdmin, firstName, lastName } = useAuth();
  const [rowsMin, setRowsMin] = useState(1);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewNote(event.target.value);
  };

  const onSelected = () => {
    setRowsMin(5);
    setSelected(true);
  };

  const onEdit = () => {
    setEdited(true);
    setNewNote(notes);
  };

  const onCancel = () => {
    setEdited(false);
    setSelected(false);
    setNewNote('');
    setRowsMin(1);
  };

  const onSubmit = async () => {
    if (!newNote) return;
    setLoading(true);
    try {
      const dateNow = moment();
      const savedNotes = edited ? newNote : `${notes} \n ${newNote} by ${firstName} ${lastName} at ${dateNow}`;

      await updateApplicationNotes(applicationId, savedNotes, type);
      setNotes(savedNotes);
      setNewNote('');
      setEdited(false);

      const successMessage = t('pages.lead.sections.notes.success', { type: toTitleCase(type) });
      setSuccess(successMessage);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error.message || true);
    }
    setLoading(false);
    setSelected(false);
    setRowsMin(1);
  };

  return (
    <>
      <form noValidate className={classes.form}>
        <Typography variant="caption">{t('pages.lead.sections.notes.title', { type: toTitleCase(type) })}</Typography>
        <TextareaAutosize
          id={`${type}-saved-notes`}
          value={notes}
          rowsMin={5}
          disabled
          placeholder={t('pages.lead.sections.notes.saved', { type })}
          rowsMax={5}
          className={classes.savedNotesTextArea}
        />

        {isAdmin && (
          <LoadingButton
            size="small"
            startIcon={<EditIcon />}
            variant="text"
            onClick={onEdit}
            color="primary"
            disableElevation
            loading={loading}
            containerClassName={classes.editNotesButton}
          >
            {t('pages.lead.admin.actions.editNotes')}
          </LoadingButton>
        )}

        {isAdmin && (
          <>
            <FormControl margin="normal">
              <TextareaAutosize
                id={`${type}-new-note`}
                value={newNote}
                rowsMin={rowsMin}
                onChange={handleChange}
                onClick={onSelected}
                placeholder={t('pages.lead.sections.notes.new', { type: toTitleCase(type) })}
              />
            </FormControl>

            <Box display="flex" justifyContent="flex-end">
              {(edited || selected) && (
                <LoadingButton
                  variant="outlined"
                  onClick={onCancel}
                  color="primary"
                  disableElevation
                  loading={loading}
                  containerClassName={classes.cancelNotesButton}
                >
                  {t('pages.lead.admin.actions.cancelNotes')}
                </LoadingButton>
              )}

              <LoadingButton
                variant="contained"
                onClick={onSubmit}
                color="primary"
                disableElevation
                loading={loading}
                containerClassName={classes.saveNotesButton}
              >
                {t('pages.lead.admin.actions.saveNotes')}
              </LoadingButton>
            </Box>
          </>
        )}
      </form>
    </>
  );
};

export default ApplicationNotes;
