import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      fontSize: theme.typography.pxToRem(15),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    sectionTitleFunding: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 700,
    },
    greySubtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      color: theme.palette.greyText.main,
    },
    textTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 600,
      marginTop: theme.spacing(1),
    },
    text: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    actionButton: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      '& span span': {
        marginRight: 4,
      },
      marginLeft: 0,
    },
    actionIcon: {
      width: 20,
      height: 20,
      color: theme.palette.primary.main,
    },
    assigneeSelect: {
      height: '2.5em',
      width: '-webkit-fill-available',
    },
    actionStatusIcon: {
      width: 20,
      height: 20,
      color: theme.palette.pending.main,
    },
    headerSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: theme.palette.primary.light,
      padding: 24,
      flexWrap: 'wrap',
      maxWidth: '100%',
      '& li': {
        display: 'inline-block',
        minHeight: 80,
        wordBreak: 'break-all',
        width: 'auto',
      },
      '& li:first-child': {
        maxWidth: 92,
        minWidth: 92,
      },
      '& li:nth-child(5)': {
        maxWidth: 220,
        minWidth: 220,
      },

      [theme.breakpoints.down('sm')]: {
        '& li': {
          minWidth: '33% !important',
        },
      },
      [theme.breakpoints.down('xs')]: {
        '& li': {
          minWidth: '100% !important',
          minHeight: 90,
        },
      },
    },
    leadAvatar: {
      height: 60,
      width: 60,
    },
    // listItem: {
    //   minWidth: 180,
    // },
    listItemAssignee: {
      maxWidth: 'auto',
    },
    listItemStatus: {
      maxWidth: 370,
      minWidth: 'auto',
    },
    // listItemTitle: {
    //   flex: 'none',
    //   '& span': {
    //     color: theme.palette.common.white,
    //     fontSize: theme.typography.pxToRem(14),
    //     lineHeight: theme.typography.pxToRem(20),
    //     fontWeight: 700,
    //   },
    // },
    // listItemText: {
    //   '& span': {
    //     fontSize: theme.typography.pxToRem(17),
    //     lineHeight: theme.typography.pxToRem(24),
    //     fontWeight: 700,
    //   },
    // },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    listItemTitle: {
      '& span': {
        fontWeight: 700,
      },
    },
    listItemText: {
      flex: 'none',
    },

    horizontalItem: {
      paddingLeft: 0,
      overflow: 'hidden',
    },
    horizontalItemKey: {
      // maxWidth: 130,
      minWidth: 130,
      marginRight: theme.spacing(1),
      '& span': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        color: theme.palette.greyText.main,
        textTransform: 'capitalize',
      },
    },
    horizontalItemValue: {
      paddingLeft: theme.spacing(1),
      maxWidth: '100%',
      '& span': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
        textAlign: 'left',
        wordWrap: 'break-word',
      },
    },
    horizontalDetailsDivider: {
      height: 1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    sectionWithIconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionIcon: {
      height: 18,
      width: 14,
      marginLeft: theme.spacing(1),
    },
    documentsContainer: {
      position: 'relative',
    },
    documentLoaderContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      left: 0,
    },

    expandIcon: {
      height: 20,
      width: 20,
      color: '#687C8D',
      pointerEvents: 'none',
      position: 'absolute',
      top: 8,
      right: 10,
    },
    uploadDocContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    uploadDocTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    uploadDocText: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    deleteButton: {
      height: '30px',
      width: '30px',
    },
    deleteIcon: {
      fontSize: '20px',
      color: theme.palette.primary.main,
    },
    notesContainer: {
      marginTop: theme.spacing(2),
    },
    selectContainer: {
      '& .MuiSelect-root': {
        height: 36,
        minWidth: '10em',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 16,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
      },
    },
    select: {
      height: '42px',
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
    rejectionReasonsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > *': {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        marginRight: theme.spacing(1.5),
      },
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: theme.spacing(1),
      },
    },
    rejectionReasonOption: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
    editRejectionButton: {
      marginTop: theme.spacing(3),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    lightBg: { background: '#f7fafc', padding: '20px', borderRadius: '20px' },
  }),
);
