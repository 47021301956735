import { FC, useState, ChangeEvent, useEffect } from 'react';
import { TableRow, TableCell, FormControlLabel, Switch, CircularProgress, Box, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { updateRule as updateRuleApi } from 'http/admin';
import { RuleEngineRule } from 'store/admin/types';
import { mapRuleFactToLabel, mapRuleOperatorToLabel } from 'core/utils';
import { getRuleParsedValue, replaceAtIndex } from 'utils';
import { useAdmin } from 'store/admin/hooks';
import { usePrevious } from 'hooks';
import useStyles from './Rule.styles';

interface RuleProps {
  rule: RuleEngineRule;
  onSelect: (rule: RuleEngineRule) => void;
}

const Rule: FC<RuleProps> = ({ rule, onSelect }) => {
  const [active, setActive] = useState(rule.active);
  const [loading, setLoading] = useState(false);
  const { rulesEngine, updateRulesEngine } = useAdmin();

  const { id, fact, operator, rules, value, parent_id, criteria } = rule;

  const classes = useStyles();
  const { t } = useTranslation();
  const { setError, setSuccess } = useAdmin();
  const prevActive = usePrevious(active);

  useEffect(() => {
    if (prevActive !== active && rule.rules) {
      const atIndex = rulesEngine.findIndex((r) => r.id === id);
      if (atIndex >= 0) {
        const updatedRules = replaceAtIndex<RuleEngineRule>([...rulesEngine], atIndex, [{ ...rule, active }]);
        updateRulesEngine(updatedRules);
      }
    }
  }, [active, id, prevActive, rule, rulesEngine, updateRulesEngine]);

  const toggleActiveRule = async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setLoading(true);
    try {
      await updateRuleApi(id, { active: checked });
      setActive(checked);
      setSuccess(t('pages.rulesEngine.messages.updateSuccess') as string);
    } catch (error) {
      setError((error as Error).message ?? true);
    }
    setLoading(false);
  };

  const parentRule = parent_id ? rulesEngine.find((rE) => rE.id === parent_id) : undefined;

  const getCheckedState = () => {
    if (parentRule && !parentRule.active) return false;
    return active;
  };

  return (
    <TableRow className={rules ? classes.highlightedRow : classes.row}>
      <TableCell>
        <span>{mapRuleFactToLabel(fact, criteria)}</span>
      </TableCell>
      <TableCell>
        <span>{mapRuleOperatorToLabel(operator)}</span>
      </TableCell>
      <TableCell>
        <span>{getRuleParsedValue(fact, value, criteria)}</span>
        <IconButton color="primary" className={classes.editButton} onClick={() => onSelect(rule)}>
          <Edit className={classes.editIcon} />
        </IconButton>
      </TableCell>
      <TableCell>
        <Box className={classes.switchContainer}>
          <FormControlLabel
            control={
              <Switch
                onChange={toggleActiveRule}
                checked={getCheckedState()}
                disabled={loading || (parentRule && !parentRule.active)}
                size="small"
              />
            }
            label={active ? t('global.states.enabled') : t('global.states.disabled')}
            className={classes.switchLabel}
          />

          {loading && <CircularProgress size={22} className={classes.loader} />}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default Rule;
