import React from 'react';
import { Button, Chip, Paper, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import Tooltip from 'components/Tooltip';
import useStyles from './Card.styles';

interface WelcomeCardInterface {
  title: React.ReactElement;
  subtitle?: string;
  tooltipTitle?: string;
  buttonText?: string;
  onClick?: () => void;
  chipText?: string;
  className?: string;
  children?: React.ReactElement;
}
const WelcomeCard: React.FC<WelcomeCardInterface> = ({
  title,
  subtitle,
  tooltipTitle,
  buttonText,
  onClick,
  chipText,
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.root, className)} elevation={0}>
      <div className={classes.details}>
        <Box>{chipText && <Chip label={chipText} color="primary" size="small" />}</Box>

        {tooltipTitle ? (
          <Tooltip title={`${tooltipTitle}`}>
            <Typography variant="caption" gutterBottom style={{ textTransform: 'uppercase' }}>
              {title}
            </Typography>
          </Tooltip>
        ) : (
          <Box className={classes.padding}>
            <Typography variant="caption" gutterBottom style={{ textTransform: 'uppercase' }}>
              {title}
            </Typography>
          </Box>
        )}

        {subtitle && (
          <Typography variant="body1" gutterBottom>
            {subtitle}
          </Typography>
        )}

        {children && children}

        {buttonText && (
          <div className={classes.btnDiv}>
            <Button
              variant="contained"
              disableElevation
              color="secondary"
              className={classes.button}
              onClick={() => onClick && onClick()}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default WelcomeCard;
