import { LenderQuery, UserRole } from 'core/types';
import { ApplicationRegion } from 'store/applications/types';

export interface LendersState {
  loading: boolean;
  error: boolean | string;
  success: boolean | string;
  allLenders: ShortLender[];
  lenders: Lender[];
  total: number;
  lenderDetails: LenderDetails | null;
}

export interface ShortLender {
  id: string;
  displayed_name: string;
  name: string;
}

export interface Lender {
  id: string;
  name: string;
  active: boolean;
  contact_person_name: string;
  contact_person_email: string;
  role?: UserRole.LENDER_MANAGER | UserRole.LENDER_MAINTAINER | UserRole.LENDER_VIEWER;
  logo_url?: string;
  supported_regions: ApplicationRegion[];
}

export interface LenderDetails extends Lender {
  owner?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  displayed_name: string;
  address?: string;
  website?: string;
  phone_number?: string;
  job_title?: string;
  country_code?: string;
  industry?: string;
  employees?: string;
}

export interface LendersReturnHook extends LendersState {
  getLenders: (query: LenderQuery) => void;
  createLender: (lender: CreateLender) => void;
  updateLender: (data: UpdateLender) => void;
  setLenderLogo: (data: SetLenderLogo) => void;
  getAllLenders: () => void;
  getLenderDetails: (lenderId: string) => void;
  setLenderDetails: (details: LenderDetails | null) => void;
  setError: (error: boolean | string) => void;
  setSuccess: (success: string | boolean) => void;
}

export interface CreateLender {
  name: string;
  displayed_name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  active: boolean;
  address?: string;
  logo_url?: string;
  website?: string;
}

export interface UpdateLender {
  id: string;
  lender: Partial<CreateLender>;
}

export interface SetLenderLogo {
  lenderId: string;
  logoUrl: string;
}

export interface ILenderHeaders extends Record<string, string> {
  name: string;
  displayedName: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  active: string;
  address: string;
  logoUrl: string;
  website: string;
}

export const lenderHeaders: ILenderHeaders = {
  name: 'name',
  displayedName: 'displayed_name',
  contactFirstName: 'contact_first_name',
  contactLastName: 'contact_last_name',
  contactEmail: 'contact_email',
  active: 'active',
  address: 'address',
  logoUrl: 'logo_url',
  website: 'website',
};
