import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Divider, Box, Avatar } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { LoadingButton } from 'components/buttons';
import { updateCompanyProfile } from 'http/applications';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { Company } from 'store/applications/types';
import companiesHouseLogo from 'assets/images/companies-house-icon.png';
import useStyles from './CompanyProfile.styles';

interface CompanyProfileProps {
  company: Company;
  applicationId: string;
}

const CompanyProfile: FC<CompanyProfileProps> = ({ company, applicationId }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { setError, setSuccess } = useAdmin();
  const classes = useStyles();
  const { getApplicationDetails } = useApplications();
  const { company_profile: companyProfile } = company;

  const getProfile = async () => {
    setLoading(true);
    try {
      await updateCompanyProfile(company.id);
      getApplicationDetails(applicationId);
      const successMessage = t('pages.lead.sections.companyProfile.success');
      setSuccess(successMessage);
    } catch (error) {
      setError((error as Error).message || true);
    }
    setLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderProfileProp = (prop: Record<string, any> | string) => {
    if (typeof prop !== 'object') return <>{String(prop)}</>;

    return (
      <>
        <Divider className={classes.horizontalDetailsDivider} />
        <>
          {/* eslint-disable-next-line */}
          {Object.keys(prop).map((item: any) => (
            <Box ml={1} mt={1} key={item}>
              <Typography component="span" className={classes.horizontalItemKey}>
                {t(`pages.lead.sections.companyProfile.labels.${item}`)}
              </Typography>
              <Typography component="span" className={classes.horizontalItemValue}>
                {renderProfileProp(prop[item])}
              </Typography>
            </Box>
          ))}
        </>
      </>
    );
  };

  const renderProfile = () => {
    if (!companyProfile) return null;

    return (
      <Grid container spacing={3} className={classes.profileContainer}>
        {Object.keys(companyProfile).map((key) => {
          const transKey = `pages.lead.sections.companyProfile.labels.${key}`;
          const translation = t(transKey);
          if (translation === transKey || key === 'sic_codes') return null;
          return (
            <Grid item xs={12} md={4} key={key} className={classes.grid}>
              <Typography component="span" className={classes.horizontalItemKey}>
                {translation}
              </Typography>

              <Typography component="span" className={classes.horizontalItemValue}>
                {renderProfileProp(companyProfile[key])}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="row" mb={3} alignItems="center">
        <Avatar alt="Companies House logo" src={companiesHouseLogo} />
        <Typography className={classes.title}>{t('pages.lead.sections.companyProfile.title')}</Typography>
      </Box>

      <Box width="100%" mt={4}>
        {renderProfile()}
      </Box>

      <LoadingButton
        variant="contained"
        onClick={getProfile}
        color="primary"
        disableElevation
        loading={loading}
        containerClassName={classes.submitButton}
        startIcon={<Refresh />}
      >
        {t('pages.lead.sections.companyProfile.buttons.getProfile')}
      </LoadingButton>
    </>
  );
};

export default CompanyProfile;
