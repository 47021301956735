import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    customGrey: Palette['primary'];
    greyText: Palette['primary'];
    pending: Palette['primary'];
    approved: Palette['primary'];
    denied: Palette['primary'];
    greyLightBorder: Palette['primary'];
    chipGreyBackground: Palette['primary'];
    greyLabel: Palette['primary'];
  }
  interface PaletteOptions {
    customGrey: PaletteOptions['primary'];
    greyText: PaletteOptions['primary'];
    pending: PaletteOptions['primary'];
    approved: PaletteOptions['primary'];
    denied: PaletteOptions['primary'];
    greyLightBorder: PaletteOptions['primary'];
    chipGreyBackground: PaletteOptions['primary'];
    greyLabel: PaletteOptions['primary'];
  }
}

const greyLightBorderColor = '#D4D7D9';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    common: {
      black: '#29373F',
    },
    customGrey: {
      main: '#EEEFF0',
    },
    greyText: {
      main: '#ABAFB2',
    },
    divider: '#CCCFD1',
    pending: {
      main: '#FF9900',
    },
    approved: {
      main: '#4AB270',
    },
    denied: {
      main: '#E74258',
    },
    greyLightBorder: {
      main: greyLightBorderColor,
    },
    chipGreyBackground: {
      main: '#e3e8ee',
    },
    greyLabel: {
      main: '#697386',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    body1: {
      fontSize: '13px',
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        color: '#29373F',
      },
    },
    MuiInput: {
      root: {
        color: '#29373F',
      },
    },
    MuiButton: {
      root: {
        padding: '10px 20px',
        height: 40,
      },
      label: {
        color: '#29373F',
        textTransform: 'none',
      },
      containedPrimary: {
        backgroundColor: 'rgb(0, 89, 178)',
        padding: '10px 20px',
        fontWeight: 700,
        lineHeight: '20px',

        '& .MuiButton-label': {
          color: '#FFFFFF',
        },

        '&[disabled]': {
          border: '1px solid #999999!important',
          background: '#cccccc!important',
          color: '#666666!important',
          opacity: 0.6,
        },
      },
      containedSecondary: {
        backgroundColor: '#000000',
      },
      outlinedSecondary: {
        border: '1px solid rgba(20,20,20,.1)',
        '& .MuiButton-label': {
          color: '#141414',
        },
      },
    },

    MuiDivider: {
      root: {
        height: 2,
      },
    },
    MuiInputBase: {
      root: {
        color: '#29373F',
      },
    },
    MuiTableHead: {
      root: {
        border: 2,
        borderColor: '#CCCFD1',
        borderStyle: 'solid',
      },
    },
    MuiTableBody: {
      root: {
        borderWidth: 2,
        borderColor: '#CCCFD1',
        borderStyle: 'solid',

        '& tr:nth-child(odd)': {
          backgroundColor: 'rgb(0, 89, 178, 0.02)',
        },
        '& tr:nth-child(even)': {
          backgroundColor: 'rgb(0, 89, 178, 0.05)',
        },
      },
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer',
        '&$hover:hover, &$selected': {
          backgroundColor: 'rgba(0, 89, 178, 0.6) !important',
          color: '#FFFFFF !important',
        },
      },
    },
    MuiTableFooter: {
      root: {
        '& tr td': {
          borderBottom: 'none',
        },
      },
    },
    MuiTableCell: {
      root: {
        minWidth: '20px',
      },
      body: {
        color: 'inherit',
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: '#EEEFF0',
      },
      checkboxRoot: {
        color: '#D4D7D9',
        paddingLeft: 14,
        '& svg': {
          width: 20,
          height: 20,
        },
      },
    },
    MuiListItemText: {
      root: {
        flex: 'initial',
      },
    },
  },
});

export default theme;
