import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& button:last-child': {
        marginLeft: theme.spacing(2),
      },
    },
    button: {
      background: '#d95359',
    },
  }),
);
