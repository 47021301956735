import { FC, useRef, useMemo, useState } from 'react';
import { TextareaAutosize, FormHelperText, Grid } from '@material-ui/core';
import { Formik, Form, FormikState, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { followUpApplication as followUpApplicationApi } from 'http/admin';
import { CustomCheckGroupField } from 'components/inputs';
import { companyDocumentTypeOptions } from 'core/constants';
import { AlertDialog } from 'components/AlertDialog';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { CompanyDocumentType } from 'core/types';
import useStyles from './FollowUpModal.styles';

interface FormValues {
  documentsRequested: CompanyDocumentType[];
  notes: string;
}

interface FollowUpModalProps {
  open: boolean;
  toggleOpen: () => void;
  selectedOfferId?: string;
}

const FollowUpModal: FC<FollowUpModalProps> = ({ open, toggleOpen, selectedOfferId }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const { setError, setSuccess } = useAdmin();
  const { applicationDetails, getApplicationDetails } = useApplications();

  const rejectSchema = yup.object({
    documentsRequested: yup
      .array(yup.string())
      .min(1, t('pages.lead.admin.followUp.inputs.documentsRequested.required')),
    notes: yup.string().nullable(),
  });

  const appRequestDetails = useMemo(() => {
    if (selectedOfferId) {
      return applicationDetails?.details_requested?.find((req) => req.offer_id === selectedOfferId && !req.resolved);
    }
    return applicationDetails?.details_requested?.find((req) => !req.offer_id && !req.resolved);
  }, [applicationDetails, selectedOfferId]);

  const initialValues = {
    documentsRequested: appRequestDetails?.document_types ?? [],
    notes: appRequestDetails?.notes ?? '',
  };

  const onSubmit = async (values: FormValues) => {
    if (!applicationDetails) return;
    setLoading(true);
    try {
      await followUpApplicationApi(applicationDetails.id, values.documentsRequested, values.notes, selectedOfferId);
      formRef.current?.resetForm();
      toggleOpen();
      setSuccess(t('pages.lead.admin.followUp.messages.success') as string);
      getApplicationDetails(applicationDetails.id);
    } catch (error) {
      setError((error as Error).message ?? true);
    }
    setLoading(false);
  };

  const onCancel = (resetForm: (nextState?: Partial<FormikState<typeof initialValues>> | undefined) => void) => {
    resetForm();
    toggleOpen();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={rejectSchema} innerRef={formRef}>
      {({ handleChange, values, handleSubmit, touched, errors, resetForm, setFieldValue }) => (
        <AlertDialog
          open={open}
          dialogContentTitle={t(`pages.lead.admin.followUp.title.${selectedOfferId ? 'offer' : 'application'}`)}
          dialogContentText=""
          handleCancel={() => onCancel(resetForm)}
          handleConfirm={handleSubmit}
          confirmButtonTitle={t('pages.lead.admin.followUp.buttons.confirm')}
          loading={loading}
        >
          <Form noValidate>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Field
                  id="documentsRequested"
                  name="documentsRequested"
                  component={CustomCheckGroupField}
                  options={companyDocumentTypeOptions}
                  value={values.documentsRequested}
                  onChange={(value: string) => {
                    const alreadyIn = values.documentsRequested.find((info) => info === value);
                    if (alreadyIn) {
                      setFieldValue(
                        'documentsRequested',
                        values.documentsRequested.filter((info) => info !== value),
                      );
                    } else {
                      setFieldValue('documentsRequested', [...values.documentsRequested, value]);
                    }
                  }}
                  title={t('pages.lead.admin.followUp.inputs.documentsRequested.label')}
                  className={classes.documentsRequested}
                  disabled={loading}
                />
              </Grid>

              <Grid item className={classes.notesContainer}>
                <TextareaAutosize
                  id="notes"
                  value={values.notes || ''}
                  aria-label="minimum height"
                  rowsMin={5}
                  onChange={handleChange}
                  className={touched.notes && errors.notes ? classes.textAreaError : ''}
                  placeholder={t('pages.lead.admin.followUp.inputs.notes.placeholder')}
                  disabled={loading}
                />

                {touched.notes && errors.notes && <FormHelperText error>{errors.notes}</FormHelperText>}
              </Grid>
            </Grid>
          </Form>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default FollowUpModal;
