import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    input: {
      width: '100%',
    },
    inputError: {
      borderColor: `${theme.palette.error.main} !important`,
    },
    submitButton: {
      marginTop: theme.spacing(2),
      maxWidth: 120,
      alignSelf: 'flex-end',
    },
  }),
);
