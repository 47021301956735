import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      marginBottom: 12,
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    tableContainer: {
      marginTop: theme.spacing(3),
      '& .MuiTableRow-root': {
        cursor: 'auto',
      },
    },
    disabled: {
      opacity: 0.5,
    },
  }),
);
