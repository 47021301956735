import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { MUIDataTableState } from 'mui-datatables';
import { useTranslation } from 'react-i18next';

import { Table, customHeadRender } from 'components/table';
import { BalanceTransaction } from 'store/applications/types';
import { BalanceTransactionsColumnNames } from 'core/types';
import { parseMoney } from 'utils';
import { useApplications } from 'store/applications/hooks';
import { mapAppRegionToSupportedCurrency } from 'core/utils';

interface TransactionsTableProps {
  transactions: BalanceTransaction[];
  total: number;
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
}

const TransactionsTable: FC<TransactionsTableProps> = ({ transactions, total, activePage, setActivePage }) => {
  const { t } = useTranslation();
  const { applicationDetails } = useApplications();

  const appRegion = applicationDetails?.region;

  const columns = useMemo(
    () => [
      {
        name: BalanceTransactionsColumnNames.AMOUNT,
        label: t('pages.lead.sections.balanceData.transactionsTable.columns.amount'),
        options: {
          customHeadRender,
          customBodyRender: (value: number) =>
            value ? parseMoney(value, mapAppRegionToSupportedCurrency(appRegion)) : null,
        },
      },
      {
        name: BalanceTransactionsColumnNames.CURRENCY,
        label: t('pages.lead.sections.balanceData.transactionsTable.columns.currency'),
        options: { customHeadRender },
      },
      {
        name: BalanceTransactionsColumnNames.CREDIT_DEBIT_INDICATOR,
        label: t('pages.lead.sections.balanceData.transactionsTable.columns.type'),
        options: { customHeadRender },
      },
      {
        name: BalanceTransactionsColumnNames.DATE_TIME,
        label: t('pages.lead.sections.balanceData.transactionsTable.columns.date'),
        options: {
          customHeadRender,
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleDateString() : null),
        },
      },
    ],
    [appRegion, t],
  );

  const onTableChange = useCallback(
    (action: string, tableState: MUIDataTableState) => {
      if (action === 'changePage') {
        setActivePage(tableState.page);
      }
    },
    [setActivePage],
  );

  return (
    <Table<BalanceTransaction>
      columns={columns}
      data={transactions}
      count={total}
      page={activePage}
      rowsPerPage={10}
      onTableChange={onTableChange}
    />
  );
};

export default TransactionsTable;
