import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: 'auto',
    },
    highlightedRow: {
      backgroundColor: theme.palette.action.hover,
      cursor: 'auto',
      '& span': {
        fontWeight: 600,
      },
    },
    switchContainer: {
      display: 'flex',
      position: 'relative',
    },
    switchLabel: {
      marginRight: theme.spacing(1.5),
      '& > span': {
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(18),
      },
    },
    loader: {
      position: 'absolute',
      right: 25,
      top: 2,
    },
    editButton: {
      height: '30px',
      width: '30px',
    },
    editIcon: {
      fontSize: '20px',
      color: theme.palette.primary.main,
    },
  }),
);
