import { FC, useState, useCallback } from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { FileRejection } from 'react-dropzone';
import { HighlightOff as RejectedFileIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { allowedFileTypes } from 'core/constants';
import { Document, DocumentUploadResponse } from 'core/types';
import useStyles from './DocumentUpload.styles';
import { FileDropCSV } from '../FileDrop';

interface DocumentUploadProps {
  uploadDocument(data: FormData | string): Promise<DocumentUploadResponse | null>;
  validationError?: string;
  onUploadSuccess?: (document: Document) => void;
}

const DocumentUpload: FC<DocumentUploadProps> = ({ uploadDocument, validationError, onUploadSuccess }) => {
  const [rejectedFiles, setRejections] = useState<FileRejection[]>([]);
  const [uploadedDoc, setUploadedDoc] = useState<File | null>(null);

  const classes = useStyles();
  const { t } = useTranslation();

  const handleUpload = useCallback(
    (documents: File[]) => {
      const document = documents[0];
      setUploadedDoc(document);
      const reader = new FileReader();

      let csv: string;
      reader.onload = () => {
        csv = reader.result as string;
      };
      reader.onloadend = async () => uploadDocument(csv);
      reader.readAsText(document);

      if (onUploadSuccess) onUploadSuccess(document);
    },
    [onUploadSuccess, uploadDocument],
  );

  const handleRejection = useCallback((files: FileRejection[]) => {
    setRejections(files);
  }, []);

  const removeRejected = useCallback(
    (rejected: FileRejection) => {
      setRejections(rejectedFiles.filter((item) => item.file.name !== rejected.file.name));
    },
    [rejectedFiles],
  );

  return (
    <Box>
      <FileDropCSV
        allowedFileTypes={allowedFileTypes.filter((type) => type.mimeType === '.csv')}
        onUpload={handleUpload}
        onReject={handleRejection}
        uploadMessage={t('global.uploadDocument')}
        validationError={rejectedFiles?.length > 0 || validationError}
      />

      {uploadedDoc && (
        <Box marginTop="15px">
          <Typography className={classes.sectionTitle}>{t('global.files.uploaded')}</Typography>

          <List>
            <ListItem className={classes.demo}>
              <ListItemText primary={uploadedDoc.name} className={classes.fileTitle} />
            </ListItem>
          </List>
        </Box>
      )}

      {(rejectedFiles.length && (
        <Box marginTop="15px">
          <Typography className={classes.sectionTitle}>{t('global.files.rejected')}</Typography>

          <List>
            {rejectedFiles.map((res: FileRejection) => {
              return (
                <ListItem key={res.file.name}>
                  <ListItemText primary={res.file.name} className={classes.fileTitle} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => removeRejected(res)}>
                      <div className={classes.deleteAvatar}>
                        <RejectedFileIcon color="error" />
                      </div>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )) ||
        ''}
    </Box>
  );
};

export default DocumentUpload;
