import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    controlLabel: {
      marginLeft: 0,
      display: 'flex',
      marginTop: theme.spacing(0.3),
    },
    strongText: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    checkbox: {
      padding: 5,
      color: theme.palette.grey[400],

      '& svg': {
        height: 18,
        width: 18,
      },
    },
    formControl: {
      width: 350,
    },
    select: {
      height: '40px',
    },
    expandIcon: {
      height: 20,
      width: 20,
      color: '#687C8D',
      pointerEvents: 'none',
      position: 'absolute',
      top: 8,
      right: 10,
    },
    chip: {
      marginRight: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(1),
    },
    appStatusButton: {
      marginLeft: theme.spacing(2),
      maxWidth: 'auto',
    },
  }),
);
