import { Box, Fade } from '@material-ui/core';
import { FC } from 'react';
import { Navbar } from '../Navbar';
import useStyles from './Layout.styles';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Navbar />

      <Fade in>
        <Box className={classes.contentContainer}>{children}</Box>
      </Fade>
    </Box>
  );
};

export default Layout;
