import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: 168,
    },
    panel: {
      width: '100%',
    },
    subtitle: { fontSize: theme.typography.pxToRem(14), lineHeight: theme.typography.pxToRem(20), fontWeight: 700 },
    tab: {
      textTransform: 'none',
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      textAlign: 'left',
      '&:focus': {
        opacity: 1,
      },
      '& $span': {
        display: 'inline',
      },
    },
  }),
);
