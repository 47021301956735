import { FC } from 'react';
import { Typography, Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { MetricsOverview } from 'components/MetricsOverview';
import useStyles from './Metrics.styles';

const Metrics: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography className={classes.title}>{t('pages.metrics.title')}</Typography>
          <Typography className={classes.subtitle}>{t('pages.metrics.subtitle')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <MetricsOverview />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Metrics;
