import { FC, useState } from 'react';
import { Button, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus } from 'core/types';
import { useGlobalState } from 'store/global/hooks';
import { useAdmin } from 'store/admin/hooks';
import { useAuth } from 'store/auth/hooks';
import { useAccount } from 'store/account/hooks';
import { CreateApplicationModal } from '../CreateApplicationModal';
import useStyles from './AppAdminActions.styles';

const AppAdminActions: FC = () => {
  const [createOpen, setCreateOpen] = useState(false);
  const [sendLink, setSendLink] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { error: globalError } = useGlobalState();
  const { error, success, setError, setSuccess } = useAdmin();
  const { isAdmin, isPartner } = useAuth();
  const { account } = useAccount();

  const toggleCreateOpen = () => setCreateOpen((prevState) => !prevState);

  const toggleSendLink = () => setSendLink((prevState) => !prevState);

  return (
    <Box className={classes.container}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disableElevation
        onClick={toggleCreateOpen}
      >
        {t('pages.dashboard.addAdminActions.buttons.create')}
      </Button>

      {isAdmin && (
        <Button variant="contained" color="primary" disableElevation onClick={toggleSendLink}>
          {t('pages.dashboard.addAdminActions.buttons.send')}
        </Button>
      )}

      <CreateApplicationModal
        open={createOpen}
        toggleOpen={toggleCreateOpen}
        partnerId={isPartner ? account.id ?? undefined : undefined}
      />

      <CreateApplicationModal open={sendLink} toggleOpen={toggleSendLink} sendLink />

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Box>
  );
};

export default AppAdminActions;
