import { FC, useCallback, useState } from 'react';
import { Grid, Typography, CircularProgress, Box } from '@material-ui/core';
import clsx from 'clsx';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { CompanyDocumentType, Document, DocumentUploadResponse } from 'core/types';
import useStyles from './FileDrop.styles';

// 20MB -> in bytes
const maxFileSize = 20000000;
interface AllowedFiletype {
  name: string;
  mimeType: string;
  extension: string;
}

interface FileDropProps {
  allowedFileTypes: Array<AllowedFiletype>;
  onUpload(documents: Document[]): void;
  onReject(rejections: FileRejection[]): void;
  uploadMessage: string;
  uploadDocument(data: FormData): Promise<DocumentUploadResponse | null>;
  validationError?: boolean | string;
  documentType?: CompanyDocumentType;
}
const FileDrop: FC<FileDropProps> = ({
  allowedFileTypes,
  onUpload,
  onReject,
  uploadMessage,
  uploadDocument,
  validationError,
  documentType,
}) => {
  const [loading, setLoading] = useState(false);
  const [docTypeError, setDocTypeError] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setDocTypeError(false);
      const Data = new FormData();
      const rejections = [...fileRejections];

      acceptedFiles.forEach((file) => {
        Data.append('file', file);
        if (documentType) Data.append('type', documentType);
      });

      let docResponse = null;
      if (acceptedFiles.length > 0) {
        setLoading(true);
        try {
          docResponse = await uploadDocument(Data);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          if (!documentType) setDocTypeError(true);
          acceptedFiles.forEach((file) => {
            rejections.push({
              file,
              errors: [
                {
                  code: 'too-many-files',
                  message: err.message,
                },
              ],
            });
          });
        }
        setLoading(false);
      }
      // No multiple upload file is allowed
      if (acceptedFiles.length > 0 && docResponse) {
        const uploadedDocuments = [{ name: acceptedFiles[0].name, ...docResponse }];
        onUpload(uploadedDocuments);
      }
      // Don't check if array has any element, we want to reset rejections on every upload op
      onReject(rejections);
    },
    [documentType, onReject, onUpload, uploadDocument],
  );

  const error = (docTypeError ? t('global.uploadDocumentValidation') : false) || validationError;
  return (
    <Dropzone
      onDrop={(acceptedFiles, fileRejections) => onDrop(acceptedFiles, fileRejections)}
      maxSize={maxFileSize}
      accept={allowedFileTypes.map((fileType) => fileType.mimeType)}
      disabled={loading}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <section className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box {...getRootProps()} className={clsx([classes.outer, error && classes.outerError])}>
                <Box className={loading ? clsx([classes.inner, classes.disabled]) : classes.inner}>
                  <input {...getInputProps()} />
                  <Typography className={classes.uploadMessage}>{uploadMessage}</Typography>
                </Box>
                {loading && <CircularProgress className={classes.progress} />}
              </Box>
              {error && (
                <Typography className={classes.errorMessage}>
                  {typeof error === 'string' ? error : t('global.error')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </section>
      )}
    </Dropzone>
  );
};
export default FileDrop;
