import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLabel, Grid } from '@material-ui/core';
import { AlertDialog } from 'components/AlertDialog';
import { DocumentUploadCSV } from 'components/DocumentUpload';
import { CustomLabel } from 'components/inputs';
import { readLendersCSV } from 'http/admin/lenders.etl.csv';

import { useAdmin } from 'store/admin/hooks';
import { LendersAction } from 'store/admin/types';
import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus } from 'core/types';
import useStyles from './UploadLendersModal.styles';

interface UploadLendersModalProps {
  open: boolean;
  toggleOpen: () => void;
}

const UploadLendersModal: FC<UploadLendersModalProps> = ({ open, toggleOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, uploadLenders, error, setError, success, setSuccess } = useAdmin(() => {
    if (open) toggleOpen();
  });

  const [csvFile, setCSVFile] = useState<string>('');

  const setCSV = async (csv: string) => {
    setCSVFile(csv);
    return null;
  };

  const onCancel = () => {
    toggleOpen();
  };

  const uploadDocument = async () => {
    const parsedLenders: LendersAction[] = await readLendersCSV(csvFile);

    const validLenders = parsedLenders.filter((item) => item.name && item.contact_first_name);
    if (validLenders.length) uploadLenders(validLenders);
  };

  return (
    <>
      <AlertDialog
        open={open}
        dialogContentTitle={t('pages.lenders.admin.actions.upload')}
        handleCancel={onCancel}
        handleConfirm={uploadDocument}
        confirmButtonTitle={t('pages.lenders.admin.actions.upload')}
        loading={loading}
        confirmButtonDisabled={!csvFile}
      >
        <form noValidate>
          <Grid container direction="column" spacing={4} className={classes.container}>
            <Grid item className={classes.documentUploadContainer}>
              <FormLabel component={() => <CustomLabel title="Lenders" />} />

              <DocumentUploadCSV uploadDocument={setCSV} />
            </Grid>
          </Grid>
        </form>

        <AlertMessage
          open={error as boolean}
          onClose={() => setError(false)}
          message={error as string}
          status={AlertMessageStatus.ERROR}
        />
      </AlertDialog>

      <AlertMessage
        open={success as boolean}
        onClose={() => setSuccess(false)}
        message={(success as string) || t('global.successMessage')}
        status={AlertMessageStatus.SUCCESS}
        autoHideDuration={5000}
      />
    </>
  );
};

export default UploadLendersModal;
