import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(1),
    },
    title: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
      marginTop: 12,
      marginBottom: 12,
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    filterText: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      marginTop: theme.spacing(1),
      fontWeight: 700,
      color: theme.palette.info.main,
      '& svg': {
        fontSize: theme.typography.pxToRem(14),
        marginBottom: -3,
        marginRight: 6,
        color: theme.palette.info.main,
      },
    },
    dateRangeGridItem: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    dateRandeContainer: {
      [theme.breakpoints.down(532)]: {
        marginTop: theme.spacing(2),
      },
    },
    datePickerContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tableContainer: {
      '& th:nth-child(n+2), td:nth-child(n+2)': {
        minWidth: 161,
      },
    },
    adminFilters: {
      display: 'flex',
      alignItems: 'center',
    },
    adminFiltersTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      marginRight: theme.spacing(1),
    },
    checkbox: {
      padding: 5,
      color: theme.palette.grey[400],
      '& svg': {
        height: 18,
        width: 18,
      },
    },
    controlLabel: {
      marginLeft: 0,
    },
    strongText: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    dateInfoContainer: {
      width: 470,
      '& > p': {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
      },
    },
    expandIcon: {
      height: 20,
      width: 20,
      color: '#687C8D',
      pointerEvents: 'none',
      position: 'absolute',
      top: 8,
      right: 10,
    },
    selectContainer: {
      marginRight: theme.spacing(2),
      '& .MuiSelect-root': {
        height: 40,
        minWidth: '10em',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 16,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
      },
    },
    select: {
      height: 40,
    },
    filtersContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > div': {
        marginRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      '& > div:last-child': {
        marginRight: 0,
      },
    },
    asigneeContainer: {
      width: 360,
    },
    regionContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    statusButton: {
      color: '#2c373e',
      fontWeight: 700,
    },
    statusButtonIcon: {
      color: '#418996',
    },
    checkboxContainer: {
      minWidth: 145,
      '& .MuiFormControlLabel-label': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
      },
    },
  }),
);
