import { ChangeEvent, FC } from 'react';
import { Box, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { applicationRegionOptions } from 'core/constants';
import { useApplications } from 'store/applications/hooks';
import { ApplicationRegion } from 'store/applications/types';
import useStyles from './RegionFilter.styles';

const RegionFilter: FC = () => {
  const classes = useStyles();
  const {
    filters: { region },
    updateApplicationsQuery,
  } = useApplications();

  return (
    <Box>
      <Select
        native
        placeholder="Region"
        IconComponent={() => <ExpandMore className={classes.expandIcon} />}
        variant="outlined"
        value={region ?? ''}
        onChange={(
          event: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
          }>,
        ) => updateApplicationsQuery({ region: event.target.value as ApplicationRegion, activePage: 0 })}
        className={classes.select}
      >
        {applicationRegionOptions.map(({ label, value }) => {
          return (
            <option key={`option${value}`} value={value}>
              {label}
            </option>
          );
        })}
      </Select>
    </Box>
  );
};

export default RegionFilter;
