import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      margin: 0,
      fontSize: theme.typography.pxToRem(14),
    },
  }),
);

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& ul': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      marginBottom: theme.spacing(3),
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    listItem: {
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
      alignItems: 'flex-start',
      paddingLeft: 0,
    },
    itemTitle: {
      marginBottom: 0,

      '& span': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
        color: theme.palette.greyText.main,
      },
    },
    itemText: {
      '& span': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    nestedList: {
      marginTop: theme.spacing(0.5),

      '& .MuiListItem-root': {
        marginTop: -theme.spacing(2.5),
      },
    },
    buttonLink: {
      padding: '4px 0px',

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '& span': {
        color: theme.palette.primary.main,
      },
    },
  }),
);
