import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useTabsStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    minHeight: 'auto',
    flexGrow: 1,
    width: '100%',
  },
});

export default makeStyles(() =>
  createStyles({
    container: {
      marginTop: '20px',
      padding: '8px',
    },
  }),
);
