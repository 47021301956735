import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MUIDataTableState } from 'mui-datatables';

import { Loader } from 'components/Loader';
import { Table, customHeadRender } from 'components/table';
import { usePartners } from 'store/partners/hooks';
import { AlertMessageStatus, PartnerQuery, PartnersTableColumnNames } from 'core/types';
import { Partner } from 'store/partners/types';
import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import { usePrevious } from 'hooks';
import { AlertMessage } from 'components/alerts';
import { useGlobalState } from 'store/global/hooks';
import { toTitleCase } from 'utils';
import useStyles from './Partners.styles';
import { PartnerAdminActions } from './components';

const Partners: FC = () => {
  const [activePage, setActivePage] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, partners, total, error, success, setSuccess, setError, getPartners } = usePartners();
  const { error: globalError } = useGlobalState();
  const prevActivePage = usePrevious(activePage);
  const history = useHistory();

  const partnerQuery: PartnerQuery = useMemo(
    () => ({
      per_page: ROWS_PER_TABLE_PAGE,
      page: activePage + 1,
    }),
    [activePage],
  );

  useEffect(() => {
    if (prevActivePage !== activePage) {
      getPartners(partnerQuery);
    }
  }, [activePage, getPartners, partnerQuery, prevActivePage]);

  const getPartnerDetails = async (partnerId: string) => history.push(`/d/partner/${partnerId}`);

  const columns = useMemo(
    () => [
      {
        name: PartnersTableColumnNames.PARTNER_NAME,
        label: t('pages.partners.table.columns.partnerName'),
        options: {
          customHeadRender,
        },
      },
      {
        name: PartnersTableColumnNames.USER_NAME,
        label: t('pages.partners.table.columns.userName'),
        options: {
          customHeadRender,
        },
      },
      {
        name: PartnersTableColumnNames.EMAIL,
        label: t('pages.partners.table.columns.email'),
        options: {
          customHeadRender,
        },
      },
      {
        name: PartnersTableColumnNames.ROLE,
        label: t('pages.partners.table.columns.role'),
        options: {
          customHeadRender,
        },
      },
      {
        name: PartnersTableColumnNames.ENABLED,
        label: t('pages.partners.table.columns.enabled'),
        options: {
          customHeadRender,
          customBodyRender: (value: boolean) => toTitleCase(String(value)),
        },
      },
      {
        name: PartnersTableColumnNames.CREATED_AT,
        label: t('pages.partners.table.columns.createdAt'),
        options: {
          customHeadRender,
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleDateString() : null),
        },
      },
    ],
    [t],
  );

  const onTableChange = useCallback((action: string, tableState: MUIDataTableState) => {
    if (action === 'changePage') {
      setActivePage(tableState.page);
    }
  }, []);

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography className={classes.title}>{t('pages.partners.title')}</Typography>
          <Typography className={classes.subtitle}>{t('pages.partners.description')}</Typography>
        </Grid>

        <Grid item xs={4} className={classes.actionContainer}>
          <PartnerAdminActions />
        </Grid>

        <Grid item xs={12} className={classes.tableContainer}>
          <Table<Partner>
            columns={columns}
            data={partners}
            onTableChange={onTableChange}
            page={activePage}
            count={total}
            rowsPerPage={ROWS_PER_TABLE_PAGE}
            onItemClick={getPartnerDetails}
          />
        </Grid>
      </Grid>

      <Loader visible={loading} />

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Partners;
