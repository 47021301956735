import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    expandIcon: {
      height: 20,
      width: 20,
      color: '#687C8D',
      pointerEvents: 'none',
      position: 'absolute',
      top: 8,
      right: 10,
    },
    select: {
      height: 40,
    },
  }),
);
