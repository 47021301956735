import { Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import TooltipMui, { TooltipProps as TooltipPropsMui } from '@material-ui/core/Tooltip';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { InfoOutlined as TooltipIcon } from '@material-ui/icons';
import React from 'react';

const useStylesBootstrap = makeStyles(() => ({
  tooltip: {
    zIndex: 1300,
    margin: 0,
  },
}));

function BootstrapTooltip(props: TooltipPropsMui) {
  const classes = useStylesBootstrap();

  return <TooltipMui arrow classes={classes} {...props} />;
}

export interface TooltipProps {
  heading?: string;
  Icon?: React.ReactElement<SvgIconProps>;
  placementMobile?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  afterInput?: boolean;
}

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 12,
  },
  caption: {
    fontSize: 11,
  },
}));

const Tooltip: React.FC<TooltipProps & TooltipPropsMui & WithWidth> = ({
  title: message,
  Icon = <TooltipIcon />,
  children,
  className,
  heading,
  placement,
  placementMobile = placement,
  width,
  afterInput = false,
}) => {
  const classes = useStyles();
  const checkPlacement = width === 'sm' || width === 'xs' ? placementMobile : placement;
  return (
    <Box>
      {!afterInput && children}
      <BootstrapTooltip
        enterTouchDelay={0}
        title={
          <>
            {heading && (
              <>
                <Typography variant="body1" className={classes.heading}>
                  {heading}
                </Typography>
              </>
            )}
            <Typography variant="caption" className={classes.caption}>
              {message}
            </Typography>
          </>
        }
        placement={checkPlacement}
        className={className}
      >
        <IconButton aria-label="tooltip-icon">{Icon}</IconButton>
      </BootstrapTooltip>
      {afterInput && children}
    </Box>
  );
};

export default withWidth()(Tooltip);
