import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { INCOMPLETED_APP_STATUS, LOCKED_FOR_INTRODUCTION_STATUS } from 'core/constants';
import { useAccount } from 'store/account/hooks';
import { useApplications } from 'store/applications/hooks';
import { ApplicationRegion, ApplicationStatus } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { getFollowUpKey } from 'utils';
import { CreateReminderModal } from '../CreateReminderModal';
import { FollowUpModal } from '../FollowUpModal';
import { GatherOffers } from '../GatherOffers';
import useLeadStyles from '../LeadDetails/LeadDetails.styles';
import { LenderIntroductionModal } from '../LenderIntroductionModal';
import { MakeOfferModal } from '../MakeOfferModal';
import { RejectModal } from '../RejectModal';
import { SendEmailModal } from '../SendEmailModal';
import useStyles from './AdminActions.styles';

interface AdminActionsProps {
  selectedOfferId?: string;
  setSelectedOfferId: Dispatch<SetStateAction<string | undefined>>;
}

const AdminActions: FC<AdminActionsProps> = ({ selectedOfferId, setSelectedOfferId }) => {
  const [rejectOpen, setRejectOpen] = useState(false);
  const [followUpOpen, setFollowUpOpen] = useState(false);
  const [makeOfferOpen, setMakeOfferOpen] = useState(false);
  const [lenderIntroOpen, setLenderIntroOpen] = useState(false);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [createReminderOpen, setCreateReminderOpen] = useState(false);
  const classes = useStyles();
  const leadClasses = useLeadStyles();
  const { t } = useTranslation();
  const { applicationDetails } = useApplications();
  const { isAdmin, isLenderAdmin } = useAuth();
  const { account } = useAccount();

  useEffect(() => {
    if (selectedOfferId) {
      setFollowUpOpen(true);
    }
  }, [selectedOfferId]);

  const toggleRejectOpen = () => setRejectOpen((prevState) => !prevState);

  const toggleFollowUpOpen = () => {
    setFollowUpOpen((prevState) => !prevState);
    if (selectedOfferId) setSelectedOfferId(undefined);
  };

  const toggleMakeOfferOpen = () => setMakeOfferOpen((prevState) => !prevState);

  const toggleLenderIntroOpen = () => setLenderIntroOpen((prevState) => !prevState);

  const toggleSendEmailOpen = () => setSendEmailOpen((prevState) => !prevState);

  const toggleCreateReminderOpen = () => setCreateReminderOpen((prevState) => !prevState);

  const isAppNotCompleted = !!applicationDetails && INCOMPLETED_APP_STATUS.includes(applicationDetails.status);

  const lockedForIntroduction =
    !!applicationDetails && LOCKED_FOR_INTRODUCTION_STATUS.includes(applicationDetails.status);

  const canMakeOffersOrFollowup =
    !!applicationDetails && applicationDetails.status === ApplicationStatus.OFFERS_REQUESTED;

  const followupKey = useMemo(() => getFollowUpKey(applicationDetails?.details_requested || []), [applicationDetails]);

  const canGatherOffers = isAdmin && applicationDetails?.status === ApplicationStatus.OFFERS_REQUESTED;

  const applicant = applicationDetails?.company.people.find((p) => p.applicant);

  return (
    <>
      <Grid item xs={12}>
        <List>
          {isAdmin && lockedForIntroduction && (
            <ListItem className={leadClasses.listItem}>
              <ListItemText className={leadClasses.listItemTitle}>
                <Typography className={classes.appIncompletedText}>
                  <ErrorOutline />
                  {t('pages.lead.admin.actions.disabledLenderIntro')}
                </Typography>
              </ListItemText>
            </ListItem>
          )}

          {isAdmin && isAppNotCompleted && (
            <ListItem className={leadClasses.listItem}>
              <ListItemText className={leadClasses.listItemTitle}>
                <Typography className={classes.appIncompletedText}>
                  <ErrorOutline />
                  {t('pages.lead.admin.actions.disabledMessage')}
                </Typography>
              </ListItemText>
            </ListItem>
          )}

          <ListItem className={leadClasses.listItem}>
            <ListItemText className={leadClasses.listItemTitle}>{t('pages.lead.admin.actions.makeOffer')}</ListItemText>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.leadActionButton}
              onClick={toggleMakeOfferOpen}
              disabled={isAppNotCompleted || !canMakeOffersOrFollowup}
            >
              {t('pages.lead.admin.actions.makeOffer')}
            </Button>
          </ListItem>

          {(isAdmin || isLenderAdmin) && (
            <ListItem className={leadClasses.listItem}>
              <ListItemText className={leadClasses.listItemTitle}>{t('pages.lead.admin.actions.reject')}</ListItemText>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.leadActionButton}
                onClick={toggleRejectOpen}
              >
                {t('pages.lead.admin.actions.reject')}
              </Button>
            </ListItem>
          )}

          {isAdmin && (
            <>
              <ListItem className={leadClasses.listItem}>
                <ListItemText className={leadClasses.listItemTitle}>
                  {t('pages.lead.admin.actions.followUp')}
                </ListItemText>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.leadActionButton}
                  onClick={toggleFollowUpOpen}
                  disabled={!canMakeOffersOrFollowup}
                >
                  {t('pages.lead.admin.actions.followUp')}
                </Button>
              </ListItem>

              <ListItem className={leadClasses.listItem}>
                <ListItemText className={leadClasses.listItemTitle}>
                  {t('pages.lead.admin.actions.sendEmail')}
                </ListItemText>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.leadActionButton}
                  onClick={toggleSendEmailOpen}
                  disabled={!applicant}
                >
                  {t('pages.lead.admin.actions.sendEmail')}
                </Button>
              </ListItem>

              <ListItem className={leadClasses.listItem}>
                <ListItemText className={leadClasses.listItemTitle}>
                  {t('pages.lead.admin.actions.createReminder')}
                </ListItemText>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.leadActionButton}
                  onClick={toggleCreateReminderOpen}
                  disabled={!applicant}
                >
                  {t('pages.lead.admin.actions.createReminder')}
                </Button>
              </ListItem>
            </>
          )}

          {applicationDetails?.company?.id && canGatherOffers && (
            <>
              <ListItem className={leadClasses.listItem}>
                <ListItemText
                  secondary={t('pages.lead.admin.lenderIntroduction.description')}
                  className={leadClasses.listItemTitle}
                >
                  {t('pages.lead.admin.lenderIntroduction.title')}
                </ListItemText>
              </ListItem>

              <ListItem className={leadClasses.listItem}>
                {applicationDetails.region === ApplicationRegion.UK && (
                  <GatherOffers companyId={applicationDetails.company.id} offers={applicationDetails.offers} />
                )}

                <LenderIntroductionModal
                  open={lenderIntroOpen}
                  toggleOpen={toggleLenderIntroOpen}
                  disabled={isAppNotCompleted || lockedForIntroduction}
                />
              </ListItem>
            </>
          )}
        </List>
      </Grid>

      <RejectModal open={rejectOpen} toggleOpen={toggleRejectOpen} />

      <FollowUpModal
        key={`${followupKey}${selectedOfferId}`}
        open={followUpOpen}
        toggleOpen={toggleFollowUpOpen}
        selectedOfferId={selectedOfferId}
      />

      <MakeOfferModal
        open={makeOfferOpen}
        toggleOpen={toggleMakeOfferOpen}
        lenderId={isLenderAdmin && account.id ? account.id : undefined}
      />

      <SendEmailModal open={sendEmailOpen} toggleOpen={toggleSendEmailOpen} />

      <CreateReminderModal open={createReminderOpen} toggleOpen={toggleCreateReminderOpen} />
    </>
  );
};

export default AdminActions;
