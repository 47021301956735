import { FC } from 'react';
import { Box, Select, FormHelperText, FormLabel } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FieldProps } from 'formik/dist/Field';

import useStyles from './CustomSelect.styles';
import { CustomLabel } from '../CustomLabel';

interface CustomSelectProps extends FieldProps {
  title: string;
  placeholder?: string;
  options: { label: string; value: string }[];
  tooltipMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
}

const CustomSelect: FC<CustomSelectProps> = ({ form, field, title, options, tooltipMessage, ...rest }) => {
  const classes = useStyles();
  const hasError = form.touched[field.name] && !!form.errors[field.name];

  return (
    <Box className={classes.selectContainer}>
      <FormLabel component={() => <CustomLabel title={title} tooltipMessage={tooltipMessage} />} />

      <Select
        native
        IconComponent={() => <ExpandMore className={classes.expandIcon} />}
        variant="outlined"
        error={hasError}
        {...rest}
      >
        {options.map(({ label, value }) => {
          return (
            <option key={`option${value}`} value={value}>
              {label}
            </option>
          );
        })}
      </Select>

      {hasError && <FormHelperText error>{form.errors[field.name]}</FormHelperText>}
    </Box>
  );
};

export default CustomSelect;
