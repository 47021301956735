import { FC, useState, useEffect } from 'react';
import { Box, Typography, Tooltip, Button, CircularProgress } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import omitBy from 'lodash.omitby';
import isNil from 'lodash.isnil';

import { useTranslation } from 'react-i18next';
import { AlertMessage } from 'components/alerts';
import { fetchApiKeys } from 'http/account';
import { AccountType } from 'store/applications/types';
import useStyles, { useTooltipStyles } from './ApiSecret.styles';

export enum Display {
  MASK = 'mask',
  HALF_MASK = 'half_mask',
  RAW = 'raw',
}
export enum KeyType {
  SECRET = 'secret',
  PUBLIC = 'public',
}
interface ApiSecretProps {
  title: string;
  secret: string;
  name: string;
  display?: Display;
  description?: string;
  type?: KeyType;
  accountId?: string;
  accountType?: AccountType;
}

const ApiSecret: FC<ApiSecretProps> = ({
  title,
  secret,
  display = Display.RAW,
  description,
  type,
  accountId,
  accountType,
  name,
}) => {
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const { t } = useTranslation();
  const [error, setError] = useState('');

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1500);
    }
  }, [copied]);

  const copyToClipboard = async () => {
    if (type === KeyType.PUBLIC) {
      copy(secret);
      setCopied(true);
      return;
    }

    setLoading(true);
    try {
      const key = (await fetchApiKeys(
        omitBy(
          {
            only: KeyType.SECRET,
            account_id: accountId,
            account_type: accountType,
          },
          isNil,
        ),
      )) as unknown as Record<string, string>;
      copy(key[name]);
      setCopied(true);
    } catch (err) {
      setError((err as Error).message);
    }
    setLoading(false);
  };

  type DisplaySecret = Record<Display, string>;

  const displaySecret: DisplaySecret = {
    mask: '********************',
    half_mask: `${secret.slice(0, 5)}***********`,
    raw: secret,
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>{title}</Typography>

      <Box className={classes.secretOuterContainer}>
        <Box className={classes.secretInnerContainer}>
          <span className={classes.text}>{displaySecret[display]}</span>

          {loading ? (
            <CircularProgress color="primary" size={20} className={classes.loadingContainer} />
          ) : (
            <Tooltip
              title={(copied ? t('global.copied') : t('global.copySecret')) as string}
              arrow
              placement="top"
              classes={tooltipClasses}
            >
              <Button
                variant="text"
                color="default"
                disableRipple
                onClick={copyToClipboard}
                className={classes.secretButton}
              >
                <FileCopyOutlined />
              </Button>
            </Tooltip>
          )}
        </Box>
        <Typography variant="caption">{description}</Typography>
      </Box>
      <AlertMessage open={!!error} onClose={() => setError('')} message={error} autoHideDuration={5000} />
    </Box>
  );
};

export default ApiSecret;
