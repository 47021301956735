import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useActionsStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 0,

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-end',
      },
    },
    pageButton: {
      height: 20,
      width: 20,
      minWidth: 20,
      padding: 0,
      marginLeft: 2,
      marginRight: 2,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    currentPage: {
      backgroundColor: theme.palette.common.black,
      '& span': {
        color: theme.palette.common.white,
      },
    },
    iconButton: {
      width: 24,
      height: 24,
      padding: 0,
    },
    icon: {
      color: theme.palette.common.black,
      width: 20,
      height: 20,
      marginLeft: 4,
      marginRight: 4,
    },
  }),
);

export default makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      display: 'none',
      visibility: 'hidden',
    },
    toolbar: {
      position: 'relative',
      padding: 2,
    },
    caption: {
      position: 'absolute',
      '& span': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
      },
    },
  }),
);
