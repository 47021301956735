import {
  BalanceTransactionsList,
  BalanceTransactionsQuery,
  BankTransactionsList,
  BankTransactionsQuery,
} from 'store/applications/types';
import { apiCall } from '../index';

export const getBankTransactions = async (
  companyId: string,
  query: BankTransactionsQuery,
): Promise<BankTransactionsList> =>
  apiCall({ method: 'get', url: `/companies/${companyId}/account-transactions`, params: query });

export const getBalanceTransactions = async (
  companyId: string,
  query: BalanceTransactionsQuery,
): Promise<BalanceTransactionsList> =>
  apiCall({ method: 'get', url: `/companies/${companyId}/account-balances`, params: query });
