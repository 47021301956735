import { FC, useState, Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import { Delete, GetApp, Description, Image, ListAltOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { AlertDialog } from 'components/AlertDialog';

import { downloadDocument as downloadDocumentApi, deleteDocument as deleteDocumentApi } from 'http/admin';
import { useAdmin } from 'store/admin/hooks';
import { CompanyDocument } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { getFileExtension, isCsvType, isExcelType, isImageType, isPDFType } from 'utils';
import useLeadStyles from '../../../LeadDetails/LeadDetails.styles';
import useStyles from './DocumentList.styles';
import { CSVAndExcelReader } from '../CSVAndExcelReader';

interface DocumentListProps {
  title: string;
  companyId: string;
  documents: CompanyDocument[];
  setDocuments?: Dispatch<SetStateAction<CompanyDocument[]>>;
}

const DocumentList: FC<DocumentListProps> = ({ title, companyId, documents, setDocuments }) => {
  const [loading, setLoading] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<CompanyDocument | null>(null);
  const [selectedDocExtension, setSelectedDocExtension] = useState<string | null>(null);
  const [viewDoc, setViewDoc] = useState<{ name: string; data: ArrayBuffer } | null>(null);
  const [viewImage, setViewImage] = useState<{ name: string; data: string } | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const leadClasses = useLeadStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { isAdmin, isLenderAdmin, isCompanyOwner } = useAuth();
  const { setError } = useAdmin();

  const downloadDocument = async (docId: string, name: string) => {
    setError(false);
    setLoading(true);
    try {
      const blob = await downloadDocumentApi(docId);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.click();
    } catch (err) {
      setError((err as Error)?.message || true);
    }
    setLoading(false);
  };

  const handleCancel = () => setSelectedDoc(null);

  const handleConfirmDelete = async () => {
    if (!selectedDoc) return;
    setError(false);
    setLoading(true);
    try {
      await deleteDocumentApi(companyId, selectedDoc.id as string);
      if (setDocuments) setDocuments((prevDocuments) => prevDocuments.filter((doc) => doc.id !== selectedDoc.id));
      setSelectedDoc(null);
    } catch (err) {
      setError((err as Error)?.message || true);
    }
    setLoading(false);
  };

  const handleCloseDocument = () => {
    setViewDoc(null);
    setViewImage(null);
  };

  const viewDocument = async (docId: string, name: string) => {
    setError(false);
    setLoading(true);
    const fileExtension = getFileExtension(name);
    setSelectedDocExtension(fileExtension);
    try {
      const blob = await downloadDocumentApi(docId);
      if (isImageType(fileExtension)) {
        const imageUrl = URL.createObjectURL(blob);
        setViewImage({ name, data: imageUrl });
      } else {
        const arrayBuffer = await blob.arrayBuffer();
        setViewDoc({ name, data: arrayBuffer });
      }
    } catch (err) {
      setError((err as Error)?.message || true);
    }
    setLoading(false);
  };

  const downloadViewedDocumnet = () => {
    if (!viewDoc) return;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([viewDoc.data]));
    link.download = viewDoc.name;
    link.click();
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: { numPages: number }) => {
    setNumPages(nextNumPages);
  };

  const getFileIcon = (name?: string) => {
    if (name) {
      const extension = name && getFileExtension(name);
      if (isImageType(extension)) return <Image />;
      if (isPDFType(extension)) return <Description />;
      if (isCsvType(extension) || isExcelType(extension)) return <ListAltOutlined />;
    }
    return <Description />;
  };

  const file = useMemo(() => (viewDoc?.data ? { data: viewDoc.data } : null), [viewDoc]);

  const isAdminUserOrCompanyOwner = isAdmin || isLenderAdmin || isCompanyOwner;

  const pageWidth = window.innerWidth ? window.innerWidth - window.innerWidth / 2 : undefined;

  return (
    <>
      {isAdminUserOrCompanyOwner && <Typography className={leadClasses.uploadDocTitle}>{title}</Typography>}

      <Box className={leadClasses.documentsContainer}>
        {documents.length >= 1 ? (
          <List>
            {documents.map((document) => {
              return (
                <ListItem className={classes.itemContainer} key={document.id}>
                  <ListItemIcon className={classes.downloadContainer}>
                    <Button
                      className={leadClasses.actionButton}
                      variant="outlined"
                      color="default"
                      onClick={() => viewDocument(document.id as string, (document.name || document.id) as string)}
                      startIcon={isAdminUserOrCompanyOwner ? getFileIcon(document.name) : undefined}
                      disabled={!isAdminUserOrCompanyOwner}
                    >
                      {document.name}
                    </Button>
                  </ListItemIcon>

                  {isAdmin && setDocuments && (
                    <ListItemSecondaryAction className={classes.actionsContainer}>
                      <IconButton
                        edge="end"
                        aria-label="download document"
                        onClick={() =>
                          downloadDocument(document.id as string, (document.name || document.id) as string)
                        }
                        className={classes.viewButton}
                      >
                        <GetApp className={leadClasses.deleteIcon} />
                      </IconButton>

                      <IconButton
                        edge="end"
                        aria-label="delete document"
                        onClick={() => setSelectedDoc(document)}
                        className={leadClasses.deleteButton}
                      >
                        <Delete className={leadClasses.deleteIcon} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Box style={{ maxWidth: '70%' }}>
            <Box className={leadClasses.spacing} />
            <Alert severity="warning">{t('pages.lead.sections.documents.notAvailable')}</Alert>
          </Box>
        )}

        {loading && (
          <Box className={leadClasses.documentLoaderContainer}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>

      <AlertDialog
        open={!!selectedDoc}
        handleCancel={handleCancel}
        handleConfirm={handleConfirmDelete}
        dialogContentTitle={selectedDoc?.name ?? t('pages.lead.admin.deleteDocument.title')}
        dialogContentText={t('pages.lead.admin.deleteDocument.description')}
        confirmButtonTitle={t('pages.lead.admin.deleteDocument.buttons.confirm')}
        loading={loading}
      />

      <AlertDialog
        open={!!viewDoc || !!viewImage}
        handleCancel={handleCloseDocument}
        dialogContentTitle={(viewDoc?.name || viewImage?.name) as string}
        disabled
        containerClassname={classes.dialogContainer}
        HeaderIcon={<GetApp />}
        onHeaderAction={downloadViewedDocumnet}
      >
        {file && isPDFType(selectedDocExtension) && (
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {numPages &&
              Array.from(new Array(numPages), (_, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={pageWidth} />
              ))}
          </Document>
        )}
        {file && selectedDocExtension && (isExcelType(selectedDocExtension) || isCsvType(selectedDocExtension)) && (
          <CSVAndExcelReader document={viewDoc} />
        )}
        {viewImage && (
          <Box className={classes.imageContainer}>
            <img src={viewImage?.data} className={classes.img} alt="Not-available" />
          </Box>
        )}
      </AlertDialog>
    </>
  );
};

export default DocumentList;
