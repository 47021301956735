import { FC, useRef, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { useApplications } from 'store/applications/hooks';
import { revenueTimePeriodOptions } from 'core/constants';
import { TimePeriod } from 'store/applications/types';
import { NumberSelectInput } from '../../NumberSelectInput';

const RevenueFilter: FC = () => {
  const { t } = useTranslation();
  const {
    filters: { minRevenue, revenuePeriod },
    updateApplicationsQuery,
  } = useApplications();

  const debouncedUpdateAppsQuery = useRef(debounce(updateApplicationsQuery, 1000)).current;

  useEffect(() => {
    return () => {
      debouncedUpdateAppsQuery.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeMinRevenue = (revenue: number) => {
    debouncedUpdateAppsQuery({ minRevenue: revenue });
  };

  const onChangeRevenueTimePeriod = (timePeriod: TimePeriod) => {
    updateApplicationsQuery({ revenuePeriod: timePeriod });
  };

  return (
    <Box>
      <NumberSelectInput
        numberValue={minRevenue}
        timePeriodValue={revenuePeriod}
        onChangeNumberInput={onChangeMinRevenue}
        onChangeTimePeriod={onChangeRevenueTimePeriod}
        withPrefix
        options={revenueTimePeriodOptions}
      />

      <Typography variant="caption" id="demo-multiple-checkbox-label">
        {t('pages.dashboard.filters.minRevenue.title')}
      </Typography>
    </Box>
  );
};

export default RevenueFilter;
