import { FC, useRef, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { useApplications } from 'store/applications/hooks';
import { TimePeriod } from 'store/applications/types';
import { tradingTimePeriodOptions } from 'core/constants';
import { NumberSelectInput } from '../../NumberSelectInput';

const TradingTimeFilter: FC = () => {
  const { t } = useTranslation();
  const {
    filters: { minTradingTime, minTradingTimePeriod },
    updateApplicationsQuery,
  } = useApplications();

  const debouncedUpdateAppsQuery = useRef(debounce(updateApplicationsQuery, 1000)).current;

  useEffect(() => {
    return () => {
      debouncedUpdateAppsQuery.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeMinTradingTime = (tradingTime: number) => {
    debouncedUpdateAppsQuery({ minTradingTime: tradingTime });
  };

  const onChangeMinTradingTimePeriod = (timePeriod: TimePeriod) => {
    updateApplicationsQuery({ minTradingTimePeriod: timePeriod });
  };

  return (
    <Box>
      <NumberSelectInput
        numberValue={minTradingTime}
        timePeriodValue={minTradingTimePeriod}
        onChangeNumberInput={onChangeMinTradingTime}
        onChangeTimePeriod={onChangeMinTradingTimePeriod}
        options={tradingTimePeriodOptions}
      />

      <Typography variant="caption" id="demo-multiple-checkbox-label">
        {t('pages.dashboard.filters.minTradingTime.title')}
      </Typography>
    </Box>
  );
};

export default TradingTimeFilter;
