import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[200],
    },
    container: {
      display: 'flex',
      minHeight: '100vh',
    },
    loginContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    formContainer: {
      maxWidth: 500,
      width: '100%',
      padding: theme.spacing(4),
    },
    textInput: {
      minWidth: '100%',

      '& .MuiInputBase-root': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
        height: 36,
      },

      '& .Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
      },

      '& .Mui-error': {
        color: theme.palette.error.main,
        marginLeft: 0,
        marginRight: 0,
      },

      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    rememberMeContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    controlLabel: {
      color: theme.palette.customGrey.main,
      '& span': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    checkbox: {
      color: theme.palette.grey[400],
    },
    link: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    actionContainer: {
      marginTop: theme.spacing(3),
    },
    registerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
