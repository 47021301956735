import { FC, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { createAccountOwner as createAccountOwnerApi } from 'http/admin';
import { AccountType } from 'store/applications/types';
import { AlertDialog } from 'components/AlertDialog';
import { CustomTextField } from 'components/inputs';
import { usePartners } from 'store/partners/hooks';
import { useLenders } from 'store/lenders/hooks';
import useStyles from './CreateAccountOwnerModal.styles';

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
}

interface CreateAccountOwnerModalProps {
  open: boolean;
  toggleOpen: () => void;
  accountType: AccountType;
}

const CreateAccountOwnerModal: FC<CreateAccountOwnerModalProps> = ({ open, toggleOpen, accountType }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    partnerDetails,
    getPartnerDetails,
    setError: setPartnersError,
    setSuccess: setPartnersSuccess,
  } = usePartners();
  const { lenderDetails, getLenderDetails, setError: setLendersError, setSuccess: setLendersSuccess } = useLenders();

  const accountDetails = accountType === AccountType.LENDER ? lenderDetails : partnerDetails;

  const formSchema = yup.object({
    first_name: yup.string().required(t('components.createAccountOwnerModal.inputs.first_name.required')),
    last_name: yup.string().required(t('components.createAccountOwnerModal.inputs.last_name.required')),
    email: yup
      .string()
      .email(t('components.createAccountOwnerModal.inputs.email.error'))
      .required(t('components.createAccountOwnerModal.inputs.email.required')),
  });

  const initialValues: FormValues = {
    first_name: accountDetails?.contact_person_name.split(' ')[0] ?? '',
    last_name: accountDetails?.contact_person_name.split(' ')[1] ?? '',
    email: accountDetails?.contact_person_email ?? '',
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  const onSubmit = async (values: FormValues) => {
    if (!accountDetails) return;

    setLoading(true);
    try {
      await createAccountOwnerApi(accountDetails.id, accountType, values);
      const successMessage = t('components.createAccountOwnerModal.messages.createSuccess');

      if (accountType === AccountType.LENDER) {
        getLenderDetails(accountDetails.id);
        setLendersSuccess(successMessage);
      } else {
        getPartnerDetails(accountDetails.id);
        setPartnersSuccess(successMessage);
      }

      onCancel();
    } catch (err) {
      const error = (err as Error)?.message ?? true;
      if (accountType === AccountType.LENDER) setLendersError(error);
      else setPartnersError(error);
    }
    setLoading(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ handleChange, values, handleSubmit }) => (
        <AlertDialog
          open={open}
          dialogContentTitle={t('components.createAccountOwnerModal.title')}
          handleCancel={onCancel}
          handleConfirm={handleSubmit}
          loading={loading}
        >
          <Form noValidate>
            <Grid container direction="column" spacing={4} className={classes.container}>
              <Grid item>
                <Field
                  autoFocus
                  id="first_name"
                  fullWidth
                  component={CustomTextField}
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  title={t('components.createAccountOwnerModal.inputs.first_name.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="last_name"
                  fullWidth
                  component={CustomTextField}
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  title={t('components.createAccountOwnerModal.inputs.last_name.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="email"
                  fullWidth
                  component={CustomTextField}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  title={t('components.createAccountOwnerModal.inputs.email.label')}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>
          </Form>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default CreateAccountOwnerModal;
