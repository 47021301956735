import { ChangeEvent, FC } from 'react';
import { Box, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { usePartners } from 'store/partners/hooks';
import { useApplications } from 'store/applications/hooks';
import useStyles from './PartnerFilter.styles';

const PartnerFilter: FC = () => {
  const classes = useStyles();
  const { allPartners } = usePartners();
  const {
    filters: { partnerId },
    updateApplicationsQuery,
  } = useApplications();

  const setPartner = (partnerID: string) => {
    updateApplicationsQuery({ partnerId: partnerID, activePage: 0 });
  };

  return (
    <Box className={classes.selectContainer}>
      <Select
        native
        IconComponent={() => <ExpandMore className={classes.expandIcon} />}
        variant="outlined"
        value={partnerId ?? ''}
        onChange={(
          event: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
          }>,
        ) => setPartner(event.target.value as string)}
        className={classes.select}
      >
        <option value=""> </option>
        {allPartners.map(({ id, name }) => {
          return (
            <option key={`option${id}`} value={id}>
              {name}
            </option>
          );
        })}
      </Select>
    </Box>
  );
};

export default PartnerFilter;
