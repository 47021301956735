import { FC, useState } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';

import { OverviewDateRange } from 'core/types';
import { AppsOverview, OffersOverview, OverviewFilter, OffersOverviewByLenders } from './components';

const MetricsOverview: FC<{ preview?: boolean }> = ({ preview }) => {
  const [dateRange, setDateRange] = useState<OverviewDateRange>(OverviewDateRange.MONTH_TO_DATE);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'month').toDate());
  const [toDate, setToDate] = useState(moment().endOf('day').toDate());

  return (
    <>
      <OverviewFilter
        dateRange={dateRange}
        setDateRange={setDateRange}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
      />

      <Grid container style={{ display: 'flex' }} spacing={3}>
        <Grid item xs={preview ? 4 : 12}>
          <AppsOverview fromDate={fromDate} toDate={toDate} preview={preview} />
        </Grid>
        <Grid item xs={preview ? 4 : 12}>
          <OffersOverview fromDate={fromDate} toDate={toDate} preview={preview} />
        </Grid>
        <Grid item xs={preview ? 4 : 12}>
          <OffersOverviewByLenders fromDate={fromDate} toDate={toDate} preview={preview} />
        </Grid>
      </Grid>
    </>
  );
};

export default MetricsOverview;
