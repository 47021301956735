import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const production = (process.env.REACT_APP_STAGE as string) === 'production';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      minHeight: '100vh',
      paddingTop: theme.spacing(14),
      flexDirection: 'column',
    },
    contentContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '100vw',
      padding: theme.spacing(2),

      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(4),
        marginTop: !production ? theme.spacing(5) : theme.spacing(0),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },
  }),
);
