import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    itemIconContainer: {
      marginRight: theme.spacing(2),
    },
    itemContentContainer: {
      flex: 1,
      position: 'relative',
      paddingRight: theme.spacing(4),
    },
    itemTitle: {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(12),
      fontWeight: 600,
    },
    itemSubtitle: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.greyLabel.main,
    },
    itemCheckbox: {
      padding: 0,
      position: 'absolute',
      top: -2,
      right: 0,
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    disabled: {
      opacity: 0.5,
    },
  }),
);
