import { FC, useCallback, useState } from 'react';
import { Grid, Typography, CircularProgress, Box } from '@material-ui/core';
import clsx from 'clsx';
import Dropzone, { FileRejection } from 'react-dropzone';

import { CompanyDocumentType, DocUploadResponse, FileErrors } from 'core/types';
import { CompanyDocument } from 'store/applications/types';
import useStyles from './FileDrop.styles';
import FileDropInfo from './FileDropInfo';

// 20MB -> in bytes
// 20MB -> in bytes
const maxFileSize = 20000000;

interface AllowedFiletype {
  name: string;
  mimeType: string;
  extension: string;
}

interface FileDropProps {
  allowedFileTypes: Array<AllowedFiletype>;
  onUpload(documents: CompanyDocument[]): void;
  onReject(rejections: FileRejection[]): void;
  uploadMessage: string;
  uploadDocument(data: FormData): Promise<DocUploadResponse | null>;
  documentType: CompanyDocumentType;
  error?: boolean | string;
  multiple?: boolean;
  uploadedDocs: CompanyDocument[];
}

interface FileState {
  duplicates: string[];
  acceptedFiles: File[];
  fileRejections: FileRejection[];
}

const FileDrop: FC<FileDropProps> = ({
  allowedFileTypes,
  onUpload,
  onReject,
  uploadMessage,
  uploadDocument,
  documentType,
  error,
  multiple,
  uploadedDocs,
}) => {
  const [fileState, setFileState] = useState<FileState>({ duplicates: [], acceptedFiles: [], fileRejections: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  const closeDuplicateModal = () => setFileState((prev) => ({ ...prev, duplicates: [] }));

  const continueUpload = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const Data = new FormData();

      Data.append('type', documentType);

      const files: { [key: string]: File } = {};
      acceptedFiles.forEach((file) => {
        Data.append('file', file);
        files[file.name] = file;
      });
      if (!fileRejections.length) {
        setLoading(true);
        try {
          const res: DocUploadResponse | null = await uploadDocument(Data);
          if (res) {
            const uploadedDocuments = res.companyDocs.map(({ name, id, type }) => ({ name, id, type }));
            onUpload(uploadedDocuments);
          }
          if (res?.errors.length) throw new Error(JSON.stringify(res.errors));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          const errors = JSON.parse(err.message) as FileErrors;
          errors.forEach(({ file, error: fileError }) => {
            fileRejections.push({
              file: files[file],
              errors: [
                {
                  code: 'too-many-files', // TODO: use actual error code
                  message: fileError,
                },
              ],
            });
          });
        }
        setLoading(false);
      }

      // Don't check if array has any element, we want to reset rejections on every upload op
      onReject(fileRejections);
    },
    [documentType, onReject, uploadDocument, onUpload],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const duplicates = uploadedDocs.reduce((acc: string[], doc) => {
        acceptedFiles.forEach((file) => {
          if (doc.name?.replace(/^.+\//, '') === file.name) {
            acc.push(doc.name);
          }
        });
        return acc;
      }, []);
      if (duplicates.length) {
        return setFileState((prev) => ({ ...prev, duplicates, acceptedFiles, fileRejections }));
      }
      return continueUpload(acceptedFiles, fileRejections);
    },
    [continueUpload, uploadedDocs],
  );

  const { duplicates, acceptedFiles: accFiles, fileRejections: rejFiles } = fileState;
  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles, fileRejections) => onDrop(acceptedFiles, fileRejections)}
        maxSize={maxFileSize}
        accept={allowedFileTypes.map((fileType) => fileType.mimeType)}
        disabled={loading}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <section className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box {...getRootProps()} className={clsx([classes.outer, error && classes.outerError])}>
                  <Box className={loading ? clsx([classes.inner, classes.disabled]) : classes.inner}>
                    <input {...getInputProps()} id={`dropDocumentInput-${documentType}`} />

                    <Typography className={classes.uploadMessage}>{uploadMessage}</Typography>
                  </Box>
                  {loading && <CircularProgress className={classes.progress} />}
                </Box>
              </Grid>
            </Grid>
          </section>
        )}
      </Dropzone>
      <FileDropInfo
        clearDuplicates={() => setFileState((prev) => ({ ...prev, duplicates: [] }))}
        duplicates={duplicates}
        handleClose={closeDuplicateModal}
        isOpen={Boolean(duplicates.length)}
        continueUpload={() => continueUpload(accFiles, rejFiles)}
      />
    </>
  );
};

export default FileDrop;
