import { ChangeEvent, FC, useRef } from 'react';
import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import pickBy from 'lodash.pickby';

import { AlertDialog } from 'components/AlertDialog';
import { CustomSelect, CustomTextField } from 'components/inputs';
import { usePartners } from 'store/partners/hooks';
import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus } from 'core/types';
import { accountCountryOptions, accountEmployeesOptions, accountIndustryOptions, websiteRegExp } from 'core/constants';
import { CreatePartner, PartnerDetails } from 'store/partners/types';
import useStyles from './CreatePartnerModal.styles';

interface FormValues {
  name: string;
  displayed_name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  active: boolean;
  address: string;
  logo_url: string;
  website: string;
  phone_number: string;
  job_title: string;
  country_code: string;
  industry: string;
  employees: string;
}

interface CreatePartnerModalProps {
  open: boolean;
  toggleOpen: () => void;
  partner?: PartnerDetails;
}

const CreatePartnerModal: FC<CreatePartnerModalProps> = ({ open, toggleOpen, partner }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, error, setError, createPartner, updatePartner } = usePartners(() => {
    if (open) {
      formRef.current?.resetForm();
      toggleOpen();
    }
  });

  const lenderSchema = yup.object({
    name: yup.string().required(t('pages.partners.admin.createPartner.inputs.name.required')),
    displayed_name: yup.string().required(t('pages.partners.admin.createPartner.inputs.displayed_name.required')),
    contact_first_name: yup
      .string()
      .required(t('pages.partners.admin.createPartner.inputs.contact_first_name.required')),
    contact_last_name: yup.string().required(t('pages.partners.admin.createPartner.inputs.contact_last_name.required')),
    contact_email: yup
      .string()
      .email(t('pages.partners.admin.createPartner.inputs.contact_email.error'))
      .required(t('pages.partners.admin.createPartner.inputs.contact_email.required')),
    active: yup.boolean(),
    logo_url: yup.string().url(t('pages.partners.admin.createPartner.inputs.logo_url.error')),
    website: yup.string().matches(websiteRegExp, t('pages.partners.admin.createPartner.inputs.website.error')),
    address: yup.string(),
    phone_number: yup.string(),
    job_title: yup.string(),
    country_code: yup.string().required(t('pages.partners.admin.createPartner.inputs.country_code.required')),
    industry: yup.string(),
    employees: yup.string(),
  });

  const initialValues: FormValues = {
    name: partner?.name ?? '',
    displayed_name: partner?.displayed_name ?? '',
    contact_first_name: partner?.contact_person_name?.split(' ')[0] ?? '',
    contact_last_name: partner?.contact_person_name?.split(' ')[1] ?? '',
    contact_email: partner?.contact_person_email ?? '',
    active: partner?.active ?? false,
    address: partner?.address ?? '',
    logo_url: partner?.logo_url ?? '',
    website: partner?.website ?? '',
    phone_number: partner?.phone_number ?? '',
    job_title: partner?.job_title ?? '',
    country_code: partner?.country_code ?? '',
    industry: partner?.industry ?? '',
    employees: partner?.employees ?? '',
  };

  const onSubmit = (values: FormValues) => {
    const partnerData = { ...pickBy(values), active: values.active } as CreatePartner;

    if (partner?.id) {
      updatePartner({ id: partner.id, partner: partnerData });
    } else {
      createPartner(partnerData);
    }
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={lenderSchema}
        innerRef={formRef}
        enableReinitialize
      >
        {({ handleChange, values, handleSubmit, setFieldValue }) => (
          <AlertDialog
            open={open}
            dialogContentTitle={t(`pages.partners.admin.createPartner.title.${partner ? 'update' : 'create'}`)}
            handleCancel={onCancel}
            handleConfirm={handleSubmit}
            confirmButtonTitle={t(`pages.partners.admin.actions.${partner ? 'update' : 'create'}`)}
            loading={loading}
          >
            <Form noValidate>
              <Grid container direction="column" spacing={4} className={classes.container}>
                <Grid item>
                  <Field
                    autoFocus
                    id="name"
                    fullWidth
                    component={CustomTextField}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    title={t('pages.partners.admin.createPartner.inputs.name.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="displayed_name"
                    fullWidth
                    component={CustomTextField}
                    name="displayed_name"
                    value={values.displayed_name}
                    onChange={handleChange}
                    title={t('pages.partners.admin.createPartner.inputs.displayed_name.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="contact_first_name"
                    fullWidth
                    component={CustomTextField}
                    name="contact_first_name"
                    value={values.contact_first_name}
                    onChange={handleChange}
                    title={t('pages.partners.admin.createPartner.inputs.contact_first_name.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="contact_last_name"
                    fullWidth
                    component={CustomTextField}
                    name="contact_last_name"
                    value={values.contact_last_name}
                    onChange={handleChange}
                    title={t('pages.partners.admin.createPartner.inputs.contact_last_name.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="contact_email"
                    fullWidth
                    component={CustomTextField}
                    name="contact_email"
                    value={values.contact_email}
                    onChange={handleChange}
                    title={t('pages.partners.admin.createPartner.inputs.contact_email.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="country_code"
                    fullWidth
                    component={CustomSelect}
                    options={accountCountryOptions}
                    name="country_code"
                    value={values.country_code}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                      setFieldValue('country_code', event.target.value)
                    }
                    title={t('pages.partners.admin.createPartner.inputs.country_code.label')}
                    className={classes.textInput}
                  />
                </Grid>

                {!partner && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                            setFieldValue('active', checked)
                          }
                          checked={values.active}
                        />
                      }
                      label={values.active ? t('global.states.enabled') : t('global.states.disabled')}
                      className={classes.switchLabel}
                    />
                  </Grid>
                )}

                <Grid item>
                  <Field
                    id="logo_url"
                    fullWidth
                    component={CustomTextField}
                    name="logo_url"
                    value={values.logo_url}
                    onChange={handleChange}
                    title={t('pages.partners.admin.createPartner.inputs.logo_url.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="website"
                    fullWidth
                    component={CustomTextField}
                    name="website"
                    value={values.website}
                    onChange={handleChange}
                    title={t('pages.partners.admin.createPartner.inputs.website.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="address"
                    fullWidth
                    component={CustomTextField}
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    title={t('pages.partners.admin.createPartner.inputs.address.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="phone_number"
                    fullWidth
                    component={CustomTextField}
                    name="phone_number"
                    value={values.phone_number}
                    onChange={handleChange}
                    title={t('pages.partners.admin.createPartner.inputs.phone_number.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="job_title"
                    fullWidth
                    component={CustomTextField}
                    name="job_title"
                    value={values.job_title}
                    onChange={handleChange}
                    title={t('pages.partners.admin.createPartner.inputs.job_title.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="industry"
                    fullWidth
                    component={CustomSelect}
                    options={accountIndustryOptions}
                    name="industry"
                    value={values.industry}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('industry', event.target.value)}
                    title={t('pages.partners.admin.createPartner.inputs.industry.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="employees"
                    fullWidth
                    component={CustomSelect}
                    options={accountEmployeesOptions}
                    name="employees"
                    value={values.employees}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('employees', event.target.value)}
                    title={t('pages.partners.admin.createPartner.inputs.employees.label')}
                    className={classes.textInput}
                  />
                </Grid>
              </Grid>
            </Form>
          </AlertDialog>
        )}
      </Formik>

      {error && (
        <AlertMessage
          open={!!error}
          onClose={() => setError(false)}
          message={typeof error === 'string' ? error : undefined}
          autoHideDuration={5000}
          status={AlertMessageStatus.ERROR}
        />
      )}
    </>
  );
};

export default CreatePartnerModal;
