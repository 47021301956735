import { FC, useMemo, useRef, useState } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import {
  Grid,
  FormHelperText,
  Box,
  Typography,
  List,
  ListItem,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import Dropzone, { FileRejection } from 'react-dropzone';
import { HighlightOff as RejectedFileIcon } from '@material-ui/icons';
import * as yup from 'yup';
import clsx from 'clsx';
import moment, { Moment } from 'moment';

import { sendEmail as sendEmailApi } from 'http/admin';
import { AlertDialog } from 'components/AlertDialog';
import { useAdmin } from 'store/admin/hooks';
import { CustomLabel, CustomTextField } from 'components/inputs';
import { useApplications } from 'store/applications/hooks';
import { allowedFileTypes, MAX_FILE_SIZE } from 'core/constants';
import { CompanyDocumentType } from 'core/types';
import { useAuth } from 'store/auth/hooks';
import { CC_DEFAULT_EMAIL_ADDRESS, formats, modules, SIGNATURE_LOGO_SRC } from './constants';
import useStyles from './SendEmailModal.styles';

interface FormValues {
  from: string;
  email: string;
  cc?: string;
  bcc?: string;
  subject: string;
  body: string;
  send_at: Date;
}

interface SendEmailModalProps {
  open: boolean;
  toggleOpen: () => void;
}

const filesInitialState: { accepted: File[]; rejected: FileRejection[] } = { accepted: [], rejected: [] };

const SendEmailModal: FC<SendEmailModalProps> = ({ open, toggleOpen }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<{ accepted: File[]; rejected: FileRejection[] }>(filesInitialState);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { setError, setSuccess, setRefreshEmails } = useAdmin();
  const { applicationDetails } = useApplications();
  const { firstName, lastName, occupation, email: adminEmail } = useAuth();

  const applicant = applicationDetails?.company.people.find((p) => p.applicant);

  const formSchema = useMemo(
    () =>
      yup.object({
        from: yup
          .string()
          .email(t('pages.lead.admin.sendEmail.inputs.from.error'))
          .required(t('pages.lead.admin.sendEmail.inputs.from.required')),
        email: yup
          .string()
          .email(t('pages.lead.admin.sendEmail.inputs.email.error'))
          .required(t('pages.lead.admin.sendEmail.inputs.email.required')),
        cc: yup.string().email(t('pages.lead.admin.sendEmail.inputs.cc.error')),
        bcc: yup.string().email(t('pages.lead.admin.sendEmail.inputs.bcc.error')),
        subject: yup.string().required(t('pages.lead.admin.sendEmail.inputs.subject.required')),
        body: yup
          .string()
          .required(t('pages.lead.admin.sendEmail.inputs.body.required'))
          .min(12, t('pages.lead.admin.sendEmail.inputs.body.required')),
        send_at: yup.date().required(t('pages.lead.admin.sendEmail.inputs.send_at.required')),
      }),
    [t],
  );

  const initialValues: FormValues = {
    from: adminEmail ?? '',
    email: applicant?.email ?? '',
    cc: CC_DEFAULT_EMAIL_ADDRESS,
    bcc: '',
    subject: '',
    body: `
    <br />
    <br />
    <p><strong>${firstName} ${lastName}</strong></p>
    ${occupation && `<p>${occupation}</p>`}
    <p><img src="${SIGNATURE_LOGO_SRC}" /></p>
    <p>Fair, fast and simple funding</p>
    <br/>
    <p>E: <a href="mailto:${adminEmail}">${adminEmail}</a></p>
    <p>W: <a href="${t('pages.auth.footer.links.websiteUrl')}">${t('pages.auth.footer.links.websiteUrl')}</a></p>
    <br/>
    <p>Find out what our <a href="https://uk.trustpilot.com/review/scorethebusiness.com">customers</a> have to say about us.</p>
    <br />
    `,
    send_at: moment().toDate(),
  };

  const onSubmit = async (values: FormValues) => {
    if (!applicationDetails?.id) return;
    setLoading(true);
    try {
      const formData = new FormData();

      if (files.accepted.length) {
        formData.append('type', CompanyDocumentType.EMAIL_ATTACHMENTS);

        files.accepted.forEach((file) => {
          formData.append('file', file);
        });
      }

      formData.append('email', values.email);
      formData.append('subject', values.subject);
      formData.append('body', values.body);

      if (values.cc) formData.append('cc', values.cc);
      if (values.bcc) formData.append('bcc', values.bcc);
      if (values.send_at) formData.append('send_at', moment(values.send_at).unix().toString());

      await sendEmailApi(applicationDetails.id, formData);

      setSuccess(t('pages.lead.admin.sendEmail.messages.success') as string);
      setRefreshEmails(true);

      formRef.current?.resetForm();
      toggleOpen();
    } catch (error) {
      setError((error as Error)?.message ?? true);
    }
    setLoading(false);
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    setFiles(filesInitialState);
    toggleOpen();
  };

  const onDrop = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setFiles({ accepted: acceptedFiles, rejected: fileRejections });
  };

  const removeAcceptedFile = (fileName: string) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles.accepted].filter((file) => file.name !== fileName);
      return { accepted: updatedFiles, rejected: prevFiles.rejected };
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit} innerRef={formRef}>
      {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => {
        return (
          <AlertDialog
            open={open}
            dialogContentTitle={t('pages.lead.admin.sendEmail.title')}
            handleCancel={onCancel}
            handleConfirm={handleSubmit}
            confirmButtonTitle={t('pages.lead.admin.sendEmail.buttons.confirm')}
            loading={loading}
          >
            <Form noValidate>
              <Grid container direction="column" spacing={4} className={classes.container}>
                <Grid item>
                  <Field
                    id="from"
                    fullWidth
                    component={CustomTextField}
                    name="from"
                    value={values.from}
                    onChange={handleChange}
                    title={t('pages.lead.admin.sendEmail.inputs.from.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textInput}
                    disabled
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="email"
                    fullWidth
                    component={CustomTextField}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    title={t('pages.lead.admin.sendEmail.inputs.email.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="cc"
                    fullWidth
                    component={CustomTextField}
                    name="cc"
                    value={values.cc}
                    onChange={handleChange}
                    title={t('pages.lead.admin.sendEmail.inputs.cc.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="bcc"
                    fullWidth
                    component={CustomTextField}
                    name="bcc"
                    value={values.bcc}
                    onChange={handleChange}
                    title={t('pages.lead.admin.sendEmail.inputs.bcc.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="subject"
                    fullWidth
                    component={CustomTextField}
                    name="subject"
                    value={values.subject}
                    onChange={handleChange}
                    title={t('pages.lead.admin.sendEmail.inputs.subject.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Box>
                    <CustomLabel title={t('pages.lead.admin.sendEmail.inputs.send_at.label')} />
                    <Field
                      id="send_at"
                      component={DateTimePicker}
                      fullWidth
                      name="send_at"
                      value={values.send_at}
                      onChange={(date: Moment | null) => setFieldValue('send_at', date ?? null)}
                      disablePast
                      InputLabelProps={{
                        shrink: true,
                        variant: 'outlined',
                      }}
                      required
                      className={clsx([classes.textInput, classes.dateInput])}
                      // SendGrid sendAt only works for 72 hours in the future - https://docs.sendgrid.com/for-developers/sending-email/scheduling-parameters
                      maxDate={moment(new Date()).add(72, 'hours')}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      showToolbar
                      toolbarTitle
                    />
                  </Box>
                </Grid>

                <Grid item>
                  <CustomLabel title={t('pages.lead.admin.sendEmail.inputs.body.label')} />

                  <ReactQuill
                    theme="snow"
                    onChange={(value: string) => setFieldValue('body', value)}
                    value={values.body}
                    modules={modules}
                    formats={formats}
                    readOnly={false}
                    preserveWhitespace
                    className={clsx([classes.editor, touched.body && errors.body && classes.editorError])}
                  />

                  {touched.body && errors.body && <FormHelperText error>{errors.body}</FormHelperText>}
                </Grid>

                <Grid item>
                  <CustomLabel title={t('pages.lead.admin.sendEmail.inputs.attachments.label')} />

                  <Dropzone
                    onDrop={onDrop}
                    maxSize={MAX_FILE_SIZE}
                    accept={allowedFileTypes.map((fileType) => fileType.mimeType)}
                    multiple
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className={classes.container}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Box {...getRootProps()} className={classes.fileDropOuter}>
                              <Box className={classes.fileDropInner}>
                                <input {...getInputProps()} id="dropDocumentInput-attachment" />

                                <Typography className={classes.dropFilesMessage}>
                                  {t('global.uploadDocument')}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </section>
                    )}
                  </Dropzone>

                  {files.accepted.length > 0 && (
                    <Box marginTop="15px">
                      <Typography className={classes.sectionTitle}>
                        {t('pages.lead.admin.sendEmail.inputs.attachments.accepted')}
                      </Typography>

                      <List>
                        {files.accepted.map((file: File) => {
                          return (
                            <ListItem key={file.name} className={classes.listItem}>
                              <ListItemText primary={file.name} className={classes.fileTitle} />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => removeAcceptedFile(file.name)}
                                  className={classes.deleteButton}
                                >
                                  <div className={classes.deleteAvatar}>
                                    <RejectedFileIcon color="primary" />
                                  </div>
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  )}

                  {files.rejected.length > 0 && (
                    <Box marginTop="15px">
                      <Typography className={classes.sectionTitle}>
                        {t('pages.lead.admin.sendEmail.inputs.attachments.rejected')}
                      </Typography>

                      <List>
                        {files.rejected.map((file: FileRejection) => {
                          return (
                            <ListItem key={file.file.name} className={classes.listItem}>
                              <ListItemText primary={file.file.name} className={classes.fileTitle} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Form>
          </AlertDialog>
        );
      }}
    </Formik>
  );
};

export default SendEmailModal;
