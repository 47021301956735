import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    formLabel: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      display: 'flex',
      maxWidth: '300px',
      marginBottom: '10px',
      marginLeft: 0,
    },
    switchLabel: {
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      display: 'flex',
      maxWidth: '300px',
      marginBottom: '10px',
      marginLeft: 0,
    },
    formGroup: {
      marginBottom: '15px',
      maxWidth: '300px',
      flexDirection: 'row',
    },
    select: {
      width: '60px',
      padding: '0 4px',
    },
    selectInput: {
      width: '240px',
    },
    input: {
      width: '300px',
      '& .MuiFormHelperText-root': {
        color: theme.palette.error.main,
      },
    },
    captionTitle: {
      marginBottom: '10px',
    },
    helpIcon: {
      fontSize: 15,
      marginRight: 4,
    },
    padRight: {
      paddingRight: '24px',
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    fontNameInput: {
      width: '300px',
      marginBottom: theme.spacing(1),
    },
    publicKeyInput: {
      width: '300px',
      marginBottom: theme.spacing(2),
    },
    configModeSwitchLabel: {
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      display: 'flex',
      maxWidth: '300px',
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
  }),
);
