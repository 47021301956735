import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.customGrey.main,
      minHeight: '60px',
      marginBottom: theme.spacing(4),
    },
    appBarLinks: {
      backgroundColor: theme.palette.common.white,
      minHeight: '44px',
      boxShadow: 'inset 0 -1px #e3e8ee',
    },
    appBarLink: {
      borderRadius: theme.shape.borderRadius,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      '& span': {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(15),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 'bold',
      },
      '&:hover': {
        background: theme.palette.customGrey.main,
      },
    },
    divider: { border: '1px solid #f5925e' },
    alert: { justifyContent: 'center' },
    appBarLinkText: {
      minWidth: 0,
    },
    appBarLinkActive: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      '& span': {
        color: theme.palette.common.white,
      },
    },
    appBarLinkIcon: {
      color: theme.palette.primary.main,
    },
    appBarLinkList: {
      display: 'flex',
      alignItems: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),

      '& svg': {
        fill: theme.palette.common.white,
      },
    },
    rightContent: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginLeft: theme.spacing(3),
    },
    leftContent: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    actionsList: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiListItemIcon-root': {
        minWidth: 30,
      },
      '& .MuiListItem-gutters': {
        paddingLeft: theme.typography.pxToRem(12),
        paddingRight: theme.typography.pxToRem(12),
      },
    },
    listText: {
      minWidth: 55,
      '& span': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 'bold',
      },
    },
    strongText: {
      minWidth: 'max-content',
      '& span': {
        fontWeight: 'bold',
      },
    },
    avatar: {
      marginRight: theme.spacing(1),
      minWidth: 'auto',
      '& svg': {
        fill: theme.palette.common.black,
      },
    },
    icon: {
      minWidth: 'auto',
    },
    bellContainer: {
      minWidth: 'auto',
      padding: 0,
      '& svg': {
        fill: theme.palette.common.black,
      },
    },
  }),
);
