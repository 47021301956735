import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MUIDataTableCustomHeadRenderer, MUIDataTableMeta, MUIDataTableState } from 'mui-datatables';

import { deleteAdmin as deleteAdminApi } from 'http/admin';
import { useAdmin } from 'store/admin/hooks';
import { AdminsTableColumnNames, AlertMessageStatus, UserQuery, UserRole } from 'core/types';
import { Table, customHeadRender, ColumnHeader } from 'components/table';
import { Admin } from 'store/admin/types';
import { usePrevious } from 'hooks';
import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import { Loader } from 'components/Loader';
import { AlertMessage } from 'components/alerts';
import { useGlobalState } from 'store/global/hooks';
import { useAuth } from 'store/auth/hooks';
import { AlertDialog } from 'components/AlertDialog';
import useStyles from './Admins.styles';
import { CreateAdminModal } from './components';

const Admins: FC = () => {
  const [activePage, setActivePage] = useState(0);
  const [createOpen, setCreateOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<{ id: string; name: string } | null>(null);
  const [deleting, setDeleting] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, admins, total, error, success, setSuccess, setError, getAdminUsers, updateAdminUsers } = useAdmin();
  const prevActivePage = usePrevious(activePage);
  const { error: globalError } = useGlobalState();
  const { isSuperAdmin } = useAuth();

  const adminQuery: UserQuery = useMemo(
    () => ({
      per_page: ROWS_PER_TABLE_PAGE,
      page: activePage + 1,
      role: `${UserRole.ADMIN},${UserRole.SUPER_ADMIN}`,
    }),
    [activePage],
  );

  useEffect(() => {
    if (prevActivePage !== activePage) {
      getAdminUsers(adminQuery);
    }
  }, [activePage, adminQuery, getAdminUsers, prevActivePage]);

  const columns = useMemo(
    () => [
      {
        name: AdminsTableColumnNames.ADMIN_NAME,
        label: t('pages.admins.table.columns.name'),
        options: {
          customHeadRender,
        },
      },
      {
        name: AdminsTableColumnNames.EMAIL,
        label: t('pages.admins.table.columns.email'),
        options: {
          customHeadRender,
        },
      },
      {
        name: AdminsTableColumnNames.OCCUPATION,
        label: t('pages.admins.table.columns.occupation'),
        options: {
          customHeadRender,
        },
      },
      {
        name: AdminsTableColumnNames.CREATED_AT,
        label: t('pages.admins.table.columns.createdAt'),
        options: {
          customHeadRender,
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleDateString() : null),
        },
      },
      ...(isSuperAdmin
        ? [
            {
              name: AdminsTableColumnNames.REMOVE,
              label: '',
              options: {
                customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) => (
                  <ColumnHeader key={`remove-${columnMeta.index}`} title="" empty />
                ),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                customBodyRender: (_: any, tableMeta: MUIDataTableMeta) => {
                  const admin = admins[tableMeta.rowIndex];
                  const onClick = () => {
                    if (admin) setSelectedAdmin({ id: admin.id, name: admin.fullName });
                  };
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={onClick}
                      className={classes.removeButton}
                    >
                      {t('pages.team.deleteUser.buttons.delete')}
                    </Button>
                  );
                },
              },
            },
          ]
        : []),
    ],
    [admins, classes.removeButton, isSuperAdmin, t],
  );

  const onTableChange = useCallback((action: string, tableState: MUIDataTableState) => {
    if (action === 'changePage') {
      setActivePage(tableState.page);
    }
  }, []);

  const toggleCreateOpen = () => setCreateOpen((prevState) => !prevState);

  const handleDeleteCancel = () => setSelectedAdmin(null);

  const handleDeleteConfirm = async () => {
    if (!selectedAdmin) return;
    setDeleting(true);
    try {
      await deleteAdminApi(selectedAdmin.id);
      updateAdminUsers(selectedAdmin.id);
      setSuccess(t('pages.admins.admin.success.delete') as string);
      setSelectedAdmin(null);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setDeleting(false);
  };

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title}>{t('pages.admins.title')}</Typography>
          <Typography className={classes.subtitle}>{t('pages.admins.description')}</Typography>
        </Grid>

        {isSuperAdmin && (
          <Grid item xs={12} className={classes.actionContainer}>
            <Button variant="contained" color="primary" disableElevation onClick={toggleCreateOpen}>
              {t('pages.admins.admin.actions.create')}
            </Button>
          </Grid>
        )}

        <Grid item xs={12} className={classes.tableContainer}>
          <Table<Admin>
            columns={columns}
            data={admins}
            onTableChange={onTableChange}
            page={activePage}
            count={total}
            rowsPerPage={ROWS_PER_TABLE_PAGE}
          />
        </Grid>
      </Grid>

      <CreateAdminModal open={createOpen} toggleOpen={toggleCreateOpen} />

      {selectedAdmin && (
        <AlertDialog
          open
          dialogContentTitle={t('pages.admins.admin.deleteAdmin.title')}
          dialogContentText={t('pages.admins.admin.deleteAdmin.description', { name: selectedAdmin?.name })}
          handleConfirm={handleDeleteConfirm}
          handleCancel={handleDeleteCancel}
          confirmButtonTitle={t('pages.admins.admin.actions.delete')}
          loading={deleting}
        />
      )}

      <Loader visible={loading} />

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Admins;
