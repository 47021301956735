import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      color: theme.palette.common.black,
      fontSize: theme.typography.pxToRem(12),
    },
    inputsContainer: {
      display: 'flex',
      width: 'fit-content',
    },
    textInput: {
      maxWidth: 125,
      marginRight: theme.spacing(1),
      '& .MuiInputBase-root': {
        height: 40,
      },
    },
    selectContainer: {
      '& .MuiSelect-root': {
        minWidth: 100,
        height: 40,
      },
    },
  }),
);
