import React from 'react';
import ReactDom from 'react-dom/client';
import { Provider } from 'react-redux';
import AxiosInterceptors from 'core/interceptors';
import { store } from './store';
import App from './App';
import './index.css';

// Create a root element
const rootElement = document.getElementById('root');
// Use createRoot to create the root element and call render on it
const root = ReactDom.createRoot(rootElement as HTMLElement);

AxiosInterceptors.setup(store);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
