import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.typography.pxToRem(17),
      lineHeight: theme.typography.pxToRem(16),
      marginBottom: 12,
      color: theme.palette.customGrey.light,
      display: 'flex',
      alignItems: 'center',
    },
    titleAvatar: { marginRight: 5, background: theme.palette.primary.main },
    bold: {},
    cardContainer: {
      border: `1px solid ${theme.palette.greyLightBorder.main}`,
      height: 214,
    },
    cardsContainer: {
      marginBottom: theme.spacing(2),
    },
  }),
);
