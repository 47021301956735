import { ComponentType } from 'react';
import { MUIDataTableCustomHeadRenderer, MUIDataTableMeta, MUISortOptions } from 'mui-datatables';

import { Lender } from 'store/lenders/types';
import { ApplicationRegion, FundingReason } from 'store/applications/types';
import { Partner } from 'store/partners/types';

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum ApplicationsTableColumnNames {
  COMPANY_NAME = 'company',
  APPLICANT_NAME = 'company',
  APPLICATION_STATUS = 'status',
  LOAN_AMOUNT = 'funding_amount',
  DATE_STARTED = 'created_at',
  PARTNER_NAME = 'partner_name',
  ASSIGNEE_NAME = 'assignee_name',
  MONTHLY_REVENUE = 'company',
  BUSINESS_AGE = 'company',
  REGION = 'region',
}
/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum AlertMessageStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum LendersTableColumnNames {
  LOGO = 'logo_url',
  LENDER_NAME = 'name',
  USER_NAME = 'contact_person_name',
  EMAIL = 'contact_person_email',
  ROLE = 'role',
  ENABLED = 'active',
  REGIONS = 'supported_regions',
}

export enum OffersTableColumnNames {
  LENDER_NAME = 'lender_name',
  PRODUCT_TYPE = 'product_type',
  STATUS = 'status',
  MIN_CREDIT = 'min_credit_offer',
  MAX_CREDIT = 'max_credit_offer',
  BORROWED_AMOUNT = '',
  DURATION = 'duration_in_months',
  LENDER_TYPE = 'type',
  DATE_STARTED = 'created_at',
  FOLLOW_UP = '',
  EDIT = '',
  REJECT = '',
  // company owner
  LOAN_AMOUNT = 'loan_amount',
  REPAYMENT = 'sweep',
  MONTHLY_INTEREST_RATE = 'interest_rate',
  MONTHLY_REPAYMENT_AMOUNT = 'monthly_repayment',
  MONTHLY_REPAYMENT_TYPE = 'monthly_repayment_type',
  TOTAL_REPAYMENT = 'total_repayment',
  VALID_UNTIL = 'valid_until',
}

export enum PartnersTableColumnNames {
  PARTNER_NAME = 'name',
  USER_NAME = 'contact_person_name',
  ROLE = 'role',
  EMAIL = 'contact_person_email',
  ENABLED = 'active',
  CREATED_AT = 'created_at',
}

export enum AdminsTableColumnNames {
  ADMIN_NAME = 'fullName',
  EMAIL = 'email',
  OCCUPATION = 'occupation',
  CREATED_AT = 'created_at',
  REMOVE = '',
}

export enum UsersTableColumnNames {
  NAME = 'fullName',
  EMAIL = 'email',
  ROLE = 'role',
  CREATED_AT = 'created_at',
  REMOVE = '',
}

export enum CompanyTransactionsColumnNames {
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  TYPE = 'type',
  DATE = 'transaction_date',
}

export enum BankTransactionsColumnNames {
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  CREDIT_DEBIT_INDICATOR = 'credit_debit_indicator',
  BOOKING_DATE_TIME = 'booking_date_time',
}

export enum BalanceTransactionsColumnNames {
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  CREDIT_DEBIT_INDICATOR = 'credit_debit_indicator',
  DATE_TIME = 'date_time',
}

export enum RemindersTableColumnNames {
  REMINDER = '',
  DUE_DATE = '',
  COMPANY = '',
  ASSIGNEE = '',
  DONE = '',
}

export interface Document {
  name: string;
  url?: string;
  id?: string;
  type?: string;
}

export enum CompanyDocumentType {
  BANK_STATEMENT = 'bank-statement',
  MANAGEMENT_ACCOUNTS = 'management-accounts',
  FILED_ACCOUNTS = 'filed-accounts',
  VAT_RETURN = 'vat-return',
  PAYMENT_DATA = 'payment-data',
  IDENTITY_DOCUMENT = 'identity-document',
  EMAIL_ATTACHMENTS = 'email-attachments',
}

export interface DocumentUploadResponse {
  id?: string;
  url?: string;
  name?: string;
  type?: string;
}

export enum ApplicationNoteType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum LenderType {
  MANUAL = 'MANUAL',
  API = 'API',
}

export enum UserRole {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  LENDER_MAINTAINER = 'lender_maintainer',
  LENDER_VIEWER = 'lender_viewer',
  LENDER_MANAGER = 'lender_manager',
  PARTNER_USER = 'partner_user',
  PARTNER_MANAGER = 'partner_manager',
  COMPANY_OWNER = 'company_owner',
}

export type PartnerRoles = UserRole.PARTNER_MANAGER | UserRole.PARTNER_USER;

export type LenderRoles = UserRole.LENDER_MANAGER | UserRole.LENDER_MAINTAINER | UserRole.LENDER_VIEWER;

export type AccountRoles = PartnerRoles | LenderRoles;

export interface User {
  id: string;
  role: UserRole;
  first_name: string;
  last_name: string;
  email: string;
  created_at: string;
  updated_at: string;
  can_delete: boolean;
  // added on FE
  fullName: string;
}

export interface BaseUserQuery {
  per_page?: number;
  page?: number;
  role?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

export interface UserQuery extends BaseUserQuery {
  partner_id?: string;
  lender_id?: string;
}

export interface UsersListResponse {
  users: User[];
  total: number;
  page: number;
  per_page: number;
}

export enum SupportedCurrencies {
  GBP = 'GBP',
  USD = 'USD',
  EUR = 'EUR',
}

export enum SupportedLocales {
  GB = 'en-GB',
  US = 'en-US',
}

export enum CurrencySymbol {
  GBP = '£',
  USD = '$',
  EUR = '€',
  JPY = '¥',
}

export type FileErrors = Array<{ file: string; error: string }>;

export interface DocUploadResponse {
  companyDocs: Document[];
  errors: FileErrors;
}

export interface DashboardConstants {
  MIN_CREDIT_AMOUNT: number;
  MAX_CREDIT_AMOUNT: number;
  ROWS_PER_TABLE_PAGE: number;
  SEARCH_ROWS_PER_TABLE_PAGE: number;
}

export interface UserResponse {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: UserRole;
  occupation?: string;
}

export interface ApiKeysQuery {
  only?: KeyType;
  account_id?: string;
  account_type?: string;
}

export interface PartnerQuery extends BaseUserQuery {
  name?: string;
}

export interface PartnersListResponse {
  partners: Partner[];
  total: number;
  page: number;
  per_page: number;
}

export interface LendersListResponse {
  lenders: Lender[];
  total: number;
  page: number;
  per_page: number;
}

export interface LenderQuery {
  per_page?: number;
  page?: number;
  name?: string;
  displayed_name?: string;
  website?: string;
  contact_person_name?: string;
  contact_person_email?: string;
  address?: string;
}

export enum OverviewDateRange {
  THREE_MONTHS = 'LAST_THREE_MONTHS',
  TODAY = 'TODAY',
  SEVEN_DAYS = 'LAST_SEVEN_DAYS',
  FOUR_WEEKS = 'LAST_FOUR_WEEKS',
  TWELVE_MONTHS = 'LAST_TWELVE_MONTHS',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  QUARTER_TO_DATE = 'QUARTER_TO_DATE',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  ALL_TIME = 'ALL_TIME',
}

export enum RejectionReasonTag {
  DISSOLVED = 'DISSOLVED',
  FIRST_GAZETTE_NOTICE_COMPULSORY_STRIKE_OFF = 'FIRST_GAZETTE_NOTICE_COMPULSORY_STRIKE_OFF',
  OVERDUE_CONFIRMATION_STATEMENTS = 'OVERDUE_CONFIRMATION_STATEMENTS',
  OVERDUE_FILED_ACCOUNTS = 'OVERDUE_FILED_ACCOUNTS',
  LOW_TURNOVER = 'LOW_TURNOVER',
  LOW_MONTHLY_TURNOVER = 'LOW_MONTHLY_TURNOVER',
  POOR_PERSONAL_CREDIT = 'POOR_PERSONAL_CREDIT',
  POOR_BUSINESS_CREDIT = 'POOR_BUSINESS_CREDIT',
  DEFAULTS = 'DEFAULTS',
  CCJS_COURT_JUDGEMENTS = 'CCJS_COURT_JUDGEMENTS',
  LOW_BALANCES_BANK_STATEMENTS = 'LOW_BALANCES_BANK_STATEMENTS',
  OTHER = 'OTHER',
}

export enum ApiLender {
  IWOCA = 'iwoca',
  YOULEND = 'youlend',
  FLEXIMIZE = 'fleximize',
  CAPITAL_ON_TAP = 'capitalontap',
}

export enum ApiLenderRulesEngine {
  IWOCA = 'iwoca',
  YOULEND = 'youlend',
  FLEXIMIZE = 'fleximize',
  CAPITAL_ON_TAP = 'capital on tap',
}

export enum EcommerceProviders {
  AMAZON = 'AMAZON',
  SHOPIFY = 'SHOPIFY',
  SQUARESPACE = 'SQUARESPACE',
  BIG_COMMERCE = 'BIG_COMMERCE',
  WOO_COMMERCE = 'WOO_COMMERCE',
  SQUARE = 'SQUARE',
  WIX = 'WIX',
  MAGENTO = 'MAGENTO',
  EBAY = 'EBAY',
  ETSY = 'ETSY',
  PRESTASHOP = 'PRESTASHOP',
  SHOPER = 'SHOPER',
  WALMART = 'WALMART',
  STRIPE = 'STRIPE',
  PAYPAL = 'PAYPAL',
}

export interface TabRoute {
  path: string;
  label: string;
  Component: ComponentType;
}

export interface TableColumnOption {
  customHeadRender: (
    columnMeta: MUIDataTableCustomHeadRenderer,
    handleToggleColumn: (columnIndex: number) => void,
    sortOrder: MUISortOptions,
  ) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customBodyRender?: (value: any, tableMeta: MUIDataTableMeta) => string | JSX.Element | null;
}

export enum StageEnv {
  LOCAL = 'local',
  DEV_STAGING = 'dev-staging',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum ApplicationFact {
  // Monthly revenue
  MONTHLY_TURNOVER = 'monthly-turnover',
  // How long has company been trading in months
  TRADING_TIME_IN_MONTHS = 'trading-time-in-months',
  // How much company wants to borrow
  LOAN_AMOUNT = 'loan-amount',
  // Homeowner owns their property with or without mortgage
  IS_HOMEOWNER = 'is-homeowner',
  // Time since the company was funded
  BUSINESS_AGE_IN_MONTHS = 'business-age-in-months',
  // Company sic codes blacklist
  SIC_CODES_BLACKLIST = 'sic-codes-blacklist',
}

export enum ApplicationFactOperator {
  GREATER_THAN_INCLUSIVE = 'greaterThanInclusive',
  LESS_THAN_INCLUSIVE = 'lessThanInclusive',
  EQUAL = 'equal',
  IS_NOT_BLACKLISTED = 'isNotBlacklisted',
}

export enum GlobalFundingCriteria {
  GLOBAL = 'GLOBAL',
}

export type RuleEngineCriteria = FundingReason | GlobalFundingCriteria;

export enum RuleValueType {
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  STRING = 'string',
}

export interface PreAuthSession {
  public_key: string;
  access_token: string;
}

export interface CreateCompany {
  registered_name: string;
  registered_number?: string;
  region: string;
  formation_date: string;
  type?: CompanyTypes;
  address?: CreateAddress;
  ein?: string;
}

export interface CreateAddress {
  house_number: string | null;
  line_1: string | null;
  line_2: string | null;
  city: string | null;
  county: string | null;
  postcode: string | null;
  country_code: string | null;
  current: boolean;
  start_date: string | null;
  status: string | null;
  property_equity: number | null;
}

export enum CompanyTypes {
  LLC = 'llc',
  LTD = 'ltd',
  LLP = 'llp',
  SOLE_TRADER = 'sole_trader',
  PLC = 'plc',
  LP = 'limited_partnership',
  EEIG = 'EEIG',
  NI = 'northern_ireland',
  NIO = 'northern_ireland_other',
  OC = 'oversea-company',
  OTHER = 'OTHER',
}

export interface CompanySearchResult {
  title: string;
  companyNumber: string;
  incorporatedOn: string;
  companyAddress: CreateAddress | null;
  companyType: CompanyTypes;
  companyStatus: string;
}

export interface CreateApplicant {
  id: string | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  applicant?: boolean;
  addresses: CreateAddress[];
  nationality?: string;
  country_of_residence?: string;
  role?: string;
  director?: boolean;
  occupation?: string;
  appointed_on?: string;
  resigned_on?: string;
  phone_number?: string;
}

export interface AdminCreateApplication {
  partner_id: string;
  company: Partial<CreateCompany>;
  applicant: Partial<CreateApplicant>;
  directors?: Partial<CreateApplicant>[];
  region: ApplicationRegion;
}

export enum CompanyRegion {
  GB = 'GB',
  USA = 'US',
  IE = 'IE',
  AE = 'AE',
  OTHER = 'other',
}

export interface CreateAccountOwner {
  first_name: string;
  last_name: string;
  email: string;
}

export enum SettingName {
  FAQS = 'FAQS',
}

export interface Setting {
  id: string;
  name: SettingName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
}

export interface SelectOption<T> {
  label: string;
  value: T;
}

export enum WidthType {
  px = 'px',
  vw = 'vw',
}

export interface WidgetFormConfig {
  kycEnabled: boolean;
  fullWidth: boolean;
  width: number;
  widthType: WidthType;
  height: number;
  exitEnabled: boolean;
  captchaEnabled: boolean;
  partnerName?: string | null;
  logoUrl?: string;
  popupEnabled?: boolean;
  fontName?: FontName;
  letterLogoUrl?: string;
  primaryColor?: string;
  secondaryColor?: string;
}

export enum FontName {
  INTER = 'Inter',
  RUBIK = 'Rubik',
  ITIM = 'Itim',
  RALEWAY = 'Raleway',
  LATO = 'Lato',
  NUNITO = 'Nunito',
  ANTONIO = 'Antonio',
}

export enum AreaPhoneCode {
  GB = '+44',
  US = '+1',
  IE = '+353',
}

export enum EmailType {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
}
