import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    lenderActionButton: {
      marginLeft: theme.spacing(2),
      maxWidth: 'auto',
    },
  }),
);
