import { FC, useState, MouseEvent, useEffect, useMemo } from 'react';
import { Box, IconButton, Typography, Menu, MenuItem, Button, CircularProgress } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useReminders } from 'store/reminders/hooks';
import { Reminder } from 'store/reminders/types';
import routes from 'router/routes';
import { useAuth } from 'store/auth/hooks';
import useStyles from './AdminReminders.styles';
import { ReminderItem } from './components';

const DATE_FORMAT = 'YYYY-MM-DD';

const NUM_REMINDERS = 20;

interface GroupedReminders {
  dueTodayReminders: Reminder[];
  otherReminders: Reminder[];
  myReminders: number;
}

const AdminRemiders: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();
  const { loading, error, reminders, getReminders } = useReminders();
  const { t } = useTranslation();
  const history = useHistory();
  const { uid: currentUserId } = useAuth();

  useEffect(() => {
    getReminders({ per_page: NUM_REMINDERS, page: 1 });
  }, [getReminders]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { dueTodayReminders, otherReminders, myReminders } = useMemo(() => {
    const today = moment(moment().format(DATE_FORMAT));

    return reminders.slice(0, NUM_REMINDERS).reduce(
      (acc: GroupedReminders, val: Reminder): GroupedReminders => {
        if (val.done) return acc;

        const dueDate = moment(val.due_date).format(DATE_FORMAT);

        if (moment(today).isSame(dueDate)) {
          acc.dueTodayReminders.push(val);
        } else {
          acc.otherReminders.push(val);
        }

        if (val.assignee.id === currentUserId) {
          acc.myReminders += 1;
        }

        return acc;
      },
      { dueTodayReminders: [], otherReminders: [], myReminders: 0 } as GroupedReminders,
    );
  }, [reminders, currentUserId]);

  const goToReminders = () => {
    history.push(routes.reminders);
    setAnchorEl(null);
  };

  const undoneReminders = dueTodayReminders.concat(otherReminders);

  return (
    <>
      <IconButton className={classes.bellButton} onClick={handleClick}>
        <Notifications fontSize="small" />

        {myReminders > 0 && (
          <Box className={classes.bellCounterContainer}>
            <Typography className={classes.bellCounterText}>{myReminders}</Typography>
          </Box>
        )}
      </IconButton>

      <Menu
        id="admin-reminders"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 300,
        }}
        PaperProps={{ style: { width: 320 } }}
      >
        <Box>
          <Box className={classes.menuHeader}>
            <Typography className={classes.menuTitle}>{t('components.adminReminders.title')}</Typography>

            {myReminders > 0 && (
              <Typography className={classes.menuSubtitle}>
                {t('components.adminReminders.subtitle', { num: myReminders })}
              </Typography>
            )}
          </Box>

          {dueTodayReminders.length > 0 && (
            <>
              <Box className={classes.menuSectionTitleContainer}>
                <Typography className={classes.menuSectionTitle}>{t('components.adminReminders.dueToday')}</Typography>
              </Box>

              {dueTodayReminders.map((reminder) => (
                <MenuItem button={false} className={classes.menuItem} key={reminder.id}>
                  <ReminderItem reminder={reminder} dueToday />
                </MenuItem>
              ))}
            </>
          )}

          {otherReminders.length > 0 && (
            <>
              <Box className={classes.menuSectionTitleContainer}>
                <Typography className={classes.menuSectionTitle}>
                  {t('components.adminReminders.otherReminders')}
                </Typography>
              </Box>

              {otherReminders.map((reminder) => (
                <MenuItem button={false} className={classes.menuItem} key={reminder.id}>
                  <ReminderItem reminder={reminder} />
                </MenuItem>
              ))}
            </>
          )}

          {!error && undoneReminders.length === 0 && (
            <Box className={classes.messageContainer}>
              <Typography className={classes.messageText}>{t('components.adminReminders.noReminders')}</Typography>
            </Box>
          )}

          {!!error && (
            <Box className={classes.messageContainer}>
              <Typography className={classes.messageText}>
                {typeof error === 'string' ? error : t('global.error')}
              </Typography>
            </Box>
          )}

          {undoneReminders.length > 0 && (
            <Box className={classes.menuFooter}>
              <Button className={classes.actionButton} onClick={goToReminders}>
                <Typography className={classes.menuActionText}>{t('components.adminReminders.viewAll')}</Typography>
              </Button>
            </Box>
          )}

          {loading && (
            <Box className={classes.loaderContainer}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Menu>
    </>
  );
};

export default AdminRemiders;
