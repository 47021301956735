import i18n from 'i18next';

import { MonthlyRepaymentType, OfferProductType } from 'store/admin/types';
import {
  AccountType,
  AddressStatus,
  ApplicationRegion,
  ApplicationStatus,
  OfferStatus,
  TimePeriod,
} from 'store/applications/types';
import {
  AccountRoles,
  ApplicationFactOperator,
  CompanyDocumentType,
  CompanyRegion,
  CurrencySymbol,
  GlobalFundingCriteria,
  RejectionReasonTag,
  RuleEngineCriteria,
  SupportedCurrencies,
  UserRole,
} from './types';

/**
 *
 * @param role
 * @returns
 */
export const mapUserRoleToName = (role: UserRole): string => {
  switch (role) {
    case UserRole.ADMIN:
    case UserRole.LENDER_MANAGER:
    case UserRole.PARTNER_MANAGER:
      return 'Admin';
    case UserRole.PARTNER_USER:
    case UserRole.LENDER_VIEWER:
      return 'Member';
    case UserRole.LENDER_MAINTAINER:
      return 'Maintainer';
    default:
      return role;
  }
};

export const mapUserRoleToAccountType = (role: AccountRoles): AccountType => {
  switch (role) {
    case UserRole.LENDER_MANAGER:
    case UserRole.LENDER_MAINTAINER:
    case UserRole.LENDER_VIEWER:
      return AccountType.LENDER;
    case UserRole.PARTNER_MANAGER:
    case UserRole.PARTNER_USER:
      return AccountType.PARTNER;
    default:
      return AccountType.PARTNER;
  }
};

export const mapCompanyDocumentTypeToLabel = (docType: CompanyDocumentType): string => {
  switch (docType) {
    case CompanyDocumentType.BANK_STATEMENT:
      return 'Bank Statements';
    case CompanyDocumentType.FILED_ACCOUNTS:
      return 'Filed Accounts';
    case CompanyDocumentType.MANAGEMENT_ACCOUNTS:
      return 'Management Accounts';
    case CompanyDocumentType.PAYMENT_DATA:
      return 'Payment Data';
    case CompanyDocumentType.VAT_RETURN:
      return 'Vat Return';
    case CompanyDocumentType.IDENTITY_DOCUMENT:
      return 'Identity Document';
    default:
      return 'Unknown document type';
  }
};

export const mapRejectionReasonTagToLabel = (rejectionReasonTag: RejectionReasonTag): string => {
  switch (rejectionReasonTag) {
    case RejectionReasonTag.DISSOLVED:
      return 'Dissolved';
    case RejectionReasonTag.FIRST_GAZETTE_NOTICE_COMPULSORY_STRIKE_OFF:
      return 'First Gazette Notice for Compulsory strike-off';
    case RejectionReasonTag.OVERDUE_CONFIRMATION_STATEMENTS:
      return 'Overdue Confirmation Statements';
    case RejectionReasonTag.OVERDUE_FILED_ACCOUNTS:
      return 'Overdue Filed Accounts';
    case RejectionReasonTag.LOW_TURNOVER:
      return 'Low Turnover';
    case RejectionReasonTag.LOW_MONTHLY_TURNOVER:
      return 'Low Monthly Turnover';
    case RejectionReasonTag.POOR_PERSONAL_CREDIT:
      return 'Poor Personal Credit';
    case RejectionReasonTag.POOR_BUSINESS_CREDIT:
      return 'Poor Business Credit';
    case RejectionReasonTag.DEFAULTS:
      return 'Defaults';
    case RejectionReasonTag.CCJS_COURT_JUDGEMENTS:
      return 'CCJs/Court Judgements';
    case RejectionReasonTag.LOW_BALANCES_BANK_STATEMENTS:
      return 'Low Balances in the Bank Statements';
    case RejectionReasonTag.OTHER:
      return 'Other';
    default:
      return 'Unkown rejection reason tag';
  }
};

export const mapAddressStatusToLabel = (status: AddressStatus): string => {
  switch (status) {
    case AddressStatus.TENANT:
      return 'Tenant';
    case AddressStatus.OWNER_WITH_MORTGAGE:
      return 'Homeowner with mortgage';
    case AddressStatus.OWNER_NO_MORTGAGE:
      return 'Homeowner without mortgage';
    case AddressStatus.RENT_FREE:
      return 'Living with friends or family';
    default:
      return 'Unknown residential status';
  }
};

export const mapRuleFactToLabel = (attr: string, criteria?: RuleEngineCriteria): string => {
  switch (attr) {
    case 'business-age-in-months':
      return 'Business age in months';
    case 'trading-time-in-months':
      return 'Trading time in months';
    case 'loan-amount':
      return 'Loan amount in GBP';
    case 'monthly-turnover': {
      if (criteria !== GlobalFundingCriteria.GLOBAL) return 'Monthly turnover in GBP';
      return 'Monthly turnover';
    }
    case 'is-homeowner':
      return 'Homeowner';
    case 'accounts-overdue':
      return 'Accounts overdue';
    case 'company-status':
      return 'Company status';
    case 'sic-codes-blacklist':
      return 'Sic codes';
    default:
      return attr;
  }
};

export const mapRuleOperatorToLabel = (operator: ApplicationFactOperator): string => {
  switch (operator) {
    case ApplicationFactOperator.GREATER_THAN_INCLUSIVE:
      return 'Greater than or equal';
    case ApplicationFactOperator.LESS_THAN_INCLUSIVE:
      return 'Less than or equal';
    case ApplicationFactOperator.EQUAL:
      return 'Equal';
    case ApplicationFactOperator.IS_NOT_BLACKLISTED:
      return 'Excluded sic codes';
    default:
      return operator;
  }
};

export const mapRuleCriteriaToLabel = (criteria: RuleEngineCriteria): string => {
  switch (criteria) {
    case GlobalFundingCriteria.GLOBAL:
      return 'Global Funding Criteria';
    default:
      return criteria;
  }
};

export const mapRevenuePeriodToLabel = (period: TimePeriod): string => {
  switch (period) {
    case TimePeriod.MONTHLY:
      return 'Per month';
    case TimePeriod.YEARLY:
      return 'Per Year';
    default:
      return 'Unknown time period';
  }
};

export const mapTradingTimePeriodToLabel = (period: TimePeriod): string => {
  switch (period) {
    case TimePeriod.MONTHLY:
      return 'Months';
    case TimePeriod.YEARLY:
      return 'Years';
    default:
      return 'Unknown time period';
  }
};

export const mapApplicationStatusToLabel = (status: ApplicationStatus): string => {
  switch (status) {
    case ApplicationStatus.NEW:
      return 'New';
    case ApplicationStatus.PENDING:
      return 'Pending';
    case ApplicationStatus.PREQUALIFIED:
      return 'Pre-qualified';
    case ApplicationStatus.REJECTED:
      return '❌ Rejected';
    case ApplicationStatus.OFFERS_REQUESTED:
      return 'Lender review';
    case ApplicationStatus.OFFER_ACCEPTED:
      return 'Lender offer accepted';
    case ApplicationStatus.FUNDS_DISBURSED:
      return '✅ Funded';
    case ApplicationStatus.CLOSED:
      return 'Closed';
    case ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED:
      return 'Awaiting client information';
    default:
      return 'Unknown application status';
  }
};

export const mapOfferProductTypeToLabel = (productType: OfferProductType): string => {
  switch (productType) {
    case OfferProductType.CREDIT_LINE:
      return 'Credit Line';
    case OfferProductType.REVENUE_BASED:
      return 'Revenue Based Finance';
    case OfferProductType.REVOLVING_CREDIT_FACILITY:
      return 'Revolving Credit Facility';
    case OfferProductType.TERM_LOAN:
      return 'Term Loan';
    case OfferProductType.BUSINESS_CREDIT_CARD:
      return 'Business credit card';
    default:
      return 'Unknown product type';
  }
};

export const mapOfferStatusToLabel = (status: OfferStatus): string => {
  switch (status) {
    case OfferStatus.PENDING:
      return 'Pending';
    case OfferStatus.DEFERRED:
      return 'Deferred';
    case OfferStatus.DECLINED:
    case OfferStatus.RULES_DECLINED:
    case OfferStatus.APPLICANT_DECLINED:
      return 'Declined';
    case OfferStatus.ADDITIONAL_DETAILS_REQUESTED:
      return 'Additional details requested';
    case OfferStatus.OFFERED:
      return 'Offered';
    case OfferStatus.ACCEPTED:
      return 'Accepted';
    case OfferStatus.CANCELLED:
      return 'Cancelled';
    case OfferStatus.LENDER_CALL_FAILED:
      return 'Lender called failed';
    default:
      return 'Unknown offer status';
  }
};

export const mapOfferInterestTypeToLabel = (monthlyRepaymentType: MonthlyRepaymentType): string => {
  switch (monthlyRepaymentType) {
    case MonthlyRepaymentType.INTEREST_ONLY:
      return 'Interest only';
    case MonthlyRepaymentType.INTEREST_AND_CAPITAL:
      return 'Capital and interest';
    default:
      return 'Unkown interest type';
  }
};

export const mapAppRegionToSupportedCurrency = (region?: ApplicationRegion): SupportedCurrencies => {
  switch (region) {
    case ApplicationRegion.UK:
      return SupportedCurrencies.GBP;
    case ApplicationRegion.USA:
      return SupportedCurrencies.USD;
    case ApplicationRegion.IRL:
      return SupportedCurrencies.EUR;
    default:
      return SupportedCurrencies.GBP;
  }
};

export const mapAppRegionToCurrencySymbol = (region?: ApplicationRegion): CurrencySymbol => {
  switch (region) {
    case ApplicationRegion.UK:
      return CurrencySymbol.GBP;
    case ApplicationRegion.USA:
      return CurrencySymbol.USD;
    case ApplicationRegion.IRL:
      return CurrencySymbol.EUR;
    default:
      return CurrencySymbol.GBP;
  }
};

export const mapCompanyRegionToSupportedCurrency = (region: CompanyRegion): SupportedCurrencies => {
  switch (region) {
    case CompanyRegion.GB:
      return SupportedCurrencies.GBP;
    case CompanyRegion.USA:
      return SupportedCurrencies.USD;
    default:
      return SupportedCurrencies.GBP;
  }
};

export const mapRejectionCriteriaToLabel = (criteria: string, appRegion: ApplicationRegion): string => {
  const businessAgeMatch = criteria.match(/(age)(\d+)(months)/);
  const monthlyRevenueMatch = criteria.match(/(monthlyRevenue)(\d+)/);

  if (businessAgeMatch) {
    return i18n.t('pages.lead.sections.rejections.criteria.ageXMonths', {
      months: Number(businessAgeMatch[2] ?? 0),
    });
  }

  if (monthlyRevenueMatch) {
    return i18n.t('pages.lead.sections.rejections.criteria.monthlyRevenueXk', {
      currencySymbol: mapAppRegionToCurrencySymbol(appRegion),
      kAmount: Math.floor(Number(monthlyRevenueMatch[2] ?? 0) / 1000),
    });
  }

  return i18n.t(`pages.lead.sections.rejections.criteria.${criteria}`);
};

export const mapAppRegionToCompanyRegion = (region: ApplicationRegion): CompanyRegion => {
  switch (region) {
    case ApplicationRegion.UK:
      return CompanyRegion.GB;
    case ApplicationRegion.USA:
      return CompanyRegion.USA;
    case ApplicationRegion.IRL:
      return CompanyRegion.IE;
    default:
      return CompanyRegion.GB;
  }
};

export const mapApplicationStatusToBackgroundColor = (status: ApplicationStatus): string => {
  switch (status) {
    case ApplicationStatus.NEW:
      return '#42a5f5';
    case ApplicationStatus.PENDING:
    case ApplicationStatus.PREQUALIFIED:
    case ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED:
      return '#f57c00';
    case ApplicationStatus.REJECTED:
      return '#d32f2f';
    case ApplicationStatus.OFFERS_REQUESTED:
      return 'rgb(205, 240, 248)';
    case ApplicationStatus.OFFER_ACCEPTED:
      return 'rgb(205, 240, 248)';
    case ApplicationStatus.FUNDS_DISBURSED:
      return 'rgb(211, 242, 205)';
    case ApplicationStatus.CLOSED:
      return '#CCCCCC';
    default:
      return '#42a5f5';
  }
};

export const mapApplicationStatusColor = (status: ApplicationStatus): string => {
  switch (status) {
    case ApplicationStatus.NEW:
      return '#FFFFFF';
    case ApplicationStatus.PENDING:
    case ApplicationStatus.PREQUALIFIED:
    case ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED:
      return 'rgb(152, 54, 6)';
    case ApplicationStatus.REJECTED:
      return '#FFFFFF';
    case ApplicationStatus.OFFERS_REQUESTED:
      return '#000000';
    case ApplicationStatus.OFFER_ACCEPTED:
      return 'rgb(5, 89, 148)';
    case ApplicationStatus.FUNDS_DISBURSED:
      return 'rgb(20, 87, 62)';
    case ApplicationStatus.CLOSED:
      return '#000000';
    default:
      return '#42a5f5';
  }
};
