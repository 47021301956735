import { FC, useEffect, useMemo } from 'react';
import { Container, Grid, Typography, Box, ButtonBase, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AvatarGroup } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { Code, People, PlaylistAddCheck } from '@material-ui/icons';

import { Loader } from 'components/Loader';
import { useApplications } from 'store/applications/hooks';
import { useAuth } from 'store/auth/hooks';
import routes from 'router/routes';
import { UserQuery, UserRole } from 'core/types';
import { LeadsTable } from 'pages/leads/components';
import { composeInitials } from 'utils';
import { useAdmin } from 'store/admin/hooks';
import { useAccount } from 'store/account/hooks';
import Card from 'components/Card';
import { MetricsOverview } from 'components/MetricsOverview';
import useStyles from './Dashboard.styles';

const adminQuery: UserQuery = {
  per_page: 1000,
  page: 1,
  role: `${UserRole.ADMIN},${UserRole.SUPER_ADMIN}`,
};

const Dashboard: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading } = useApplications();
  const { isLender, isAdmin, isPartner, firstName, isCompanyOwner } = useAuth();
  const { admins, getAdminUsers } = useAdmin();
  const { users } = useAccount();
  const formattedDate = new Date().toDateString();
  const [dayOfTheWeek, month, day, year] = formattedDate.split(' ');
  const history = useHistory();

  useEffect(() => {
    if (!isAdmin) return;

    getAdminUsers(adminQuery);
  }, [getAdminUsers, isAdmin]);

  const gotoLeads = () => history.push(routes.leads);

  const gotoWidget = () => history.push(routes.widget);

  const gotoTeam = () => history.push(routes.team);

  const gotoAdmins = () => history.push(routes.admins);

  const avatarClick = isAdmin ? gotoAdmins : gotoTeam;

  const teamMembers: { id: string; initials: string }[] = useMemo(
    () =>
      (isAdmin ? admins : users).map((user) => ({
        id: user.id,
        initials: composeInitials(user.first_name, user.last_name),
      })),
    [admins, isAdmin, users],
  );

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item container spacing={3}>
          <Grid item xl={6} lg={7} md={7} sm={12} xs={12}>
            <Typography className={clsx([classes.header, classes.semibold])}>
              {t('pages.home.banner.welcome', { name: firstName ?? '' })}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {dayOfTheWeek}, {day} {month} {year}
            </Typography>
            <Box className={classes.titleContainer}>
              <Typography className={clsx([classes.title, classes.semibold])}>
                {t('pages.home.banner.getStarted')}
              </Typography>

              {isAdmin && (
                <Typography className={clsx([classes.body])}>{t('pages.home.banner.admin.message')}</Typography>
              )}
              {isLender && (
                <Typography className={clsx([classes.body])}>{t('pages.home.banner.lender.message')}</Typography>
              )}
              {isPartner && (
                <Typography className={clsx([classes.body])}>{t('pages.home.banner.partner.message')}</Typography>
              )}
              {isCompanyOwner && (
                <Typography className={clsx([classes.body])}>{t('pages.home.banner.companyOwner.message')}</Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} container spacing={3}>
            {!isCompanyOwner && (
              <Grid item sm={3} xs={12}>
                <Card
                  title={
                    <Box display="flex" alignItems="center" className={classes.textIconWrapper}>
                      <PlaylistAddCheck className={classes.icon} />
                      {t('pages.home.banner.welcomeCards.applications.title')}
                    </Box>
                  }
                  subtitle={t('pages.home.banner.welcomeCards.applications.sub')}
                  buttonText={t('pages.home.banner.welcomeCards.applications.buttonText')}
                  onClick={() => gotoLeads()}
                  className={classes.card}
                />
              </Grid>
            )}

            {isPartner && (
              <>
                <Grid item sm={3} xs={12}>
                  <Card
                    title={
                      <Box display="flex" alignItems="center" className={classes.textIconWrapper}>
                        <Code className={classes.icon} />
                        {t('pages.home.banner.welcomeCards.api.title')}
                      </Box>
                    }
                    subtitle={t('pages.home.banner.welcomeCards.api.sub')}
                    buttonText={t('pages.home.banner.welcomeCards.api.buttonText')}
                    onClick={() => window.open(t('pages.auth.footer.links.docsUrl'))}
                    className={classes.card}
                  />
                </Grid>

                <Grid item sm={3} xs={12}>
                  <Card
                    title={
                      <Box className={classes.textIconWrapper}>
                        <Code className={classes.icon} />
                        {t('pages.home.banner.welcomeCards.widget.title')}
                      </Box>
                    }
                    subtitle={t('pages.home.banner.welcomeCards.widget.sub')}
                    buttonText={t('pages.home.banner.welcomeCards.widget.buttonText')}
                    onClick={() => gotoWidget()}
                    className={classes.card}
                  />
                </Grid>
              </>
            )}

            {!isCompanyOwner && (
              <Grid item sm={3} xs={12}>
                <Card
                  title={
                    <Box display="flex" alignItems="center" className={classes.textIconWrapper}>
                      <People className={classes.icon} />
                      {t('pages.home.banner.welcomeCards.team.title')}
                    </Box>
                  }
                  className={clsx(classes.card, classes.teamCard)}
                  buttonText={t('pages.home.banner.welcomeCards.team.buttonText')}
                  onClick={avatarClick}
                >
                  <AvatarGroup max={5}>
                    {teamMembers.map(({ initials }, idx) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <ButtonBase onClick={avatarClick} style={{ border: 0 }} key={`${idx}-${initials}`}>
                          <Avatar className={classes.teamAvatar}>{initials}</Avatar>
                        </ButtonBase>
                      );
                    })}
                  </AvatarGroup>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>

        {(isAdmin || isPartner) && (
          <Grid item xs={12}>
            <MetricsOverview preview />
          </Grid>
        )}

        {isCompanyOwner && (
          <Grid item xs={12}>
            <LeadsTable />
          </Grid>
        )}
      </Grid>

      <Loader visible={loading} />
    </Container>
  );
};

export default Dashboard;
