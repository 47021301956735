import { FC } from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { ResponsiveContainer, LineChart, Line, Tooltip, TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { BalanceTransaction } from 'store/applications/types';
import { parseMoney } from 'utils';
import { useApplications } from 'store/applications/hooks';
import { mapAppRegionToSupportedCurrency } from 'core/utils';
import useStyles from '../chartStyles';

const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { applicationDetails } = useApplications();

  if (active && payload && payload.length) {
    const {
      payload: { amount, credit_debit_indicator, booking_date_time },
    } = payload[0];

    return (
      <Box className={classes.tooltipContainer}>
        <Typography>{`${t('pages.lead.sections.paymentData.transactionsChart.tooltip.date')}: ${moment(
          booking_date_time,
        ).format('MMM YYYY')}`}</Typography>
        <Typography>{`${t('pages.lead.sections.paymentData.transactionsChart.tooltip.amount')}: ${parseMoney(
          Number(amount),
          mapAppRegionToSupportedCurrency(applicationDetails?.region),
        )}`}</Typography>
        <Typography>
          {`${t('pages.lead.sections.paymentData.transactionsChart.tooltip.type')}: ${credit_debit_indicator}`}
        </Typography>
      </Box>
    );
  }

  return null;
};

interface TransactionsChartProps {
  loading: boolean;
  error: boolean;
  transactions: BalanceTransaction[];
}

const TransactionsChart: FC<TransactionsChartProps> = ({ loading, error, transactions }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const startTransaction = transactions[0];
  const endTransaction = transactions.slice(-1)[0];

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{t('pages.lead.sections.paymentData.transactionsChart.title')}</Typography>

      <ResponsiveContainer width="100%" height={240}>
        <LineChart data={transactions} margin={{ bottom: 30, top: 10 }}>
          <Tooltip content={<CustomTooltip />} />

          <Line type="monotone" dataKey="amount" stroke="#8884d8" fill="#8884d8" dot={false} />
        </LineChart>
      </ResponsiveContainer>

      {transactions.length > 0 ? (
        <Box className={classes.labelContainer}>
          <Typography className={classes.label}>{moment(startTransaction?.date_time).format('MMM YYYY')}</Typography>
          <Typography className={classes.label}>{moment(endTransaction?.date_time).format('MMM YYYY')}</Typography>
        </Box>
      ) : (
        <Box className={classes.absContainer}>
          <Typography className={classes.greyText}>
            {t('pages.lead.sections.paymentData.transactionsChart.noData')}
          </Typography>
        </Box>
      )}

      {loading && (
        <Box className={classes.absContainer}>
          <CircularProgress color="primary" />
        </Box>
      )}

      {error && (
        <Box className={classes.absContainer}>
          <Typography className={classes.greyText}>
            {t('pages.lead.sections.paymentData.transactionsChart.error')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TransactionsChart;
