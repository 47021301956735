import { FC } from 'react';
import { Typography, Box, Chip } from '@material-ui/core';
import { ResponsiveContainer, LineChart, Line, Tooltip, TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { getDateFromDateOnly, numberToPercentage } from 'utils';
import useStyles from './Overview.styles';

interface OverviewProps {
  totalCount: number;
  data: { date: string; amount: number }[];
  name: string;
  total: number;
  Icon?: JSX.Element;
}

const CustomTooltip = ({ active, payload, dateFormat }: TooltipProps<ValueType, NameType> & { dateFormat: string }) => {
  const classes = useStyles();

  if (active && payload && payload.length) {
    return (
      <Box className={classes.tooltipContainer}>
        <Typography>{moment(getDateFromDateOnly(payload[0].payload.date)).format(dateFormat)}</Typography>
        <Typography>{`${payload[0].name}: ${payload[0].value}`}</Typography>
      </Box>
    );
  }

  return null;
};

const Overview: FC<OverviewProps> = ({ name, total, data, totalCount, Icon }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const startDate = data[0];
  const endDate = data.slice(-1)[0];
  const dateFormat = startDate && startDate.date.length === 7 ? 'MMM YYYY' : 'MMM DD';

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.titleContainer}>
          <Typography className={classes.status}>{name.split('_').join(' ')}</Typography>

          <Chip size="small" label={numberToPercentage(total / totalCount)} className={classes.chip} />

          {Icon && <Box className={classes.iconContainer}>{Icon}</Box>}
        </Box>

        <Typography className={classes.subtitle}>{total}</Typography>
      </Box>

      <Box className={classes.chartContainer}>
        <ResponsiveContainer width="100%" height={140}>
          <LineChart data={data} margin={{ bottom: 30, top: 10 }}>
            <Tooltip content={<CustomTooltip dateFormat={dateFormat} />} />

            <Line type="monotone" dataKey="amount" stroke="#8884d8" fill="#8884d8" dot={false} />
          </LineChart>
        </ResponsiveContainer>

        {data.length > 0 && (
          <Box className={classes.labelContainer}>
            {startDate && (
              <Typography className={classes.label}>
                {moment(getDateFromDateOnly(startDate.date)).format(dateFormat)}
              </Typography>
            )}
            {endDate && (
              <Typography className={classes.label}>
                {moment(getDateFromDateOnly(endDate.date)).isSame(moment(), 'day')
                  ? t('global.time.today')
                  : moment(getDateFromDateOnly(endDate.date)).format(dateFormat)}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Overview;
