import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    semibold: {
      fontFamily: 'inter semibold',
    },
    selectContainer: {
      marginRight: theme.spacing(2),
      '& .MuiSelect-root': {
        height: 36,
        minWidth: '17em',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 16,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
      },
    },
    expandIcon: {
      height: 20,
      width: 20,
      color: '#687C8D',
      pointerEvents: 'none',
      position: 'absolute',
      top: 8,
      right: 10,
    },
    dateRangeContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexWrap: 'wrap',
    },
    datePickerContainer: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(494)]: {
        marginTop: theme.spacing(2),
      },
    },
  }),
);
