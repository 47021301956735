import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useApplications } from 'store/applications/hooks';
import { SelectInput } from 'components/inputs';
import { onlineSalesOptions } from 'core/constants';
import useStyles from './OnlineSalesFilter.styles';

const OnlineSalesFilter: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    filters: { onlineSales },
    updateApplicationsQuery,
  } = useApplications();

  const onSelectOnlineSales = (value: number) => {
    updateApplicationsQuery({ onlineSales: Number(value) });
  };

  return (
    <Box>
      <SelectInput<number>
        labelClassName={classes.inputLabel}
        selectClassName={classes.selectInput}
        options={onlineSalesOptions}
        value={onlineSales ?? 0}
        onSelect={onSelectOnlineSales}
      />

      <Typography variant="caption" id="demo-multiple-checkbox-label">
        {t('pages.dashboard.filters.onlineSales.title')}
      </Typography>
    </Box>
  );
};

export default OnlineSalesFilter;
