import { ChangeEvent, FC, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, StandardTextFieldProps, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Edit } from '@material-ui/icons';
import clsx from 'clsx';
import { mapAppRegionToSupportedCurrency } from 'core/utils';
// eslint-disable-next-line import/no-cycle
import { AmountNumberInput } from 'pages/leads/components/AmountNumberInput';
import { updateApplication as updateApplicationApi } from 'http/admin';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { useAuth } from 'store/auth/hooks';
import { parseMoney } from 'utils';
import useStyles from './EditableTextField.styles';

type TextFieldType = 'currency' | 'phoneNumber' | 'text';

interface EditableTextFieldProps {
  type: TextFieldType;
  initialValue: string;
  propKey: string;
}

const EditableTextField: FC<EditableTextFieldProps> = ({ type, initialValue, propKey }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(initialValue);
  const classes = useStyles();
  const { t } = useTranslation();
  const { isAdmin } = useAuth();
  const { applicationDetails, updateApplicationDetails } = useApplications();
  const { setError } = useAdmin();

  const toggleEditing = () => setEditing((prevState) => !prevState);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onCancel = () => {
    toggleEditing();
    setValue(updatedValue);
  };

  const getParsedPropValue = (val: string) => {
    if (type === 'currency') {
      return Number(val);
    }
    return val;
  };

  // eslint-disable-next-line
  const onSave = async (event: any) => {
    if (event.key === 'Enter') {
      if (!applicationDetails) return;
      setLoading(true);
      try {
        await updateApplicationApi(applicationDetails.id, { [propKey]: getParsedPropValue(value) });
        setUpdatedValue(value);
        updateApplicationDetails({ fundingAmount: Number(value) });
      } catch (error) {
        setError((error as Error).message ?? true);
      }
      setLoading(false);
      toggleEditing();
    }
  };

  const getInputProps = () => {
    const inputProps: Partial<StandardTextFieldProps['InputProps']> = {};
    if (type === 'currency') {
      inputProps.inputComponent = AmountNumberInput;
    }
    return inputProps;
  };

  const getParsedValue = () => {
    if (type === 'currency') {
      return parseMoney(Number(updatedValue), mapAppRegionToSupportedCurrency(applicationDetails?.region));
    }
    return updatedValue;
  };
  if (!isAdmin) return <>{getParsedValue()}</>;

  if (editing) {
    return (
      <Box className={classes.container}>
        <TextField
          type="text"
          value={value}
          InputProps={getInputProps()}
          className={classes.inputField}
          fullWidth
          disabled={loading}
          variant="outlined"
          onChange={onChange}
          onKeyDown={onSave}
        />

        {loading ? (
          <CircularProgress color="secondary" size={17} className={classes.progress} />
        ) : (
          <Button onClick={onCancel} className={classes.cancelButton}>
            {t('global.actions.cancel')}
          </Button>
        )}
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {getParsedValue()}

      <IconButton
        className={clsx([classes.actionButton, classes.editActionButton])}
        onClick={toggleEditing}
        disabled={loading}
      >
        <Edit className={classes.actionIcon} />
      </IconButton>
    </Box>
  );
};

export default EditableTextField;
