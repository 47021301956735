import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    inputField: {
      '& input': {
        width: '140px',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 16,
        height: '42px',
        textAlign: 'right',
      },
    },
    actionButton: {
      position: 'absolute',
      height: '30px',
      width: '30px',
      top: 0,
    },
    editActionButton: {
      top: -4,
      right: -28,
    },
    actionIcon: {
      fontSize: '20px',
      color: theme.palette.common.black,
    },
    cancelButton: {
      padding: 0,
      height: 'fit-content',
      '& > span': {
        fontSize: '13px !important',
      },
    },
    progress: {
      marginRight: 10,
      marginTop: 10,
    },
  }),
);
