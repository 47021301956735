import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { Typography, Box, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import clsx from 'clsx';

import { CustomDatePicker } from 'components/inputs';
import { overviewFilterOptions, MIN_DATE_DATA_OVERVIEW } from 'core/constants';
import { OverviewDateRange } from 'core/types';
import { getDateRangeByFilter } from 'utils';
import useStyles from './OverviewFilter.styles';

interface OverviewFilterProps {
  dateRange: OverviewDateRange;
  setDateRange: Dispatch<SetStateAction<OverviewDateRange>>;
  fromDate: Date;
  setFromDate: Dispatch<SetStateAction<Date>>;
  toDate: Date;
  setToDate: Dispatch<SetStateAction<Date>>;
}

const OverviewFilter: FC<OverviewFilterProps> = ({
  dateRange,
  setDateRange,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onFromDateChange = (date: Date) => {
    setFromDate(moment(date).startOf('day').toDate());
  };

  const onToDateChange = (date: Date) => {
    setToDate(moment(date).endOf('day').toDate());
  };

  const handleDateRangeChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const dateRangeFilter = event.target.value as OverviewDateRange;
    setDateRange(dateRangeFilter);
    const { start, end } = getDateRangeByFilter(dateRangeFilter);
    setFromDate(start);
    setToDate(end);
  };

  return (
    <Box className={classes.dateRangeContainer}>
      <Box className={classes.selectContainer}>
        <Select
          native
          IconComponent={() => <ExpandMore className={classes.expandIcon} />}
          variant="outlined"
          value={dateRange}
          onChange={handleDateRangeChange}
        >
          {overviewFilterOptions.map(({ label, value }) => {
            return (
              <option key={`option${value}`} value={value}>
                {label}
              </option>
            );
          })}
        </Select>
      </Box>

      <Box className={classes.datePickerContainer}>
        <Typography className={clsx([classes.subtitle, classes.semibold])}>
          {t('pages.dashboard.filters.date.from')}:
        </Typography>
        <CustomDatePicker
          name="fromDate"
          value={fromDate}
          onChange={onFromDateChange}
          disableFuture
          openTo="date"
          format="MMM D"
          collapsed
          maxDate={toDate}
          minDate={MIN_DATE_DATA_OVERVIEW}
        />

        <Typography className={clsx([classes.subtitle, classes.semibold])}>
          {t('pages.dashboard.filters.date.to')}:
        </Typography>
        <CustomDatePicker
          name="toDate"
          value={toDate}
          onChange={onToDateChange}
          disableFuture
          openTo="date"
          format="MMM D"
          collapsed
          minDate={fromDate}
        />
      </Box>
    </Box>
  );
};

export default OverviewFilter;
