import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    switchContainer: {
      display: 'flex',
      position: 'relative',
      width: 120,
    },
    switchLabel: {
      '& > span': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(18),
      },
    },
    loader: {
      position: 'absolute',
      right: 32,
      top: 8,
    },
  }),
);
