import { FC } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import useStyles from './Loader.styles';

interface LoaderProps {
  visible: boolean;
}

const Loader: FC<LoaderProps> = ({ visible }) => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default Loader;
