import { useState, useCallback } from 'react';

import { getBankTransactions as getBankTransactionsApi } from 'http/bank';
import { BankTransaction, BankTransactionsQuery } from 'store/applications/types';

interface BankValuationsReturnHook {
  loading: boolean;
  error: string | boolean;
  transactions: BankTransaction[];
  total: number;
  getBankTransactions: (companyId: string, query: BankTransactionsQuery) => Promise<void>;
}

const useBankTransactions = (): BankValuationsReturnHook => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [transactionData, setTransactionsData] = useState<{ transactions: BankTransaction[]; total: number }>({
    transactions: [],
    total: -1,
  });

  const getBankTransactions = useCallback(async (companyId: string, query: BankTransactionsQuery) => {
    setLoading(true);
    try {
      const data = await getBankTransactionsApi(companyId, query);
      setTransactionsData({ transactions: data.transactions, total: data.total });
    } catch (err) {
      setError((err as Error)?.message || true);
    }
    setLoading(false);
  }, []);

  return { loading, error, ...transactionData, getBankTransactions };
};

export default useBankTransactions;
