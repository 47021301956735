import { FC, useState, ChangeEvent } from 'react';
import { Box, Typography, Checkbox, CircularProgress } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';

import { checkReminder as checkReminderApi } from 'http/reminders';
import { Reminder } from 'store/reminders/types';
import { useReminders } from 'store/reminders/hooks';
import routes from 'router/routes';
import { useAuth } from 'store/auth/hooks';
import useStyles from './ReminderItem.styles';

interface ReminderItemProps {
  reminder: Reminder;
  dueToday?: boolean;
}

const ReminderItem: FC<ReminderItemProps> = ({ reminder, dueToday = false }) => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(reminder.done);
  const classes = useStyles();
  const { setError, updateReminder } = useReminders();
  const { t } = useTranslation();
  const { uid: currentUserId } = useAuth();

  const disabled = currentUserId !== reminder.assignee.id;

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    setLoading(true);
    try {
      const updatedDone = event.target.checked;
      await checkReminderApi(reminder.id, updatedDone);
      setDone(updatedDone);
      setTimeout(() => updateReminder({ id: reminder.id, done: updatedDone }), 350);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  return (
    <Box className={classes.itemContainer}>
      <Box className={classes.itemIconContainer}>
        <CheckCircleOutline fontSize="large" />
      </Box>

      <Box className={classes.itemContentContainer}>
        <Typography className={clsx([classes.itemTitle, disabled && classes.disabled])}>{reminder.reminder}</Typography>
        <Link to={`${routes.lead}/${reminder.application_id}`} className={classes.itemSubtitle}>
          {reminder.company_name}
        </Link>
        <Typography className={classes.itemSubtitle}>
          {t('components.adminReminders.assignee')}: {reminder.assignee.name}
        </Typography>
        <Typography className={classes.itemSubtitle}>
          {dueToday
            ? t('components.adminReminders.dueToday')
            : `${t('components.adminReminders.dueDate')} ${moment(reminder.due_date).format('DD/MM/YYYY')}`}
        </Typography>
        <Checkbox
          checked={done}
          onChange={handleChange}
          className={classes.itemCheckbox}
          color="primary"
          disabled={disabled}
        />
      </Box>

      {loading && (
        <Box className={classes.loaderContainer}>
          <CircularProgress size={30} />
        </Box>
      )}
    </Box>
  );
};

export default ReminderItem;
