import Papa from 'papaparse';
import camelCase from 'lodash.camelcase';
import { LendersAction } from 'store/admin/types';
import { lenderHeaders } from 'store/lenders/types';

export const readLendersCSV = async (file: string): Promise<LendersAction[]> => {
  const response = await fetch(file);
  const text = await response.text();

  const parsedCSV = Papa.parse(text, {
    delimiter: ',',
    header: true,
    dynamicTyping: true,
    transformHeader: (header: string) => lenderHeaders[camelCase(header)],
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformedData: LendersAction[] = parsedCSV.data.map((row: any) => {
    // Map properties from row to LendersAction type
    const transformedRow: LendersAction = {
      name: row.name || '', // Provide default value if necessary
      displayed_name: row.displayed_name || '',
      contact_first_name: row.contact_first_name || '',
      contact_last_name: row.contact_last_name || '',
      contact_email: row.contact_email || '',
      active: row.active || false,
      address: row.address || '',
      logo_url: row.logo_url || '',
      website: row.website || '',
    };
    return transformedRow;
  });

  return transformedData;
};
