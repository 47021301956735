import { ChangeEvent, FC, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { updateRule as updateRuleApi } from 'http/admin';
import { CustomNumberField, CustomRadioField, CustomTextarea, CustomTextField } from 'components/inputs';
import { AlertDialog } from 'components/AlertDialog';
import { useAdmin } from 'store/admin/hooks';
import { RuleEngineRule } from 'store/admin/types';
import { ruleBooleanValueOptions } from 'core/constants';
import { mapRuleCriteriaToLabel, mapRuleFactToLabel, mapRuleOperatorToLabel } from 'core/utils';
import { camelCaseToSentenceCaseText, getRuleParsedValue } from 'utils';
import { ApplicationFact, GlobalFundingCriteria } from 'core/types';
import useStyles from './ActionRuleModal.styles';
import yup from './yup-extended';

const SIC_CODES_LIST_SEPARATOR = ',';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AmountNumberInput: FC = (props: any) => (
  <CustomNumberField {...props} prefix="£" decimalScale={0} fixedDecimalScale />
);

interface FormValues {
  value: string | number | boolean;
}

interface ActionRuleModalProps {
  open: boolean;
  toggleOpen: () => void;
  rule: RuleEngineRule;
}

const ActionRuleModal: FC<ActionRuleModalProps> = ({ open, toggleOpen, rule }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { rulesEngine, setError, setSuccess, getRulesEngine } = useAdmin();

  const formSchema = yup.object({
    value: yup.lazy((value) => {
      switch (typeof value) {
        case 'boolean':
          return yup.boolean().required(t('pages.rulesEngine.modal.inputs.value.required'));
        case 'number':
          return yup
            .number()
            .min(1, t('pages.rulesEngine.modal.inputs.value.numberError'))
            .required(t('pages.rulesEngine.modal.inputs.value.required'));
        default: {
          if (rule.fact !== ApplicationFact.SIC_CODES_BLACKLIST) {
            return yup.string().required(t('pages.rulesEngine.modal.inputs.value.required'));
          }
          return yup.string().required(t('pages.rulesEngine.modal.inputs.value.required'));
          // .siCodes(t('pages.rulesEngine.modal.inputs.value.sicCodeError'));
        }
      }
    }),
  });

  const initialValues: FormValues = {
    value: rule.value ?? '',
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      await updateRuleApi(rule.id, { value: JSON.stringify(values.value) });
      setSuccess(t('pages.rulesEngine.messages.updateSuccess') as string);
      getRulesEngine();
      setLoading(false);
      onCancel();
    } catch (err) {
      setLoading(false);
      setError((err as Error).message ?? true);
    }
  };

  const getInputComponent = () => {
    if (typeof rule.value === 'number') {
      if (
        rule.criteria !== GlobalFundingCriteria.GLOBAL &&
        (rule.fact.includes('amount') || rule.fact.includes('turnover'))
      ) {
        return AmountNumberInput;
      }
      return CustomNumberField;
    }
    return undefined;
  };

  const getRuleTitle = () => {
    if (rule.lender_name) return camelCaseToSentenceCaseText(rule.lender_name);
    if (rule.criteria) return mapRuleCriteriaToLabel(rule.criteria);
    return '';
  };

  const renderField = (
    values: FormValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    if (typeof rule.value === 'boolean') {
      return (
        <Field
          id="value"
          aria-label="ruleValue"
          component={CustomRadioField}
          options={ruleBooleanValueOptions}
          name="value"
          value={values.value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue('value', event.target.value === 'true')}
          title={t('pages.rulesEngine.modal.inputs.value.label')}
          className={classes.textInput}
        />
      );
    }

    if (rule.fact === ApplicationFact.SIC_CODES_BLACKLIST) {
      return (
        <Field
          id="value"
          fullWidth
          component={CustomTextarea}
          name="value"
          value={values.value}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            const targetValue = event.target.value
              .replace(/\s+/g, ' ')
              .replace(/([a-zA-Z0-9])(?=(\s)+(?![a-zA-Z0-9]))/g, `$1${SIC_CODES_LIST_SEPARATOR}`);
            setFieldValue('value', targetValue);
          }}
          title={t('pages.rulesEngine.modal.inputs.value.label')}
          placeholder={t('pages.rulesEngine.modal.inputs.value.placeholder')}
          className={classes.textInput}
          disabled={loading}
        />
      );
    }

    return (
      <Field
        id="value"
        fullWidth
        component={CustomTextField}
        name="value"
        value={values.value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const targetValue = event.target.value;
          // Needed for correct yup validation
          setFieldValue('value', typeof rule.value === 'number' ? Number(targetValue) : targetValue);
        }}
        title={t('pages.rulesEngine.modal.inputs.value.label')}
        placeholder={t('pages.rulesEngine.modal.inputs.value.placeholder')}
        className={classes.textInput}
        InputLabelProps={{ shrink: true }}
        InputProps={{ inputComponent: getInputComponent() }}
        disabled={loading}
      />
    );
  };

  const parentRule = rule.parent_id ? rulesEngine.find((rE) => rE.id === rule.parent_id) : undefined;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <AlertDialog
          open={open}
          dialogContentTitle={t('pages.rulesEngine.modal.title')}
          handleCancel={onCancel}
          handleConfirm={handleSubmit}
          confirmButtonTitle={t('pages.rulesEngine.modal.buttons.confirm')}
          loading={loading}
        >
          <Form noValidate>
            <Grid container direction="column" spacing={4} className={classes.container}>
              <Grid item>
                <Typography className={classes.title}>
                  <span>{getRuleTitle()}</span>
                  {parentRule && (
                    <span>
                      {' '}
                      - {mapRuleFactToLabel(parentRule.fact, parentRule.criteria)}:{' '}
                      {getRuleParsedValue(parentRule.fact, parentRule.value)}
                    </span>
                  )}
                </Typography>

                <Typography className={classes.subtitle}>{mapRuleFactToLabel(rule.fact, rule.criteria)}</Typography>
                <Typography className={classes.subtitle}>{mapRuleOperatorToLabel(rule.operator)}</Typography>
              </Grid>

              <Grid item>{renderField(values, setFieldValue)}</Grid>
            </Grid>
          </Form>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default ActionRuleModal;
