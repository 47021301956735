import { Box, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './CustomSearch.styles';

interface CustomSearchProps {
  value: string;
  onChange: (value: string) => void;
  onClose: () => void;
}

const CustomSearch: FC<CustomSearchProps> = ({ value, onChange, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box className={classes.container}>
      <TextField
        value={value}
        onChange={onSearchChange}
        variant="outlined"
        title=""
        placeholder={t('global.search')}
        fullWidth
      />

      {value && <Close className={classes.closeIcon} onClick={onClose} />}
      <Search className={classes.searchIcon} />
    </Box>
  );
};

export default CustomSearch;
