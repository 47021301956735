import axios from 'axios';

import { RequestConfig, RequestError } from './types';
import { accept20x, getErrorMessage } from './utils';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const http = axios.create({ baseURL });

export const apiCall = async <T>(config: RequestConfig): Promise<T> => {
  try {
    const { getRawData, ...rest } = config;

    const { status, data } = await http(rest);

    if (!accept20x(status)) {
      throw new Error(data?.message);
    }

    if (getRawData) return data;

    return data.data;
  } catch (error) {
    const message = getErrorMessage(error as RequestError);
    throw new Error(message);
  }
};

export default http;
