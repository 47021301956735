import { FC, useMemo, useState, useRef, ChangeEvent } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Moment } from 'moment';

import { createReminder as createReminderApi } from 'http/reminders';
import { AlertDialog } from 'components/AlertDialog';
import { CustomDatePicker, CustomSelect, CustomTextField } from 'components/inputs';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { useReminders } from 'store/reminders/hooks';
import useStyles from './CreateReminderModal.styles';

interface FormValues {
  assignee_id: string;
  reminder: string;
  due_date: Date | null;
}

interface CreateReminderModalProps {
  open: boolean;
  toggleOpen: () => void;
}

const CreateReminderModal: FC<CreateReminderModalProps> = ({ open, toggleOpen }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { admins, setError, setSuccess } = useAdmin();
  const { applicationDetails } = useApplications();
  const { getReminders } = useReminders();

  const formSchema = useMemo(
    () =>
      yup.object({
        assignee_id: yup.string().required(t('pages.lead.admin.createReminder.inputs.assignee_id.required')),
        reminder: yup.string().required(t('pages.lead.admin.createReminder.inputs.reminder.required')),
        due_date: yup.date().required(t('pages.lead.admin.createReminder.inputs.due_date.required')).nullable(),
      }),
    [t],
  );

  const initialValues: FormValues = {
    assignee_id: '',
    reminder: '',
    due_date: null,
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  const onSubmit = async (values: FormValues) => {
    if (!applicationDetails || !values.due_date) return;

    setLoading(true);
    try {
      await createReminderApi({
        ...values,
        application_id: applicationDetails.id,
        due_date: values.due_date.toISOString(),
      });
      getReminders({ per_page: 20, page: 1 });
      setSuccess(t('pages.lead.admin.createReminder.messages.success') as string);
      onCancel();
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  const assigneeOptions = useMemo(() => {
    return [{ label: '', value: '' }].concat(admins.map((admin) => ({ label: admin.fullName, value: admin.id })));
  }, [admins]);

  return (
    <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit} innerRef={formRef}>
      {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
        <AlertDialog
          open={open}
          dialogContentTitle={t('pages.lead.admin.createReminder.title')}
          handleCancel={onCancel}
          handleConfirm={handleSubmit}
          loading={loading}
        >
          <Form noValidate>
            <Grid container direction="column" spacing={4} className={classes.container}>
              <Grid item>
                <Field
                  autoFocus
                  id="assignee_id"
                  fullWidth
                  component={CustomSelect}
                  name="assignee_id"
                  options={assigneeOptions}
                  value={values.assignee_id}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('assignee_id', event.target.value)}
                  title={t('pages.lead.admin.createReminder.inputs.assignee_id.label')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textInput}
                  disabled={loading}
                />
              </Grid>

              <Grid item>
                <Field
                  id="reminder"
                  fullWidth
                  component={CustomTextField}
                  name="reminder"
                  value={values.reminder}
                  onChange={handleChange}
                  title={t('pages.lead.admin.createReminder.inputs.reminder.label')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textInput}
                  disabled={loading}
                />
              </Grid>

              <Grid item>
                <CustomDatePicker
                  name="due_date"
                  label={t('pages.lead.admin.createReminder.inputs.due_date.label')}
                  value={values.due_date}
                  onChange={(date: Moment | null) => setFieldValue('due_date', date?.toDate() ?? null)}
                  clearable
                  disabled={loading}
                  error={touched.due_date && errors.due_date ? errors.due_date : undefined}
                  openTo="date"
                  format="DD MMMM YYYY"
                />
              </Grid>
            </Grid>
          </Form>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default CreateReminderModal;
