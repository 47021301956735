import { ChangeEvent, FC, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Select, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { useApplications } from 'store/applications/hooks';
import { useAuth } from 'store/auth/hooks';
import { useAccount } from 'store/account/hooks';
import { useAdmin } from 'store/admin/hooks';
import { composeFullName } from 'utils';
import useStyles from './AsigneeFilter.styles';

const AsigneeFilter: FC = () => {
  const classes = useStyles();
  const {
    filters: { assigneeId },
    updateApplicationsQuery,
  } = useApplications();
  const { isAdmin, uid: adminId } = useAuth();
  const { users } = useAccount();
  const { admins } = useAdmin();

  const setAssignee = (assigneeID: string | null) => {
    // Important to reset assigne id passing an empty string rather than 'undefined'
    if (!assigneeID) updateApplicationsQuery({ assigneeId: '', activePage: 0 });
    else if (assigneeID) updateApplicationsQuery({ assigneeId: assigneeID, activePage: 0 });
  };

  const assigneeOptions: { id: string; fullName: string }[] = useMemo(() => {
    if (isAdmin) return admins;
    return users.map((user) => ({ id: user.id, fullName: composeFullName(user.first_name, user.last_name) }));
  }, [admins, isAdmin, users]);

  return (
    <Box className={classes.adminFilters}>
      <Box className={classes.selectContainer}>
        <Select
          native
          IconComponent={() => <ExpandMore className={classes.expandIcon} />}
          variant="outlined"
          value={assigneeId ?? ''}
          onChange={(
            event: ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>,
          ) => setAssignee(event.target.value as string)}
          className={classes.select}
        >
          <option value=""> </option>
          {assigneeOptions.map(({ id, fullName }) => {
            return (
              <option key={`option${id}`} value={id}>
                {fullName}
              </option>
            );
          })}
        </Select>
      </Box>

      <FormControlLabel
        className={classes.controlLabel}
        control={
          <Checkbox
            checked={assigneeId === adminId}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              event.target.checked ? setAssignee(adminId as string) : setAssignee('')
            }
            name="assigneeId"
            color="secondary"
            size="medium"
          />
        }
        label={<Typography className={classes.strongText}>Assigned To Me</Typography>}
      />
    </Box>
  );
};

export default AsigneeFilter;
