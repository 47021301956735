import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      paddingLeft: 0,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
    },
    downloadContainer: {
      flex: 1,
      marginRight: 10,
      paddingLeft: theme.spacing(1),
      '& span': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
        textAlign: 'left',
        wordWrap: 'break-word',
      },
    },
    actionsContainer: {
      width: 60,
      right: 0,
    },
    viewButton: {
      height: '30px',
      width: '30px',
      marginRight: 1,
    },
    dialogContainer: {
      maxWidth: '55%',
      height: '100%',
    },
    imageContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  }),
);
