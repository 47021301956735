import { FC, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { FileRejection } from 'react-dropzone';
import { HighlightOff as RejectedFileIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { allowedFileTypes } from 'core/constants';
import { CompanyDocumentType, DocUploadResponse } from 'core/types';
import { noop } from 'utils';
import { CompanyDocument } from 'store/applications/types';
import useStyles from './DocumentUpload.styles';
import { FileDropMulti } from '../FileDrop';

interface DocumentUploadProps {
  uploadDocument(data: FormData): Promise<DocUploadResponse | null>;
  onUploadSuccess?: (documents: CompanyDocument[]) => void;
  documentType: CompanyDocumentType;
  companyDocs: CompanyDocument[];
  afterUpdate: (docs: CompanyDocument[]) => void;
}

const DocumentUpload: FC<DocumentUploadProps> = ({
  uploadDocument,
  onUploadSuccess,
  documentType,
  companyDocs,
  afterUpdate,
}) => {
  const [rejectedFiles, setRejections] = useState<FileRejection[]>([]);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleUpload = useCallback(
    (documents: CompanyDocument[]) => {
      if (documents.length) {
        documents.forEach((document) => {
          document.url = `${document.url}?${Date.now()}`;
        });
      }
      afterUpdate(documents);
      if (onUploadSuccess) {
        onUploadSuccess(documents);
      }
    },
    [onUploadSuccess, afterUpdate],
  );

  const handleRejection = useCallback((files: FileRejection[]) => {
    setRejections(files);
  }, []);

  return (
    <Box>
      <FileDropMulti
        allowedFileTypes={allowedFileTypes}
        onUpload={handleUpload}
        onReject={handleRejection}
        uploadMessage={t('global.uploadDocument')}
        uploadDocument={uploadDocument}
        documentType={documentType}
        uploadedDocs={companyDocs}
      />

      {rejectedFiles.length ? (
        <Box marginTop="15px">
          <Typography className={classes.sectionTitle}>{t('global.files.rejected')}</Typography>
          <List>
            {rejectedFiles.map((res: FileRejection) => {
              return (
                <ListItem key={res.file.name}>
                  <ListItemText primary={res.file.name} className={classes.fileTitle} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={noop}>
                      <Tooltip title={`${t(res.errors[0].message)}`} placement="top-start">
                        <div className={classes.deleteAvatar}>
                          <RejectedFileIcon color="error" />
                        </div>
                      </Tooltip>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Box>
      ) : undefined}
    </Box>
  );
};

export default DocumentUpload;
