import { FC } from 'react';
import {
  AppBar,
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import { AccountCircle, ExitToApp as Logout, OpenInNew } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AdminRemiders } from 'components/AdminReminders';
import { AppAdminActions } from 'pages/leads/components/AppAdminActions';
import routes from 'router/routes';
import { ReactComponent as Logo } from '../../assets/icons/fullLogo.svg';
import { useAuth } from '../../store/auth/hooks';
import useStyles from './Navbar.styles';

const Navbar: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isAdmin, isPartner, isCompanyOwner, firstName, logout } = useAuth();

  const accountName = firstName ?? '';

  const production = (process.env.REACT_APP_STAGE as string) === 'production';

  return (
    <>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Container disableGutters maxWidth="xl">
          <Toolbar>
            <Box className={classes.leftContent}>
              <Logo title="Business Score" height="30px" width="80px" />
            </Box>

            <Box className={classes.rightContent}>
              {(isAdmin || isPartner) && <AppAdminActions />}

              <List className={classes.actionsList}>
                {isAdmin && (
                  <ListItem className={classes.bellContainer}>
                    <AdminRemiders />
                  </ListItem>
                )}

                <ListItem
                  button
                  onClick={() => {
                    window.open('https://www.scorethebusiness.com/resources/knowledge-hub', '_blank');
                    return null;
                  }}
                >
                  <ListItemAvatar className={classes.avatar}>
                    <OpenInNew fontSize="small" />
                  </ListItemAvatar>
                  <ListItemText className={classes.listText}>{t('global.learn')}</ListItemText>
                </ListItem>

                {accountName && (
                  <ListItem>
                    <ListItemAvatar className={classes.avatar}>
                      <AccountCircle fontSize="small" />
                    </ListItemAvatar>
                    <ListItemText className={clsx([classes.listText, classes.strongText])}>{accountName}</ListItemText>
                  </ListItem>
                )}

                <ListItem button onClick={logout}>
                  <ListItemAvatar className={classes.avatar}>
                    <Logout fontSize="small" />
                  </ListItemAvatar>
                  <ListItemText className={classes.listText}>{t('global.logout')}</ListItemText>
                </ListItem>
              </List>
            </Box>
          </Toolbar>

          <Toolbar className={classes.appBarLinks}>
            <Box className={classes.leftContent}>
              <List className={classes.appBarLinkList} component="nav">
                <ListItem
                  button
                  className={classes.appBarLink}
                  component={NavLink}
                  to={routes.dashboard}
                  activeClassName={classes.appBarLinkActive}
                  exact
                >
                  <ListItemText className={classes.appBarLinkText}>{t('global.dashboard')}</ListItemText>
                </ListItem>

                {!isCompanyOwner && (
                  <ListItem
                    button
                    className={classes.appBarLink}
                    component={NavLink}
                    to={routes.leads}
                    activeClassName={classes.appBarLinkActive}
                  >
                    <ListItemText className={classes.appBarLinkText}>{t('global.leads')}</ListItemText>
                  </ListItem>
                )}

                {(isAdmin || isPartner) && (
                  <ListItem
                    className={classes.appBarLink}
                    component={NavLink}
                    to={routes.metrics}
                    activeClassName={classes.appBarLinkActive}
                  >
                    <ListItemText className={classes.appBarLinkText} primary={t('global.metrics')} />
                  </ListItem>
                )}

                {isPartner && (
                  <ListItem
                    button
                    className={classes.appBarLink}
                    component={NavLink}
                    to={routes.developers}
                    activeClassName={classes.appBarLinkActive}
                  >
                    <ListItemText className={classes.appBarLinkText}>{t('global.developer')}</ListItemText>
                  </ListItem>
                )}

                {isAdmin && (
                  <>
                    <ListItem
                      button
                      className={classes.appBarLink}
                      component={NavLink}
                      to={routes.lenders}
                      activeClassName={classes.appBarLinkActive}
                    >
                      <ListItemText className={classes.appBarLinkText}>{t('global.lenders')}</ListItemText>
                    </ListItem>

                    <ListItem
                      button
                      className={classes.appBarLink}
                      component={NavLink}
                      to={routes.partners}
                      activeClassName={classes.appBarLinkActive}
                    >
                      <ListItemText className={classes.appBarLinkText}>{t('global.partners')}</ListItemText>
                    </ListItem>

                    <ListItem
                      button
                      className={classes.appBarLink}
                      component={NavLink}
                      to={routes.admins}
                      activeClassName={classes.appBarLinkActive}
                    >
                      <ListItemText className={classes.appBarLinkText}>{t('global.admins')}</ListItemText>
                    </ListItem>
                  </>
                )}

                {!isCompanyOwner && (
                  <ListItem
                    className={classes.appBarLink}
                    component={NavLink}
                    to={routes.settings}
                    activeClassName={classes.appBarLinkActive}
                  >
                    <ListItemText className={classes.appBarLinkText} primary={t('global.settings')} />
                  </ListItem>
                )}
              </List>
            </Box>
          </Toolbar>
        </Container>

        {!production && (
          <>
            <Box justifyContent="center" display="flex" flexDirection="column" zIndex={10}>
              <Divider variant="fullWidth" className={classes.divider} />
              <Alert severity="info" className={classes.alert}>
                {t('global.testMode')}
              </Alert>
            </Box>
          </>
        )}
      </AppBar>
    </>
  );
};

export default Navbar;
