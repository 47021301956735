import { FC, useState } from 'react';
import { Typography, Box, Button, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ApplicationRegion, ApplicationRejection } from 'store/applications/types';
import { mapRejectionCriteriaToLabel, mapRejectionReasonTagToLabel } from 'core/utils';
import useStyles from '../LeadDetails/LeadDetails.styles';
import { RejectModal } from '../RejectModal';

interface RejectionProps {
  rejection: ApplicationRejection;
  offerId?: string;
  isPartner?: boolean;
  appRegion: ApplicationRegion;
}

const Rejection: FC<RejectionProps> = ({ rejection, offerId, isPartner, appRegion }) => {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const toggleEdit = () => setEdit((prevEdit) => !prevEdit);

  return (
    <>
      <Typography className={classes.sectionTitle}>{t('pages.lead.sections.rejections.title')} </Typography>

      {rejection.rejection_reason && (
        <>
          <Typography className={classes.greySubtitle} style={{ marginTop: 16 }}>
            {t('pages.lead.sections.rejections.rejection_reason')}
          </Typography>

          <Typography className={classes.text}>{rejection.rejection_reason}</Typography>
        </>
      )}

      {rejection.rejected_at && (
        <>
          <Typography className={classes.greySubtitle} style={{ marginTop: 16 }}>
            {t('pages.lead.sections.rejections.rejected_at')}
          </Typography>

          <Typography className={classes.text}>{new Date(rejection.rejected_at).toLocaleDateString()}</Typography>
        </>
      )}

      {!offerId && rejection.unmet_criteria && !isPartner && (
        <>
          <Typography className={classes.greySubtitle} style={{ marginTop: 16 }}>
            {t('pages.lead.sections.rejections.unmet_criteria')}
          </Typography>

          {Object.keys(rejection.unmet_criteria).map((key) => (
            <Box key={key}>
              <Typography className={classes.textTitle}>{key}</Typography>

              {rejection.unmet_criteria &&
                rejection.unmet_criteria[key].map((criteria) => (
                  <Typography className={classes.text} key={criteria}>
                    {mapRejectionCriteriaToLabel(criteria, appRegion)}
                  </Typography>
                ))}
            </Box>
          ))}
        </>
      )}

      {rejection.rejection_reason_tags && rejection.rejection_reason_tags.length > 0 && (
        <>
          <Typography className={classes.greySubtitle} style={{ marginTop: 16 }}>
            {t('pages.lead.sections.rejections.rejection_reason_tags')}
          </Typography>

          <Box className={classes.rejectionReasonsContainer}>
            {rejection.rejection_reason_tags.map((tag) => (
              <Chip
                key={tag}
                color="primary"
                label={mapRejectionReasonTagToLabel(tag)}
                className={classes.rejectionReasonOption}
              />
            ))}
          </Box>
        </>
      )}

      {!isPartner && (
        <>
          <Button
            variant="contained"
            onClick={toggleEdit}
            color="primary"
            disableElevation
            className={classes.editRejectionButton}
          >
            {t('pages.lead.admin.actions.editRejection')}
          </Button>

          <RejectModal open={edit} toggleOpen={toggleEdit} rejection={rejection} offerId={offerId} />
        </>
      )}
    </>
  );
};

export default Rejection;
