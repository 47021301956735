import { FC, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import debounce from 'lodash.debounce';

import { useApplications } from 'store/applications/hooks';
import { RangeNumberInput } from '../../RangeNumberInput';

const LoanAmountFilter: FC = () => {
  const {
    filters: { minLoanAmount, maxLoanAmount },
    updateApplicationsQuery,
  } = useApplications();

  const debouncedUpdateAppsQuery = useRef(debounce(updateApplicationsQuery, 1000)).current;

  useEffect(() => {
    return () => {
      debouncedUpdateAppsQuery.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeMinLoanAmount = (loanAmount: number) => {
    debouncedUpdateAppsQuery({ minLoanAmount: loanAmount });
  };

  const onChangeMaxLoanAmount = (loanAmount: number) => {
    debouncedUpdateAppsQuery({ maxLoanAmount: loanAmount });
  };

  return (
    <Box>
      <RangeNumberInput
        minValue={minLoanAmount}
        maxValue={maxLoanAmount}
        onChangeMinValue={onChangeMinLoanAmount}
        onChangeMaxValue={onChangeMaxLoanAmount}
        withPrefix
      />
    </Box>
  );
};

export default LoanAmountFilter;
