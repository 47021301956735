import { FC, useState, useMemo, ChangeEvent, useRef, useCallback, useEffect } from 'react';
import { Grid, CircularProgress, InputAdornment } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ExpandMore } from '@material-ui/icons';
import moment, { Moment } from 'moment';

import { searchCompanies as searchCompaniesApi, searchDirectors as searchDirectorsApi } from 'http/companies';
import { createPreAuthLink as createPreAuthLinkApi, createApplication as createApplicationApi } from 'http/admin';
import { AlertDialog } from 'components/AlertDialog';
import { CustomDatePicker, CustomRadioField, CustomSelect, CustomTextField } from 'components/inputs';
import { composeDirectorName, parseObject } from 'utils';
import {
  AdminCreateApplication,
  AreaPhoneCode,
  CompanyRegion,
  CompanySearchResult,
  CreateApplicant,
  CreateCompany,
} from 'core/types';
import { usePartners } from 'store/partners/hooks';
import { useAdmin } from 'store/admin/hooks';
import { useDebounce, useWidget } from 'hooks';
import { ApplicationRegion } from 'store/applications/types';
import { applicationRegionOptions } from 'core/constants';
import { mapAppRegionToCompanyRegion } from 'core/utils';
import { useApplications } from 'store/applications/hooks';
import useStyles from './CreateApplicationModal.styles';
import { SEARCH_DEBOUNCE_DELAY, formSchema, PhoneNumberInput, EINNumberInput, FormValidation } from './utils';

interface FormValues {
  partner_id: string;
  email: string;
  registered_name: string;
  director_id: string;
  first_name: string;
  last_name: string;
  formation_date: string;
  registered_number: string;
  company_status: string;
  region: string;
  phone_number: string;
  ein: string;
}

interface CreateApplicationModalProps {
  open: boolean;
  toggleOpen: () => void;
  sendLink?: boolean;
  partnerId?: string;
}

const CreateApplicationModal: FC<CreateApplicationModalProps> = ({ open, toggleOpen, sendLink = false, partnerId }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [options, setOptions] = useState<CompanySearchResult[]>([]);
  const [selectedOption, setSelectedOption] = useState<CompanySearchResult | null>(null);
  const [searchingDirectors, setSearchingDirectors] = useState(false);
  const [directors, setDirectors] = useState<CreateApplicant[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DEBOUNCE_DELAY);
  const classes = useStyles();
  const { t } = useTranslation();
  const { setError, setSuccess } = useAdmin();
  const { allPartners } = usePartners();
  const { openWidget } = useWidget();
  const { getApplications } = useApplications();

  const searchCompanies = useCallback(async () => {
    setLoadingOptions(true);
    try {
      const data = await searchCompaniesApi(debouncedSearchTerm);
      setOptions(data);
    } catch (err) {
      /** */
    }
    setLoadingOptions(false);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      searchCompanies();
    } else {
      setOptions([]);
    }
  }, [debouncedSearchTerm, searchCompanies]);

  useEffect(() => {
    if (directors?.length === 1) {
      const currentValues = formRef.current?.values;
      if (currentValues) {
        const { id, first_name, last_name } = directors[0];
        formRef.current?.setValues({
          ...currentValues,
          director_id: id ?? '',
          first_name: first_name ?? '',
          last_name: last_name ?? '',
        });
      }
    }
  }, [directors]);

  const initialValues: FormValues = {
    partner_id: partnerId ?? '',
    email: '',
    registered_name: '',
    director_id: '',
    first_name: '',
    last_name: '',
    formation_date: '',
    registered_number: '',
    company_status: '',
    region: '',
    phone_number: '',
    ein: '',
  };

  const searchDirectors = async (companyNumber: string) => {
    setSearchingDirectors(true);
    try {
      const data = await searchDirectorsApi(companyNumber);
      setDirectors(data.map((dir, idx) => ({ ...dir, id: `${Math.random().toString(36).slice(2, 9)}-${idx}` })));
    } catch (err) {
      /** */
    }
    setSearchingDirectors(false);
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
    setSelectedOption(null);
    setDirectors([]);
    setOptions([]);
  };

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const region = values.region as ApplicationRegion;

      const company: Partial<CreateCompany> = {
        registered_name: values.registered_name,
        formation_date: values.formation_date,
        region: mapAppRegionToCompanyRegion(region),
      };

      if (selectedOption?.companyAddress) {
        company.address = {
          ...selectedOption.companyAddress,
          country_code: selectedOption.companyAddress.country_code
            ? selectedOption.companyAddress.country_code
            : CompanyRegion.GB,
        };
      }

      let applicant: Partial<CreateApplicant> = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        addresses: [],
        applicant: true,
      };

      if (region === ApplicationRegion.UK) {
        company.registered_number = values.registered_number;
        company.type = selectedOption?.companyType;
      } else if (region === ApplicationRegion.USA) {
        company.ein = values.ein;
        applicant.phone_number = `${AreaPhoneCode.US}-${values.phone_number}`;
      } else if (region === ApplicationRegion.IRL) {
        company.registered_number = values.registered_number;
        applicant.phone_number = `${AreaPhoneCode.IE}-${values.phone_number}`;
      }

      const selectedDirector = directors.find((dir) => dir.id === values.director_id);

      if (selectedDirector) {
        const props = {
          nationality: 1,
          country_of_residence: 1,
          role: 1,
          occupation: 1,
          appointed_on: 1,
          resigned_on: 1,
          addresses: 1,
        };

        applicant = { ...parseObject(selectedDirector, props), ...applicant };
      }

      const directorsData =
        directors.reduce((dirs: Partial<CreateApplicant>[], { id, ...rest }) => {
          if (id !== values.director_id) {
            const addresses = rest.addresses.map((addr) => ({
              ...addr,
              country_code: addr.country_code ?? CompanyRegion.GB,
            }));
            return [...dirs, { ...rest, addresses }];
          }
          return dirs;
        }, []) ?? [];

      const payload: AdminCreateApplication = {
        partner_id: values.partner_id,
        company,
        applicant,
        directors: directorsData,
        region,
      };

      if (sendLink) {
        await createPreAuthLinkApi(payload);

        const message = t('pages.dashboard.addAdminActions.messages.createSuccess');
        setSuccess(message);
      } else {
        const data = await createApplicationApi(payload);
        openWidget(data.public_key, data.access_token, getApplications);
      }
      onCancel();
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  const handleDirectorChange = (value: string, values: FormValues, setValues: (values: FormValues) => void) => {
    const selectedDirector = directors.find((dir) => dir.id === value);
    if (selectedDirector) {
      const updatedValues = {
        ...values,
        director_id: selectedDirector.id ?? '',
        first_name: selectedDirector.first_name ?? '',
        last_name: selectedDirector.last_name ?? '',
      };
      setValues(updatedValues);
    }
  };

  const partnersOptions = useMemo(() => {
    const emptyOption = [{ label: '', value: '' }];
    if (allPartners.length > 0) {
      return emptyOption.concat(allPartners.map((partner) => ({ label: partner.name, value: partner.id })));
    }
    return emptyOption;
  }, [allPartners]);

  const companyDirectorsOptions = useMemo(() => {
    return directors.map((d) => ({
      value: d.id,
      label: composeDirectorName(d.last_name || '', d.first_name || ''),
    }));
  }, [directors]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      enableReinitialize
      innerRef={formRef}
      validateOnChange={false}
    >
      {({ values, errors, touched, submitCount, handleChange, handleSubmit, setFieldValue, setValues }) => {
        const requiredName = Boolean(errors.registered_name);
        const alreadyActiveApplication = /active application/i.test(errors.registered_number ?? '');
        const notActive = Boolean(errors.company_status);
        const isUKCompany = values.region === ApplicationRegion.UK;
        const isUSACompany = values.region === ApplicationRegion.USA;
        const isIrishCompany = values.region === ApplicationRegion.IRL;

        return (
          <AlertDialog
            open={open}
            dialogContentTitle={t(`pages.dashboard.addAdminActions.title.${sendLink ? 'send' : 'create'}`)}
            handleCancel={onCancel}
            handleConfirm={handleSubmit}
            loading={loading}
          >
            <Form noValidate>
              <FormValidation />

              <Grid container direction="column" spacing={4} className={classes.container}>
                <Grid item>
                  <Field
                    autoFocus
                    id="region"
                    fullWidth
                    component={CustomSelect}
                    options={applicationRegionOptions}
                    name="region"
                    value={values.region}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      setFieldValue('region', event.target.value);
                    }}
                    title={t('pages.dashboard.addAdminActions.inputs.region.label')}
                    className={classes.textInput}
                    disabled={loading}
                  />
                </Grid>

                {!partnerId && (
                  <Grid item>
                    <Field
                      id="partner_id"
                      fullWidth
                      component={CustomSelect}
                      options={partnersOptions}
                      name="partner_id"
                      value={values.partner_id}
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue('partner_id', event.target.value);
                      }}
                      title={t('pages.dashboard.addAdminActions.inputs.partner_id.label')}
                      className={classes.textInput}
                      disabled={loading}
                    />
                  </Grid>
                )}

                <Grid item>
                  <Field
                    id="email"
                    fullWidth
                    component={CustomTextField}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    title={t('pages.dashboard.addAdminActions.inputs.email.label')}
                    placeholder={t('pages.dashboard.addAdminActions.inputs.email.placeholder')}
                    className={classes.textInput}
                    disabled={loading}
                  />
                </Grid>

                <Grid item>
                  {isUKCompany ? (
                    <Autocomplete
                      id="registered_name"
                      value={selectedOption}
                      open={autoCompleteOpen}
                      onOpen={() => setAutoCompleteOpen(true)}
                      onClose={() => setAutoCompleteOpen(false)}
                      onChange={(e, value) => {
                        if (typeof value === 'string') return;
                        setSelectedOption(value);
                        if (value?.companyNumber) searchDirectors(value.companyNumber);
                        else setDirectors([]);
                        setValues({
                          ...values,
                          director_id: '',
                          first_name: '',
                          last_name: '',
                          registered_name: value?.title ?? '',
                          registered_number: value?.companyNumber ?? '',
                          formation_date: value?.incorporatedOn ?? '',
                          company_status: value?.companyStatus ?? '',
                        });
                      }}
                      onInputChange={(e, value) => setSearchTerm(value)}
                      options={options}
                      loading={loadingOptions}
                      getOptionSelected={(option, value) => option.title === value.title}
                      getOptionLabel={(option) => option.title}
                      renderInput={(props) => (
                        <Field
                          {...props}
                          name="registered_name"
                          component={CustomTextField}
                          title={t('pages.dashboard.addAdminActions.inputs.registered_name.label')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder={t('pages.dashboard.addAdminActions.inputs.registered_name.placeholder')}
                          error={alreadyActiveApplication || notActive || (touched.registered_name && requiredName)}
                          helperText={
                            (touched.registered_name && errors.registered_name) ?? alreadyActiveApplication
                              ? errors.registered_number
                              : errors.company_status
                          }
                          disabled={loading}
                        />
                      )}
                      className={classes.autoCompleteInput}
                      popupIcon={<ExpandMore className={classes.popupIcon} />}
                      freeSolo={debouncedSearchTerm === '' && options.length === 0}
                    />
                  ) : (
                    <Field
                      id="registered_name"
                      fullWidth
                      component={CustomTextField}
                      name="registered_name"
                      value={values.registered_name}
                      onChange={handleChange}
                      title={t('pages.dashboard.addAdminActions.inputs.registered_name.label')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t('pages.dashboard.addAdminActions.inputs.registered_name.placeholder')}
                      className={classes.textInput}
                      disabled={loading}
                    />
                  )}
                </Grid>

                {isUKCompany && (
                  <>
                    {searchingDirectors && (
                      <Grid item className={classes.loadingContainer}>
                        <CircularProgress size={25} />
                      </Grid>
                    )}

                    {values.registered_name && companyDirectorsOptions.length > 0 && (
                      <Grid item>
                        <Field
                          id="director_id"
                          aria-label="directors-choice"
                          component={CustomRadioField}
                          options={companyDirectorsOptions}
                          name="director_id"
                          value={values.director_id || ''}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleDirectorChange(event.target.value, values, setValues)
                          }
                          title={t('pages.dashboard.addAdminActions.inputs.director.label')}
                          className={classes.textInput}
                          disabled={loading}
                        />
                      </Grid>
                    )}
                  </>
                )}

                {isIrishCompany && (
                  <Grid item>
                    <Field
                      id="registered_number"
                      fullWidth
                      component={CustomTextField}
                      name="registered_number"
                      value={values.registered_number || ''}
                      onChange={handleChange}
                      title={t('pages.dashboard.addAdminActions.inputs.registered_number.label')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t('pages.dashboard.addAdminActions.inputs.registered_number.placeholder')}
                      className={classes.textInput}
                    />
                  </Grid>
                )}

                <Grid item>
                  <Field
                    id="first_name"
                    fullWidth
                    component={CustomTextField}
                    name="first_name"
                    value={values.first_name || ''}
                    onChange={handleChange}
                    title={t('pages.dashboard.addAdminActions.inputs.first_name.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t('pages.dashboard.addAdminActions.inputs.first_name.placeholder')}
                    className={classes.textInput}
                    disabled={loading}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="last_name"
                    fullWidth
                    component={CustomTextField}
                    name="last_name"
                    value={values.last_name || ''}
                    onChange={handleChange}
                    title={t('pages.dashboard.addAdminActions.inputs.last_name.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t('pages.dashboard.addAdminActions.inputs.last_name.placeholder')}
                    className={classes.textInput}
                    disabled={loading}
                  />
                </Grid>

                {!isUKCompany && (
                  <>
                    <Grid item>
                      <CustomDatePicker
                        name="formation_date"
                        label={t('pages.dashboard.addAdminActions.inputs.formation_date.label')}
                        value={values.formation_date ? moment(values.formation_date).toDate() : null}
                        onChange={(date: Moment | null) => {
                          setFieldValue('formation_date', date?.toDate().toISOString() ?? null);
                        }}
                        clearable
                        error={submitCount > 0 ? errors.formation_date : undefined}
                        openTo="date"
                        format="DD MMMM YYYY"
                        disabled={loading}
                      />
                    </Grid>

                    <Grid item>
                      <Field
                        id="phone_number"
                        fullWidth
                        component={CustomTextField}
                        name="phone_number"
                        value={values.phone_number}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('phone_number', event.target.value?.trim())
                        }
                        title={t('pages.dashboard.addAdminActions.inputs.phone_number.label')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {isUSACompany ? AreaPhoneCode.US : AreaPhoneCode.IE}
                            </InputAdornment>
                          ),
                          inputComponent: PhoneNumberInput,
                        }}
                        className={classes.textInput}
                        disabled={loading}
                      />
                    </Grid>
                  </>
                )}

                {isUSACompany && (
                  <Grid item>
                    <Field
                      id="ein"
                      fullWidth
                      component={CustomTextField}
                      name="ein"
                      value={values.ein}
                      onChange={handleChange}
                      title={t('pages.dashboard.addAdminActions.inputs.ein.label')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputComponent: EINNumberInput,
                      }}
                      className={classes.textInput}
                      disabled={loading}
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          </AlertDialog>
        );
      }}
    </Formik>
  );
};

export default CreateApplicationModal;
