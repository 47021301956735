import { FC, useMemo, useEffect, useCallback } from 'react';
import { Link, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TabRoute } from 'core/types';

import routes from 'router/routes';
import { useAuth } from 'store/auth/hooks';
import { Reminders } from 'pages/reminders';
import { Account } from 'pages/account';
import { RulesEngine } from '../rules-engine';
import { Team } from './Team';
import { Faqs } from './Faqs';
import useStyles from './Settings.styles';

const Settings: FC = () => {
  const classes = useStyles();
  const { isAdmin, isPartner, isLender } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const tabs: TabRoute[] = useMemo(() => {
    if (isAdmin) {
      return [
        { path: routes.rulesEngine, label: t('global.rulesEngine'), Component: RulesEngine },
        { path: routes.faqs, label: t('global.faqs'), Component: Faqs },
        { path: routes.reminders, label: t('global.reminders'), Component: Reminders },
      ];
    }
    if (isPartner) return [{ path: routes.team, label: t('global.team'), Component: Team }];
    if (isLender)
      return [
        { path: routes.team, label: t('global.team'), Component: Team },
        { path: routes.account, label: t('global.account'), Component: Account },
      ];
    return [];
  }, [isAdmin, isPartner, isLender, t]);

  const navToFirstTab = useCallback(() => {
    const initialTab = tabs[0];
    if (initialTab) {
      history.replace(initialTab.path);
    }
  }, [history, tabs]);

  useEffect(() => {
    if (location.pathname === routes.settings) {
      navToFirstTab();
    }
  }, [location.pathname, navToFirstTab]);

  return (
    <Box className={classes.root}>
      <Tabs value={location.pathname} orientation="vertical" variant="fullWidth" className={classes.tabs}>
        <Typography className={classes.subtitle}>{t('global.settings')}</Typography>
        {tabs.map((route) => (
          <Tab
            key={route.path}
            label={route.label}
            component={Link}
            value={route.path}
            to={route.path}
            className={classes.tab}
          />
        ))}
      </Tabs>

      <Box paddingLeft="20px">
        <Switch>
          {tabs.map((tab) => (
            <Route key={tab.path} path={tab.path} component={tab.Component} />
          ))}
        </Switch>
      </Box>
    </Box>
  );
};

export default Settings;
