import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      marginLeft: theme.spacing(2),
    },
    sectionTitle: {
      fontSize: theme.typography.pxToRem(15),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    submitButton: {
      marginTop: theme.spacing(2),
      alignSelf: 'flex-end',
    },
    grid: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    profileContainer: {
      marginBottom: theme.spacing(2),
    },
    horizontalItemKey: {
      minWidth: 130,
      marginRight: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.greyText.main,
    },
    horizontalItemValue: {
      paddingLeft: theme.spacing(1),
      maxWidth: '100%',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      textAlign: 'left',
      wordWrap: 'break-word',
    },
    horizontalDetailsDivider: {
      height: 1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);
