import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    title: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      paddingBottom: theme.spacing(2),
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      paddingBottom: theme.spacing(2),
    },
    subtitle2: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    text: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      paddingBottom: theme.spacing(2),
    },
    sectionHeader: {
      paddingBottom: '0px !important',
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',

      '& button': {
        marginRight: theme.spacing(1),
      },

      '& button:last-child': {
        marginRight: 0,
      },
    },
    keysContainer: {
      padding: theme.spacing(3),
    },
    alignLeft: { textAlign: 'left' },
    gutterBottom: {
      marginBottom: theme.spacing(3),
    },
  }),
);
