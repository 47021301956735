import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),

      '& .MuiGrid-item': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    textInput: {
      minWidth: '100%',

      '& .MuiInputBase-root': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700,
        height: 36,
      },

      '& .Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
      },

      '& .Mui-error': {
        color: theme.palette.error.main,
        marginLeft: 0,
        marginRight: 0,
      },

      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
  }),
);
