import { FC } from 'react';
import { FormLabel, RadioGroup, Box, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';
import { FieldProps } from 'formik';

import { CustomLabel } from '../CustomLabel';
import useStyles from './CustomRadioField.styles';

interface CustomRadioFieldProps extends FieldProps {
  title: string;
  options: { label: string; value: string }[];
  tooltipMessage?: string;
  error?: boolean;
  helperText?: string;
}

const CustomRadioField: FC<CustomRadioFieldProps> = ({
  form,
  field,
  title,
  options,
  tooltipMessage,
  error,
  helperText,
  ...rest
}) => {
  const classes = useStyles();

  const hasError = !!(form.touched[field.name] && form.errors[field.name]) || error;

  return (
    <Box>
      <FormLabel component={() => <CustomLabel title={title} tooltipMessage={tooltipMessage} />} />

      <RadioGroup {...rest}>
        {options.map((option) => (
          <FormControlLabel
            key={`${option.value}`}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
            className={classes.controlLabel}
          />
        ))}
      </RadioGroup>

      {hasError && <FormHelperText error>{(form.errors[field.name] || helperText) as string}</FormHelperText>}
    </Box>
  );
};

export default CustomRadioField;
