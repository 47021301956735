import { FC, useCallback, useState } from 'react';
import { Grid, Typography, CircularProgress, Box } from '@material-ui/core';
import clsx from 'clsx';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import useStyles from './FileDrop.styles';

// 20MB -> in bytes
const maxFileSize = 20000000;

interface AllowedFiletype {
  name: string;
  mimeType: string;
  extension: string;
}

interface FileDropProps {
  allowedFileTypes: Array<AllowedFiletype>;
  onUpload(documents: File[]): void;
  onReject(rejections: FileRejection[]): void;
  uploadMessage: string;
  validationError?: boolean | string;
}

const FileDrop: FC<FileDropProps> = ({ allowedFileTypes, onUpload, onReject, uploadMessage, validationError }) => {
  const [loading, setLoading] = useState(false);
  const [docTypeError, setDocTypeError] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setLoading(true);
      if (fileRejections.length) {
        onReject(fileRejections);
        setDocTypeError(true);
      } else {
        onUpload(acceptedFiles);
      }
      setLoading(false);
    },
    [onReject, onUpload],
  );

  const error = (docTypeError ? t('global.uploadDocumentValidation') : false) || validationError;

  return (
    <Dropzone
      onDrop={(acceptedFiles, fileRejections) => onDrop(acceptedFiles, fileRejections)}
      maxSize={maxFileSize}
      accept={allowedFileTypes.map((fileType) => fileType.mimeType)}
      disabled={loading}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <section className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box {...getRootProps()} className={clsx([classes.outer, error && classes.outerError])}>
                <Box className={loading ? clsx([classes.inner, classes.disabled]) : classes.inner}>
                  <input {...getInputProps()} />

                  <Typography className={classes.uploadMessage}>{uploadMessage}</Typography>
                </Box>

                {loading && <CircularProgress className={classes.progress} />}
              </Box>

              {error && (
                <Typography className={classes.errorMessage}>
                  {typeof error === 'string' ? error : t('global.error')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </section>
      )}
    </Dropzone>
  );
};

export default FileDrop;
