import { FC, ChangeEvent, useState } from 'react';
import { Checkbox, Box, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { checkReminder as checkReminderApi } from 'http/reminders';
import { Reminder } from 'store/reminders/types';
import { useReminders } from 'store/reminders/hooks';
import { useAuth } from 'store/auth/hooks';
import useStyles from './ReminderCheckbox.styles';

interface ReminderCheckboxProps {
  reminder: Reminder;
}

const ReminderCheckbox: FC<ReminderCheckboxProps> = ({ reminder }) => {
  const [loading, setLoading] = useState(false);
  const { setError, setSuccess, updateReminder } = useReminders();
  const classes = useStyles();
  const { t } = useTranslation();
  const { uid: currentUserId } = useAuth();

  const disabled = currentUserId !== reminder.assignee.id;

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    setLoading(true);
    try {
      const updatedDone = event.target.checked;
      await checkReminderApi(reminder.id, updatedDone);
      updateReminder({ id: reminder.id, done: updatedDone });
      setSuccess(t('pages.reminders.messages.success') as string);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  return (
    <Box className={classes.container}>
      <Checkbox checked={reminder.done} onChange={handleChange} color="primary" disabled={disabled} />

      {loading && (
        <Box className={classes.loaderContainer}>
          <CircularProgress size={30} />
        </Box>
      )}
    </Box>
  );
};

export default ReminderCheckbox;
