import { makeStyles, createStyles } from '@material-ui/core/styles';
import theme from 'core/theme-extended';

export default makeStyles(() =>
  createStyles({
    tableStyle: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px', // Add spacing above the table
    },

    thStyle: {
      border: '1px solid #ddd',
      backgroundColor: '#f2f2f2',
      padding: '8px',
      textAlign: 'left',
    },

    tdStyle: {
      border: '1px solid #ddd',
      padding: '8px',
      textAlign: 'left',
    },

    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    },

    icon: {
      color: theme.palette.common.black,
      width: 20,
      height: 20,
      marginLeft: 4,
      marginRight: 4,
    },
  }),
);
