import { FC } from 'react';
import { List, ListItem, ListItemText, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { countryCodeToContryName } from 'core/constants';
import { decimalToPercentage, getKeyStringValue, parseMoney } from 'utils';
import { mapAddressStatusToLabel, mapAppRegionToSupportedCurrency } from 'core/utils';
import { ApplicationRegion } from 'store/applications/types';
import useStyles from '../LeadDetails/LeadDetails.styles';

const dateFormatRegex = new RegExp(/\d{4}-\d{2}-\d{2}/);

interface DataListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entity: Record<string, any>;
  translationKey?: string;
  appRegion: ApplicationRegion;
}

const DataList: FC<DataListProps> = ({ entity, translationKey, appRegion }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <List>
      {Object.keys(entity).map((key: string) => {
        let dataValue = entity[key];

        if ((typeof dataValue !== 'boolean' && !dataValue) || key === 'id') return null;

        if (key === 'sic_codes') {
          dataValue = (dataValue ?? []).join(', ');
          if (!dataValue) return null;
        }

        if (dataValue === true) dataValue = t('global.yes');
        else if (dataValue === false) dataValue = t('global.no');
        else if (key === 'country_code') {
          dataValue = `${countryCodeToContryName[getKeyStringValue(entity, key)] ?? ''} (${entity[key]})`;
        } else if (key.includes('percentage')) {
          dataValue = decimalToPercentage(dataValue);
        } else if (key.includes('revenue') || key.includes('amount') || key === 'credit_limit') {
          dataValue = parseMoney(dataValue, mapAppRegionToSupportedCurrency(appRegion));
        } else if (dateFormatRegex.test(dataValue)) {
          if (moment().diff(moment(dataValue), 'days') === 0) {
            dataValue = 'Present';
          } else {
            dataValue = moment(dataValue).format('DD/MM/YYYY');
          }
        } else if (translationKey === 'address' && key === 'status') {
          dataValue = mapAddressStatusToLabel(dataValue);
        }

        return (
          <ListItem className={classes.horizontalItem} key={key} alignItems="flex-start">
            <ListItemText className={classes.horizontalItemKey}>
              {t(`pages.lead.sections.${translationKey}.${key}`)}
            </ListItemText>
            <p />

            <ListItemText className={classes.horizontalItemValue}>
              {key === 'phone_number' && <Link href={`tel:${dataValue}`}>{dataValue}</Link>}

              {key === 'email' && <Link href={`mailto:${dataValue}`}>{dataValue}</Link>}

              {key !== 'phone_number' && key !== 'email' && String(dataValue)}
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

export default DataList;
